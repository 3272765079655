<template>
	<div id="dashboard-page"
		dusk="dashboard-page"
		class="home">
		<div id="dashboard-page-section"
			class="page-section">
			<h1>Hello {{ user.first_name }}</h1>

			<div v-if="user.groups">
				<h2>Groups</h2>
				<div v-for="(group, index) in user.groups"
					:key="index">
					<group-thumbnail :group="group" />
				</div>
			</div>

			<div v-if="user.classrooms">
				<h2>Classrooms</h2>
				<div v-for="(classroom, index) in user.classrooms"
					:key="index">
					<classroom-thumbnail :classroom="classroom" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";
import groupThumbnail from "./groups/groupThumbnail";
import classroomThumbnail from "./classrooms/classroomThumbnail";

export default {
	name: "Dashboard",

	components: {
		groupThumbnail,
		classroomThumbnail,
	},

	data() {
		return {};
	},

	computed: {

		...mapState({
			user: state => state.auth,
		})

	},

	mounted() {
	},

	methods: {}
};
</script>


<style lang="scss" scoped>
#dashboard-page {
	#dashboard-page-section {
		background: none;
	}
}
</style>
