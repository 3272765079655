<template>
	<div>
		<ul
			class="pagination">
			<li class="page-item">
				<a
					v-if="links.prev != null"
					class="page-link"
					@click="$emit('prev', links.prev)">Previous</a>
			</li>
			<li class="page-item">
				<a
					v-if="links.first != null"
					class="page-link"
					@click="$emit('first', links.first)">First</a>
			</li>
			<li class="page-item">
				<a
					class="page-link disabled"
					disabled
				>{{ `Page ${meta.current_page} of ${meta.last_page}` }}</a>
			</li>
			<li class="page-item">
				<a
					v-if="links.last != null"
					class="page-link"
					@click="$emit('last', links.last)">Last</a>
			</li>
			<li class="page-item">
				<a
					v-if="links.next != null"
					class="page-link"
					@click="$emit('next', links.next)">Next</a>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	props: {
		links: {
			type: Object,
			default: () => {
			},
		},
		meta: {
			type: Object,
			default: () => {
			}
		}
	},
};
</script>

<style>

</style>
