<template>
	<div class="page-section" style="margin-top: 80px;">
		<div class="container">
			<br>
			<div class="row">
				<div class="col-md-12">
					<h4>Explore L.I.T</h4>
				</div>
			</div>
			<br>
			<!-- search bar -->
			<div class="row">
				<div class="col-md-12">
					<div class="input-group">
						<input ref="searchLessonValue"
							class="form-control"
							placeholder="Search Lessons"
							type="text">
						<div class="input-group-append">
							<button class="btn btn-success"
								@click="searchLessons">
								Search
							</button>
						</div>
						<br>
					</div>
				</div>
			</div>
			<br>
			<!-- lesson grid -->
			<div class="row">
				<div class="col-md-12">
					<div v-if="lessons !== null && loading === false">
						<div :class="classes1">
							<div :id="'hscroll' + _uid"
								:class="classes2">
								<div v-for="(lesson,index) in lessons"
									:key="index"
									:class="classes3">
									<div class="lesson-thumbnail card"
										@click="viewLesson(lesson)">
										<div :style="{ backgroundImage: 'url(' + lesson.cover + ')'}"
											class="lesson-image text-left">
											<div class="overlay" />
											<div class="hover-content">
												<i class="fas fa-play fa-lg play-icon" />
											</div>
											<i
												:class="getLessonIcon(lesson)"
												:title="lesson.type"
												class="type-icon fas"
												data-toggle="tooltip" />
											<p
												class="classification">
												{{ lesson.classification }}
											</p>
										</div>
										<div class="lesson-details text-left">
											<h5
												class="title pl-2">
												{{ lesson.title }}
											</h5>
											<p class="excerpt pl-2">
												{{ lesson.focus }}
											</p>
										</div>
										<div class="clearfix">
											<div class="float-left">
												<button
													class="btn btn-primary btn-sm assign_button ml-2"
													type="button"
													@click="viewLesson(lesson)">
													<i class="fas fa-play-circle" /> View Lesson
												</button>
											</div>
											<div class="float-right" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-else-if="loading === true"
						class="text-center">
						<div class="spinner-border text-muted" />
					</div>
					<div v-else>
						<p>No Data</p>
					</div>
				</div>
			</div>
			<br>

			<!-- pagination -->
			<div class="row">
				<div class="col-md-12">
					<div class="clearfix">
						<span class="float-left" />
						<span class="float-right">
							<pagination v-if="links && meta"
								:links="links"
								:meta="meta"
								@first="(url) => fetchLessons(url)"
								@last="(url) => fetchLessons(url)"
								@next="(url) => fetchLessons(url)"
								@prev="(url) => fetchLessons(url)" />
						</span>
					</div>
				</div>
			</div>
		</div>
		<lesson-detail-modal :lesson="lessonData"
			:visible="modalVisible"
			@hide="modalVisible = false" />
	</div>
</template>

<script>
import axiosDebounce from "../../utility/debounce";
import LessonDetailModal from "./lessonDetailModal";
import pagination from "../../components/utility/pagination.vue";
import {EventActionEnum, EventCategoryEnum, EventLabelEnum} from "../../config/enums";

export default {
	name: "Explore",

	components: {
		LessonDetailModal,
		pagination
	},
	data() {
		return {
			lessons: null,
			lessonData: null,
			modalVisible: false,
			links: null,
			meta: null,
			loading: true,
			type_icon: "",
			classes1: "page-section",
			classes2: "row",
			classes3: "col-md-6 col-lg-4 col-xl-3 text-center",
		};
	},

	computed: {
		lessonsPerPage: function () {
			return this.$route.query.lessonsPerPage ? this.$route.query.lessonsPerPage : 16;
		}
	},

	async mounted() {
		await this.fetchLessons();
	},

	methods: {
		async fetchLessons(url) {
			try {
				this.loading = true;
				const response = await axios.get(url != null ? url : "/api/public/lessons");
				const {status, data, links, meta} = response;
				this.loading = false;
				if (status) {
					this.lessons = data;
					this.links = links;
					this.meta = meta;
					// log with GA
					this.$gtag.event(EventActionEnum.View, {
						"event_category": EventCategoryEnum.Guest,
						"event_label": EventLabelEnum.Lessons,
						"value": "Explore Lessons"
					});
				} else {
					toastr["error"]("unable to fetch lessons");
				}
			} catch (error) {
				console.log(error);
				this.loading = false;
				toastr["error"]("unable to fetch lessons");
			}
		},

		async searchLessons() {
			try {
				this.loading = true;
				let response;
				const value = this.$refs.searchLessonValue.value;
				if (value != null && value !== "") {
					response = await axiosDebounce({
						method: "get",
						url: `/api/public/search/lesson/${this.$refs.searchLessonValue.value}`,
						timeout: 60000
					});
				} else {
					response = await axios.get("/api/public/lessons");
				}
				const {status, data, links, meta} = response;
				this.loading = false;
				if (status) {
					this.lessons = data;
					this.loading = false;
					this.links = links;
					this.meta = meta;
				} else {
					toastr["error"]("Could not find a lesson");
				}
			} catch (error) {
				this.loading = false;
				toastr["error"]("Could not find a lesson");
			}

		},

		getLessonIcon(lesson) {
			if (lesson) {
				switch (lesson.type) {
				case "Comic":
					return "fa-book-reader";
				case "Video":
					return "fa-video";
				case "Article":
					return "fa-file-alt";
				case "Activity":
					return "fa-paste";
				default:
					return "";
				}
			}
		},

		viewLesson(lesson) {
			this.lessonData = lesson;
			this.modalVisible = true;
		}

	}
};
</script>

<style lang="scss" scoped>

.lessonScroll {
	position: relative;
	padding: $page-section-padding;
	background-color: white;

	.lesson-hscroll {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;

		.hscroll-button {
			position: absolute;
			// Height of each image in panel is known to be 150 px else top would be 50% for true center
			transform: translateY(calc($lesson-thumb-image-height / 4));

			&.left {
				left: 0;
			}

			&.right {
				right: 0;
			}
		}

		.thumbnail {
			margin-right: 10px;
			margin-left: 10px;
		}

		&::-webkit-scrollbar {
			width: 0.5px; /* remove scrollbar space */
			background: transparent; /* optional: just make scrollbar invisible */
		}

		/* optional: show position indicator in red */
		&::-webkit-scrollbar-thumb {
			display: none;
			background: $theme;
			background-clip: content-box;
			border: 6px solid transparent;
			border-radius: 9px;
		}

		&:hover {
			&::-webkit-scrollbar-thumb {
				display: block;
			}
		}
	}

	.view-all {
		text-align: right;

		a {
			color: $white;
		}
	}
}

</style>

<style lang="scss" scoped>
.lesson-thumbnail {
	position: relative;
	display: inline-block;
	flex: 0 0 auto;
	width: 220px;
	height: 300px;
	margin-bottom: 15px;
	cursor: pointer;

	.type-icon {
		margin: 15px;
		font-size: 20px;
		color: white;

		&::before {
			text-shadow: 3px 3px 16px #272634;
		}
	}

	&.playlist-thumbnail .play-icon {
		margin-top: 50px;
	}

	.classification {
		position: relative;
		top: -50px;
		left: 135px;
		width: 34%;
		padding: 2px;
		margin: auto 10px;
		font-size: 10px;
		font-weight: 600;
		color: #ffffff;
		text-align: center;
		text-transform: uppercase;
		background-color: $theme;
	}

	.lesson-image {
		width: 100%;
		height: $lesson-thumb-image-height;
		margin-bottom: 10px;
		background-size: cover;

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: $lesson-thumb-image-height;
			background-color: #000000;
			border: $active-lesson-image-border;
			border-radius: 5px;
			opacity: 0;
		}

		.hover-content {
			z-index: 10;
			display: none;
			margin: 40px;
			overflow: hidden;
			text-align: center;
		}
	}

	.lesson-details {
		.title {
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.excerpt {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			font-size: 12.5px;
		}
	}

	&:hover {
		.lesson-image {
			border: $active-lesson-image-border;

			.overlay {
				opacity: 0.4;
				transition: opacity 0.25s ease-in;
			}

			.hover-content {
				display: block;
			}
		}

		.classification {
			/* left: 132px; */
			display: none;
		}

		.type-icon {
			display: none;
		}
	}
}
</style>

