import { storeExpiry } from "./encrypt";
import axios from "axios";

export default {
	async refreshToken() {
		const response = await axios.put("/api/auth/refresh-token");
		storeExpiry("auth_token", response.data.token, true);
		axios.defaults.headers.common[
			"Authorization"
		] = `Bearer ${response.data.token}`;
	},

	check() {
		return axios
			.post("/api/auth/check")
			.then(({ data }) => {
				return data.authenticated;
			})
			.catch((error) => {
				return error.status;
			});
	},

	getFilterURL(data) {
		let url = "";
		$.each(data, function (key, value) {
			url += value ? "&" + key + "=" + encodeURI(value) : "";
		});
		return url;
	},

	formAssign(form, data) {
		for (let key of Object.keys(form)) {
			if (
				key !== "originalData" &&
				key !== "errors" &&
				key !== "autoReset"
			) {
				form[key] = data[key];
			}
		}
		return form;
	},

	formatDate(date) {
		if (!date) return;

		return moment(date).format("MMMM Do YYYY");
	},

	formatDateTime(date) {
		if (!date) return;

		return moment(date).format("MMMM Do YYYY h:mm a");
	},

	ucword(value) {
		if (!value) return;

		return value.toLowerCase().replace(/\b[a-z]/g, function (value) {
			return value.toUpperCase();
		});
	},

	splitText(text) {
		if (text) {
			let lines = text
				.split(/\r?\n|<br>|<br\s*?\/\s*?>|<br\s*?\\s*?>/)
				.filter((val) => {
					return val && val.trim().length != 0;
				});

			let newText = "";
			for (let line of lines) {
				newText += "<p>" + line + "</p>";
			}

			return newText;
		}
	},

	fallbackCopyTextToClipboard(text) {
		return new Promise((resolve, reject) => {
			const textArea = document.createElement("textarea");
			textArea.value = text;
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();

			try {
				const successful = document.execCommand("copy");
				document.body.removeChild(textArea);
				return resolve(successful);
			} catch (err) {
				document.body.removeChild(textArea);
				return reject(false);
			}
		});
	},

	copyTextToClipboard(text) {
		if (!navigator.clipboard) {
			return this.fallbackCopyTextToClipboard(text).then((data) => data);
		} else {
			return navigator.clipboard
				.writeText(text)
				.then(() => {
					return true;
				})
				.catch((e) => {
					return false;
				});
		}
	},

	isSameURL(a, b) {
		return a.split("?")[0] === b.split("?")[0];
	},

	stripHtml(htmlString) {
		return $("<div>").html(htmlString).text();
	},

	paginationTotalPages(last, per_page, total) {
		if (last === 1) {
			return 1;
		} else {
			return (per_page / total) % 10;
		}
	},

	deepClone(data) {
		return JSON.parse(JSON.stringify(data));
	},

	generateQueryString(queryObject) {
		return Object.keys(queryObject)
			.map(
				(key) =>
					encodeURIComponent(key) +
					"=" +
					encodeURIComponent(queryObject[key])
			)
			.join("&");
	},
};
