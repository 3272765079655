<template>
	<div>
		<div class="page">
			<div class="row">
				<div class="col-md-6">
					<!-- page title -->
					<h3>{{ pageTitle }}</h3>
				</div>
			</div>
			<div>
				<!-- breadcrumb links -->
				<b-breadcrumb :items="breadcrumbitems"/>
			</div>
			<div style="padding-top: 20px; background: white;">
				<div class="row">
					<div class="col-md-12 lesson-text">
						<form enctype="multipart/form-data"
							  @submit.prevent="submitPrevent">
							<!-- Announcement title -->
							<div class="col-md-12 form-group">
								<label class="control-label"
									   for="title">Title</label>
								<input
									id="title"
									v-model="announcementForm.title"
									v-validate
									class="form-control"
									name="title"
									type="text">
							</div>

							<!-- Announcement message -->
							<div class="col-md-12 form-group">
								<label class="control-label"
									   for="title">Message</label>
								<quill
									ref="richEditor"
									v-model="announcementForm.message"
									:bus="EventBus"
									:config="config"
									output="html"/>
							</div>


							<!-- Announcement type -->
							<div class="col-md-12 form-group">
								<label
									class="control-label">Audience</label>
								<multiselect
									id="audience"
									v-model="announcementForm.audience_type"
									v-validate
									:allow-empty="false"
									:options="audience_types"
									:searchable="false"
									:show-labels="false"
									name="type"
									placeholder="Select Audience"/>
							</div>

							<!-- Announcement Group -->
							<div
								v-if="announcementForm.audience_type != null && announcementForm.audience_type !== AudienceTypeEnum.Global"
								class="col-md-12 form-group">
								<label
									class="control-label">Group</label>
								<multiselect
									id="group"
									v-model="selectedGroup"
									v-validate
									:allow-empty="false"
									:options="groupsList"
									:searchable="false"
									:show-labels="false"
									label="name"
									name="groups"
									placeholder="Select Groups"
									track-by="id"/>
							</div>

							<!-- Announcement Audience -->
							<div v-if="announcementForm.audience_type === AudienceTypeEnum.Classroom"
								 class="col-md-12 form-group">
								<label
									class="control-label">Classroom</label>
								<multiselect
									id="classroom"
									v-model="selectedClassroom"
									v-validate
									:allow-empty="false"
									:options="classroomList"
									:searchable="false"
									:show-labels="false"
									label="name"
									name="classrooms"
									placeholder="Select Classroom"
									track-by="id"/>
							</div>

							<!-- action buttons -->
							<div class="col-md-12 form-group">
								<button
									v-if="changed || !announcement_id"
									:disabled="!(errors.items.length <= 0) || isSaving"
									class="btn btn-success"
									type="button"
									@click="saveAnnouncement">
									<i v-if="isSaving"
									   class="fas fa-circle-notch fa-spin"/>
									<span>Save</span>
								</button>
								<button v-if="changed"
										class="btn btn-warning"
										type="button"
										@click="discardChanges">
									<span>Discard</span>
								</button>
								<button v-if="announcement_id"
										class="btn btn-danger"
										type="button"
										@click="deleteAnnouncement">
									<i v-if="isDeleting"
									   class="fas fa-circle-notch fa-spin"/>
									<span>Delete</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import EventBus from "../../utility/eventbus";
import {AudienceTypeEnum} from "../../config/enums";
import helper from "../../utility";
import axios from "axios";

export default {
	name: "Announcements",
	components: {
		Multiselect,
	},
	data() {
		return {
			breadcrumbitems: [
				{
					text: "Announcements",
					href: "#"
				},
				{
					text: "All",
					active: true
				}
			],
			pageTitle: "",
			announcement_id: null,
			EventBus,
			inputText: "",
			config: {
				readOnly: false,
				strict: false,
				modules: {
					toolbar: [
						["bold", "italic", "underline", "strike", "blockquote"],
						[
							{header: "1"},
							{header: "2"},
							{header: [3, 4, 5, 6]},
							{font: []}
						],
						[{size: []}],
						[{color: []}, {background: []}],
						[{list: "ordered"}, {list: "bullet"}],
						[{script: "sub"}, {script: "super"}],
						[{indent: "-1"}, {indent: "+1"}],
						["link", "image", "video"],
						["code-block"],
						["clean"]
					]
				}
			},
			AudienceTypeEnum: AudienceTypeEnum,
			audience_types: [
				AudienceTypeEnum.Classroom,
				AudienceTypeEnum.Group,
				AudienceTypeEnum.Teacher,
				AudienceTypeEnum.Student,
			],
			announcementForm: {
				id: "",
				author_id: "",
				title: "",
				message: "",
				group_id: "",
				audience_type: "",
				audience_id: "",
			},
			announcement: {
				id: "",
				author_id: "",
				title: "",
				message: "",
				group_id: "",
				audience_type: "",
				audience_id: "",
			},
			isSaving: false,
			isDeleting: false,
			isLoadingAnnouncement: false,
			groupsList: [],
			selectedGroup: null,
			classroomList: [],
			selectedClassroom: null,
			request_id: null,
			changed: false,
		};
	},
	computed: {
		userType() {
			return this.$store.getters["auth/getUserType"];
		},
		user() {
			return this.$store.getters["auth/getAuthUser"];
		},
	},
	watch: {
		inputText: {
			handler(val) {
				if (val !== "") {
					this.inputText = "";
					this.EventBus.$emit("set-html", val);
				}
			},
			deep: true
		},
		selectedGroup: {
			handler(val) {
				if (val != null) {
					this.fetchClassrooms(val.id);
				}
			},
			deep: true
		},
		announcementForm: {
			handler(val) {
				this.changed = JSON.stringify(val) !== JSON.stringify(this.announcement);
			},
			deep: true,
		}
	},
	beforeMount() {
		if (this.userType === "admin" || this.userType === "super-admin") {
			this.audience_types.push(AudienceTypeEnum.Global);
		}
		if (this.$route.params.announcement_id === "new") {
			this.pageTitle = "Create new announcement";
			this.breadcrumbitems[1].text = "New";
			this.fetchGroups();
		} else {
			this.announcement_id = this.$route.params.announcement_id;
			this.fetchData(this.$route.params.announcement_id);
		}
	},
	methods: {
		submitPrevent(e) {
			return e.preventDefault();
		},
		async fetchAnnouncement(announcement_id) {
			try {
				const {data} = await axios.get(`/api/notifications/${announcement_id}`);
				const {
					id,
					author_id,
					title,
					message,
					group_id,
					audience_type,
					audience_id, group, audience
				} = data;

				this.announcement = helper.deepClone({
					id,
					author_id,
					title,
					message,
					group_id,
					audience_type,
					audience_id, group, audience
				});
				this.announcementForm = helper.deepClone({
					id,
					author_id,
					title,
					message,
					group_id,
					audience_type,
					audience_id, group, audience
				});

				if (title) {
					this.pageTitle = `${title}`;
					this.breadcrumbitems[1].text = title;
					this.inputText = message;
					if (group) {
						this.selectedGroup = {
							name: group.name,
							id: group.id
						};
					}
					if (audience) {
						this.selectedClassroom = {
							name: audience.name,
							id: audience.id,
						};
						await this.fetchClassrooms(group.id);
					}
					audience_type == null ? this.announcementForm.audience_type = AudienceTypeEnum.Global : null;
				} else {
					this.pageTitle = "Untitled";
					this.breadcrumbitems[1].text = "Untitled";
				}

			} catch (e) {
				toastr["error"]("unable to fetch announcement");
			}
		},
		async fetchClassrooms(group_id) {
			try {
				const {data} = await axios.get(`/api/group/${group_id}/classrooms`);
				this.classroomList = data;
			} catch (e) {
				console.log("unable to fetch classrooms");
			}

		},
		async fetchGroups(user_id) {
			try {
				const {data} = await axios.get(`/api/user/${this.user.id}/groups`);
				this.groupsList = data;
			} catch (e) {
				console.log("unable to fetch groups");
			}
		},
		async fetchData(id) {
			await Promise.all([
				this.fetchAnnouncement(id),
				this.fetchGroups(this.user.id),
			]);
		},
		clear() {
			this.EventBus.$emit("set-content", "");
			window.toastr["success"]("Editor Cleared");
		},
		saveAnnouncement() {
			this.isSaving = true;

			if (this.selectedGroup) {
				this.announcementForm.group_id = this.selectedGroup.id;
			}

			if (this.selectedClassroom) {
				this.announcementForm.audience_id = this.selectedClassroom.id;
			}

			if (this.announcementForm.audience_type === AudienceTypeEnum.Global || this.announcementForm.audience_type == null) {
				this.announcementForm.audience_type = null;
				this.announcementForm.audience_id = null;
				this.announcementForm.group_id = null;
			}

			this.announcementForm.author_id = this.user.id;

			if (this.$route.params.announcement_id === "new") {
				// save as new announcement
				delete this.announcementForm.id;

				axios
					.post("/api/notifications", this.announcementForm)
					.then(({data}) => {
						toastr["success"]("Save successful");
						this.isSaving = false;
						this.$router.go(-1);
					})
					.catch(({message}) => {
						this.isSaving = false;
						toastr["error"](message);
					});
			} else {
				axios
					.put(`/api/notifications/${this.announcement_id}`, this.announcementForm)
					.then(({data, message}) => {
						toastr["success"]("Save successful");
						this.isSaving = false;
						this.$router.go(0);
					})
					.catch(({message}) => {
						this.isSaving = false;
						toastr["error"](message);
					});
			}
		},
		async viewAnnouncement(id) {
			if (this.userType !== "admin" || this.userType !== "super-admin") {
				return this.$router.push({
					path: `/principal/announcements/${id}`
				});
			}
			return this.$router.push({
				path: `/admin/announcements/${id}`,
			});
		},
		discardChanges() {
			if (this.$route.params.announcement_id === "new") {
				this.announcementForm = {
					id: "",
					author_id: "",
					title: "",
					message: "",
					group_id: "",
					audience_type: "",
					audience_id: "",
				};
				this.inputText = "";
				this.clear();
			} else {
				const {
					id,
					author_id,
					title,
					message,
					group_id,
					audience_type,
					audience_id,
					group,
					audience,
				} = this.announcement;
				this.announcementForm = {
					id,
					author_id,
					title,
					message,
					group_id,
					audience_type,
					audience_id,
					group,
					audience,
				};

				if (group) {
					this.selectedGroup = {
						name: group.name,
						id: group.id
					};
				}
				if (audience) {
					this.selectedClassroom = {
						name: audience.name,
						id: audience.id,
					};
				}

				this.inputText = message;
			}
		},
		deleteAnnouncement() {
			this.isDeleting = true;
			try {
				return axios
					.delete(`/api/notifications/${this.announcement_id}`)
					.then(({data}) => {
						this.isDeleting = false;
						this.$router.go(-1);
					});
			} catch (error) {
				this.isDeleting = false;
				return toastr["error"](
					"An error occurred while deleting announcement"
				);
			}
		}
	}
};
</script>

<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}
</style>
