<template>
	<div>
		<div class="avatar">
			<profileImage :image="user.avatar" :is-large="true"/>
		</div>

		<table class="table table-borderless table-responsive">
			<tbody>
			<tr>
				<td>First Name:</td>
				<td> {{ user.first_name }}</td>
			</tr>
			<tr>
				<td>Last Name:</td>
				<td> {{ user.last_name }}</td>
			</tr>
			<tr>
				<td>Email:</td>
				<td> {{ user.email }}</td>
			</tr>
			<tr v-if="!$can('view','student')">
				<td>Current Plan:</td>
				<td> {{ user.user_type.toUpperCase() }}</td>
			</tr>
			<tr>
				<td>Badges:</td>
				<td>
					<div v-if="user.badges[0]">
							<span v-for="(badge, index) in user.badges"
								  :key="index"
								  class="badges">{{ badge.name }}</span>
					</div>
					<div v-else>
						No badges earned yet
					</div>
				</td>
			</tr>
			<tr>
				<td>Email/Tracking</td>
				<td>
					<input v-model="email_tracking"
						   type="checkbox"
						   @click="configureOption('email_tracking')">
				</td>
			</tr>
			<tr>
				<td>Future Communications</td>
				<td>
					<input v-model="communications"
						   type="checkbox"
						   @click="configureOption('communications')">
				</td>
			</tr>
			</tbody>
		</table>
		<div class="clearfix">
			<button class="btn btn-primary"
					type="button"
					@click="$emit('edit-profile')">
				Edit Profile
			</button>
		</div>
	</div>
</template>

<script>
import profileImage from "../../components/profile/profile-image";
import {mapState} from "vuex";

export default {
	components: {
		profileImage,

	},
	name: "ProfileSettingsDetails",
	data() {
		return {
			email_tracking: false,
			communications: false,

		};
	},

	computed: {
		...mapState({
			user: "auth"
		})
	},

	mounted() {
		this.initializeUserOptions();

	},


	methods: {

		initializeUserOptions() {
			if (this.user.user_options === "") {
				this.email_tracking = true;
				this.communications = true;
			} else {
				this.email_tracking = this.user.user_options.email_tracking.value || true;
				this.communications = this.user.user_options.communications.value || true;
			}
		},
		async configureOption(val) {
			this[val] = !this[val];
			await this.$store.dispatch("auth/setUserOptions", {
				option: val,
				value: this[val]
			});
			await this.$store.dispatch("auth/refreshAuth");
		}
	}
};
</script>

<style lang="scss" scoped>
.avatar {
	position: relative;
	display: inline-flex;
	margin: 20px;

	.image {
		width: 150px;
		height: 150px;
		border-radius: 50%;
	}

	.upload-stack {
		position: absolute;
		right: -10px;
		bottom: 0;
		cursor: pointer;

		.upload-background {
			top: 7px;
			color: $theme;
		}

		.upload-icon {
			color: white;
		}
	}
}

table tr td:first-of-type {
	font-weight: bold;
}

.badges {
	&::after {
		content: ", ";
	}

	&:last-of-type {
		&::after {
			content: "";
		}
	}
}
</style>
