import moment from "moment/moment";
import * as timeago from "timeago.js";

const filters = {
	date: function (date) {
		if (date == null) {
			return moment(new Date()).format("llll");
		}
		return moment(new Date(date)).format("llll");
	},
	timeago: function (date) {
		if (date == null) {
			return timeago.format(new Date(), "en_US");
		}
		return timeago.format(new Date(date), "en_US");
	}
};

export default filters;
