<template>
	<div class="page">
		<div class="row">
			<div class="col-md-12">
				<div class="clearfix">
					<span class="float-left">
						<h3>Classroom Details</h3>
					</span>
					<span class="float-right"/>
				</div>
			</div>
		</div>
		<br>
		<!-- class details -->
		<div v-if="classData" class="card">
			<div class="card-body">
				<h6>Classroom Information</h6>
				<ul>
					<li>Name: {{ classData.name }}</li>
					<li>
						Code: {{ classData.code }}
						<clipboard-link :copy="prepareShareLink(classData.code)"
										message="Copy Invite Link"
										style="margin-left: 20px;"/>
					</li>
					<li>Admin: {{ `${classData.admin.first_name} ${classData.admin.last_name}` }}</li>
				</ul>
				<template v-if="classData.group != null">
					<h6>School Information</h6>
					<ul>
						<li>Name: {{ classData.group.name }}</li>
						<li>Address: {{ classData.group.address }} {{ classData.group.address2 }}</li>
						<li>City: {{ classData.group.city }}</li>
						<li>State: {{ classData.group.state }}</li>
					</ul>
				</template>
				<div class="clearfix">
					<span class="float-left">
						<button
							class="btn btn-info btn-sm"
							type="button"
							@click="editClassroom(classData.id)">
							Edit Class
						</button>
						<button
							class="btn btn-danger btn-sm"
							type="button"
							@click="deleteClassroom(classData.id)">
							Delete Class
						</button>
					</span>
					<span class="float-right">
						<button
							class="btn btn-success btn-sm"
							type="button"
							@click="assignToClass(classData.id)">
							Assign to Class
						</button>
					</span>
				</div>
			</div>
		</div>
		<br>
		<div v-if="students" class="card">
			<div class="card-body">
				<h6>Classroom Members</h6>
				<br>
				<div v-if="students.length > 0" class="table-responsive">
					<table class="table table-hover">
						<thead class="thead-light">
						<tr>
							<th>Avatar</th>
							<th>Username</th>
							<th>Date Registered</th>
							<th>Action</th>
						</tr>
						</thead>

						<tbody>
						<tr v-for="(u, index) in students" :key="index">
							<td>
								<img
									:src="u.user.avatar || '/images/default-profile.png'"
									class="img-thumbnail"
									height="75px"
									width="75px"
									alt="image here">
							</td>
							<td>{{ u.user.username || "n/a" }}</td>
							<td>{{ u["created_at"] | date }}</td>
							<td>
								<button
									class="btn btn-warning btn-sm"
									type="button"
									@click="resetPassword(u.user.id)">
									<i class="fas fa-sync"/>
								</button>
								<button
									class="btn btn-danger btn-sm"
									type="button"
									@click="removeStudent(u.user.id)">
									<i class="far fa-trash-alt"/>
								</button>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
				<empty v-else/>
			</div>
		</div>
		<br>
		<assign-playlist-lesson-to-classroom/>
	</div>
</template>

<script>
import empty from "../../components/utility/empty.vue";
import clipboardLink from "../../components/utility/clipboard-link.vue";
import EventBus from "../../utility/eventbus";
import assignPlaylistLessonToClassroom from "../../components/lesson-playlist/assign-playlist-lesson-to-classroom";
import {EventActionEnum, EventLabelEnum} from "../../config/enums";

export default {
	components: {
		clipboardLink,
		empty,
		assignPlaylistLessonToClassroom,
	},
	data() {
		return {
			classData: null,
			students: null,
			loading: true,
			class_id: this.$route.params.id,
		};
	},
	mounted() {
		this.fetchClass();
	},
	watch: {
		classData: {
			handler(newVal) {
				if (newVal != null) {
					this.students = this.classData.users.filter((user) => {
						return user.role !== "Teacher";
					});
				}
			},
			deep: true,
		},
	},
	updated() {
		this.classData == null ? this.fetchClass() : null;
	},
	computed: {
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},
	methods: {
		async fetchClass() {
			try {
				this.loading = true;
				const response = await axios.get(`/api/classroom/${this.class_id}`);
				this.loading = false;
				this.classData = response.data;
				// log with GA
				this.$gtag.event(EventActionEnum.View, {
					"event_category": this.userEventCategory,
					"event_label": EventLabelEnum.Classroom,
					"value": this.classData.name,
				});
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch classes, please try again later");
			}
		},
		prepareShareLink(code) {
			return `${window.location.protocol}//${window.location.host}/join-class/${code}`;
		},
		async resetPassword(id) {
			try {
				if (confirm("Are you sure you want to reset this student's password?")) {
					this.loading = true;
					await axios.get(`/api/teacher/students/${id}/reset-password`);
					this.loading = false;
					return toastr["success"]("Password reset successful");
				}
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to reset student password");
			}
		},
		async removeStudent(id) {
			try {
				if (confirm("Are you sure you want to remove student from this class?")) {
					this.loading = true;
					await axios.delete(`/api/principal/students/${id}`);
					this.loading = false;
					toastr["success"]("Student Removed from classroom");
					return this.$router.go(0);
				}
			} catch (e) {
				this.loading = false;
				return toastr["error"]("Unable to remove student from classroom");
			}
		},
		async deleteClassroom(id) {
			try {
				if (confirm("Are you sure?")) {
					this.loading = true;
					await axios.delete(`/api/classroom/${id}`);
					this.loading = false;
					toastr["success"]("Classroom deleted");
					return this.$router.go(-1);
				}
			} catch (e) {
				return toastr["error"]("Unable to delete classroom");
			}
		},
		async editClassroom(id) {
			return this.$router.push({
				path: `/teacher/classes/${id}/edit`
			});
		},
		async assignToClass(id) {
			EventBus.$emit("ASSIGN-PLAYLIST-LESSON-TO-CLASSROOM", id);
		}
	}
};
</script>

<style lang="scss" scoped>
h3 {
	padding-left: 10px;
}

.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}
</style>
<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}
</style>
