<template>
	<div>
		<div class="page">
			<div class="row">
				<div class="col-md-12">
					<h3>All Custom Lessons</h3>
					<br>
					<!-- breadcrumb -->
					<div class="row">
						<div class="col-md-4">
							<b-breadcrumb :items="breadcrumbitems" />
						</div>
						<div class="col-md-4" />
						<div class="col-md-4">
							<div class="clearfix">
								<span class="float-left" />
								<span class="float-right">
									<button class="btn btn-info" @click="viewLesson('new')">
										Create Lesson
									</button>
								</span>
							</div>
						</div>
					</div>

					<!-- table -->
					<div>
						<table class="table table-hover">
							<thead>
								<tr>
									<th><input v-model="bulkAllToggle" type="checkbox"></th>
									<th>Cover</th>
									<th>Hero</th>
									<th>Title</th>
									<th>focus</th>
									<th width="70px">
										Author
									</th>
								</tr>
							</thead>
							<tbody v-if="lessons && !loading && lessons.length > 0">
								<tr
									v-for="(lesson,index) in lessons"
									:key="index"
									style="cursor: pointer;">
									<td>
										<input :id="`item-${index}`"
											:ref="`item-${index}`"
											v-model="checkedItems"
											:value="lesson.id"
											type="checkbox">
									</td>
									<td @click="viewLesson(lesson.id)">
										<img :src="lesson.cover"
											class="img-thumbnail"
											height="75px"
											width="100px">
									</td>
									<td @click="viewLesson(lesson.id)">
										{{ lesson.hero }}
									</td>
									<td @click="viewLesson(lesson.id)">
										{{ lesson.title }}
									</td>
									<td>{{ lesson.focus }}</td>
									<td width="70px">
										{{ `${lesson.author.first_name} ${lesson.author.last_name}` }}
									</td>
									<td
										v-if="lesson.published_at === null">
										<span id="draft">
											(Draft)
										</span>
									</td>
								</tr>
							</tbody>
							<tbody v-else-if="loading">
								Loading lessons...
							</tbody>
							<tbody v-else>
								No lessons
							</tbody>
						</table>
						<div class="clearfix">
							<span class="float-left">
								<BulkActionsButtonGroup
									v-if="bulkVisible"
									:busy="loading"
									:delete-action="true"
									:publish-action="true"
									:unpublish-action="true"
									@bulkDelete="bulkDeleteItems"
									@bulkPublish="bulkPublishItems"
									@bulkUnpublish="bulkUnpublishItems" />
							</span>
							<span class="float-right">
								<pagination v-if="links && meta"
									:links="links"
									:meta="meta"
									@first="(url) => updateLessonsTable(url)"
									@last="(url) => updateLessonsTable(url)"
									@next="(url) => updateLessonsTable(url)"
									@prev="(url) => updateLessonsTable(url)" />
							</span>
						</div>
					</div>
					<br>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import BulkActionsButtonGroup from "../../components/forms/bulk-actions-button-group.vue";
import axios from "axios";
import pagination from "../../components/utility/pagination.vue";

export default {
	data() {
		return {
			breadcrumbitems: [
				{
					text: "Lessons",
					href: "#"
				},
				{
					text: "All",
					active: true
				}
			],
			lessons: null,
			links: null,
			meta: null,
			loading: true,
			checkedItems: [],
			bulkVisible: false,
			bulkAllToggle: false,
		};
	},
	components: {
		BulkActionsButtonGroup,
		pagination
	},
	mounted() {
		this.fetchLessons();
	},
	watch: {
		checkedItems: {
			handler(val) {
				this.bulkVisible = val.length > 0;
			},
			deep: true,
		},
		bulkAllToggle: {
			handler(val) {
				this.toggleAll(val);
			},
			deep: true,
		}
	},
	updated() {
		this.lessons === null ? this.fetchLessons() : null;
	},
	methods: {
		toggleAll(val) {
			this.bulkVisible = val;
			this.lessons.forEach((data, index) => {
				this.$refs[`item-${index}`][0].checked = val;
				if (val) {
					this.checkedItems.push(data.id);
				} else {
					this.checkedItems = [];
				}
			});
		},
		async bulkDeleteItems() {
			try {
				this.loading = true;
				if (this.checkedItems.length === 0) {
					return toastr["error"]("Cannot bulk delete nothing!");
				}
				await axios.delete(`/api/lesson/bulk?ids=${this.checkedItems.join(",")}`,);
				toastr["success"]("Items successfully deleted");
			} catch (e) {
				toastr["error"]("Unable to perform bulk delete");
			} finally {
				this.loading = false;
				await this.fetchLessons();
			}
		},
		async bulkPublishItems() {
			try {
				this.loading = true;
				if (this.checkedItems.length === 0) {
					return toastr["error"]("Cannot bulk publish nothing!");
				}
				await axios.get(`/api/lesson/bulk/publish?ids=${this.checkedItems.join(",")}`,);
				toastr["success"]("Items successfully published!");
			} catch (e) {
				toastr["error"]("Unable to perform bulk publish");
			} finally {
				this.loading = false;
				await this.fetchLessons();
			}
		},
		async bulkUnpublishItems() {
			try {
				this.loading = true;
				if (this.checkedItems.length === 0) {
					return toastr["error"]("Cannot bulk unpublish nothing!");
				}
				await axios.get(`/api/lesson/bulk/unpublish?ids=${this.checkedItems.join(",")}`,);
				toastr["success"]("Items successfully unpublished!");
			} catch (e) {
				toastr["error"]("Unable to perform bulk unpublish");
			} finally {
				this.loading = false;
				await this.fetchLessons();
			}
		},
		fetchLessons() {
			return axios
				.get("/api/principal/custom-lessons")
				.then(({data, links, meta}) => {
					this.lessons = data;
					this.loading = false;
					this.links = links;
					this.meta = meta;
				})
				.catch(error => {
					this.loading = false;
					toastr["error"]("Could not fetch lessons");
				});
		},
		viewLesson(id) {
			return this.$router.push({
				path: `/principal/custom-lessons/${id}`
			});
		},
		updateLessonsTable(url) {
			this.loading = true;
			return axios
				.get(url)
				.then(({data, links, meta}) => {
					this.lessons = data;
					this.loading = false;
					this.links = links;
					this.meta = meta;
				})
				.catch(error => {
					toastr["error"]("Could not fetch lessons");
				});
		}
	}
};
</script>
<style lang="scss" scoped>
h3 {
	padding-left: 10px;
}

.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}
</style>
<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}
</style>

