import CryptoJS from "crypto-js";

const secret = process.env.MIX_STORE_KEY;

const expiryDuration = 1680000;

// Plain encrypt
const encrypt = (data) => {
	if (data != null) {
		return CryptoJS.AES.encrypt(
			JSON.stringify(data),
			secret
		).toString();
	}
	return null;
};

// Plain decrypt
const decrypt = ciphertext => {
	try {
		if (
			ciphertext != null &&
			ciphertext !== "null"
		) {
			let bytes = CryptoJS.AES.decrypt(ciphertext.toString(), secret);
			let decrypted = bytes.toString(CryptoJS.enc.Utf8);
			return JSON.parse(decrypted);
		}
		return null;
	} catch (e) {
		return null;
	}
};

// vuex in localStorage
const store = (key,value) => {
	return localStorage.setItem(key,value);
};

// read from localstorage
const read = (key) => {
	return localStorage.getItem(key);
};

const getExpiry = () => {
	return (new Date().getTime() + expiryDuration);
};

const isExpired = (expiry) => {
	return (new Date().getTime() > parseInt(expiry, 10));
};

// Encrypt and vuex with time expiry functionality
const storeExpiry = (key, value, expiry = false) => {
	const encryptedData = encrypt(value);
	if (expiry === true) {
		const encryptedExpiry = encrypt(getExpiry());
		store(`${key}.e`,encryptedExpiry);
	}
	return store(key,encryptedData);
};

// decrypt and read with time expiry functionality
const readExpiry = key => {
	const expiryData = decrypt(read(`${key}.e`));
	const data = decrypt(read(key));
	if (data != null) {
		if (data && isExpired(expiryData)) {
			return { response: data, expired: true };
		}
		if (data && !isExpired(expiryData)) {
			return { response: data, expired: false };
		}
	}
	return {response: null, expired: true};
};

const clear = () => {
	localStorage.clear();
	return null;
};

export { encrypt, decrypt, clear, storeExpiry, readExpiry, read, store };
