<template>
	<div class="page">
		<div class="row">
			<div class="col-md-12">
				<h3>Students</h3>
				<div class="clear-fix mb-5">
					<span class="float-left">
						<p style="padding-left: 10px;">All Registered Students</p>
					</span>
					<span class="float-right" />
				</div>
				<div v-if="studentData && studentData.data.length > 0" class="table-responsive">
					<table class="table table-hover">
						<thead class="thead-light">
							<tr>
								<th>Avatar</th>
								<th>Username</th>
								<th>Date Joined</th>
								<th />
							</tr>
						</thead>
						<tbody>
							<tr v-for="(s,index) in studentData.data" :key="index">
								<td>
									<img
										:src="s.user.avatar || '/images/default-profile.png'"
										class="img-thumbnail"
										width="75px"
										height="75px"
										alt="avatar">
								</td>
								<td>{{ s.user.username }}</td>
								<td>{{ new Date(s.created_at).toLocaleString() }}</td>
								<td>
									<a
										@click="resetPassword(s.user.id)"
										href="#">
										Reset Password
									</a>
									<br>
									<a
										@click="removeStudent(s.user.id)"
										href="#">
										Remove Student
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<empty v-else />
				<!-- pagination -->
				<div class="clearfix">
					<span class="float-right">
						<pagination v-if="studentData"
							:links="studentData.links"
							:meta="studentData.meta"
							@first="(url) => updateStudentTable(url)"
							@prev="(url) => updateStudentTable(url)"
							@last="(url) => updateStudentTable(url)"
							@next="(url) => updateStudentTable(url)" />
					</span>
					<span class="float-left" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import pagination from "../../components/utility/pagination.vue";
import empty from "../../components/utility/empty.vue";

export default {
	components: {
		pagination,
		empty
	},
	data() {
		return {
			studentData: null,
			loading: true,
		};
	},
	mounted() {
		this.fetchData();
	},
	updated() {
		this.studentData == null ? this.fetchData() : null;
	},
	methods: {
		async fetchData() {
			try {
				this.loading = true;
				const response = await axios.get("/api/principal/students");
				this.loading = false;
				this.studentData = response;
			} catch (e) {
				this.loading = false;
				return toastr["error"]("Unable to fetch students, please try again later");
			}
		},
		async updateStudentTable(url) {
			try {
				this.loading = true;
				const response = await axios.get(url);
				this.loading = false;
				this.studentData = response;
			} catch (e) {
				this.loading = false;
				return toastr["error"]("Unable to fetch students, please try again later");
			}
		},
		async resetPassword(id) {
			try {
				if (confirm("Are you sure you want to reset this student's password?")) {
					this.loading = true;
					await axios.get(`/api/teacher/students/${id}/reset-password`);
					this.loading = false;
					return toastr["success"]("Password reset successful");
				}
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to reset student password");
			}
		},
		async removeStudent(id) {
			try {
				if (confirm("Are you sure you want to remove student from this class?")) {
					this.loading = true;
					await axios.delete(`/api/principal/students/${id}`);
					this.loading = false;
					toastr["success"]("Student Removed from Group");
					return this.$router.go(0);
				}
			} catch (e) {
				this.loading = false;
				return toastr["error"]("Unable to remove student from Group");
			}
		},
	}
};
</script>

<style scoped>

</style>
