<template>
	<div class="search">
		<form @submit.prevent="onEnter">
			<div class="input-group">
				<input
					v-model="search"
					:class="{ 'open': isOpen }"
					class="form-control"
					placeholder="Search"
					type="text"
					@input="onChange"
					@keydown.down.prevent="onArrowDown"
					@keydown.up.prevent="onArrowUp"
					@keydown.enter.prevent="onEnter">

				<div class="input-group-append">
					<button
						:disabled="isOpen === false"
						class="search-button btn btn-link"
						type="button"
						@click="searchResultsPage(search)">
						<i class="fa fa-search"/>
					</button>
				</div>
			</div>
		</form>

		<div class="autocomplete">
			<ul v-show="isOpen"
				class="autocomplete-results">
				<li v-if="isLoading"
					class="loading">
					<div class="spinner">
						<div/>
						<div/>
						<div/>
						<div/>
					</div>
				</li>
				<li
					v-for="(result,i) in results"
					v-else
					:key="result.id"
					:class="{ 'is-active': i === arrowCounter }"
					class="autocomplete-result"
					@click="routeToResult(result.id)">
					{{ `${result.title}` }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import debounce from "../../utility/debounce";

export default {
	name: "Search",

	data() {
		return {
			search: null,
			results: [],
			isOpen: false,
			arrowCounter: -1,
			isLoading: false,
			endpoint: "/api/search/lesson",
			operation: null,
		};
	},

	// to listen for outside clicks when mounted
	mounted() {
		const {addEventListener} = document;
		addEventListener("click", this.handleClickOutside);
	},

	// to remove listener for outside click when unmounted
	destroyed() {
		const {removeEventListener} = document;
		removeEventListener("click", this.handleClickOutside);
	},

	methods: {
		// when the input has been changed
		async onChange() {
			this.isOpen = true;
			this.isLoading = true;
			if (this.operation != null) {
				clearImmediate(this.operation);
			}
			this.operation = setImmediate(() => {
				return debounce({
					method: "get",
					url: `${this.endpoint}/${this.search}`,
					timeout: 60000
				})
					.then(({data}) => {
						this.results = data;
					})
					.catch(error => {
						console.log(error);
					}).finally(() => {
						this.isLoading = false;
					});
			}, 0);
		},
		// to perform action on selected result
		searchResult(result) {
			this.search = result;
			this.isOpen = false;
		},
		// using the arrowkeys
		onArrowDown() {
			if (this.arrowCounter < this.results.length - 1) {
				return (this.arrowCounter += 1);
			}
			return null;
		},
		onArrowUp() {
			if (this.arrowCounter > 0) {
				return (this.arrowCounter -= 1);
			}
			return null;
		},
		// on pressing the enter key
		onEnter() {
			// route to lesson here
			if (this.arrowCounter !== -1) {
				return this.routeToResult(this.results[this.arrowCounter].id);
			} else {
				return this.searchResultsPage(this.search);
			}
		},
		initialData() {
			return {
				search: "",
				results: [],
				isOpen: false,
				arrowCounter: -1,
				isLoading: false,
				endpoint: "/api/search/lesson",
				operation: null
			};
		},
		// to route to search results page
		searchResultsPage(value) {
			// if (this.$route.name === "searchResults") {
			// 	Object.assign(this.$data, this.initialData());
			// 	this.$router.replace({
			// 		name: "searchResults",
			// 		query: {
			// 			q: value
			// 		}
			// 	});
			// 	return this.$router.go(0);
			// }
			Object.assign(this.$data, this.initialData());
			return this.$router.push({
				name: "searchResults",
				query: {
					q: value
				}
			});
		},
		// on clicking a result
		routeToResult(id) {
			// reset the component before re-routing
			Object.assign(this.$data, this.initialData());
			return this.$router.push({
				name: "showLesson",
				params: {
					lesson_id: id
				}
			});
		},
		// close when the user clicks outside
		handleClickOutside(e) {
			if (!this.$el.contains(e.target)) {
				// reset the component
				Object.assign(this.$data, this.initialData());
				return null;
			}
			return null;
		}
	}
};
</script>

<style lang="scss" scoped>
$search-width: 400px;
$search-width-tablet: 140px;
$search-width-mobile: 140px;
$search-button-width: 30px;
$search-width-closed: 100px;

.search {
	.input-group {
		&:focus-within {
			border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
		}

		input[type="text"],
		select.form-control {
			width: $search-width-closed;
			background: transparent;
			border: none;
			border-radius: 0;
			-webkit-box-shadow: none;
			box-shadow: none;
			transition: 0.4s ease-in-out;

			&:focus,
			&.open {
				width: $search-width;
				-webkit-box-shadow: none;
				box-shadow: none;
			}

			@include breakpoint($xs) {
				width: $search-width-mobile;
			}

			@include breakpoint($sm-only) {
				width: $search-width-mobile;
			}
		}

		.search-button {
			width: $search-button-width;
			color: #7d7d7d;

			&:disabled {
				color: #bbbbbb;
			}
		}
	}

	.autocomplete {
		position: sticky;

		.autocomplete-results {
			position: absolute;
			z-index: 1;
			width: $search-width + $search-button-width;
			height: auto;
			padding: 0;
			margin: 0;
			overflow: auto;
			background-color: white;
			transition: 0.4s ease-in-out;

			.loading {
				.spinner div {
					position: absolute;
					left: -20px;
					width: 10px;
					height: 10px;
					margin-top: -20px;
					background-color: #333333;
					border-radius: 50%;
					animation: move 4s infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
				}

				.spinner div:nth-child(2) {
					animation-delay: 150ms;
				}

				.spinner div:nth-child(3) {
					animation-delay: 300ms;
				}

				.spinner div:nth-child(4) {
					animation-delay: 450ms;
				}

				@keyframes move {
					0% {
						left: 0%;
					}

					75% {
						left: 100%;
					}

					100% {
						left: 100%;
					}
				}
			}

			@keyframes spin {
				0% {
					transform: rotate(0deg);
				}

				100% {
					transform: rotate(360deg);
				}
			}

			.autocomplete-result {
				padding: 7px 4px;
				text-align: left;
				list-style: none;
				cursor: pointer;
				border-bottom: 0.5px solid #eeeeee;
			}

			.autocomplete-result.is-active,
			.autocomplete-result:hover {
				width: $search-width + $search-button-width;
				color: white;
				background-color: #4aae9b;
			}
		}
	}
}
</style>
