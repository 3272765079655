<template>
	<div class="playlist page-section"
		:style="`border-radius: 5px;`">
		<div>
			<div class="media mb-2 mt-3 ml-2">
				<div class="media-body">
					<!--					<h3 class="section-heading">-->
					<!--						{{ title }}-->
					<!--					</h3>-->
					<!--					<p>{{ playlist.description }}</p>-->
					<ul
						class="tags">
						<li
							class="tag">
							{{ title }}
						</li>
					</ul>
					<!--					<ul v-if="playlist.tags[0]"-->
					<!--						class="tags">-->
					<!--						<li v-for="tag in playlist.tags"-->
					<!--							:key="tag.id"-->
					<!--							class="tag">-->
					<!--							{{ tag.name }}-->
					<!--						</li>-->
					<!--					</ul>-->
				</div>
				<!--				<img id="playlist-image"-->
				<!--					:src="null"-->
				<!--					class="align-self-center ml-5">-->
			</div>
			<thumbnail-index v-if="playlists[0]"
				:playlists="playlists"
				class="lesson-index" />
			<div v-else>
				<p><b>Playlist is currently empty</b></p>
			</div>
		</div>
	</div>
</template>

<script>
import ThumbnailIndex from "../../components/lesson-playlist/thumbnailIndex.vue";

export default {
	name: "PlaylistIndex",

	components: {
		ThumbnailIndex,
	},

	props: {
		playlists: {
			type: Array,
			default: () => []
		},
		title: {
			type: String,
			default: ""
		},
	},
};
</script>

<style lang="scss" scoped>
.page-section {
	padding-bottom: 0;
}

.playlist {
	padding-top: $padding * 1.5;

	&:hover {
		box-shadow: 0 0 0 3px $theme;
	}

	.media-body {
		@include breakpoint($md) {
			max-width: 65%;
		}
	}

	#playlist-image {
		position: relative;
		display: none;
		right: 20px;
		top: 25px;
		max-width: 120px;
		max-height: 120px;

		@include breakpoint($md) {
			display: block;
		}
	}

	.lesson-index {
		width: 104%;
		margin-right: -$padding;
		margin-left: -$padding;
	}

	.tags {
		padding: 0;
		margin: 0;
		overflow: hidden;
		list-style: none;
	}

	.tags li {
		float: left;
	}

	.tag {
		position: relative;
		display: inline-block;
		height: 26px;
		padding: 0 20px 0 23px;
		margin: 0 10px 10px 0;
		line-height: 26px;
		color: #999999;
		text-decoration: none;
		background: #eeeeee;
		border-radius: 3px 0 0 3px;
		-webkit-transition: color 0.2s;
	}

	.tag::before {
		position: absolute;
		top: 10px;
		left: 10px;
		width: 6px;
		height: 6px;
		content: "";
		background: #ffffff;
		border-radius: 10px;
		box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
	}

	.tag::after {
		position: absolute;
		top: 0;
		right: 0;
		content: "";
		background: #ffffff;
		border-top: 13px solid transparent;
		border-bottom: 13px solid transparent;
		border-left: 10px solid #eeeeee;
	}

	.tag:hover {
		color: white;
		background-color: $dark-blue;
	}

	.tag:hover::after {
		border-left-color: $dark-blue;
	}
}
</style>

