<template>
	<b-card title="Login"
		align="left">
		<h4 v-if="status"
			class="text-center m-t-20 m-b-20 alert alert-success"
			v-text="message" />
		<h4 v-else
			class="text-center m-t-20 m-b-20 alert alert-danger"
			v-text="message" />

		<p>
			Back to Login?
			<router-link to="/login"
				class="text-info m-l-5">
				<b>Sign In</b>
			</router-link>
		</p>
	</b-card>
</template>

<script>
export default {
	data() {
		return {
			token: this.$route.params.token,
			message: "Processing...",
			status: true
		};
	},
	mounted() {
		axios
			.get("/api/auth/activate/" + this.token)
			.then(({message}) => {
				this.message = message;
			})
			.catch(({message}) => {
				this.message = message;
				this.status = false;
			});
	}
};
</script>

<style lang="scss" scoped>
p {
	font-size: $font-size-base;
	font-family: $font-family-base;
}
</style>
