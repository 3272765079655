<template>
	<div>
		<b-navbar :id="'header'"
			class="backend-header"
			type="light"
			variant="light">
			<div class="container-fluid">
				<b-navbar-brand @click.prevent="routeToHome">
					<img :id="'navbar-logo'"
						:src="links.logo"
						alt="BV"
						class="d-inline-block align-top">
					<img :id="'navbar-logo-mobile'"
						:src="links.logo_mobile"
						alt="BV"
						class="d-inline-block align-top">
				</b-navbar-brand>
				<b-navbar-nav v-if="!isPublic" class="ml-auto">
					<b-nav-item class=" d-none d-sm-none d-md-block d-lg-block">
						<search />
					</b-nav-item>
					<b-nav-item id="nav_seperator" class=" d-none d-sm-none d-md-block d-lg-block">
						<span class="line">|</span>
					</b-nav-item>
					<b-nav-item-dropdown id="header-dropdown"
						right>
						<template #button-content>
							<profileImage :image="getAuthUserAvatar()" />
							<span class=" d-none d-sm-none d-md-block d-lg-block"
								style="margin: 0 10px;">{{ getAuthUserFullName() }}</span>
						</template>
						<b-dropdown-item
							v-if="userType !== 'student'"
							@click.prevent="routeToProfile">
							Profile
						</b-dropdown-item>
						<b-dropdown-item
							href="#"
							@click.prevent="logout">
							Logout
						</b-dropdown-item>
					</b-nav-item-dropdown>
				</b-navbar-nav>
			</div>
		</b-navbar>
		<div v-if="!isPublic" class="topnav">
			<router-link
				tag="a"
				to="/">
				<i class="fa fa-fw fa-home" /> Home
			</router-link>
			<router-link v-if="$can('view','all-lessons')"
				tag="a"
				to="/lesson/explore">
				<i class="fas fa-photo-video" /> Explore Lessons
			</router-link>
			<router-link v-if="canViewDashboardLink"
				:to="prepareDashboardLink()"
				tag="a">
				<i class="fas fa-chart-line" /> Dashboard
			</router-link>
			<!--			<router-link tag="a"-->
			<!--				v-if="$can('view','all-playlists')"-->
			<!--				to="/playlist/featured">-->
			<!--				<i class="fas fa-layer-group" /> Featured Playlists-->
			<!--			</router-link>-->
			<router-link v-if="$can('view', 'student-dashboard')"
				tag="a"
				to="/student/class">
				Your Classes
			</router-link>
			<router-link tag="a" to="/announcements">
				<i class="fas fa-bell" /> Announcements
				<span v-if="unreadNotificationsCount > 0" class="badge badge-light ml-1">New</span>
			</router-link>
			<div style="float: right; position: relative; right: 2px;">
				<a v-if="$can('view', 'teacher-dashboard') && !$can('view', 'group-dashboard')"
					href="#"
					@click="() => joinGroupModal = true">
					<i class="fas fa-sign-in-alt" /> Join School/District
				</a>
				<a v-if="$can('view', 'student-dashboard')"
					href="#"
					@click="() => joinClassModal = true">
					<i class="fas fa-sign-in-alt" /> Join Class
				</a>
			</div>
		</div>
		<!--		join class modal-->
		<b-modal v-model="joinClassModal"
			centered
			hide-footer
			title="Join a Class">
			<div>
				<b-form @submit.prevent="joinClass">
					<br>
					<b-form-group id="code.">
						<b-form-input id="code."
							v-model="class_code"
							autocomplete="code"
							placeholder="Enter the Class Code"
							required
							type="text" />
					</b-form-group>
					<br>
					<b-button id="joinclass"
						:disabled="class_loading"
						:loading="class_loading"
						type="submit"
						variant="primary">
						<i v-if="class_loading"
							class="fas fa-circle-notch fa-spin" />
						Proceed
					</b-button>
				</b-form>
			</div>
		</b-modal>

		<!--		join group modal-->
		<b-modal v-model="joinGroupModal"
			centered
			hide-footer
			title="Join a School or District">
			<div>
				<b-form @submit.prevent="joinGroup">
					<br>
					<b-form-group id="code*">
						<b-form-input id="code*"
							v-model="group_code"
							autocomplete="code"
							placeholder="Enter the Group Code"
							required
							type="text" />
					</b-form-group>
					<br>
					<b-button id="joingroup"
						:disabled="group_loading"
						:loading="group_loading"
						type="submit"
						variant="primary">
						<i v-if="group_loading"
							class="fas fa-circle-notch fa-spin" />
						Proceed
					</b-button>
				</b-form>
			</div>
		</b-modal>
	</div>
</template>

<script>
import search from "../search/search.vue";
import profileImage from "../profile/profile-image.vue";
import {mapGetters,} from "vuex";

export default {
	props: {
		isPublic: {
			type: Boolean,
			default: false
		}
	},
	components: {
		search,
		profileImage
	},

	data() {
		return {
			links: {
				logo: "/images/logo/Site_Logo.png",
				logo_mobile: "/images/logo/WE_LOGO.png",
				home: "/",
				admin: "/admin",
				student_dashboard: "/student/overview",
				teacher_dashboard: "/teacher/overview",
				group_dashboard: "/principal/overview",
				district_dashboard: "/district/overview",
				profile: "/profile",
				logout: "/logout"
			},
			joinClassModal: false,
			class_loading: false,
			class_code: "",
			joinGroupModal: false,
			group_loading: false,
			group_code: ""
		};
	},

	computed: {
		userType() {
			return this.$store.getters["auth/getUserType"];
		},
		unreadNotificationsCount() {
			return this.$store.getters["auth/unreadNotificationsCount"];
		},
		canViewDashboardLink() {
			const can = this.$can;
			return (
				(can("view", "admin")) ||
				(can("view", "group-dashboard")) ||
				(can("view", "editor-dashboard")) ||
				(can("view", "teacher-dashboard"))
			);
		}
	},

	methods: {

		...mapGetters({
			getAuthUserFullName: "auth/getAuthUserFullName",
			getAuthUserAvatar: "auth/getAuthUserAvatar",
		}),

		async logout() {
			await this.$store.dispatch("auth/logout");
			await this.$router.replace({path: "/login"});
		},

		routeToHome() {
			if (this.$props.isPublic !== true) {
				this.$router.push({
					name: "home"
				});
			}
		},

		routeToProfile() {
			this.$router.push({
				path: "/profile"
			});
		},
		prepareDashboardLink() {
			switch (this.userType) {
			case "super-admin":
				return this.links.admin;
			case "admin":
				return this.links.admin;
			case "student":
				return this.links.student_dashboard;
			case "teacher":
				return this.links.teacher_dashboard;
			case "group-admin":
				return this.links.group_dashboard;
			default:
				return this.links.student_dashboard;
			}
		},
		async joinClass() {
			try {
				if (this.class_code === "") {
					return toastr["error"]("Please input your class code");
				}
				this.class_loading = true;
				await axios.post("/api/student/classroom", {class_code: this.class_code});
				this.class_loading = false;
				toastr["success"]("Registration successful");
				this.joinClassModal = false;
				this.class_code = "";
				this.$router.go();
			} catch (error) {
				this.class_loading = false;
				return toastr["error"]("Unable to join classroom");
			}

		},
		async joinGroup() {
			try {
				if (this.group_code === "") {
					return toastr["error"]("Please input a group code");
				}
				this.group_loading = true;
				await axios.post("/api/teacher/groups", {group_code: this.group_code});
				this.group_loading = false;
				toastr["success"]("Registration successful");
				this.joinGroupModal = false;
				this.group_code = "";
				this.$router.go();
			} catch (e) {
				this.group_loading = false;
				return toastr["error"]("Unable to join group");
			}
		}
	}
};
</script>

<style lang="scss" scoped>
#header {
	position: fixed;
	z-index: 100;
	width: 100%;
	height: $header-height;
	padding: 0 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);

	@include breakpoint($xs) {
		padding: 10px 30px;
	}

	@include breakpoint($sm-only) {
		padding: 10px 30px;
	}

	#navbar-logo {
		max-width: 255px;

		@include breakpoint($xs) {
			display: none !important;
		}
	}

	#navbar-logo-mobile {
		max-width: 80px;

		@include breakpoint($xs) {
			display: block;
		}

		@include breakpoint($sm) {
			display: none !important;
		}
	}

	.navbar-nav {
		flex-flow: row nowrap;

		li {
			@include breakpoint($md) {
				padding: 10px;
			}
		}

		#nav_seperator {
			@include breakpoint($xs) {
				display: none;
			}
		}

		.dropdown-menu {
			position: absolute;
			float: left;
		}
	}
}

.line {
	margin-top: -5px;
	margin-left: 10px;
	font-size: 30px;
	font-weight: 300;
	line-height: 1;
}
</style>

<style lang="scss">
#header-dropdown {
	display: inline;
	vertical-align: middle;
}

.nav-link.dropdown-toggle {
	display: flex;
	align-items: center;
	justify-content: center;
}

.dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.4em solid;
	border-right: 0.4em solid transparent;
	border-bottom: 0;
	border-left: 0.4em solid transparent;
}
</style>

<style lang="scss" scoped>
/* Add a black background color to the top navigation */
.topnav {
	position: fixed;
	z-index: 60;
	width: 100%;
	margin-top: 80px;
	background-color: $blue;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

/* Style the links inside the navigation bar */
.topnav a {
	float: left;
	color: $white;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
	font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
	background-color: $white;
	color: $blue;
}

/* Add a color to the active/current link */
.topnav a.active {
	background-color: $blue;
	color: $white;
}
</style>
