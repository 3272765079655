<template>
	<div>
		<b-card v-if="step === 0"
				align="left"
				title="Join A Classroom">
			<b-card-text>
				Input your class code to get started:
			</b-card-text>

			<b-form @submit.prevent="onSubmitCode">
				<b-form-group
					id="input-group-1"
					description="Your teacher will give you a code to your class.">
					<b-form-input
						id="input-1"
						v-model="code"
						placeholder="Input Class Code"
					/>
				</b-form-group>

				<b-button :disabled="code_loading"
						  :loading="code_loading"
						  type="submit"
						  variant="primary">
					Proceed
				</b-button>
			</b-form>
		</b-card>
		<b-card v-if="step === 1"
				align="left"
				title="Create your account">
			<p>
				<span>Welcome to <em>{{ classData.name }}</em>, </span>
				<span v-if="classData.group != null">{{ classData.group.name }}</span>
			</p>
			<br>

			<b-form @submit.prevent="registerStudent">
				<b-form-group id="username" :description="username_loading ? 'checking username' : null">
					<b-form-input
						id="username"
						v-model="registerForm.username"
						:state="username_validation"
						autocomplete="username"
						class="mb-2 mr-sm-2 mb-sm-0"
						dusk="username"
						placeholder="Username"
						required/>
					<b-form-invalid-feedback
						v-if="username_loading === false"
						:state="username_validation">
						Username has been taken
					</b-form-invalid-feedback>
					<b-form-valid-feedback v-if="username_loading === false"
										   :state="username_validation && username_loading === false">
						Looks Good.
					</b-form-valid-feedback>
				</b-form-group>

				<b-form-group id="password">
					<b-form-input
						id="password"
						v-model="registerForm.password"
						autocomplete="new-password"
						dusk="password"
						placeholder="Password"
						required
						type="password"/>
				</b-form-group>

				<b-form-group id="password_confirmation">
					<b-form-input
						id="password_confirmation"
						v-model="registerForm.password_confirmation"
						autocomplete="new-password"
						dusk="password_confirmation"
						placeholder="Confirm Password"
						required
						type="password"/>
				</b-form-group>
				<b-button :disabled="register_loading"
						  :loading="register_loading"
						  block
						  type="submit"
						  variant="primary">
					Register
				</b-button>
			</b-form>
			<b-modal v-model="alreadyLoggedInModal"
					 centered
					 no-close-on-backdrop
					 no-close-on-esc
					 size="md"
					 title="Already Logged In"
					 @ok.prevent="joinClass"
					 @cancel.prevent="() => {alreadyLoggedInModal = false;}">
				<p>You are already logged in as <strong>{{ user.username }}</strong></p>
				<p>Click Proceed to join class: <strong>{{ classData.name }}</strong></p>
				<template #modal-footer="{ ok, cancel }">
					<b-button
						variant="info"
						@click="cancel()">
						Cancel
					</b-button>
					<b-button
						:disabled="register_loading"
						:loading="register_loading"
						variant="info"
						@click="ok()">
						Proceed
					</b-button>
				</template>
			</b-modal>
		</b-card>
	</div>
</template>

<script>
import {mapActions} from "vuex";
import {adjectives, names, uniqueNamesGenerator} from "unique-names-generator";
import {encrypt, storeExpiry} from "../../utility/encrypt";

export default {
	data() {
		return {
			step: 0,
			code: "",
			registerForm: {
				username: "",
				password: "",
				password_confirmation: "",
			},
			classData: null,
			code_loading: false,
			register_loading: false,
			username_validation: true,
			username_loading: false,
			alreadyLoggedInModal: false,
		};
	},
	created() {
		this.$route.params.code != null && this.$route.params.code !== "new" ?
			this.fetchClass(this.$route.params.code) : null;
		this.setConfig({guestShowPicture: true});
	},

	computed: {
		user() {
			return this.$store.getters["auth/getAuthUser"];
		},
		userType() {
			return this.$store.getters["auth/getUserType"];
		}
	},

	watch: {
		"registerForm": {
			handler(newVal, oldVal) {
				if (newVal.username !== "" && oldVal !== null && newVal.username !== oldVal.username) {
					window._.throttle(this.checkUsername(), 300)();
				}
			},
			deep: true,
			immediate: true,
		}
	},

	mounted() {
		this.registerForm.username = uniqueNamesGenerator({
			dictionaries: [adjectives, names],
			length: 2,
			separator: ""
		});
		if (this.user.id !== "" && this.userType === "student") {
			//if user is already logged in and hitting this link trigger the logged in modal
			this.alreadyLoggedInModal = true;
		}
	},

	methods: {
		...mapActions({
			setConfig: "config/setConfig"
		}),
		async checkUsername() {
			try {
				this.username_loading = true;
				const response = await axios.post("/api/public/student/check-username", {username: this.registerForm.username});
				this.username_loading = false;
				this.username_validation = true;
			} catch (error) {
				this.username_validation = false;
				this.username_loading = false;
				return toastr["error"]("Unable to verify username");
			}
		},
		async fetchClass(code) {
			try {
				this.code_loading = true;
				const response = await axios.post("/api/public/student/check-code", {code});
				this.code_loading = false;
				this.classData = response.data;
				this.step = 1;
			} catch (error) {
				this.code_loading = false;
				this.step = 0;
				return toastr["error"]("Unable to find any classes with that code");
			}
		},
		async onSubmitCode() {
			if (this.code === "") {
				return toastr["error"]("Input your class code");
			}
			await this.fetchClass(this.code);
		},
		async joinClass() {
			try {

				this.register_loading = true;
				await axios.post("/api/student/classroom", {class_code: this.classData.code || code});
				toastr["success"]("Class Joined");
				return await this.setUserInitial();
			} catch (e) {
				this.register_loading = false;
				return toastr["error"]("Unable to join class");
			}
		},
		async registerStudent() {
			try {
				if (this.username_validation === false) {
					return toastr["error"]("Username not valid");
				}
				if (this.registerForm.password !== this.registerForm.password_confirmation) {
					return toastr["error"]("Passwords do not match");
				}

				const studentData = {...this.registerForm, class_code: this.classData.code || code};

				this.register_loading = true;

				const response = await axios.post("/api/auth/student", studentData);

				localStorage.setItem("auth_type", encrypt("single"));

				storeExpiry("auth_token", response.data.token, false);
				axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;


				await this.setUserInitial();
				return toastr["success"]("Registration Successful");

			} catch (error) {
				this.register_loading = false;
				return toastr["error"]("Unable to create student account");
			}

		},

		async setUserInitial() {
			try {
				const data = await axios.get("/api/auth/user");
				const response = data.data;
				sessionStorage.setItem("_gml.rl", encrypt(response));
				this.$store.commit("auth/setAuthUserDetail", {
					id: response.id,
					username: response.username,
					first_name: response.first_name,
					last_name: response.last_name,
					email: response.email,
					avatar: response.avatar,
					plan_id: response.plan_id,
					roles: encrypt(response.roles),
					badges: response.badges,
					favorites: response.favorites,
					favorite_playlists: response.favorite_playlists,
					groups: response.groups,
					classrooms: response.classrooms,
					activity: response.activity,
					subscription: response.subscription,
					user_options: response.user_options
				});

				this.$store.commit("auth/setAuthenticated", true);

				//check if subscription is active
				if (["expired",
					"incomplete",
					"incomplete_expired",
					"past_due",
					"unpaid"
				].includes(response.subscription.status)) {
					this.$store.commit("auth/setSubscriptionActive", false);
					router.replace("/profile/settings");
				} else {
					this.$store.commit("auth/setSubscriptionActive", true);
				}

				this.$store.commit("auth/setSubscriptionChecked", true);

				const defaultRoles = ["group-admin", "teacher", "student", "admin", "super-admin"];

				const buffer = [];

				const roles = response.roles.map(role => role.name);

				roles.forEach(role => {
					if (defaultRoles.includes(role)) {
						buffer.push(role);
					}
				});


				this.$store.commit("auth/setUserType", buffer[0]);
				setTimeout(() => {
					this.$router.replace({path: "/home"});
				}, 0);

				return this.register_loading = false;
			} catch (error) {
				this.register_loading = false;
				return toastr["error"]("Unable to register user, please try again");
			}
		}
	}
};
</script>

<style>

</style>
