<template>
	<nav
		id="footer"
		class="justify-content-center navbar-light bg-light">
		(C){{ new Date().getFullYear() }} Weird Enough Productions
		<cookie-law>
			<template #props="props">
				<div>
					<button class="skew" @click="props.accept">
						<span>Got it</span>
					</button>
					<p>
						This site uses 🍪
					</p>
					<button class="skew" @click="props.close">
						<span>Ignore me</span>
					</button>
				</div>
			</template>
		</cookie-law>
	</nav>
</template>

<script>
import CookieLaw from "vue-cookie-law";

export default {
	components: {
		CookieLaw,
	},
};
</script>

<style lang="scss" scoped>
#footer {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 20000;
	width: 100%;
	height: $footer-height + 15px;
	padding-top: 20px;
	padding-bottom: 5px;
	//margin-top: -$footer-height;
	//margin-top: 20px;
	//background-color: white;
	// box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.04);
}

.Cookie--mytheme .Cookie__button {
	background: $green;
}

.Cookie--mytheme div.Cookie__button:hover {
	background: $green;
}
</style>
