<template>
	<div>
		<div class="page">
			<div class="row">
				<div class="col-md-12">
					<h3>All Announcements</h3>
					<br>
					<!-- breadcrumb -->
					<div class="row">
						<div class="col-md-4">
							<b-breadcrumb :items="breadcrumbitems"/>
						</div>
						<div class="col-md-4"/>
						<div class="col-md-4">
							<div class="clearfix">
								<div class="float-left"/>
								<div class="float-right">
									<button class="btn btn-info"
											@click="viewAnnouncement('new')">
										Create Announcement
									</button>
								</div>
							</div>
						</div>
					</div>
					<!-- table -->
					<div>
						<table class="table table-hover">
							<thead>
							<tr>
								<th>Type</th>
								<th>Title</th>
								<th>Total Sent</th>
								<th>Total Read</th>
								<th>Total Unread</th>
								<th>Date Created</th>
							</tr>
							</thead>
							<tbody v-if="announcements && !loading && announcements.length > 0">
							<tr
								v-for="(announcement,index) in announcements"
								:key="index"
								style="cursor: pointer;"
								@click="viewAnnouncement(announcement.id)">
								<td>
										<span v-if="announcement.group_id == null"
											  class="badge badge-primary mr-1">App</span>
									<span v-else class="badge badge-success mr-1">{{ announcement.group.name }}</span>
								</td>
								<td>{{ announcement.title }}</td>
								<td>{{ announcement.total_sent }}</td>
								<td>{{ announcement.total_read }}</td>
								<td>{{ announcement.total_unread }}</td>
								<td>{{ new Date(announcement.created_at).toLocaleDateString() }}</td>
							</tr>
							</tbody>
							<tbody v-else-if="loading">
							Loading announcements...
							</tbody>
							<tbody v-else>
							No announcements
							</tbody>
						</table>
						<br>
						<div class="clearfix">
							<span class="float-left"/>
							<span class="float-right">
								<pagination v-if="links && meta"
											:links="links"
											:meta="meta"
											@first="(url) => updateAnnouncementsTable(url)"
											@last="(url) => updateAnnouncementsTable(url)"
											@next="(url) => updateAnnouncementsTable(url)"
											@prev="(url) => updateAnnouncementsTable(url)"/>

							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {EventActionEnum, EventLabelEnum} from "../../config/enums";
import pagination from "../../components/utility/pagination.vue";

export default {
	components: {
		pagination
	},
	name: "Announcements",
	data() {
		return {
			breadcrumbitems: [
				{
					text: "Announcements",
					href: "#"
				},
				{
					text: "All",
					active: true
				}
			],
			announcements: null,
			links: null,
			meta: null,
			loading: true,
			viewed: []
		};
	},
	async mounted() {
		await this.fetchAllAnnouncements();
	},
	computed: {
		user() {
			return this.$store.getters["auth/getAuthUser"];
		},
		userType() {
			return this.$store.getters["auth/getUserType"];
		},
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},
	updated() {
		this.announcements === null ? this.fetchAllAnnouncements() : null;
	},
	methods: {
		async fetchAllAnnouncements() {
			try {
				this.loading = true;
				const {data, meta, links} = await axios.get(`/api/notifications/author/${this.user.id}`);
				this.loading = false;
				this.announcements = data;
				this.links = links;
				this.meta = meta;
				// log with GA
				this.$gtag.event(EventActionEnum.View, {
					"event_category": this.userEventCategory,
					"event_label": EventLabelEnum.Announcements,
					"value": "All Announcements"
				});
			} catch (e) {
				this.loading = false;
				toastr["error"]("Could not fetch announcements");
			}
		},
		async updateAnnouncementsTable(url) {
			try {
				this.loading = true;
				const {data, meta, links} = await axios.get(url);
				this.loading = false;
				this.announcements = data;
				this.links = links;
				this.meta = meta;
			} catch (e) {
				this.loading = false;
				toastr["error"]("Could not fetch announcements");
			}
		},
		toogleVisible(id, announcement) {
			if (!(announcement.is_read || this.viewed.includes(id))) {
				// background operation
				this.readAnnouncement(announcement.id);
			}
			this.$refs[id][0].toggle();
		},
		async viewAnnouncement(id) {
			if (this.userType !== "admin" || this.userType !== "super-admin") {
				return this.$router.push({
					path: `/principal/announcements/${id}`
				});
			}
			return this.$router.push({
				path: `/admin/announcements/${id}`,
			});
		}
	}
};
</script>

<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}
</style>
