import {Ability, AbilityBuilder} from "@casl/ability";
import {decrypt, encrypt} from "../utility/encrypt";
import ApplicationRules from "./rules";

let rules, can;

export let ability = new Ability();

const defineAbilityFor = (userRole, userId) => {

	if (ApplicationRules[userRole] != null) {
		let ruleConfig = ApplicationRules[userRole];

		ruleConfig.forEach(([action, entity]) => {
			can(action, entity);
		});
	}

	can("update", "user" + userId);
};

const registerRole = (role, id) => {
	switch (role) {
		case "super-admin":
			return defineAbilityFor("SuperAdmin", id);
		case "admin":
			return defineAbilityFor("Admin", id);
		case "student":
			return defineAbilityFor("Student", id);
		case "teacher":
			return defineAbilityFor("Teacher", id);
		case "group-admin":
			return defineAbilityFor("GroupAdmin", id);
		default:
			return defineAbilityFor("Guest", id);
	}
};

const selectRole = () => {
	const abilityBuilder = new AbilityBuilder();

	rules = abilityBuilder.rules;

	can = abilityBuilder.can;

	const user = decrypt(localStorage.getItem("_gml.rl"));

	if (user != null && user !== "") {
		// setup roles
		if (Array.isArray(user.role)) {
			user.role.forEach((role) => {
				registerRole(role, user.id);
			});
		} else {
			registerRole(user.role, user.id);
		}

		// setup permissions for the user
		if (user.permissions != null) {
			for (let permission of user.permissions) {
				permission = permission.split("-");
				can(permission[0], permission[1]);
			}
		}

	} else {
		defineAbilityFor("Guest", 0);
	}

	return ability.update(rules);
};

export const abilityPlugin = store => {

	selectRole();

	return store.subscribe((mutation, state) => {
		if (mutation.type === "auth/setAuthUserDetail") {

			if (mutation.payload.roles) {

				let roles;

				if (Array.isArray(decrypt(mutation.payload.roles))) {
					roles = decrypt(mutation.payload.roles).map((role) => role.name);
				} else {
					roles = decrypt(mutation.payload.roles).name;
				}

				// handle for auth mutations
				const user = {id: mutation.payload.id, role: roles, permissions: decrypt(mutation.payload.permissions)};
				localStorage.setItem("_gml.rl", encrypt(user));

				return selectRole();
			}

		} else if (mutation.type === "auth/resetAuthUserDetail") {
			// handle for logout mutation
			localStorage.setItem("_gml.rl", encrypt(""));
			return selectRole();
			// handle for auth mutations
		} else {
			return selectRole();
		}
	});
};
