/* eslint-disable vue/no-deprecated-destroyed-lifecycle */
<template>
	<div v-if="user"
		class="page-section">
		<div class="row">
			<div class="col-md-12">
				<ul id="profileSettingsTabs"
					class="nav nav-tabs"
					role="tablist">
					<li v-if="!forceSubscription"
						class="nav-item">
						<a
							id="details-tab"
							aria-controls="details"
							aria-selected="true"
							class="nav-link active"
							data-toggle="tab"
							href="#details"
							role="tab">Profile Details</a>
					</li>
					<!--					<li v-if="!$can('view','student')"-->
					<!--						class="nav-item">-->
					<!--						<a-->
					<!--							id="subscription-tab"-->
					<!--							:class="{ 'active' : forceSubscription}"-->
					<!--							class="nav-link"-->
					<!--							data-toggle="tab"-->
					<!--							href="#subscription"-->
					<!--							role="tab"-->
					<!--							aria-controls="subscription"-->
					<!--							aria-selected="false">Subscription</a>-->
					<!--					</li>-->
				</ul>
				<div id="profileSettingsTabContent"
					class="tab-content">
					<div
						v-if="!forceSubscription"
						id="details"
						aria-labelledby="details-tab"
						class="tab-pane fade show active"
						role="tabpanel">
						<profileSettingsDetails v-if="!editDetails"
							@edit-profile="editProfile" />

						<profileSettingsDetailsUpdate v-else
							@edit-profile="editProfile" />
					</div>
					<!--					<div-->
					<!--						id="subscription"-->
					<!--						:class="{ 'show active' : forceSubscription}"-->
					<!--						class="tab-pane fade"-->
					<!--						role="tabpanel"-->
					<!--						aria-labelledby="subscription-tab">-->
					<!--						<subscriptions :user="user"-->
					<!--							:admin="false" />-->
					<!--					</div>-->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import profileSettingsDetails from "./profileSettingsDetails";
import profileSettingsDetailsUpdate from "./profileSettingsDetailsUpdate";
import {mapState} from "vuex";


export default {
	name: "ProfileSettings",

	components: {
		profileSettingsDetails,
		profileSettingsDetailsUpdate,
		// subscriptions,
	},

	data() {
		return {
			editDetails: false
		};
	},
	computed: {
		...mapState({
			user: "auth",
		}),

		forceSubscription() {
			// return [
			// 	"expired",
			// 	"incomplete",
			// 	"incomplete_expired",
			// 	"past_due",
			// 	"unpaid"
			// ].includes(this.user.subscription.status);
			return false;
		},
	},

	methods: {
		editProfile: function () {
			this.editDetails = !this.editDetails;
		}
	}
};
</script>

<style lang="scss" scoped>
#profileSettingsTabContent {
	padding: 20px;
	border-right: 1px solid #dee2e6;
	border-bottom: 1px solid #dee2e6;
	border-left: 1px solid #dee2e6;
}
</style>
