<template>
	<div id="guest-page">
		<div class="guest-container container h-100">
			<div class="row h-100 align-items-center">
				<div align="center"
					class="col-lg-8 offset-lg-2 col-12">
					<div class="row no-gutters">
						<div v-if="guestShowPicture"
							id="guestSlider"
							class="col-md-4 col-0">
							<div id="guestSliderCarousel"
								class="carousel slide h-100"
								data-ride="carousel">
								<div class="carousel-inner h-100">
									<div class="carousel-item active card h-100 border-light"
										style="background: url('/images/guest-slideshow-1.png');">
										<div class="card-body">
											<img data-v-ebfdf548=""
												src="/images/logo/WE_LOGO.png"
												width="75"
												class="float-left">
										</div>
									</div>
									<div class="carousel-item card h-100 border-light"
										style="background: url('/images/guest-slideshow-2.png');" />
									<div class="carousel-item card h-100 border-light"
										style="background: url('/images/guest-slideshow-3.png');" />
									<div class="carousel-item card h-100 border-light"
										style="background: url('/images/guest-slideshow-4.png');" />
									<div class="carousel-item card h-100 border-light"
										style="background: url('/images/guest-slideshow-5.png');" />
								</div>
							</div>
						</div>

						<div v-if="guestShowPicture"
							class="guest-content col-12 col-md-8">
							<router-view />
						</div>

						<div v-else
							class="guest-content col-12">
							<router-view />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";

export default {
	name: "GuestPage",

	computed: {
		...mapState("config", [
			"guestShowPicture",
		]),
	},

	mounted() {
		dataLayer.push({
			"visitorLoginState": "logged-out",
		});
	},
};

</script>

<style lang="scss" scoped>

#guest-page {
	.card {
		background-repeat: no-repeat !important;
		background-position: center !important;
		background-size: cover !important;
		border: 0;
		border-radius: 0;
	}

	.guest-container > button {
		margin-bottom: 20px;
	}

	#guestSlider {
		.carousel {
			img {
				display: none;
				max-width: 100%;
			}
		}

		@include breakpoint($xs) {
			display: none;
		}
	}

	.guest-content {
		padding: 25px;
		background-color: #ffffff;
	}
}
</style>
