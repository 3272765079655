<template>
	<div>
		<div class="page">
			<div>
				<div class="row">
					<div class="col-md-6">
						<!-- page title -->
						<h3>{{ pageTitle }}</h3>
					</div>
				</div>
				<br>
				<div>
					<!-- breadcrumb links -->
					<b-breadcrumb :items="breadcrumbitems"/>
				</div>

				<div style="padding-top: 20px; background: white;">
					<!-- Media manager -->
					<b-modal
						v-if="mediaManager"
						id="modal-center"
						v-model="mediaManager"
						centered
						hide-footer
						lazy
						size="xl"
						title="Media Manager">
						<div class="row">
							<div class="col-md-12">
								<media-manager :modal-mode="true"/>
							</div>
						</div>
					</b-modal>

					<div class="row">
						<div class="col-md-12 lesson-text">
							<form enctype="multipart/form-data"
								  @submit.prevent="submitPrevent">
								<!-- lesson cover image -->
								<div class="col-md-12 form-group">
									<img
										v-if="lessonForm.cover"
										:src="lessonForm.cover"
										class="img-responsive"
										width="60%"
										@change="onChanged('cover')">
								</div>
								<!-- Lesson file upload button -->
								<div class="col-md-12 form-group">
									<label class="control-label"
										   for="cover">Lesson Cover</label>
									<br>
									<b-button class="m-1"
											  variant="primary"
											  @click="DispatchMediaEvent('LESSON_COVER')">
										Upload
									</b-button>
								</div>

								<!-- Lesson Cover-->
								<div class="col-md-12 form-group">
									<input id="cover"
										   v-validate
										   class="form-control"
										   hidden
										   name="cover"
										   type="text">
								</div>

								<!-- Lesson title -->
								<div class="col-md-12 form-group">
									<label class="control-label"
										   for="title">Lesson Title</label>
									<input
										id="title"
										v-model="lessonForm.title"
										v-validate
										class="form-control"
										name="title"
										type="text">
								</div>

								<!-- Lesson Hero -->
								<div class="col-md-12 form-group">
									<label class="control-label"
										   for="hero">Lesson Hero</label>
									<multiselect
										id="hero"
										v-model="lessonForm.hero"
										v-validate
										:allow-empty="false"
										:options="lesson_heros"
										:searchable="false"
										:show-labels="false"
										name="hero"
										placeholder="Select Hero"/>
								</div>

								<!-- Lesson Type -->
								<div class="col-md-12 form-group">
									<label class="control-label"
										   for="type">Type</label>
									<multiselect
										id="type"
										v-model="lessonForm.type"
										v-validate
										:options="lesson_types"
										:searchable="false"
										:show-labels="false"
										name="type"
										placeholder="Select Type"/>
								</div>

								<!-- Lesson classification -->
								<div class="col-md-12 form-group">
									<label class="control-label"
										   for="classification">Classification</label>
									<multiselect
										id="classification"
										v-model="lessonForm.classification"
										v-validate
										:options="lesson_classifications"
										:searchable="false"
										:show-labels="false"
										name="classification"
										placeholder="Select Classification"/>
								</div>

								<!-- Lesson focus -->
								<div class="col-md-12 form-group">
									<label class="control-label"
										   for="focus">Lesson Focus</label>
									<textarea
										id="focus"
										v-model="lessonForm.focus"
										v-validate
										class="form-control"
										name="focus"
										rows="5"/>
								</div>

								<!-- Lesson overview -->
								<div class="col-md-12 form-group">
									<label class="control-label"
										   for="overview">Overview</label>
									<textarea
										id="overview"
										v-model="lessonForm.overview"
										v-validate
										class="form-control"
										name="overview"
										rows="5"/>
								</div>

								<!-- Lesson standards -->
								<div class="col-md-12 form-group">
									<label class="control-label"
										   for="standards">Primary Standards</label>
									<textarea
										id="standards"
										v-model="lessonForm.standards"
										v-validate
										class="form-control"
										name="standards"
										rows="5"/>
								</div>

								<!--								Lesson digital citizenship-->
								<div class="col-md-12 form-group">
									<label class="control-label"
										   for="citizenship">21st Century Skills</label>
									<textarea
										id="citizenship"
										v-model="lessonForm.skills"
										v-validate
										class="form-control"
										name="skills"
										rows="5"/>
								</div>

								<div class="col-md-12 form-group">
									<label>Select Group:</label>
									<group-multiselect @updatedData="setGroup"/>
								</div>

								<div class="col-md-12 form-group">
									<label class="control-label"
										   for="competencies">SEL Competencies</label>
									<textarea
										id="competencies"
										v-model="lessonForm.sel_competencies"
										v-validate
										class="form-control"
										name="skills"
										rows="5"/>
								</div>

								<!-- Lesson content -->
								<div class="addLessonContentForm">
									<div class="col-md-12 form-group"
										 style="margin-bottom: 0.5rem;">
										<label class="control-label d-block"
											   for="contents">Lesson Content</label>
									</div>

									<div class="row col-md-12 form-group"
										 style="padding-right: 0;">
										<div class="form-group col-md-8">
											<multiselect
												id="contents"
												v-model="selectedContent"
												v-validate
												:clear-on-select="false"
												:close-on-select="true"
												:internal-search="false"
												:loading="isLoadingContent"
												:multiple="true"
												:options="contentList"
												:options-limit="15"
												:searchable="true"
												class="contents_multi"
												name="contents"
												placeholder="Type to search for content"
												track-by="id"
												@input="onChanged('contents')"
												@search-change="getContentAsync">
												<template #tag="{ option }">
													<div class="option__block row">
														<div class="option__order col-md-1">
															{{ option.order }}
														</div>

														<div class="option__title multiselect__tag col-md-4">
															<a href="#"
															   @click="routeToEditContent(option.id)">
																{{ option.title }}
																<i class="fas fa-pencil-alt"/>
															</a>
														</div>

														<div class="option__preview col-md-7">
															<img
																v-if="option.type === ContentTypeEnum.IMAGE ||
																	option.type === ContentTypeEnum.COMIC"
																:src="option.link">
															<div v-if="option.type === ContentTypeEnum.TEXT">
																{{ helper.stripHtml(option.text) }}
															</div>
															<div v-if="option.type === ContentTypeEnum.VIDEO">
																<div class="embed-responsive embed-responsive-21by9">
																	<iframe :src="option.link"/>
																</div>
															</div>
															<div v-if="option.type === ContentTypeEnum.AUDIO">
																<audio v-if="contentForm.link"
																	   controls>
																	<source :src="contentForm.link">
																</audio>
															</div>
														</div>
													</div>
												</template>
												<template #option="{ option }"

														  style>
													<div class="option__block row">
														<div class="option__order col-md-1">
															{{ option.order }}
														</div>
														<div class="option__title multiselect__tag col-md-4">
															{{ option.title }}
														</div>
														<div class="option__preview col-md-7">
															<img
																v-if="option.type === ContentTypeEnum.IMAGE ||
																	option.type === ContentTypeEnum.COMIC"
																:src="option.link">
															<div v-if="option.type === ContentTypeEnum.TEXT">
																{{ helper.stripHtml(option.text) }}
															</div>
															<div v-if="option.type === ContentTypeEnum.VIDEO">
																<div class="embed-responsive embed-responsive-21by9">
																	<iframe :src="option.link"/>
																</div>
															</div>
															<div v-if="option.type === ContentTypeEnum.AUDIO">
																<audio v-if="contentForm.link"
																	   controls>
																	<source :src="contentForm.link">
																</audio>
															</div>
														</div>
													</div>
												</template>
											</multiselect>
										</div>

										<div class="form-group col-md-4"
											 style="padding-right: 0;">
											<button
												id="addContentButton"
												aria-controls="collapseAddNewContent"
												aria-expanded="false"
												class="btn btn-primary btn-block btn-lg expand"
												data-target="#collapseAddNewContent"
												data-toggle="collapse"
												type="button"
												@click="toggleAddContent">
												Add New Content to Lesson
											</button>
										</div>
									</div>

									<div id="collapseAddNewContent"
										 class="offset-md-1 col-md-10 collapse">
										<div class="card">
											<div class="card-body">
												<h5 class="card-title">
													Add New Content to Lesson
												</h5>
												<createLessonContent @content-added="onNewContentAdded"/>
											</div>
										</div>
									</div>
								</div>
								<hr>

								<!-- Page action buttons -->
								<div class="col-md-12 form-group">
									<button
										v-if="changed || !lesson_id"
										:disabled="!(errors.items.length <= 0) || isSaving"
										class="btn btn-success"
										type="button"
										@click="saveLesson">
										<i v-if="isSaving"
										   class="fas fa-circle-notch fa-spin"/>
										<span>Save</span>
									</button>
									<button v-if="changed"
											class="btn btn-warning"
											type="button"
											@click="discardChanges">
										<span>Discard</span>
									</button>
									<button v-if="lesson_id"
											class="btn btn-danger"
											type="button"
											@click="deleteLesson">
										<i v-if="isDeleting"
										   class="fas fa-circle-notch fa-spin"/>
										<span>Delete</span>
									</button>

									<button
										v-if="lesson_id"
										class="btn btn-primary float-right"
										type="button"
										@click="viewLesson">
										<span>View Lesson</span>
									</button>

									<button v-if="!published"
											class="btn btn-success float-right"
											type="button"
											@click="publishLesson">
										<i v-if="isPublishing"
										   class="fas fa-circle-notch fa-spin"/>
										<span>Publish</span>
									</button>

									<button v-if="published"
											class="btn btn-warning float-right"
											type="button"
											@click="unpublishLesson">
										<i v-if="isUnpublishing"
										   class="fas fa-circle-notch fa-spin"/>
										<span>Unpublish</span>
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import CreateLessonContent from "../lessons/createLessonContent";
import debounce from "../../utility/debounce";
import helper from "../../utility";
import mediaManager from "../../components/utility/media-manager.vue";
import eventbus from "../../utility/eventbus.js";
import EventBus from "../../utility/eventbus.js";
import {mapState} from "vuex";
import {ContentTypeEnum, LessonClassificationEnum, LessonHeroEnum, LessonTypeEnum} from "../../config/enums";
import {setTimeout} from "timers";
import groupMultiselect from "../../components/forms/groups-multiselect.vue";

export default {
	components: {
		Multiselect,
		CreateLessonContent,
		mediaManager,
		groupMultiselect
	},

	data: function () {
		return {
			breadcrumbitems: [
				{
					text: "Lessons",
					href: "#"
				},
				{
					text: "All",
					active: true
				}
			],
			pageTitle: "",
			lesson_id: null,
			helper: helper,
			ContentTypeEnum: ContentTypeEnum,
			LessonClassificationEnum: LessonClassificationEnum,
			LessonHeroEnum: LessonHeroEnum,
			LessonTypeEnum: LessonTypeEnum,
			lesson_types: [
				LessonTypeEnum.ACTIVITY,
				LessonTypeEnum.COMIC,
				LessonTypeEnum.ARTICLE,
				LessonTypeEnum.VIDEO
			],
			lesson_classifications: [
				LessonClassificationEnum.INQUIRE,
				LessonClassificationEnum.TRANSFORM,
				LessonClassificationEnum.LEARN
			],
			lesson_heros: [
				LessonHeroEnum.CHIKARA,
				LessonHeroEnum.ECHO,
				LessonHeroEnum.INFLUENCER,
				LessonHeroEnum.IRIS,
				LessonHeroEnum.SAGE
			],
			lessonForm: {
				title: "",
				hero: "",
				type: "",
				classification: "",
				cover: "",
				cover_image_id: "",
				author_id: this.author_id,
				focus: "",
				standards: "",
				overview: "",
				content: [],
				skills: "",
				sel_competencies: "",
				publish: false,
				group_id: null
			},
			lesson: {
				title: "",
				hero: "",
				type: "",
				classification: "",
				cover: "",
				cover_image_id: "",
				author_id: this.author_id,
				focus: "",
				standards: "",
				overview: "",
				content: [],
				skills: "",
				sel_competencies: "",
				published_at: null,
				group_id: null,
			},
			isPublishing: false,
			isUnpublishing: false,
			isSaving: false,
			isDeleting: false,
			isLoadingTags: false,
			isLoadingContent: false,
			isLoadingPlaylist: false,
			searchTimer: "",
			tagsList: [],
			contentList: [],
			selectedContent: [],
			playlistList: [],
			selectedPlaylists: [],
			mediaManager: false,
			request_id: null,
			changed: false,
		};
	},

	computed: {
		...mapState("auth", {
			author_id: "id",
		}),

		published() {
			return this.lesson.published_at != null;
		}
	},

	beforeMount() {
		if (this.$route.params.custom_lesson_id === "new") {
			this.pageTitle = "Create new lesson";
			this.breadcrumbitems[1].text = "New";
		} else {
			// fetch the lesson here;
			this.fetchLesson(this.$route.params.custom_lesson_id);
			this.lesson_id = this.$route.params.custom_lesson_id;
		}
	},

	mounted() {
		eventbus.$on("media-manager-complete", this.MediaEventHandler);
	},

	destroyed() {
		eventbus.$off("media-manager-complete");
	},

	watch: {
		lessonForm: {
			handler(val) {
				return this.changed = JSON.stringify(val) !== JSON.stringify(this.lesson);
			},
			deep: true
		}
	},

	methods: {
		setGroup(e) {
			if (!Array.isArray(e)) {
				this.lessonForm.group_id = e.id;
			}
		},

		MediaEventHandler(data) {
			switch (data.response_id) {
				case "LESSON_COVER":
					if ((data.response_id = this.request_id)) {
						this.onChanged("cover");
						this.lessonForm.cover = data.url;
						this.lessonForm.cover_image_id = data.id;
						this.mediaManager = null;
					}
					break;
				default:
					break;
			}
		},

		DispatchMediaEvent(type) {
			this.mediaManager = !this.mediaManager;
			this.request_id = type;
			setTimeout(() => {
				eventbus.$emit("media-manager", type);
			}, 1000);
		},

		// To fetch lesson data
		fetchLesson(id) {
			axios
				.get(`/api/lesson/${id}`)
				.then(({data}) => {
					const {
						id,
						title,
						hero,
						type,
						classification,
						cover,
						focus,
						standards,
						overview,
						// tags,
						content,
						//	playlists,
						published_at,
						skills,
						sel_competencies,
						group_id,
						group
					} = data;
					this.pageTitle = `Lesson: ${title}`;
					this.breadcrumbitems[1].text = title;
					this.lesson = helper.deepClone({
						id,
						title,
						hero,
						type,
						classification,
						cover,
						focus,
						standards,
						overview,
						// tags: tags.map(tag => tag.name),
						content,
						//playlists,
						published_at,
						skills,
						sel_competencies,
						group_id
					});
					EventBus.$emit("SET-INITIAL-GROUP", group);
					this.lessonForm = helper.deepClone(this.lesson);
					this.selectedContent = content;
				})
				.catch(({message}) => {
					toastr["error"](message);
				});
		},

		// Get Tags for multi-select
		getTagsAsync: function (query) {
			clearTimeout(this.searchTimer);
			this.searchTimer = setTimeout(this.tagSearch(query), 500);
		},

		tagSearch(query) {
			if (query) {
				this.isLoadingTags = true;
				debounce({
					method: "get",
					url: `/api/search/tags/${query}`,
					timeout: 60000
				})
					.then(({data}) => {
						this.tagsList = data.map(tag => tag.name);
						this.isLoadingTags = false;
					})
					.catch(error => {
						return null;
					});
			}
		},

		addTag(newTag) {
			this.lessonForm.tags.push(newTag);
			this.onChanged("tags");
		},

		// Get Content for multi-select
		getContentAsync: function (query) {
			clearTimeout(this.searchTimer);
			this.searchTimer = setTimeout(this.contentSearch(query), 500);
		},

		contentSearch(query) {
			if (query) {
				this.isLoadingContent = true;
				debounce({
					method: "get",
					url: `/api/search/content/${query}`,
					timeout: 60000
				})
					.then(({data}) => {
						this.contentList = data;
						this.isLoadingContent = false;
					})
					.catch(error => {
						return null;
					});
			}
		},

		// Get Playlists for multi-select
		getPlaylistAsync: function (query) {
			clearTimeout(this.searchTimer);
			this.searchTimer = setTimeout(this.playlistSearch(query), 500);
		},

		playlistSearch(query) {
			if (query) {
				this.isLoadingPlaylist = true;
				debounce({
					method: "get",
					url: `/api/search/playlist/${query}`,
					timeout: 60000
				})
					.then(({data}) => {
						this.playlistList = data.map(function (playlist) {
							return {name: playlist.name, id: playlist.id};
						});
						this.isLoadingPlaylist = false;
					})
					.catch(error => {
						return null;
					});
			}
		},

		clearAll() {
			this.contentList = [];
			this.playlistList = [];
		},

		// Show/hide Content form
		toggleAddContent: function () {
			let buttonText = $("#addContentButton");
			if (buttonText.html().indexOf("Add New Content to Lesson") >= 0) {
				buttonText.html("Cancel");
				buttonText.removeClass("expand");
				buttonText.addClass("closeup");
			} else if (buttonText.html().indexOf("Cancel") >= 0) {
				buttonText.html("Add New Content to Lesson");
				buttonText.removeClass("closeup");
				buttonText.addClass("expand");
			}
		},

		// Show/hide Playlist form
		toggleAddPlaylist: function () {
			let buttonText = $("#addPlaylistButton");
			if (buttonText.html().indexOf("Add Lesson to New Playlist") >= 0) {
				buttonText.html("Cancel");
				buttonText.removeClass("expand");
				buttonText.addClass("closeup");
			} else if (buttonText.html().indexOf("Cancel") >= 0) {
				buttonText.html("Add Lesson to New Playlist");
				buttonText.removeClass("closeup");
				buttonText.addClass("expand");
			}
		},

		// Handle content added to lesson
		onNewContentAdded: function (newContent) {
			this.selectedContent.push(newContent);
			this.onChanged("contents");
		},

		//
		routeToEditContent(content) {
			return this.$router.push({
				name: "adminEditContent",
				params: {
					content_id: content,
				}
			});
		},

		// Handle content added to lesson

		onNewPlaylistAdded: function (newPlaylist) {
			let mappedPlaylist = {name: newPlaylist.name, id: newPlaylist.id};
			this.lessonForm.playlists.push(mappedPlaylist);
		},

		// Change multiselect arrays to ids
		unMapArrays() {
			if (this.selectedContent[0]) {
				this.lessonForm.content = this.selectedContent.map(function (
					content
				) {
					return content.id;
				});
			}
			if (this.selectedPlaylists[0]) {
				this.lessonForm.playlists = this.selectedPlaylists.map(function (
					playlist
				) {
					return playlist.id;
				});
			}
		},

		submitPrevent(e) {
			return e.preventDefault();
		},

		// Handle lesson image upload
		onLessonImageChange(e) {
			let files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;
			this.createImage(files[0]);
			this.onChanged("cover");
		},

		createImage(file) {
			let reader = new FileReader();
			let vm = this;
			reader.onload = e => {
				vm.lessonForm.cover = e.target.result;
			};
			reader.readAsDataURL(file);
		},

		// Set default author for lesson
		setAuthor() {
			this.lessonForm.author_id = this.author_id;
		},

		// Save or update lesson
		saveLesson(publish = false) {
			try {
				this.isSaving = true;
				this.unMapArrays();
				this.setAuthor();
				//this.lessonForm.publish = publish;
				if (this.$route.params.custom_lesson_id === "new") {
					// save as new lesson
					delete this.lessonForm.id;
					axios
						.post("/api/lesson", this.lessonForm)
						.then(({data}) => {
							toastr["success"]("Save successful");
							this.isSaving = false;
							//	this.$router.go(0);
						})
						.catch(({message}) => {
							this.isSaving = false;
							this.isPublishing = false;
							toastr["error"](message);
						});
				} else {
					delete this.lessonForm.id;

					if (this.lessonForm["cover"] === this.lesson["cover"]) {
						delete this.lessonForm.cover;
					}
					// update existing lesson
					axios
						.put(`/api/lesson/${this.lesson_id}`, this.lessonForm)
						.then(({message}) => {
							toastr["success"]("Save successful");
							this.isSaving = false;
							this.isPublishing = false;
							this.$router.go(0);
						})
						.catch(({message}) => {
							this.isSaving = false;
							this.isPublishing = false;
							toastr["error"](message);
						});
				}
			} catch (error) {
				this.isSaving = false;
				return toastr["error"]("An error occurred while saving lesson");
			}
		},

		publishLesson() {
			this.isPublishing = true;

			if (this.$route.params.custom_lesson_id === "new") {
				this.saveLesson(true);
			} else {
				try {
					// publish existing lesson
					axios
						.get(`/api/lesson/${this.lesson_id}/publish`)
						.then(({message}) => {
							toastr["success"]("Publish successful");
							this.isPublishing = false;
							this.fetchLesson(this.lesson_id);
						})
						.catch(({message}) => {
							this.isPublishing = false;
							toastr["error"](message);
						});
				} catch (error) {
					this.isisPublishing = false;
					return toastr["error"]("An error occurred while publishing lesson");
				}
			}
		},

		unpublishLesson() {
			this.isUnpublishing = true;
			try {
				// unpublish existing lesson
				axios
					.get(`/api/lesson/${this.lesson_id}/unpublish`)
					.then(({message}) => {
						toastr["success"]("Unpublish successful");
						this.isUnpublishing = false;
						this.fetchLesson(this.lesson_id);
					})
					.catch(({message}) => {
						this.isUnpublishing = false;
						toastr["error"](message);
					});
			} catch (error) {
				this.isUnpublishing = false;
				return toastr["error"]("An error occurred while publishing lesson");
			}
		},

		// Reset input changes
		discardChanges() {
			if (this.$route.params.custom_lesson_id === "new") {
				this.lessonForm = {
					title: "",
					hero: "",
					type: "",
					classification: "",
					cover: "",
					author_id: this.author_id,
					focus: "",
					standards: "",
					overview: "",
					content: [],
					playlists: [],
					skills: "",
					sel_competencies: "",
					tags: [],
					publish: false
				};
			} else {
				this.lessonForm = {
					...this.lesson
				};
				this.changed = false;
			}
		},

		// View lesson
		viewLesson() {
			return this.$router.push({
				name: "showLesson",
				params: {
					lesson_id: this.lesson.id
				}
			});
		},

		// Delete lesson
		deleteLesson(id) {
			this.isDeleting = true;
			try {
				return axios
					.delete(`/api/lesson/${this.lesson_id}`)
					.then(data => {
						this.$router.replace({
							path: "/principal/custom-lessons"
						});
						this.isSaving = false;
					});
			} catch (error) {
				this.isDeleting = false;
				return toastr["error"](
					"An error occurred while deleting lesson"
				);
			}
		},

		// Manually handle some input changes
		onChanged(field) {
			this.$validator.flag(field, {
				dirty: true
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}

#collapseAddNewContent,
#collapseAddNewPlaylist {
	margin-top: 20px;
	margin-bottom: 20px;
}

#addContentButton,
#addPlaylistButton {
	font-size: small;

	&.closeup::before {
		content: "\F057";
	}

	&.expand::before {
		content: "\F055";
	}

	&::before {
		display: inline-block;
		margin-right: 5px;
		font-family: "Font Awesome 5 Free", serif;
		font-style: normal;
		font-weight: 900;
		font-variant: normal;
		color: $white;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
	}
}

.option__block {
	.multiselect__tag {
		height: fit-content;
		margin-right: 0;
	}

	padding: 5px;
	margin: 10px 10px;
	border: 1px solid #ced4da;
	border-radius: 3px;
}

.option__title {
	a {
		color: white;
	}

	i {
		color: gray;

		&:hover {
			color: red;
		}
	}
}

.option__preview {
	white-space: normal;
}
</style>

<style lang="scss" scoped>
.contents_multi .multiselect__content-wrapper,
.contents_multi .multiselect__content {
	width: 650px !important;
	word-wrap: normal;
}
</style>
