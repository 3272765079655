<template>
	<div>
		<div v-if="classroom"
			id="classroom-thumbnail"
			@click="routeToViewClassroom()">
			<div class="page-section">
				<h3>{{ classroom.name }} {{ classroom.type }}</h3>
			</div>
		</div>
	</div>
</template>

<script>
export default {

	name: "ClassroomThumbnail",

	props: {
		classroom: {
			type: Object,
			default: null,
		},
	},

	data() {
		return {
		};
	},
	
	computed: {
	},
	
	mounted() {
	},

	methods: {
		routeToViewClassroom() {
			return this.$router.push({
				name: "showClassroom",
				params: {
					classroom_id: this.classroom.id,
				}
			});
		},
		
	}

};
</script>

<style lang="scss" scoped>
	#classroom-thumbnail {
		margin-bottom: 30px;
	}
</style>
