export const ContentTypeEnum = {
	VIDEO: "Video",
	IMAGE: "Image",
	COMIC: "Comic",
	AUDIO: "Audio",
	TEXT: "Text"
};

export const LessonClassificationEnum = {
	LEARN: "Learn",
	INQUIRE: "Inquire",
	TRANSFORM: "Transform"
};

export const LessonHeroEnum = {
	INFLUENCER: "Influencer",
	CHIKARA: "Chikara",
	SAGE: "Sage",
	IRIS: "Iris",
	ECHO: "Echo"
};

export const LessonTypeEnum = {
	VIDEO: "Video",
	COMIC: "Comic",
	ARTICLE: "Article",
	ACTIVITY: "Activity"
};

export const PlanTypeEnum = {
	TEACHER_FREE_TRIAL: "teacher-free-trial",
	INDIVIDUAL_MONTHLY: "individual-monthly",
	INDIVIDUAL_YEARLY: "individual-yearly",
	STUDENT_FREE_TRIAL: "student-free-trial",
	SCHOOL: "school",
	DISTRICT: "district",
	FREE_TRIAL: "free-trial",
};

export const UserTypeEnum = {
	SUPER_ADMIN: "SuperAdmin",
	ADMIN: "Admin",
	GROUP_ADMIN: "GroupAdmin",
	EDITOR: "Editor",
	STUDENT: "Student",
	TEACHER: "Teacher",
	GUEST: "Guest"
};

export const AudienceTypeEnum = {
	Teacher: "Teacher",
	Student: "Student",
	Group: "Group",
	Classroom: "Classroom",
	Global: "Global",
};

export const EventActionEnum = {
	Login: "Login",
	Signup: "Signup",
	ResetPassword: "ResetPassword",
	View: "View",
	Update: "Update",
	Delete: "Delete",
	Create: "Create",
};

export const EventCategoryEnum = {
	Admin: "Admin",
	Student: "Student",
	Teacher: "Teacher",
	GroupAdmin: "GroupAdmin",
	Guest: "Guest",
	SuperAdmin: "SuperAdmin",
};

export const EventLabelEnum = {
	Users: "Users",
	Roles: "Roles",
	Lessons: "Lessons",
	Lesson: "Lesson",
	Contents: "Contents",
	Content: "Content",
	Playlists: "Playlists",
	Playlist: "Playlist",
	Subscription: "Subscription",
	Plans: "Plans",
	Announcements: "Announcements",
	Auth: "Auth",
	Classrooms: "Classrooms",
	Classroom: "Classroom",
	Dashboard: "Dashboard",
	Assignments: "Assignments",
	Assignment: "Assignment",
	Students: "Students",
	Teachers: "Teachers",
};
