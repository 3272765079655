/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import "./config/bootstrap";
import router from "./config/routes";
import {store} from "./vuex/index";
import Vue from "vue";
import NProgress from "vue-nprogress";
import LoadingScreen from "./components/utility/loading-screen.vue";


// Create Application
new Vue({
	nprogress: new NProgress(),
	components: {
		LoadingScreen
	},
	el: "#app",
	store,
	router,
	data: function () {
		return {
			isLoading: true
		};
	},
	mounted() {
		this.isLoading = false;
	},
});
