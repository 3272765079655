<template>
	<div>
		<div class="page">
			<div>
				<div class="row">
					<div class="col-md-6">
						<!-- page title -->
						<h3>{{ pageTitle }}</h3>
					</div>
				</div>
				<br>

				<div>
					<!-- breadcrumb links -->
					<b-breadcrumb :items="breadcrumbitems"/>
				</div>
				<div style="padding-top: 20px; background: white;">
					<!-- Media manager -->
					<b-modal
						v-if="mediaManager"
						id="modal-center"
						v-model="mediaManager"
						size="xl"
						hide-footer
						lazy
						centered
						title="Media Manager">
						<div class="row">
							<div class="col-md-12">
								<media-manager :modal-mode="true"
											   :collection="'content'"
											   :public="true"/>
							</div>
						</div>
					</b-modal>
					<div class="row">
						<div class="col-md-12 lesson-text">
							<form enctype="multipart/form-data"
								  @submit.prevent="submitPrevent">
								<!-- Content title -->
								<div class="col-md-12 form-group">
									<label for="title"
										   class="control-label">Content Title</label>
									<input
										v-validate
										id="title"
										v-model="contentForm.title"
										name="title"
										type="text"
										class="form-control">
								</div>

								<!-- Content lesson -->
								<div class="col-md-12 form-group">
									<label for="title"
										   class="control-label">Lesson</label>
									<multiselect
										v-validate
										id="lessons"
										:searchable="true"
										:loading="isLoadingLesson"
										v-model="selectedLesson"
										:internal-search="false"
										:options="lessonList"
										:options-limit="15"
										:close-on-select="true"
										:allow-empty="true"
										name="lessons"
										placeholder="Type to search for lesson"
										track-by="id"
										label="title"
										@search-change="getLessonAsync"/>
								</div>

								<!-- Content order number -->
								<div class="col-md-12 form-group">
									<label for="title"
										   class="control-label">Content Order No:</label>
									<input
										v-validate="'required|integer'"
										id="order"
										v-model="contentForm.order"
										name="order"
										type="number"
										class="form-control">
									<span class="error">{{ errors.first("order") }}</span>
								</div>

								<!-- Content type -->
								<div class="col-md-12 form-group">
									<label for="type"
										   class="control-label">Type</label>
									<multiselect
										v-validate
										id="type"
										v-model="contentForm.type"
										:options="content_types"
										:searchable="false"
										:show-labels="false"
										:allow-empty="false"
										name="type"
										placeholder="Select Content Type"
										@input="changeContentType(contentForm.type)"/>
								</div>

								<input v-validate
									   id="link"
									   v-model="contentForm.link"
									   name="link"
									   hidden>

								<!-- Comic/Image preview -->
								<div
									v-if="contentForm.type === ContentTypeEnum.COMIC ||
										contentForm.type === ContentTypeEnum.IMAGE"
									class="col-md-12 form-group"
									height="600px"
									width="100%">
									<img
										v-if="contentForm.link"
										:src="contentForm.link"
										class="img-responsive"
										width="100%"
										height="100%">
								</div>

								<div v-if="contentForm.type === ContentTypeEnum.AUDIO"
									 class="col-md-12 form-group">
									<audio v-if="contentForm.link"
										   id="content_audio"
										   controls>
										<source :src="contentForm.link">
									</audio>
								</div>

								<!-- Video preview -->
								<div
									v-if="contentForm.type === ContentTypeEnum.VIDEO && contentForm.link &&
										!errors.first('video')"
									class="col-md-12 text-center form-group">
									<div class="embed-responsive embed-responsive-21by9">
										<iframe :src="contentForm.link"/>
									</div>
								</div>

								<!-- Upload button -->
								<div
									v-if="contentForm.type === ContentTypeEnum.COMIC || contentForm.type ===
										ContentTypeEnum.IMAGE || contentForm.type === ContentTypeEnum.AUDIO"
									class="col-md-12 form-group">
									<b-button
										class="m-1"
										variant="primary"
										@click="DispatchMediaEvent(`CONTENT_${contentForm.type.toUpperCase()}`)">
										Upload {{ contentForm.type || "" }}
									</b-button>
								</div>

								<!-- Video Link -->
								<div v-if="contentForm.type === ContentTypeEnum.VIDEO"
									 class="col-md-12 form-group">
									<label class="control-label">Video Embed Link</label>
									<input
										v-validate="{ url: {require_protocol: true }}"
										id="video"
										v-model="contentForm.link"
										name="video"
										type="text"
										class="form-control">

									<span class="error">{{ errors.first("video") }}</span>
								</div>

								<!-- Content Text Textarea -->
								<div v-if="contentForm.type === ContentTypeEnum.TEXT"
									 class="col-md-12 form-group">
									<label class="control-label">Content Text</label>

									<quill
										v-validate
										id="text"
										ref="richEditor"
										v-model="contentForm.text"
										output="html"
										name="text"
										:config="config"
										:bus="EventBus"
									></quill>
								</div>

								<!-- Content Caption Textarea -->
								<div
									class="col-md-12 form-group">
									<label class="control-label">Content Caption</label>

									<wysiwyg
										v-validate
										id="caption"
										v-model="contentForm.caption"
										name="caption"
										placeholder="Add the caption to this lesson content"
										@change="onChanged('caption')"/>

								</div>

								<!-- Content Notes Textarea -->
								<div class="col-md-12 form-group">
									<label class="control-label">Content Notes</label>

									<wysiwyg
										v-validate
										id="notes"
										v-model="contentForm.notes"
										name="notes"
										placeholder="Add the text content"
										@change="onChanged('notes')"/>
								</div>

								<!-- action buttons -->
								<div class="col-md-12 form-group">
									<button
										v-if="changed || !content_id"
										:disabled="!(errors.items.length <= 0) || isSaving"
										type="button"
										class="btn btn-success"
										@click="saveContent">
										<i v-if="isSaving"
										   class="fas fa-circle-notch fa-spin"/>
										<span>Save</span>
									</button>
									<button v-if="changed"
											type="button"
											class="btn btn-warning"
											@click="discardChanges">
										<span>Discard</span>
									</button>
									<button v-if="content_id"
											type="button"
											class="btn btn-danger"
											@click="deleteContent">
										<i v-if="isDeleting"
										   class="fas fa-circle-notch fa-spin"/>
										<span>Delete</span>
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import debounce from "../../utility/debounce";
import Multiselect from "vue-multiselect";
import axios from "axios";
import {ContentTypeEnum} from "../../config/enums";
import mediaManager from "../../components/utility/media-manager.vue";
import eventbus from "../../utility/eventbus.js";
import EventBus from "../../utility/eventbus.js";
import helper from "../../utility";

export default {
	components: {
		Multiselect,
		mediaManager
	},

	data: function () {
		return {
			breadcrumbitems: [
				{
					text: "Contents",
					href: "#"
				},
				{
					text: "All",
					active: true
				}
			],
			pageTitle: "",
			content_id: null,
			ContentTypeEnum: ContentTypeEnum,
			content_types: [
				ContentTypeEnum.VIDEO,
				ContentTypeEnum.IMAGE,
				ContentTypeEnum.COMIC,
				ContentTypeEnum.AUDIO,
				ContentTypeEnum.TEXT
			],
			contentForm: {
				id: "",
				lesson: "",
				lesson_id: "",
				link: "",
				notes: "",
				order: "",
				text: "",
				title: "",
				type: "",
				caption: "",
				media_id: "",
			},
			content: {
				id: "",
				lesson: "",
				link: "",
				notes: "",
				order: "",
				text: "",
				title: "",
				type: "",
				caption: "",
				media_id: "",
			},
			isSaving: false,
			isDeleting: false,
			isLoadingLesson: false,
			lessonList: [],
			selectedLesson: null,
			mediaManager: false,
			request_id: null,
			EventBus,
			config: {
				readOnly: false,
				strict: false,
				modules: {
					toolbar: [
						["bold", "italic", "underline", "strike", "blockquote"],
						[
							{header: "1"},
							{header: "2"},
							{header: [3, 4, 5, 6]},
							{font: []}
						],
						[{size: []}],
						[{color: []}, {background: []}],
						[{list: "ordered"}, {list: "bullet"}],
						[{script: "sub"}, {script: "super"}],
						[{indent: "-1"}, {indent: "+1"}],
						["link", "image", "video"],
						["code-block"],
						["clean"]
					]
				}
			}
		};
	},

	computed: {
		changed() {
			return Object.keys(this.fields).some(key => this.fields[key].dirty);
		},

		upload_accept() {
			switch (this.contentForm.type) {
				case this.ContentTypeEnum.AUDIO:
					return "audio/*";
				case this.ContentTypeEnum.COMIC:
					return "image/*";
				case this.ContentTypeEnum.IMAGE:
					return "image/*";
				default:
					return "";
			}
		}
	},

	// to retrieve the neccessary details before mount
	beforeMount() {
		if (this.$route.params.content_id === "new") {
			this.pageTitle = "Create new Content";
			this.breadcrumbitems[1].text = "New";
			this.contentForm.order = 1;
			this.contentForm.type = this.ContentTypeEnum.COMIC;
		} else {
			// fetch the content here
			this.fetchContent(this.$route.params.content_id);
			this.content_id = this.$route.params.content_id;
		}

		this.setContentMime();
	},

	mounted() {
		eventbus.$on("media-manager-complete", this.MediaEventHandler);
	},

	destroyed() {
		eventbus.$off("media-manager-complete");
	},

	methods: {
		MediaEventHandler(data) {
			switch (data.response_id) {
				case "CONTENT_IMAGE":
					if ((data.response_id = this.request_id)) {
						this.onChanged("link");
						this.contentForm.link = data.url;
						this.contentForm.media_id = data.id;
						this.mediaManager = null;
					}
					break;
				case "CONTENT_COMIC":
					if ((data.response_id = this.request_id)) {
						this.onChanged("link");
						this.contentForm.link = data.url;
						this.contentForm.media_id = data.id;
						this.mediaManager = null;
					}
					break;
				default:
					break;
			}
		},

		DispatchMediaEvent(type) {
			this.mediaManager = !this.mediaManager;
			this.request_id = type;
			setTimeout(() => {
				eventbus.$emit("media-manager", type);
			}, 1000);
		},

		getLessonAsync: function (query) {
			clearTimeout(this.searchTimer);
			this.searchTimer = setTimeout(this.lessonSearch(query), 500);
		},

		lessonSearch(query) {
			if (query) {
				this.isLoadingLesson = true;
				debounce({
					method: "get",
					url: `/api/search/lesson/${query}`,
					timeout: 60000
				})
					.then(({data}) => {
						this.lessonList = data.map(function (lesson) {
							return {title: lesson.title, id: lesson.id};
						});
						this.isLoadingLesson = false;
					})
					.catch(error => {
						this.isLoadingLesson = false;
						return null;
					});
			}
		},

		// Method to switch content type
		changeContentType(type) {
			if (!this.changed) {
				//clear link
				this.contentForm.link = "";
			} else if (this.changed && this.content.type === type) {
				//reset original content
				this.contentForm.link = this.content.link;
			} else if (this.changed && this.content.type !== type) {
				//reset original content
				this.contentForm.link = "";
			}

			this.setContentMime();
		},

		setContentMime() {
		},

		onChanged(field) {
			this.$validator.flag(field, {
				dirty: true
			});
		},

		// To handle content image upload
		onContentFileChange(e) {
			if (this.contentForm.type !== this.ContentTypeEnum.VIDEO) {
				let files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;
				this.createFile(files[0]);
			}
		},

		// same as above
		createFile(file) {
			this.contentForm.link = "";
			let reader = new FileReader();
			let vm = this;
			reader.onload = e => {
				vm.contentForm.link = e.target.result;
			};
			reader.readAsDataURL(file);
		},

		// to fetch content data from api
		fetchContent(id) {
			axios
				.get(`/api/content/${id}`)
				.then(({data}) => {
					const {
						id,
						lesson,
						link,
						notes,
						order,
						text,
						title,
						type,
						caption
					} = data;

					this.content = {
						id,
						lesson,
						link,
						notes,
						order,
						text,
						title,
						type,
						caption
					};
					this.contentForm = helper.deepClone({
						id,
						lesson,
						link,
						notes,
						order,
						text,
						title,
						type,
						caption
					});
					if (title) {
						this.pageTitle = `Content: ${title}`;
						this.breadcrumbitems[1].text = title;
						if (lesson) {
							this.selectedLesson = {
								title: lesson.title,
								id: lesson.id
							};
						}
					} else {
						this.pageTitle = "Content: Untitled";
						this.breadcrumbitems[1].text = "Untitled";
					}
				})
				.catch(error => {
					toastr["error"]("Could not retrieve content");
				});
		},
		submitPrevent(e) {
			return e.preventDefault();
		},

		// to save or update content
		saveContent() {
			this.isSaving = true;

			if (this.selectedLesson) {
				this.contentForm.lesson_id = this.selectedLesson.id;
			}

			if (this.$route.params.content_id === "new") {
				// save as new Content
				delete this.contentForm.id;

				axios
					.post("/api/content", this.contentForm)
					.then(({data}) => {
						toastr["success"]("Save successful");
						this.isSaving = false;
						this.$router.push({
							path: `/admin/contents/${data.id}`
						});
					})
					.catch(({message}) => {
						this.isSaving = false;
						toastr["error"](message);
					});
			} else {
				delete this.contentForm.lesson;
				// update existing Content
				axios
					.put(`/api/content/${this.content_id}`, this.contentForm)
					.then(({data, message}) => {
						toastr["success"]("Save successful");
						this.isSaving = false;
						this.$router.push({
							path: `/admin/contents/${this.content.id}`
						});
					})
					.catch(({message}) => {
						this.isSaving = false;
						toastr["error"](message);
					});
			}
		},

		// to revert changes
		discardChanges() {
			if (this.$route.params.content_id === "new") {
				this.contentForm = {
					id: "",
					lesson: "",
					link: "",
					notes: "",
					order: "",
					text: "",
					title: "",
					type: ""
				};
			} else {
				const {
					id,
					lesson,
					link,
					notes,
					order,
					text,
					title,
					type
				} = this.content;
				this.contentForm = {
					id,
					lesson,
					link,
					notes,
					order,
					text,
					title,
					type
				};
			}
		},
		// to delete content
		deleteContent(id) {
			this.isDeleting = true;
			try {
				return axios
					.delete(`/api/content/${this.content_id}`)
					.then(({data}) => {
						this.isDeleting = false;
						this.$router.replace({
							path: "/admin/contents"
						});
					});
			} catch (error) {
				this.isDeleting = false;
				return toastr["error"](
					"An error occurred while deleting content"
				);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}

span.error {
	display: block;
	margin-top: 5px;
	color: red;
}

.html-preview {
	padding: 30px;
	border: 2px solid #ced4da;
}
</style>
