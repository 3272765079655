import Errors from "./errors";

class Form {

	/**
	 * Create a new Form instance.
	 *
	 * @param {object} data
	 */
	constructor(data, autoReset) {

		this.originalData = data;
		this.autoReset = autoReset !== undefined ? autoReset : true;
		for (let field in data) {
			this[field] = data[field];
		}


		this.errors = new Errors();
	}


	/**
	 * Fetch all relevant data for the form.
	 */
	data() {
		let data = {};

		for (let property in this.originalData) {
			data[property] = this[property];
		}

		return data;
	}


	/**
	 * Reset the form fields.
	 */
	reset() {
		for (let field in this.originalData) {
			this[field] = "";
		}

		this.errors.clear();
	}


	/**
	 * Send a POST request to the given URL.
	 * .
	 * @param {string} url
	 */
	post(url) {
		return this.submit("post", url);
	}


	/**
	 * Send a PUT request to the given URL.
	 * .
	 * @param {string} url
	 */
	put(url) {
		return this.submit("put", url);
	}


	/**
	 * Send a PATCH request to the given URL.
	 * .
	 * @param {string} url
	 */
	patch(url) {
		return this.submit("patch", url);
	}


	/**
	 * Send a DELETE request to the given URL.
	 * .
	 * @param {string} url
	 */
	delete(url) {
		return this.submit("delete", url);
	}


	/**
	 * Submit the form.
	 *
	 * @param {string} requestType
	 * @param {string} url
	 */
	submit(requestType, url) {
		return new Promise((resolve, reject) => {
			axios[requestType](url, this.data())
				.then(({data, status}) => {
					if (status)
						this.onSuccess(data);

					resolve(data);
				})
				.catch(({message}) => {
					this.onFail(message);

					reject(message);
				});
		});
	}


	/**
	 * Handle a successful form submission.
	 *
	 * @param {object} data
	 */
	onSuccess(data) {
		if (this.autoReset === true) {
			this.reset();
		}
	}


	/**
	 * Handle a failed form submission.
	 *
	 * @param {object} errors
	 */
	onFail(errors) {
		this.errors.record(errors);
	}
}

export default Form;
