<template>
	<b-modal title="Create Classroom"
			 hide-footer
			 centered
			 v-model="modalVisible">
		<div>
			<b-form @submit="onSubmit"
					@reset="onReset">
				<b-form-group
					id="input-group-1"
					label="NAME:"
					label-for="input-1">
					<b-form-input
						id="input-1"
						v-model="form.name"
						required
						placeholder="What do we call this class"/>
				</b-form-group>

				<b-form-group id="input-group-2"
							  label="CODE:"
							  label-for="input-2">
					<b-form-input
						id="input-2"
						v-model="form.code"
						required
						placeholder="Give this class a Code"/>
				</b-form-group>

				<b-form-group id="input-group-3"
							  label="School/Group:"
							  label-for="input-3">
					<b-form-select
						id="input-3"
						v-model="form.group_id"
						:options="group_options"/>
				</b-form-group>

				<b-form-group id="input-group-4"
							  label="Type:"
							  label-for="input-3">
					<b-form-select
						id="input-3"
						v-model="form.type"
						:options="type_options"
						required/>
				</b-form-group>


				<b-button :loading="loading"
						  :disabled="loading"
						  type="submit"
						  variant="primary">
					Submit
				</b-button>
				<b-button type="reset" variant="danger">
					Reset
				</b-button>
			</b-form>
		</div>
	</b-modal>
</template>

<script>
import EventBus from "../../utility/eventbus";

export default {
	data() {
		return {
			groups: null,
			form: {
				name: "",
				code: "",
				group_id: null,
				type: "Class",
			},
			group_options: [
				{text: "Select a School/Group", value: null},
			],
			type_options: [
				"Class",
				"Club"
			],
			modalVisible: false,
			loading: false,
		};
	},
	watch: {
		groups: {
			handler(newVal) {
				if (newVal != null && newVal.length > 0) {
					this.parseGroupOptions(newVal);
				}
			},
			deep: true,
		},
	},
	computed: {
		user() {
			return this.$store.getters["auth/getAuthUser"];
		}
	},
	mounted() {
		EventBus.$on("CREATE-CLASSROOM", () => {
			this.modalVisible = true;
		});
		this.fetchGroups();
	},
	updated() {
		this.groups == null ? this.fetchGroups() : null;
	},
	methods: {
		parseGroupOptions(data) {
			const buffer = [
				{text: "Select a School/Group", value: null},
			];

			data.forEach((group) => {
				buffer.push({text: group.name, value: group.id});
			});

			this.group_options = buffer;
		},
		async fetchGroups() {
			try {
				const response = await axios.get("/api/teacher/groups");
				this.groups = response.data;
			} catch (error) {
				return toastr["error"]("Unable to get Groups");
			}
		},
		async onSubmit(evt) {
			evt.preventDefault();
			try {
				this.loading = true;
				const request = {...this.form};
				request.admin_id = this.user.id;
				const response = await axios.post("/api/classroom", request);
				this.loading = false;
				toastr["success"]("Class created Successfully");
				this.onReset();
				this.modalVisible = false;
				EventBus.$emit("CLASSROOM-CREATED");
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to create Class");
			}
		},
		onReset(evt) {
			evt != null ? evt.preventDefault() : null;
			this.form.name = "";
			this.form.code = "";
			this.form.group_id = null;
			this.form.type = "Class";
		}
	}
};
</script>
;
<style>

</style>
