<template>
	<span :title="isLoading" />
</template>

<script>
import {pleaseWait} from "please-wait";

export default {
	name: "LoadingScreen",
	props: {
		isLoading: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			pleaseWaitInstance: null
		};
	},
	mounted() {
		if (process.env.MIX_NODE_ENV !== "development") {
			this.updatePleaseWait();
		}
	},
	beforeUpdate() {
		if (process.env.MIX_NODE_ENV !== "development") {
			this.updatePleaseWait();
		}
	},
	methods: {
		updatePleaseWait() {
			if (this.isLoading && this.pleaseWaitInstance == null) {
				this.pleaseWaitInstance = pleaseWait({
					logo: "/images/logo/Site_Logo.png",
					backgroundColor: "#f3f3f3",
					backgroundImage: "/images/default-background.png",
					loadingHtml: `
					<div style="position: absolute; background-image: url('/images/default-background.png');  background-size: contain; min-height: 100%; min-width: 100%;">
					    <style>
					       .dot-1:before {
					       		background-color: #d9534f;
					       }
					       .dot-2:before {
					       		background-color: #2bb5dd;
					       }
					    </style>
					    <div class="sk-circle" style="position:fixed; top: 60%; left: 50%;">
					    	<div class="sk-circle-dot dot-1"></div>
  							<div class="sk-circle-dot dot-2"></div>
  							<div class="sk-circle-dot dot-1"></div>
  							<div class="sk-circle-dot dot-2"></div>
  							<div class="sk-circle-dot dot-1"></div>
  							<div class="sk-circle-dot dot-2"></div>
  							<div class="sk-circle-dot dot-1"></div>
  							<div class="sk-circle-dot dot-2"></div>
  							<div class="sk-circle-dot dot-1"></div>
  							<div class="sk-circle-dot dot-2"></div>
  							<div class="sk-circle-dot dot-1"></div>
  							<div class="sk-circle-dot dot-2"></div>
						</div>
				</div>`
				});
			}
			if (!this.isLoading && this.pleaseWaitInstance != null) {
				this.pleaseWaitInstance.finish();
			}
		}
	}
};
</script>

<style scoped>

</style>
