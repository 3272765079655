<template>
	<div>
		<h2>Create Lesson</h2>

		<div class="page-section">
			<div class="row">
				<div class="col-md-12 lesson-text">
					<form enctype="multipart/form-data"
						  @submit.prevent="submitPrevent">
						<b-modal
							v-if="mediaManager"
							id="modal-center"
							v-model="mediaManager"
							centered
							hide-footer
							lazy
							size="xl"
							title="Media Manager">
							<div class="row">
								<div class="col-md-12">
									<media-manager :modal-mode="true"/>
								</div>
							</div>
						</b-modal>

						<div class="col-md-12 form-group">
							<img v-if="lessonForm.cover"
								 :src="lessonForm.cover"
								 class="img-responsive"
								 width="200">
						</div>

						<div class="col-md-12 form-group">
							<b-button
								class="m-1"
								variant="primary"
								@click="DispatchMediaEvent('LESSON_COVER')">
								Upload Cover Image
							</b-button>
						</div>

						<div class="col-md-12 form-group">
							<label class="control-label"
								   for="title">Lesson Title</label>
							<input
								id="title"
								v-model="lessonForm.title"
								class="form-control"
								placeholder="Enter title"
								type="text">
						</div>

						<div class="col-md-12 form-group">
							<label class="control-label"
								   for="focus">Lesson Focus</label>
							<textarea
								id="focus"
								v-model="lessonForm.focus"
								class="form-control"
								placeholder="Enter focus"
								rows="5"/>
						</div>

						<div class="col-md-12 form-group">
							<label class="control-label"
								   for="hero">Lesson Hero</label>
							<multiselect
								id="hero"
								v-model="lessonForm.hero"
								v-validate
								:allow-empty="false"
								:options="lesson_heros"
								:searchable="false"
								:show-labels="false"
								name="hero"
								placeholder="Select Hero"/>
						</div>

						<div class="col-md-12 form-group">
							<label class="control-label"
								   for="type">Lesson Type</label>
							<multiselect
								id="type"
								v-model="lessonForm.type"
								v-validate
								:options="lesson_types"
								:searchable="false"
								:show-labels="false"
								name="type"
								placeholder="Select Type"/>
						</div>

						<div class="col-md-12 form-group">
							<label class="control-label"
								   for="classification">Lesson Classification</label>
							<multiselect
								id="classification"
								v-model="lessonForm.classification"
								v-validate
								:options="lesson_classifications"
								:searchable="false"
								:show-labels="false"
								name="classification"
								placeholder="Select Classification"/>
						</div>

						<div class="col-md-12 form-group"
							 hidden>
							<input id="author_id"
								   v-model="lessonForm.author_id"
								   type="text">
						</div>

						<div class="col-md-12 form-group">
							<label class="control-label"
								   for="overview">Overview</label>
							<textarea
								id="overview"
								v-model="lessonForm.overview"
								class="form-control"
								placeholder="Enter overview"
								rows="5"/>
						</div>

						<div class="col-md-12 form-group">
							<label class="control-label"
								   for="standards">Primary Standards</label>
							<textarea id="standards"
									  v-model="lessonForm.standards"
									  class="form-control"
									  rows="5"/>
						</div>

						<!-- tags section -->
						<div class="row col-md-12 form-group">
							<div class="form-group col-md-12">
								<label class="control-label"
									   for="tags">Lesson Tags</label>
								<div class="chips chips-initial input-field">
									<div v-for="(tag,index) in lessonForm.tags"
										 :key="`${tag}-${index}`"
										 class="chip">
										{{ tag }}
										<span class="closebtn"
											  @click="deleteTag(tag)">&times;</span>
									</div>
								</div>
							</div>
							<br>
							<div class="form-group col-md-8">
								<input
									ref="newTag"
									class="form-control"
									placeholder="Enter Tag"
									type="text"
									@keyup="addTagEnter">
							</div>
							<div class="form-group col-md-4"
								 style="padding-right: 0;">
								<button class="btn btn-primary btn-block btn-md"
										type="button"
										@click="addTag">
									<i class="fas fa-plus-circle icon"/>Add Tag
								</button>
							</div>
						</div>

						<div v-if="showAddContentForm"
							 class="addLessonContentForm">
							<div class="col-md-12 form-group"
								 style="margin-bottom: 0.5rem;">
								<label class="control-label d-block"
									   for="contents">Lesson Content</label>
							</div>

							<div class="row col-md-12 form-group"
								 style="padding-right: 0;">
								<div class="form-group col-md-8">
									<multiselect
										id="contents"
										v-model="lessonForm.content"
										:clear-on-select="false"
										:close-on-select="false"
										:internal-search="false"
										:loading="isLoadingContent"
										:multiple="true"
										:options="contentList"
										:options-limit="15"
										:searchable="true"
										label="title"
										placeholder="Type to search for content"
										track-by="id"
										@search-change="getContentAsync"/>
								</div>

								<div class="form-group col-md-4"
									 style="padding-right: 0;">
									<button
										id="addContentButton"
										aria-controls="collapseAddNewContent"
										aria-expanded="false"
										class="btn btn-primary btn-block btn-lg"
										data-target="#collapseAddNewContent"
										data-toggle="collapse"
										type="button"
										@click="toggleAddContent">
										<i class="fas fa-plus-circle icon"/>Add New Content to Lesson
									</button>
								</div>
							</div>

							<div id="collapseAddNewContent"
								 class="offset-md-1 col-md-10 collapse">
								<div class="card">
									<div class="card-body">
										<h5 class="card-title">
											Add New Content to Lesson
										</h5>
										<createLessonContent @content-added="onNewContentAdded"/>
									</div>
								</div>
							</div>
						</div>

						<div v-if="showAddPlaylistForm"
							 class="addPlaylistForm">
							<div class="col-md-12 form-group"
								 style="margin-bottom: 0.5rem;">
								<label class="control-label d-block"
									   for="contents">Playlists</label>
							</div>

							<div class="row col-md-12 form-group"
								 style="padding-right: 0;">
								<div class="form-group col-md-8">
									<multiselect
										id="playlists"
										v-model="lessonForm.playlists"
										:clear-on-select="false"
										:close-on-select="false"
										:internal-search="false"
										:loading="isLoadingPlaylist"
										:multiple="true"
										:options="playlistList"
										:options-limit="15"
										:searchable="true"
										label="name"
										placeholder="Type to search for playlist."
										track-by="id"
										@search-change="getPlaylistAsync"/>
								</div>

								<div class="form-group col-md-4"
									 style="padding-right: 0;">
									<button
										id="addPlaylistButton"
										aria-controls="collapseAddNewPlaylist"
										aria-expanded="false"
										class="btn btn-primary btn-block btn-lg"
										data-target="#collapseAddNewPlaylist"
										data-toggle="collapse"
										type="button"
										@click="toggleAddPlaylist">
										<i class="fas fa-plus-circle icon"/>Add Lesson to New Playlist
									</button>
								</div>
							</div>

							<div id="collapseAddNewPlaylist"
								 class="offset-md-1 col-md-10 collapse">
								<div class="card">
									<div class="card-body">
										<h5 class="card-title">
											Add Lesson to New Playlist
										</h5>
										<createPlaylist :show-add-lessons-form="false"
														@playlist-added="onNewPlaylistAdded"/>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-12 form-group">
							<button id="saveLesson"
									class="btn btn-success"
									type="button"
									@click="saveLesson">
								<i v-if="isSaving"
								   class="fas fa-circle-notch fa-spin"/>
								<span v-else>Create Lesson</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import CreateLessonContent from "./createLessonContent";
import CreatePlaylist from "./../playlists/createPlaylist";
import debounce from "../../utility/debounce";
import {ability} from "../../config/ability";
import mediaManager from "../../components/utility/media-manager.vue";
import eventbus from "../../utility/eventbus";
import {mapState} from "vuex";

import {LessonClassificationEnum, LessonHeroEnum, LessonTypeEnum} from "../../config/enums";

export default {
	name: "CreateLesson",

	components: {
		Multiselect,
		CreateLessonContent,
		CreatePlaylist,
		mediaManager
	},

	beforeRouteEnter(to, from, next) {
		if (ability.can("create", "lessons")) {
			return next();
		}
		return next({path: "/401"});
	},

	props: {
		showAddContentForm: {
			type: Boolean,
			default: true
		},
		showAddPlaylistForm: {
			type: Boolean,
			default: true
		}
	},

	data: function () {
		return {
			lessonForm: {
				title: "",
				hero: "",
				type: "",
				classification: "",
				cover: "",
				cover_image_id: "",
				author_id: this.author_id,
				focus: "",
				standards: "",
				overview: "",
				content: [],
				playlists: [],
				tags: []
			},
			LessonClassificationEnum: LessonClassificationEnum,
			LessonHeroEnum: LessonHeroEnum,
			LessonTypeEnum: LessonTypeEnum,
			lesson_types: [
				LessonTypeEnum.ACTIVITY,
				LessonTypeEnum.COMIC,
				LessonTypeEnum.ARTICLE,
				LessonTypeEnum.VIDEO
			],
			lesson_classifications: [
				LessonClassificationEnum.INQUIRE,
				LessonClassificationEnum.TRANSFORM,
				LessonClassificationEnum.LEARN
			],
			lesson_heros: [
				LessonHeroEnum.CHIKARA,
				LessonHeroEnum.ECHO,
				LessonHeroEnum.INFLUENCER,
				LessonHeroEnum.IRIS,
				LessonHeroEnum.SAGE
			],
			isLoadingContent: false,
			isLoadingPlaylist: false,
			isSaving: false,
			mediaManager: false,
			searchTimer: "",
			contentList: [],
			playlistList: []
		};
	},

	computed: {
		...mapState("auth", {
			author_id: "id",
		}),
	},

	mounted() {
		eventbus.$on("media-manager-complete", this.MediaEventHandler);
	},

	destroyed() {
		eventbus.$off("media-manager-complete");
	},

	methods: {
		MediaEventHandler(data) {
			switch (data.response_id) {
				case "LESSON_COVER":
					this.lessonForm.cover = data.url;
					this.lessonForm.cover_image_id = data.id;
					this.mediaManager = null;
					break;
				default:
					break;
			}
		},
		DispatchMediaEvent(type) {
			this.mediaManager = !this.mediaManager;
			this.request_id = type;
			setTimeout(() => {
				eventbus.$emit("media-manager", type);
			}, 1000);
		},
		submitPrevent(e) {
			return e.preventDefault();
		},
		deleteTag(tag) {
			this.lessonForm.tags = this.lessonForm.tags.filter(
				element => element !== tag
			);
		},
		addTag() {
			this.lessonForm.tags.push(this.$refs.newTag.value);
			return (this.$refs.newTag.value = "");
		},
		addTagEnter(e) {
			if (e.keyCode === 13) {
				e.preventDefault();
				this.lessonForm.tags.push(this.$refs.newTag.value);
				return (this.$refs.newTag.value = "");
			}
			return null;
		},
		getContentAsync: function (query) {
			clearTimeout(this.searchTimer);
			this.searchTimer = setTimeout(this.contentSearch(query), 500);
		},

		contentSearch(query) {
			if (query) {
				this.isLoadingContent = true;
				debounce({
					method: "get",
					url: `/api/search/content/${query}`,
					timeout: 60000
				})
					.then(({data}) => {
						this.contentList = data.map(function (content) {
							return {title: content.title, id: content.id};
						});
						this.isLoadingContent = false;
					})
					.catch(error => {
						return null;
					});
			}
		},

		getPlaylistAsync: function (query) {
			clearTimeout(this.searchTimer);
			this.searchTimer = setTimeout(this.playlistSearch(query), 500);
		},

		playlistSearch(query) {
			if (query) {
				this.isLoadingPlaylist = true;
				debounce({
					method: "get",
					url: `/api/search/playlist/${query}`,
					timeout: 60000
				})
					.then(({data}) => {
						this.playlistList = data.map(function (playlist) {
							return {name: playlist.name, id: playlist.id};
						});
						this.isLoadingPlaylist = false;
					})
					.catch(error => {
						return null;
					});
			}
		},

		clearAll() {
			this.contentList = [];
			this.playlistList = [];
		},

		onLessonImageChange: function (e) {
			let files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;
			this.createImage(files[0]);
		},

		createImage(file) {
			let reader = new FileReader();
			let vm = this;
			reader.onload = e => {
				vm.lessonForm.cover = e.target.result;
			};
			reader.readAsDataURL(file);
		},

		toggleAddContent: function () {
			let buttonText = $("#addContentButton");
			if (buttonText.html().indexOf("Add New Content to Lesson") >= 0) {
				buttonText.html("<i class=\"fas fa-times-circle icon\"/>Cancel");
			} else if (buttonText.html().indexOf("Cancel") >= 0) {
				buttonText.html(
					"<i class=\"fas fa-plus-circle icon\"/>Add New Content to Lesson"
				);
			}
		},

		toggleAddPlaylist: function () {
			let buttonText = $("#addPlaylistButton");
			if (buttonText.html().indexOf("Add Lesson to New Playlist") >= 0) {
				buttonText.html("<i class=\"fas fa-times-circle icon\"/>Cancel");
			} else if (buttonText.html().indexOf("Cancel") >= 0) {
				buttonText.html(
					"<i class=\"fas fa-plus-circle icon\"/>Add Lesson to New Playlist"
				);
			}
		},

		onNewContentAdded: function (newContent) {
			let mappedContent = {title: newContent.title, id: newContent.id};
			this.lessonForm.content.push(mappedContent);
		},

		onNewPlaylistAdded: function (newPlaylist) {
			let mappedPlaylist = {name: newPlaylist.name, id: newPlaylist.id};
			this.lessonForm.playlists.push(mappedPlaylist);
		},

		setAuthor: function () {
			this.lessonForm.author_id = this.author_id;
		},

		unMapArrays() {
			if (this.lessonForm.content !== null) {
				this.lessonForm.content = this.lessonForm.content.map(function (
					content
				) {
					return content.id;
				});
			}
			if (this.lessonForm.playlists !== null) {
				this.lessonForm.playlists = this.lessonForm.playlists.map(
					function (playlist) {
						return playlist.id;
					}
				);
			}
		},

		saveLesson: function () {
			this.isSaving = true;
			this.setAuthor();
			this.unMapArrays();
			axios
				.post("/api/lesson", this.lessonForm)
				.then(response => {
					toastr["success"](response.message);
					this.isSaving = false;
					this.$emit("lesson-added", response.data);
					setTimeout(() => {
						this.$router.go(0);
					}, 1000);
				})
				.catch(response => {
					this.isSaving = false;
					toastr["error"](response.message);
				});
		}
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
#collapseAddNewContent,
#collapseAddNewPlaylist {
	margin-top: 20px;
	margin-bottom: 20px;
}

#addContentButton,
#addPlaylistButton {
	font-size: small;
}

.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}

#saveLesson {
	min-width: 200px;
	text-align: center;

	i.fas.fa-circle-notch {
		margin-right: 10px;
	}
}
</style>
