<template>
	<b-modal :title="`Assign ${type} to Class`"
			 hide-footer
			 centered
			 v-model="modalVisible">
		<div>
			<p>{{ type === "lesson" ? "Lesson" : "Playlist" }} Preview:</p>
			<b-card
				v-if="type === 'lesson' && lesson_data"
				:title="lesson_data.title"
				tag="article"
				class="mb-2">
				<div :style="{ backgroundImage: `url(${lesson_data.cover})`}"
					 class="cover-image"/>
				<br>
				<b-card-text>
					{{ lesson_data.focus }}
				</b-card-text>
			</b-card>
			<b-card
				v-else-if="type === 'playlist' && playlist_data"
				:title="playlist_data.name"
				tag="article"
				class="mb-2">
				<div :style="{ backgroundImage: `url(${playlist_data.cover})`}"
					 class="cover-image"/>
				<br>
				<b-card-text v-html="playlist_data.description"/>
			</b-card>
			<div v-else/>
		</div>
		<br>
		<b-form @submit="onSubmit">
			<b-form-group id="input-group-3"
						  label="Class:"
						  label-for="input-3">
				<b-form-select
					id="input-3"
					v-model="class_id"
					:options="classes"
					required/>
			</b-form-group>
			<b-button :loading="loading"
					  :disabled="loading"
					  type="submit"
					  variant="primary">
				Proceed
			</b-button>
		</b-form>
	</b-modal>
</template>

<script>
import EventBus from "../../utility/eventbus";

export default {
	props: {
		type: {
			type: String,
			default: "Lesson"
		}
	},
	data() {
		return {
			class_id: null,
			modalVisible: false,
			loading: false,
			lesson_data: null,
			playlist_data: null,
			class_data: [],
			classes: null,
		};
	},
	watch: {
		class_data: {
			handler(newVal) {
				if (newVal != null && newVal.length > 0) {
					this.parseClassData(newVal);
				}
			},
			deep: true,
		}
	},
	mounted() {
		EventBus.$on("ASSIGN-TO-CLASSROOM", (data) => {
			if (this.type === "lesson") {
				this.lesson_data = data;
			} else {
				this.playlist_data = data;
			}
			this.modalVisible = true;
		});
		this.fetchClasses();
	},
	updated() {
		this.classes == null ? this.fetchClasses() : null;
	},
	methods: {
		parseClassData(data) {
			const buffer = [
				{
					text: "Select a Class", value: null
				},
			];

			data.forEach((singleClass) => {
				buffer.push({text: singleClass.name, value: singleClass.id});
			});
			this.classes = buffer;
		},
		async fetchClasses() {
			try {
				const response = await axios.get("/api/teacher/classrooms");
				this.class_data = response.data;
			} catch (error) {
				return toastr["error"]("Unable to get Classes");
			}
		},
		async onSubmit(evt) {
			evt.preventDefault();
			try {
				this.loading = true;
				let response;
				if (this.$props.type === "lesson") {
					response = await axios.post(`/api/teacher/assignments/${this.$props.type}/${this.lesson_data.id}/class/${this.class_id}`);
				} else {
					response = await axios.post(`/api/teacher/assignments/${this.$props.type}/${this.playlist_data.id}/class/${this.class_id}`);
				}
				this.loading = false;
				toastr["success"]("Assigned to Class");
				this.modalVisible = false;
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to assign to Class");
			}
		}
	},
};
</script>

<style scoped>
.cover-image {
	height: 200px;
	background-repeat: no-repeat;
	background-size: cover;
}
</style>
