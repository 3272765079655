<template>
	<div>
		<button v-if="deleteAction"
			:disabled="busy"
			type="button"
			class="btn btn-danger"
			@click="$emit('bulkDelete')">
			Delete
		</button>
		<button v-if="publishAction"
			:disabled="busy"
			type="button"
			class="btn btn-info"
			@click="$emit('bulkPublish')">
			Publish
		</button>
		<button v-if="unpublishAction"
			:disabled="busy"
			type="button"
			class="btn btn-warning"
			@click="$emit('bulkUnpublish')">
			Unpublish
		</button>
	</div>
</template>

<script>
export default {
	name: "BulkActionButton",
	props: {
		deleteAction: {
			type: Boolean,
			default: false,
		},
		busy: {
			type: Boolean,
			default: false
		},
		publishAction: {
			type: Boolean,
			default: false
		},
		unpublishAction: {
			type: Boolean,
			default: false
		}
	},
};
</script>

<style scoped>

</style>
