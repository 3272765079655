<template>
	<div class="page">
		<div class="row">
			<div class="col-md-12">
				<div class="clearfix">
					<span class="float-left">
						<h3>Assignment Activity</h3>
						<p style="padding-left: 10px;">All Student interactions for this assignment</p>
					</span>
					<span class="float-right">
						<router-link :to="`/lesson/${id}`"
									 v-if="type === 'lesson'"
									 tag="a">View Lesson</router-link>
						<router-link :to="`/playlist/${id}`" v-else>View Playlist</router-link>
					</span>
				</div>
				<br>
				<div v-if="pageData && pageData.data.length > 0" class="table-responsive">
					<table class="table table-hover">
						<thead class="thead-light">
						<tr>
							<th>Avatar</th>
							<th>User Name</th>
							<th>Date Viewed</th>
						</tr>
						</thead>

						<tbody>
						<tr
							v-for="(s,index) in pageData.data"
							:key="index">
							<td>
								<img
									:src="s.user.avatar || '/images/default-profile.png'"
									class="img-thumbnail"
									width="75px"
									height="75px"
									alt="image here">
							</td>
							<td>{{ s.user.username }}</td>
							<td>
								{{
									s["view_timestamp"] ? $options.filters["date"](s["view_timestamp"]) : "Not viewed yet"
								}}
							</td>
						</tr>
						</tbody>
					</table>
				</div>

				<empty v-else message="Nothing here yet, you can invite students to join this class"/>
				<!-- pagination -->
				<div class="clearfix">
					<span class="float-right">
						<pagination v-if="pageData"
									:links="pageData.links"
									:meta="pageData.meta"
									@first="(url) => updateAssignmentTable(url)"
									@prev="(url) => updateAssignmentTable(url)"
									@last="(url) => updateAssignmentTable(url)"
									@next="(url) => updateAssignmentTable(url)"/>
					</span>
					<span class="float-left">
						<button type="button"
								class="btn btn-danger"
								@click="deleteAssignment">Delete Assignment</button>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import pagination from "../../components/utility/pagination.vue";
import empty from "../../components/utility/empty.vue";
import {EventActionEnum, EventLabelEnum} from "../../config/enums";

export default {
	components: {
		pagination,
		empty
	},
	data() {
		return {
			loading: true,
			pageData: null,
			type: this.$route.params.type,
			id: this.$route.params.id,
			class_id: this.$route.params.class_id
		};
	},
	mounted() {
		this.fetchData();
	},
	updated() {
		(this.pageData == null) ? this.fetchData() : null;
	},
	computed: {
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},
	methods: {
		async fetchData() {
			try {
				let response = await axios.get(`/api/teacher/assignments/${this.type}/${this.id}/class/${this.class_id}`);
				this.loading = false;
				this.pageData = response;
				// log with GA
				this.$gtag.event(EventActionEnum.View, {
					"event_category": this.userEventCategory,
					"event_label": EventLabelEnum.Assignment,
					"value": "Assignment"
				});
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch data, please try again");
			}
		},
		async deleteAssignment() {
			try {
				const canDelete = await this.$bvModal.msgBoxConfirm("These would destroy all the data related with this assignment", {title: "Are you sure you want to delete assignment ?"});
				if (canDelete) {
					const response = await axios.delete(`/api/teacher/assignments/${this.type}/${this.id}/class/${this.class_id}`);
					toastr["success"]("Assignment deleted successfully");
					this.$router.go(-1);
				}
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to delete assignment, please try again");
			}
		},

		async updateAssignmentTable(url) {
			try {
				this.loading = true;
				let response = await axios.get(url);
				this.loading = false;
				this.pageData = response;
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch data, please try again");
			}
		}
	}
};
</script>

<style lang="scss" scoped>
h3 {
	padding-left: 10px;
}

.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}
</style>
<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}
</style>
