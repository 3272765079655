<template>
	<div v-if="lesson != null"
		class="lesson-content-page page-section container">
		<div class="row">
			<!-- CONTENT MAIN -->
			<div
				:class="{'col-lg-12 col-md-12 col-sm-12' : !($can('view', 'lesson_notes') || recap), 'col-lg-9 col-md-9 col-sm-9' : (($can('view', 'lesson_notes') || recap) && showSideNav)}"
				class="lesson-content container"
				:style="!showSideNav ? 'padding-left: 15px; padding-right: 15px;' : ''">
				<div class="clearfix">
					<span class="float-left">
						<!--						<h2 class="pt-2 mr-2">-->
						<!--							{{ content.title || '' }}-->
						<!--						</h2>-->
					</span>
					<span id="side-nav-toggle"
						class="float-right"
						style="vertical-align: middle;">
						<i
							@click="() => showSideNav = !showSideNav"
							class="pointer fas fa-bars" />

					</span>
					<b-tooltip target="side-nav-toggle" :title="showSideNav ? `hide sidebar` : `show sidebar`" />
				</div>

				<!-- CAROUSEL MAIN -->
				<carousel v-if="lesson.content[0] != null"
					ref="carousel"
					:per-page="1"
					:mouse-drag="false"
					:navigation-enabled="false"
					:pagination-enabled="false"
					:navigate-to="carouselIndex"
					:scroll-per-page="true"
					navigation-next-label="<i  class='fas fa-chevron-right arrow-button right'></i>"
					navigation-prev-label="<i  class='fas fa-chevron-left arrow-button left '></i>"
					class="carousel-container">
					<slide v-for="(c,index) in lesson.content"
						@pageChange="handlePageChange"
						:key="index">
						<!--						content without caption-->
						<div v-if="c.caption == null || c.caption === ''" class="display-content">
							<div v-if="c.type === ContentTypeEnum.VIDEO"
								style="height: 100%;">
								<iframe ref="video"
									:src="c.link"
									height="100%"
									width="100%"
									allowfullscreen />
							</div>
							<div class="slide-image"
								v-else-if="c.type === ContentTypeEnum.COMIC || c.type === ContentTypeEnum.IMAGE">
								<img :src="c.link"
									height="100%"
									width="100%"
									class="img-fluid"
									alt="content image or video">
							</div>
							<div v-else-if="c.type === ContentTypeEnum.AUDIO"
								class="col-md-12 form-group"
								style="height: 100%;">
								<audio id="content_audio"
									controls>
									<source v-if="c.link"
										:src="c.link">
								</audio>
							</div>
							<p v-else-if="c.type === ContentTypeEnum.TEXT"
								class="content-text p-2"
								style="height: 100%;"
								v-html="c.text" />
							<div v-else />
						</div>
						<!--						content with caption-->
						<div class="display-split-content" v-else>
							<div v-if="c.type === ContentTypeEnum.VIDEO"
								class="split-content">
								<iframe ref="video"
									:src="c.link"
									height="100%"
									width="100%"
									allowfullscreen />
							</div>
							<div class="split-content"
								v-else-if="c.type === ContentTypeEnum.COMIC || c.type === ContentTypeEnum.IMAGE">
								<img :src="c.link"
									alt="content image or video">
							</div>
							<div v-else-if="c.type === ContentTypeEnum.AUDIO"
								class="col-md-12 form-group split-content">
								<audio id="content_audio"
									controls>
									<source v-if="c.link"
										:src="c.link">
								</audio>
							</div>
							<p v-else-if="c.type === ContentTypeEnum.TEXT"
								class="content-text p-2 split-content"
								v-html="c.text" />
							<div v-else />
							<div class="split-caption">
								<p v-html="content.caption" />
							</div>
						</div>
					</slide>
				</carousel>
				<div v-else>
					<h2>This lesson has no content.</h2>
				</div>
				<!-- CAROUSEL FOOTER -->
				<div id="content-footer"
					class="clearfix">
					<span class="float-left">
						<button class="btn btn-sm btn-primary"
							@click="previous()">
							<i class="fas fa-angle-left" />
						</button>
						<button class="btn btn-sm btn-primary"
							@click="next()">
							<i class="fas fa-angle-right" />
						</button>
						<span class="ml-2"
							style="vertical-align: middle; display: inline;">
							Slide {{ currentContent }} / {{ lesson.content.length }}
						</span>
					</span>
					<span v-if="$can('view', 'presenter')"
						class="float-right">
						<button v-if="$can('view', 'admin')"
							class="btn btn-sm btn-warning"
							style="color: whitesmoke;"
							@click="routeToEditContent(lesson.content[currentContent - 1].id)">
							<i class="fas fa-pencil-alt icon" />
							Edit
						</button>
						<button id="fullscreen"
							class="btn btn-sm btn-primary"
							@click="activatePresenterView()">
							<i class="fas fa-expand mr-1" />
							Presentation Mode
						</button>
					</span>
				</div>
			</div>

			<!-- CONTENT SIDEBAR-->
			<!-- TODO: Check casing of "lesson_notes" -->
			<div v-if="(($can('view', 'lesson_notes') || recap) && showSideNav)"
				class="col-lg-3 col-md-3 lesson-sidebar">
				<!--				<div v-if="lesson.content[0] != null && $can('read','lesson-notes')">-->
				<!--					<h4 v-if="getCurrentNotes() !== ''">-->
				<!--						Notes-->
				<!--					</h4>-->
				<!--					<p v-html="getCurrentNotes()" />-->
				<!--				</div>-->
				<div v-if="recap"
					id="upnext">
					<div v-if="playlist != null && nextLessonSequence != null">
						<h4>Up Next</h4>
						<thumbnail :playlist-id="playlist.id"
							:lesson="playlist_lessons[nextLessonSequence - 1]"
							:lesson-sequence="nextLessonSequence" />
					</div>
					<div v-else-if="playlist != null">
						<!--TODO: Replace with suggested next playlist-->
						<h5>End of Playlist</h5>
					</div>
					<div v-if="playlist == null && upnext != null">
						<h4>Up Next</h4>
						<thumbnail :lesson="upnext" />
					</div>
				</div>
				<br>
			</div>
		</div>
	</div>
</template>

<script>
import "video.js/dist/video-js.css";
import Player from "@vimeo/player";
import {Carousel, Slide} from "vue-carousel";
import thumbnail from "../../components/lesson-playlist/thumbnail.vue";
import {ContentTypeEnum} from "../../config/enums";
import EventBus from "../../utility/eventbus";
import axios from "axios";
import helper from "../../utility";

export default {
	name: "ShowLessonContent",

	components: {
		Carousel,
		Slide,
		thumbnail
	},

	data() {
		return {
			isPlaylist: this.$route.params.playlist_id != null,
			playlist: null,
			playlist_lessons: [],
			lesson: null,
			lessonSequence: null,
			recap: false,
			ContentTypeEnum: ContentTypeEnum,
			upnext: null,
			carouselIndex: 0,
			currentContent: 1,
			previousLessonSequence: null,
			nextLessonSequence: null,
			content: null,
			showSideNav: false,
		};
	},

	computed: {
		currentIndex: {
			cache: false,
			get() {
				return this.$refs.carousel
					? this.$refs.carousel.currentPage
					: 0;
			}
		},

		carouselInstance: {
			cache: false,
			get() {
				return this.$refs.carousel ? this.$refs.carousel : null;
			}
		},
		activity() {
			return this.$store.getters["getUserActivity"];
		},
		user_type() {
			return this.$store.getters["getUserType"];
		}
	},

	watch: {
		carouselIndex: {
			handler(val, oldVal) {
				if (this.lesson) {
					this.carouselVideoHandler();
					this.currentContent = val + 1;
					this.content = this.lesson.content[val];
					if (val === this.lesson.content.length - 1) {
						this.showSideNav = true;
						return (this.recap = true);
					}
					this.getCurrentNotes();
					this.showSideNav = false;
					return (this.recap = false);
				}
			},
			deep: true,
		},
		lessonSequence: {
			handler() {
				this.computeNextLessonSequence();
				this.computePreviousLessonSequence();
			},
			deep: true,
			immediate: true,
		}
	},

	created() {
		window.addEventListener("keydown", this.navigateKeys);
	},

	beforeDestroy() {
		window.removeEventListener("keydown", this.navigateKeys);
	},

	async mounted() {
		await this.fetchData();
		this.getNextLesson();
		if (this.lesson.content.length === 1) {
			return (this.recap = true);
		}
		return (this.recap = false);
	},
	async updated() {
		if (this.lesson == null && this.playlist == null) {
			await this.fetchData();
		}
		this.getCurrentNotes();
	},

	methods: {
		async fetchData() {
			const {lesson_id, playlist_id, lesson_sequence} = this.$route.params;

			let url = `/api/lesson/${lesson_id}`;

			if (playlist_id != null) {
				url =
					`/api/playlist/${+playlist_id}`;
			}


			return axios
				.get(url)
				.then(async ({data}) => {
					if (this.isPlaylist) {
						this.playlist = data;
						this.playlist_lessons = data.lessons;
						this.lessonSequence = +lesson_sequence;
						const lesson_data_id = this.playlist_lessons[+lesson_sequence - 1].id;
						const response = await axios.get(`/api/lesson/${lesson_data_id}`);
						this.setLesson(response.data);
						EventBus.$emit("SET-PLAYLIST", this.playlist);
					} else {
						this.setLesson(data);
					}
				})
				.catch(error => {
					this.$router.go(-1);
					toastr.error("Error fetching lesson");
				});
		},

		computeNextLessonSequence() {
			if (this.playlist_lessons[+this.lessonSequence] != null && this.playlist != null) {
				this.nextLessonSequence = +this.lessonSequence + 1;
			} else {
				this.nextLessonSequence = null;
			}
		},

		computePreviousLessonSequence() {
			if (this.playlist != null && this.playlist_lessons[+this.lessonSequence - 1] != null) {
				this.previousLessonSequence = (+this.lessonSequence - 1);
			} else {
				this.previousLessonSequence = null;
			}
		},

		setLesson(data) {
			// Split the content lines as well
			this.lesson = {
				id: data.id,
				title: data.title,
				cover: data.cover,
				hero: data.hero,
				type: data.hero,
				classification: data.hero,
				author: data.author,
				focus: data.focus,
				standards: data.standards,
				overview: helper.splitText(data.overview),
				content: data.content,
				playlists: data.playlists,
				links: data.links,
				tags: data.tags
			};
			EventBus.$emit("SET-LESSON", this.lesson);
			this.content = this.lesson.content[0];
		},

		async handlePageChange(value) {
			if (value === this.lesson.content.length - 1) {
				return (this.recap = true);
			}
		},

		navigateKeys(event) {
			if (
				event.key === "ArrowRight" &&
				this.currentIndex !== this.lesson.content.length - 1
			) {
				this.carouselIndex = this.currentIndex + 1;
			} else if (event.key === "ArrowLeft" && this.currentIndex !== 0) {
				this.carouselIndex = this.currentIndex - 1;
			}
		},

		carouselVideoHandler() {
			if (this.lesson) {
				const previousSlideNumber = this.carouselInstance.getPreviousPage();
				const previousSlide = this.carouselInstance.$slots.default[
					previousSlideNumber
				].context;

				// if user is going to next page remove the video playing in the previous if there is
				if (
					previousSlide.$refs.video &&
					previousSlide.$refs.video[0].src !== undefined
				) {
					var player = new Player(previousSlide.$refs.video[0]);
					player.pause();
					return;
				}
				return null;
			}
		},

		previous() {
			let {currentPage, slideCount} = this.carouselInstance;

			if (currentPage === 0) {
				this.carouselIndex = slideCount - 1;
			} else {
				this.carouselIndex = currentPage - 1;
			}
		},

		next() {
			let {currentPage, slideCount} = this.carouselInstance;
			if (currentPage === slideCount - 1) {
				this.carouselIndex = 0;
			} else {
				this.carouselIndex = currentPage + 1;
			}
		},

		getCurrentNotes() {
			if (this.lesson != null && this.lesson.content != null && this.lesson.content[this.currentIndex].notes != null) {
				return this.lesson.content[this.currentIndex].notes;
			} else {
				return "";
			}
		},

		getNextLesson() {
			if (!this.playlist) {
				axios.get("/api/lesson/next").then(({data}) => {
					this.upnext = data;
				});
			}
		},

		routeToLessonStart() {
			if (this.$route.params.playlist_id) {
				return this.$router.push({
					name: "playlistShowLesson",
					params: {
						playlist_id: this.$route.params.playlist_id,
						lesson_id: this.lesson.id,
						lesson_sequence: this.lessonSequence
					}
				});
			} else {
				return this.$router.push({
					name: "showLesson",
					params: {lesson_id: this.lesson.id}
				});
			}
		},

		routeToHome() {
			return this.$router.push({
				path: "/"
			});
		},

		routeToEditContent(content) {
			return this.$router.push({
				name: "adminEditContent",
				params: {
					content_id: content
				}
			});
		},

		activatePresenterView() {
			this.$router.push({path: `/presenter/${this.lesson.id}`});
			// return this.$router.push({
			// 	name: "showLessonPresenter",
			// 	params: { lesson_id: this.lesson.id }
			// });
		}
	}
};
</script>

<style lang="scss" scoped>
.lesson-content {
	padding-top: 15px;
	padding-bottom: 15px;

	@include breakpoint($xs) {
		padding: 10px;
	}

	.display-content {
		padding-bottom: 15px;
		overflow-y: scroll;
	}

	.slide-image {
		overflow: auto; /* adds scrollbars */
		max-height: 65vh;
		position: relative;
	}

	.slide-image > img {
		height: 200%; /* probably looks neater if auto */
		width: 100%; /* double width image to show only first quarter */
		vertical-align: middle; /* moves image to true text bottom */
	}

	.content-text {
		margin-bottom: 30px;
		font-size: 22px;
	}
}

.carousel-container {
	max-height: 64vh;
	overflow-y: scroll;
}

.lesson-sidebar {
	padding: $container-padding;
	padding-left: 20px;

	@include breakpoint($sm) {
		border-left: 1px solid #f5f6f9;
	}

	#upnext {
		@include breakpoint($xs) {
			padding-top: 5px;
			border-top: 2px solid #f5f6f9;
		}
	}
}

#content-footer {
	bottom: 0;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 5px;

	#fullscreen {
		@include breakpoint($xs) {
			display: none;
		}
	}
}
</style>

<style lang="scss">
.content-text p {
	font-size: 20px;

	@include breakpoint($sm) {
		font-size: 30px;
	}
}

.split-content {
	max-height: 50vh;
	overflow-y: scroll;
	overflow-x: scroll;
	border-bottom: #f5f6f9 solid 1px;
}

.split-caption {
	overflow-y: scroll;
	min-height: 10vh;
	padding: 10px;
}
</style>
