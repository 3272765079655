<template>
	<div class="page">
		<div class="row">
			<div class="col-md-12">
				<h3>Teachers</h3>
				<div class="clear-fix mb-5">
					<span class="float-left">
						<p style="padding-left: 10px;">All Registered Teachers</p>
					</span>
					<span class="float-right"/>
				</div>
				<div v-if="teacherData && teacherData.data.length > 0" class="table-responsive">
					<table class="table table-hover">
						<thead class="thead-light">
						<tr>
							<th>Avatar</th>
							<th>Full Name</th>
							<th>Email Address</th>
							<th>Date Joined</th>
							<th/>
						</tr>
						</thead>
						<tbody>
						<tr v-for="(t, index) in teacherData.data" :key="index">
							<td>
								<img
									:src="t.user.avatar || '/images/default-profile.png'"
									class="img-thumbnail"
									width="75px"
									height="75px">
							</td>
							<td>{{ t.user.first_name }} {{ t.user.last_name }}</td>
							<td> {{ t.user.email }}</td>
							<td> {{ new Date(t.created_at).toLocaleString() }}</td>
							<td>
								<!--									<router-link :to="`/principal/teachers/${t.user.id}`">-->
								<!--										View-->
								<!--									</router-link>-->
								<!--									<br>-->
								<a
									href="#"
									@click="removeTeacher(t.user.id)">
									Delete
								</a>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
				<empty v-else/>
				<!-- pagination -->
				<div class="clearfix">
					<span class="float-right">
						<pagination v-if="teacherData"
									:links="teacherData.links"
									:meta="teacherData.meta"
									@first="(url) => updateTeacherTable(url)"
									@prev="(url) => updateTeacherTable(url)"
									@last="(url) => updateTeacherTable(url)"
									@next="(url) => updateTeacherTable(url)"/>
					</span>
					<span class="float-left"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import pagination from "../../components/utility/pagination.vue";
import empty from "../../components/utility/empty.vue";
import {EventActionEnum, EventLabelEnum} from "../../config/enums";

export default {
	components: {
		pagination,
		empty
	},
	data() {
		return {
			teacherData: null,
			loading: true,
		};
	},
	mounted() {
		this.fetchData();
	},
	updated() {
		this.teacherData == null ? this.fetchData() : null;
	},
	computed: {
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},
	methods: {
		async fetchData() {
			try {
				this.loading = true;
				const response = await axios.get("/api/principal/teachers");
				this.loading = false;
				this.teacherData = response;
				// log with GA
				this.$gtag.event(EventActionEnum.View, {
					"event_category": this.userEventCategory,
					"event_label": EventLabelEnum.Teachers,
					"value": "All Teachers"
				});
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch teachers, please try again later");
			}
		},
		async updateTeacherTable(url) {
			try {
				this.loading = true;
				const response = await axios.get(url);
				this.loading = false;
				this.teacherData = response;
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch teachers, please try again later");
			}
		},
		async removeTeacher(id) {
			try {
				if (confirm("Are you sure you want to remove teacher from this group?")) {
					this.loading = true;
					await axios.delete(`/api/principal/teachers/${id}`);
					this.loading = false;
					toastr["success"]("Teacher removed from group");
					return this.$router.go(0);
				}
			} catch (e) {
				this.loading = false;
				return toastr["error"]("Unable to remove teacher from group");
			}
		}
	}
};
</script>

<style scoped>

</style>
