<template>
	<b-card align="left"
			title="Student Login">
		<b-form @submit.prevent="submit">
			<br>

			<b-form-group id="username.">
				<b-form-input
					id="username"
					v-model="loginForm.username"
					autocomplete="username"
					name="username"
					placeholder="username"
					required
					type="text"/>
			</b-form-group>

			<b-form-group id="password.">
				<b-form-input
					id="password"
					v-model="loginForm.password"
					autocomplete="current-password"
					name="password"
					placeholder="Password"
					required
					type="password"/>
			</b-form-group>
			<br>
			<b-button id="login"
					  block
					  type="submit"
					  variant="primary">
				<i v-if="loading"
				   class="fas fa-circle-notch fa-spin"/>
				Login
			</b-button>

			<!--			<br>-->

			<!--			<b-button block-->
			<!--				id="googleSignInCustom"-->
			<!--				variant="outline-primary"-->
			<!--				class="btn  btn-github">-->
			<!--				<i v-if="loading"-->
			<!--					class="fas fa-circle-notch fa-spin" />-->
			<!--				<i v-else class="fab fa-google" />-->
			<!--				Continue with Google-->
			<!--			</b-button>-->

			<p>&nbsp;</p>
			<p>
				<router-link
					class="text-info m-l-5"
					to="/login">
					<b>Not a student?</b>
				</router-link>
			</p>
			<p>
				<router-link
					id="create-account"
					class="text-info m-l-5"
					dusk="create-account"
					to="/select-plan">
					<b>Don't have an account?</b>
				</router-link>
			</p>
		</b-form>
	</b-card>
</template>

<script>
import {encrypt, storeExpiry} from "../../utility/encrypt";
import {mapActions} from "vuex";
import {EventActionEnum, EventCategoryEnum, EventLabelEnum} from "../../config/enums";

export default {
	data() {
		return {
			loginForm: {
				username: "",
				password: ""
			},
			rememberMe: false,
			loading: false,
			gauth: null,
		};
	},
	mounted() {
		this.setConfig({guestShowPicture: true});
		// this.startGoogleAuth();
	},
	computed: {
		userRoles() {
			return this.$store.getters["auth/getAuthUser"];
		}
	},
	methods: {
		...mapActions({
			setConfig: "config/setConfig",
		}),

		selectUserType(user_type) {
			this.$store.commit("auth/setUserType", user_type);
			setTimeout(() => {
				this.$router.push({path: "/home"});
			}, 0);
			return (this.loading = false);
		},

		submit(e) {
			this.loading = true;
			axios
				.post("/api/auth/login/student", this.loginForm)
				.then(async ({data, message, status}) => {
					if (status) {
						return this.handleLoginSuccess(data);
					} else {
						toastr["error"](message);
						if (message === "Invalid Credentials! Please try again.") {
							this.resetpass = true;
						}
						return (this.loading = false);
					}
				})
				.catch(e => {
					toastr["error"](e.message);
					if (e.message === "Invalid Credentials! Please try again.") {
						this.resetpass = true;
					}
					return (this.loading = false);
				});
		},

		async handleLoginSuccess(data) {
			if (this.rememberMe) {
				localStorage.setItem("auth_type", encrypt("refresh"));
				storeExpiry("auth_token", data.token, true);
			} else {
				localStorage.setItem("auth_type", encrypt("single"));
				storeExpiry("auth_token", data.token, false);
				//localStorage.setItem("auth_token", response.data.token);
			}

			axios.defaults.headers.common["Authorization"] = `Bearer ${
				data.token
			}`;

			return await this.setUserInitial();
		},

		async setUserInitial() {
			try {
				const data = await axios.get("/api/auth/user");
				const response = data.data;
				sessionStorage.setItem("_gml.rl", encrypt(response));
				this.$store.commit("auth/setAuthUserDetail", {
					id: response.id,
					first_name: response.first_name,
					last_name: response.last_name,
					username: response.username,
					email: response.email,
					avatar: response.avatar,
					plan_id: response.plan_id,
					roles: encrypt(response.roles),
					permissions: encrypt(response.permissions),
					badges: response.badges,
					favorites: response.favorites,
					favorite_playlists: response.favorite_playlists,
					groups: response.groups,
					classrooms: response.classrooms,
					activity: response.activity,
					subscription: response.subscription,
					user_options: response.user_options,
					unread_count: response.unread_count,
				});

				this.$store.commit("auth/setAuthenticated", true);

				//check if subscription is active
				// if (["expired",
				// 	"incomplete",
				// 	"incomplete_expired",
				// 	"past_due",
				// 	"unpaid"
				// ].includes(response.subscription.status)) {
				// 	this.$vuex.commit("auth/setSubscriptionActive", false);
				// 	router.replace("/profile/settings");
				// } else {
				// 	this.$vuex.commit("auth/setSubscriptionActive", true);
				// }

				this.$store.commit("auth/setSubscriptionActive", true);

				this.$store.commit("auth/setSubscriptionChecked", true);

				// detect if one email has multiple user types

				const defaultRoles = ["group-admin", "teacher", "student", "admin", "super-admin"];

				const buffer = [];

				const roles = response.roles.map(role => role.name);

				roles.forEach(role => {
					if (defaultRoles.includes(role)) {
						buffer.push(role);
					}
				});

				if (buffer.length > 1) {
					this.userTypes = buffer;
					this.multiple_accounts = true;
					this.$gtag.event(EventActionEnum.Login, {
						"event_category": EventCategoryEnum.Student,
						"event_label": EventLabelEnum.Auth,
						"value": "successful"
					});
				} else {
					toastr["success"]("Login Successful");
					this.$store.commit("auth/setUserType", buffer[0]);
					this.$gtag.event(EventActionEnum.Login, {
						"event_category": EventCategoryEnum.Student,
						"event_label": EventLabelEnum.Auth,
						"value": "successful"
					});
					setTimeout(() => {
						this.$router.push({path: "/home"});
					}, 0);
					return (this.loading = false);
				}

			} catch (error) {
				this.loading = false;
				this.$gtag.event(EventActionEnum.Login, {
					"event_category": EventCategoryEnum.Student,
					"event_label": EventLabelEnum.Auth,
					"value": "failed"
				});
				return toastr["error"]("Unable to login user, please try again");
			}
		},

		attachSignin(element) {
			this.gauth.attachClickHandler(element, {},
				function (googleUser) {
					this.onSignInGoogle(googleUser);
				}.bind(this), function (error) {
					this.onSignInGoogleFailed(error);
				}.bind(this));
		},

		async onSignInGoogle(user) {
			const profile = user.getBasicProfile();

			const name = profile.getName();
			const email = profile.getEmail();
			const avatar = profile.getImageUrl();

			const {status, message, data} = await axios.post("/api/auth/login/student/social", {
				type: "google",
				data: {name, email, avatar}
			});

			if (status) {
				return await this.handleLoginSuccess(data, email);
			} else {
				toastr["error"](message);
				return (this.loading = false);
			}

		},

		async onSignInGoogleFailed(error) {
			this.loading = false;
			this.$gtag.event(EventActionEnum.Login, {
				"event_category": EventCategoryEnum.Student,
				"event_label": EventLabelEnum.Auth,
				"value": "failed"
			});
			return toastr["error"]("Unable to login user, please try again");
		},

		startGoogleAuth() {
			window.gapi.load("auth2", function () {
				// Retrieve the singleton for the GoogleAuth library and set up the client.
				this.gauth = gapi.auth2.init({
					client_id: "743283059851-heb6afiokdpvn39mjitibkg1jt3e94nc.apps.googleusercontent.com",
					cookiepolicy: "single_host_origin",
				});
				this.attachSignin(document.querySelector("#googleSignInCustom"));
			}.bind(this));
		},
	}
};
</script>

<style lang="scss" scoped>
.card {
	cursor: pointer;
}

p {
	font-size: $font-size-base;
	font-family: $font-family-base;
}
</style>
