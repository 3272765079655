<script src="../../vuex/modules/auth.js"></script>
<template>
	<div>
		<form v-if="user && userForm"
			  enctype="multipart/form-data"
			  @submit.prevent="updateUser">
			<div class="form-group avatar">
				<profileImage :image="display_avatar" :is-large="true"/>
				<!-- <img
					:src="display_avatar"
					class="image"
					alt=" Profile Pic"> -->
				<label>
					<div class="fa-stack fa-2x upload-stack" @click="DispatchMediaEvent('AVATAR')">
						<!--						<input-->
						<!--							id="avatar"-->
						<!--							ref="avatar"-->
						<!--							v-validate-->
						<!--							:disabled="isSaving"-->
						<!--							accept="image/*"-->
						<!--							class="form-control"-->
						<!--							hidden-->
						<!--							name="avatar"-->
						<!--							type="file"-->
						<!--							@change="handleFileUpload">-->
						<i class="fas fa-circle fa-lg fa-stack-2x upload-background"/>
						<i class="fas fa-camera fa-stack-1x upload-icon grow"/>
					</div>
				</label>
			</div>

			<div class="form-group">
				<label for="first_name">First Name:</label>
				<input
					id="first_name"
					v-model="userForm.first_name"
					v-validate
					class="form-control"
					name="first_name"
					required
					type="first_name">
			</div>
			<div class="form-group">
				<label for="last_name">Last Name:</label>
				<input
					id="last_name"
					v-model="userForm.last_name"
					class="form-control"
					type="last_name">
			</div>
			<div class="form-group">
				<label for="email">Email address:</label>
				<input id="email"
					   v-model="userForm.email"
					   v-validate
					   class="form-control"
					   name="email"
					   required
					   type="email">
				<span v-show="errors.has('email')"
					  class="error">
					{{ errors.first("email") }}
				</span>
			</div>
			<div v-if="changePassword">
				<b-collapse
					id="collapse1"
					v-model="changePassword"
					class="mt-2">
					<div class="form-group">
						<label for="current_password">Current Password:</label>
						<input id="current_password"
							   v-model="userForm.current_password"
							   v-validate="'required'"
							   class="form-control"
							   data-vv-as="current password"
							   name="current_password"
							   type="password">
						<span class="error">
							{{ errors.first("current_password") }}
						</span>
					</div>
					<div class="form-group">
						<label for="password">New Password:</label>
						<input id="password"
							   ref="password"
							   v-model="userForm.password"
							   v-validate="'required|min:6'"
							   class="form-control"
							   data-vv-as="password"
							   name="password"
							   type="password">
						<span class="error">
							{{ errors.first("password") }}
						</span>
					</div>
					<div class="form-group">
						<label for="password_confirmation">Confirm Password:</label>
						<input
							id="password_confirmation"
							v-model="userForm.password_confirmation"
							v-validate="'required|confirmed:password'"
							class="form-control"
							data-vv-as="password"
							name="password_confirmation"
							type="password">
						<span class="error">
							{{ errors.first("password_confirmation") }}
						</span>
					</div>
				</b-collapse>
			</div>

			<br>

			<div>
				<button
					:disabled="!(errors.items.length <= 0) || isSaving"
					class="btn btn-success btn-md"
					type="submit">
					<i v-if="isSaving"
					   class="fas fa-circle-notch fa-spin"/>
					Save
				</button>

				<button
					:disabled="isSaving"
					class="btn btn-danger btn-md"
					type="button"
					@click="$emit('edit-profile')">
					Cancel
				</button>

				<button
					v-show="!changePassword"
					:disabled="isSaving"
					class="btn btn-primary btn-md"
					type="button"
					@click="() => {changePassword = true;}">
					Change password
				</button>
			</div>
		</form>
		<b-modal
			v-if="mediaManager"
			id="modal-center"
			v-model="mediaManager"
			centered
			hide-footer
			lazy
			size="xl"
			title="Media Manager">
			<div class="row">
				<div class="col-md-12">
					<media-manager :collection="'user'"
								   :modal-mode="true"
								   :public="false"/>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import profileImage from "../../components/profile/profile-image";
import {mapActions, mapState} from "vuex";
import {encrypt} from "../../utility/encrypt";
import mediaManager from "../../components/utility/media-manager.vue";
import eventbus from "../../utility/eventbus";
import {setTimeout} from "timers";

export default {
	name: "ProfileSettingsDetailsUpdate",
	components: {
		profileImage,
		mediaManager
	},

	data() {
		return {
			userForm: new Form({
				autoReset: false,
				first_name: null,
				last_name: null,
				email: null,
				current_password: null,
				password: null,
				password_confirmation: null,
			}),
			display_avatar: null,
			loading: false,
			isSaving: false,
			changePassword: true,
			mediaManager: false,
			request_id: null
		};
	},

	computed: {
		...mapState({
			user: "auth",
		})
	},

	mounted() {
		this.userForm.first_name = this.user.first_name;
		this.userForm.last_name = this.user.last_name;
		this.userForm.email = this.user.email;
		this.userForm.avatar = this.user.avatar;
		this.display_avatar = this.userForm.avatar;
		eventbus.$on("media-manager-complete", this.MediaEventHandler);
	},

	destroyed() {
		eventbus.$off("media-manager-complete");
	},

	methods: {
		...mapActions({
			setAuthUserDetail: "auth/setAuthUserDetail"
		}),

		MediaEventHandler(data) {
			switch (data.response_id) {
				case "AVATAR":
					if ((data.response_id = this.request_id)) {
						this.userForm.avatar = data.url;
						this.userForm.avatar_id = data.id;
						this.display_avatar = data.url;
						this.mediaManager = null;
					}
					break;
				default:
					break;
			}
		},
		DispatchMediaEvent(type) {
			this.mediaManager = !this.mediaManager;
			this.request_id = type;
			setTimeout(() => {
				eventbus.$emit("media-manager", type);
			}, 1000);
		},

		handleFileUpload(e) {
			let files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;
			this.createImage(files[0]);
			this.display_avatar = this.userForm.avatar;
		},

		createImage(file) {
			let reader = new FileReader();
			let vm = this;
			reader.onload = e => {
				vm.userForm.avatar = e.target.result;
				vm.display_avatar = e.target.result;
			};
			reader.readAsDataURL(file);
		},

		updateUser() {
			this.isSaving = true;
			// if (!this.fields.avatar.dirty) {
			// 	delete this.userForm.avatar;
			// }

			return this.userForm
				.put("/api/user/" + this.user.id)
				.then(response => {
					this.$store.commit("auth/setAuthUserDetail", {
						first_name: response.first_name,
						last_name: response.last_name,
						email: response.email,
						avatar: response.avatar,
						plan_id: response.plan_id,
						roles: encrypt(response.roles),
						permissions: encrypt(response.permissions),
						badges: response.badges,
						favorites: response.favorites,
						id: response.id,
						favorite_playlists: response.favorite_playlists
					});
					toastr["success"]("Update complete");
					this.isSaving = false;
					//	return this.routeToProfileOverview();
				})
				.catch(error => {
					toastr["error"](error);
					this.isSaving = false;
				});
		},

		routeToProfileOverview() {
			return this.$router.go(0);
		}
	},
};
</script>

<style lang="scss" scoped>

.avatar {
	position: relative;
	display: inline-flex;
	margin: 20px;

	.image {
		width: 150px;
		height: 150px;
		border-radius: 50%;
	}

	.upload-stack {
		position: absolute;
		right: -10px;
		bottom: 0;
		cursor: pointer;

		.upload-background {
			top: 7px;
			color: $green;
		}

		.upload-icon {
			color: white;
		}
	}
}

form {
	input {
		float: right;
		max-width: 80%;
	}

	label {
		font-weight: bold;
	}
}

span.error {
	display: block;
	margin-top: 5px;
	color: red;
}

</style>
