<template>
	<div>
		<h2>Custom Playlist</h2>
		<div class="page-section">
			<div>
				<br>
				<div style="padding-top: 20px; background: white;">
					<!-- Media manager -->
					<b-modal
						id="modal-center"
						v-model="mediaManager"
						size="xl"
						hide-footer
						lazy
						centered
						title="Media Manager">
						<div class="row">
							<div class="col-md-12">
								<media-manager :modal-mode="true"/>
							</div>
						</div>
					</b-modal>

					<div class="row">
						<div class="col-md-12 lesson-text">
							<form enctype="multipart/form-data"
								  @submit.prevent="submitPrevent">
								<!-- Playlist image -->
								<div class="col-md-12 form-group">
									<img
										v-if="playlistForm.cover"
										:src="playlistForm.cover"
										class="img-responsive"
										width="200"
										@change="onChanged('cover')">
								</div>
								<!-- Playlist image upload button -->
								<div class="col-md-12 form-group">
									<b-button
										class="m-1"
										variant="primary"
										@click="DispatchMediaEvent('PLAYLIST_COVER')">
										Upload
									</b-button>
								</div>

								<!-- Playlist Cover -->
								<div class="col-md-12 form-group">
									<input v-validate
										   id="cover"
										   name="cover"
										   hidden
										   type="text"
										   class="form-control">
								</div>

								<!-- playlist title -->
								<div class="col-md-12 form-group">
									<label for="title"
										   class="control-label">Playlist Title</label>
									<input
										id="title"
										v-model="playlistForm.name"
										placeholder="Input a title for your playlist"
										type="text"
										class="form-control"
										@change="onChanged('name')">
								</div>

								<!-- playlist description-->
								<div class="col-md-12 form-group">
									<label for="overview"
										   class="control-label">Playlist Description</label>
									<textarea
										id="overview"
										v-model="playlistForm.description"
										placeholder="Input a short description of your playlist"
										rows="5"
										class="form-control"
										@change="onChanged('description')"/>
								</div>

								<!-- playlist lessons -->
								<div class="col-md-12 form-group">
									<label for="lessons">Playlist Lessons</label>
								</div>
								<div class="col-md-12 form-group">
									<b-button class="m-1"
											  variant="primary"
											  @click="lessonsModal = true">
										Configure Lessons
									</b-button>
								</div>
								<!-- playlist tags-->
								<div class="row col-md-12 form-group">
									<div class="form-group col-md-12">
										<label class="control-label">Playlist Tags</label>
										<div class="chips chips-initial input-field">
											<div v-for="(tag,index) in playlistForm.tags"
												 :key="`${tag}-${index}`"
												 class="chip">
												{{ tag }}
												<span class="closebtn"
													  @click="deleteTag(tag)">&times;</span>
											</div>
										</div>
									</div>
									<br>
									<div class="form-group col-md-8">
										<input
											ref="newTag"
											type="text"
											class="form-control"
											placeholder="Enter Tag"
											@keyup="addTagEnter">
									</div>
									<div class="form-group col-md-4"
										 style="padding-right: 0;">
										<button class="btn btn-primary btn-block btn-md"
												type="button"
												@click="addTag">
											<i class="fas fa-plus-circle icon"/>Add Tag
										</button>
									</div>
								</div>
								<!-- page action buttons -->
								<div class="col-md-12 form-group">
									<button
										v-if="changed || !playlist_id"
										type="button"
										class="btn btn-success"
										@click="savePlaylist">
										<span>Save</span>
									</button>
									<button v-if="changed"
											type="button"
											class="btn btn-warning"
											@click="discardChanges">
										<span>Discard</span>
									</button>
									<button v-if="playlist_id"
											type="button"
											class="btn btn-danger"
											@click="deletePlaylist">
										<span>Delete</span>
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- lessons modal -->
		<b-modal
			id="modal-tall"
			:cancel-disabled="true"
			v-model="lessonsModal"
			size="xl"
			ok-only
			lazy
			title="Setup Lessons">
			<div class="row">
				<div class="col-md-12 form-group">
					<label for="lessons">Search Lessons</label>
				</div>
				<div style="margin-bottom: 30px;"
					 class="col-md-12">
					<multiselect
						id="lessons"
						:multiple="true"
						:searchable="true"
						:loading="isLoading"
						v-model="playlistForm.lessons"
						:internal-search="false"
						:options="lessonList"
						:options-limit="15"
						:clear-on-select="false"
						:close-on-select="false"
						placeholder="Type to search for lesson"
						track-by="id"
						label="title"
						@search-change="getLessonsAsync"/>
				</div>

				<div class="col-md-12 form-group">
					<label for="lessons">Rearrange Order</label>
				</div>
				<div class="root col-md-12">
					<br>
					<SlickList v-model="playlistForm.lessons"
							   class="list"
							   lock-axis="y"
							   helper-class="list-item">
						<SlickItem
							v-for="(lesson,index) in playlistForm.lessons"
							:key="index"
							:index="index"
							class="list-item">
							{{ index }}. {{ lesson.title }}
						</SlickItem>
					</SlickList>
					<br>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import eventbus from "../../utility/eventbus.js";
import Multiselect from "vue-multiselect";
import mediaManager from "../../components/utility/media-manager.vue";
import {SlickItem, SlickList} from "vue-slicksort";

export default {
	components: {
		mediaManager,
		SlickItem,
		SlickList,
		Multiselect
	},
	data: function () {
		return {
			pageTitle: "",
			playlist_id: null,
			playlistForm: {
				id: "",
				name: "",
				cover: "",
				author_id: "",
				description: "",
				lessons: [],
				tags: []
			},
			playlist: "",
			changed: false,
			isSaving: false,
			isLoading: false,
			isDeleting: false,
			mediaManager: false,
			lessonsModal: false,
			lessonList: [],
			request_id: null
		};
	},

	computed: {
		author_id() {
			return this.$store.getters["auth/getAuthUser"].id;
		}
	},

	mounted() {
		eventbus.$on("MEDIA_MANAGER_COMPLETE", this.MediaEventHandler);
		this.playlistForm.author_id = this.author_id;
	},

	destroyed() {
		eventbus.$off("MEDIA_MANAGER_COMPLETE");
	},

	methods: {
		MediaEventHandler(data) {
			switch (data.response_id) {
				case "PLAYLIST_COVER":
					if ((data.response_id = this.request_id)) {
						this.playlistForm.cover = data.url;
						this.mediaManager = null;
						this.onChanged("cover");
					}
					break;
				default:
					break;
			}
		},
		DispatchMediaEvent(type) {
			this.mediaManager = !this.mediaManager;
			this.request_id = type;
			setTimeout(() => {
				eventbus.$emit("MEDIA_MANAGER", type);
			}, 1000);
		},
		fetchPlaylist(id) {
			id !== "new"
				? axios
					.get(`/api/playlist/${id}`)
					.then(({data}) => {
						const {
							id,
							name,
							cover,
							description,
							lessons,
							tags
						} = data;
						this.pageTitle = `Playlist: ${name}`;
						this.breadcrumbitems[1].text = name;
						this.playlist = {
							id,
							name,
							cover,
							description,
							lessons,
							tags
						};
						this.playlistForm = {
							id,
							name,
							cover,
							description,
							lessons,
							tags
						};
					})
					.catch(({message}) => {
						toastr["error"](message);
					})
				: null;
		},
		submitPrevent(e) {
			return e.preventDefault();
		},
		deleteTag(tag) {
			this.playlistForm.tags = this.playlistForm.tags.filter(
				element => element !== tag
			);
		},
		addTag() {
			this.playlistForm.tags.push(this.$refs.newTag.value);
			return (this.$refs.newTag.value = "");
		},
		addTagEnter(e) {
			if (e.keyCode === 13) {
				e.preventDefault();
				this.playlistForm.tags.push(this.$refs.newTag.value);
				return (this.$refs.newTag.value = "");
			}
			return null;
		},
		onPlaylistImageChange(e) {
			let files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;
			this.createImage(files[0]);
		},
		createImage(file) {
			let reader = new FileReader();
			let vm = this;
			reader.onload = e => {
				vm.playlistForm.cover = e.target.result;
			};
			reader.readAsDataURL(file);
		},
		// Save or update playlist
		savePlaylist() {
			this.isSaving = true;

			const {name, cover, description, lessons} = this.playlistForm;

			if (cover === "") {
				toastr["error"]("Include a playlist image");
				return (this.isSaving = false);
			}
			if (name === "") {
				toastr["error"]("Input a playlist title");
				return (this.isSaving = false);
			}
			if (description === "") {
				toastr["error"]("Input a playlist description");
				return (this.isSaving = false);
			}

			if (lessons[0] == null) {
				toastr["error"]("Assign some lessons to the playlist");
				return (this.isSaving = false);
			}

			if (this.$route.params.playlist_id === "new") {
				// save as new lesson
				delete this.playlistForm.id;
				return axios
					.post("/api/playlist", this.playlistForm)
					.then(({data}) => {
						toastr["success"]("Save successful");
						this.isSaving = false;
						setTimeout(() => this.$router.go(), 1000);
					})
					.catch(({message}) => {
						this.isSaving = false;
						return toastr["error"](message);
					});
			}
			delete this.playlistForm.id;
			delete this.playlistForm.lessons;
			// update existing lesson
			return axios
				.put(`/api/playlist/${this.playlist_id}`, this.playlistForm)
				.then(response => {
					toastr["success"]("Save successful");
					this.isSaving = false;
					this.$router.go();
				});
		},
		// reset input changes
		discardChanges() {
			if (this.$route.params.playlist_id === "new") {
				this.playlistForm = {
					name: "",
					cover: "",
					description: "",
					lessons: [],
					tags: []
				};
			} else {
				const {
					name,
					cover,
					description,
					lessons,
					tags
				} = this.playlist;
				this.playlistForm = {
					name,
					cover,
					description,
					lessons,
					tags
				};
				this.changed = false;
			}
		},
		deletePlaylist() {
			this.isDeleting = true;
			try {
				return axios
					.delete(`/api/playlist/${this.playlist_id}`)
					.then(data => {
						this.$router.replace({
							path: "/admin/playlists"
						});
						this.isDeleting = false;
					});
			} catch (error) {
				this.isDeleting = false;
				return toastr["error"](
					"An error occurred while deleting playlist"
				);
			}
		},
		// handle input changes
		onChanged(field) {
			if (this.playlistForm[`${field}`] === this.playlist[`${field}`]) {
				return this.changed === true ? null : (this.changed = false);
			} else {
				return (this.changed = true);
			}
		},
		getLessonsAsync: function (query) {
			clearTimeout(this.searchTimer);
			this.searchTimer = setTimeout(this.lessonSearch(query), 500);
		},

		lessonSearch(query) {
			if (query) {
				this.isLoading = true;
				axios.get("/api/search/lesson/" + query).then(({data}) => {
					this.lessonList = data.map(function (lesson) {
						return {title: lesson.title, id: lesson.id};
					});
					this.isLoading = false;
				});
			}
		},

		clearAll() {
			this.lessonList = [];
		}
	}
};
</script>
<style lang="scss">
.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}
</style>
<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}

.list-item {
	z-index: 2000;
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	width: 100%;
	padding: 20px;
	font-weight: 400;
	color: #333333;
	background-color: #ffffff;
	border-bottom: 1px solid #efefef;
}

.list {
	max-height: 80vh;
	padding: 0;
	margin: 0 auto;
	overflow: auto;
	background-color: #f3f3f3;
	border: 1px solid #efefef;
	border-radius: 3;
}

.list,
pre {
	width: 80%;
	max-width: 500px;
}

ul {
	padding: 0;
	list-style-type: none;
}

.root {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	height: 100%;
	background-color: #f3f3f3;
}
</style>
