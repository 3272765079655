<template>
	<b-card align="left"
			title="Login">
		<b-form @submit.prevent="submit">
			<br>

			<b-form-group id="email.">
				<b-form-input
					id="email"
					v-model="loginForm.email"
					autocomplete="username"
					dusk="email"
					name="email"
					placeholder="Email"
					required
					type="email"/>
			</b-form-group>

			<b-form-group id="password.">
				<b-form-input
					id="password"
					v-model="loginForm.password"
					autocomplete="current-password"
					dusk="password"
					name="password"
					placeholder="Password"
					required
					type="password"/>
			</b-form-group>

			<br>

			<b-button id="login"
					  block
					  type="submit"
					  variant="primary">
				<i v-if="loading"
				   class="fas fa-circle-notch fa-spin"/>
				Login
			</b-button>

			<br>

			<b-button id="googleSignInCustom"
					  block
					  class="btn  btn-github"
					  variant="outline-primary">
				<i v-if="loading"
				   class="fas fa-circle-notch fa-spin"/>
				<i v-else class="fab fa-google"/>
				Continue with Google
			</b-button>

			<!--<div class="row">-->
			<!--<div class="col-xs-12 col-sm-12 col-md-12 m-t-10 text-center">-->
			<!--<div class="social">-->
			<!--<a href="/auth/social/github" class="btn  btn-github" data-toggle="tooltip" title="Login with Github"> <i aria-hidden="true" class="fa fa-github"></i> </a>-->
			<!--<a href="/auth/social/twitter" class="btn  btn-twitter" data-toggle="tooltip" title="Login with Twitter"> <i aria-hidden="true" class="fa fa-twitter"></i> </a>-->
			<!--<a href="/auth/social/facebook" class="btn  btn-facebook" data-toggle="tooltip" title="Login with Facebook"> <i aria-hidden="true" class="fa fa-facebook"></i> </a>-->
			<!--</div>-->
			<!--</div>-->
			<!--</div>-->

			<p>&nbsp;</p>
			<p>
				<router-link id="student-login"
							 class="text-info m-l-5"
							 dusk="student-login"
							 to="/login/student">
					<b>Login as a Student</b>
				</router-link>
			</p>
			<p>
				<router-link id="reset-password"
							 class="text-info m-l-5"
							 dusk="reset-password"
							 to="/password">
					<b>Reset Password!</b>
				</router-link>
			</p>
			<p>
				<router-link
					id="create-account"
					class="text-info m-l-5"
					dusk="create-account"
					to="/select-plan">
					<b>Don't have an account?</b>
				</router-link>
			</p>
		</b-form>
		<b-modal id="select-account"
				 v-model="multiple_accounts"
				 centered
				 hide-footer
				 hide-header-close
				 size="xl"
				 title="You have multiple accounts, Which do we activate?"
				 @close="() => loading = false"
				 @hide="() => loading = false">
			<div class="card-deck"
				 height="300px"
				 style="padding: 50px;">
				<div v-if="userTypes.includes('super-admin')"
					 class="card bg-info"
					 @click="selectUserType('super-admin')">
					<div class="card-body text-center">
						<h2>Super Admin</h2>
						<p class="card-text">
							Welcome Super Admin, at your service.
						</p>
					</div>
				</div>
				<div v-if="userTypes.includes('admin')"
					 class="card bg-info"
					 @click="selectUserType('admin')">
					<div class="card-body text-center">
						<h2>Admin</h2>
						<p class="card-text">
							Welcome Admin, at your service.
						</p>
					</div>
				</div>
				<div v-if="userTypes.includes('student')"
					 class="card bg-primary"
					 @click="selectUserType('student')">
					<div class="card-body text-center">
						<h2>Student</h2>
						<p class="card-text">
							Checkout your classes and assignments
						</p>
					</div>
				</div>
				<div v-if="userTypes.includes('teacher')"
					 class="card bg-warning"
					 @click="selectUserType('teacher')">
					<div class="card-body text-center">
						<h2>Teacher</h2>
						<p class="card-text">
							Create classrooms and set assignments for your students
						</p>
					</div>
				</div>
				<div v-if="userTypes.includes('group-admin')"
					 class="card bg-success"
					 @click="selectUserType('group-admin')">
					<div class="card-body text-center">
						<h2>Principal</h2>
						<p class="card-text">
							Manage your classrooms and teachers.
						</p>
					</div>
				</div>
				<div v-if="userTypes.includes('district')"
					 class="card bg-info"
					 @click="selectUserType('district')">
					<div class="card-body text-center">
						<h2>District</h2>
						<p class="card-text">
							Manage schools in your districts.
						</p>
					</div>
				</div>
			</div>
		</b-modal>
	</b-card>
</template>

<script>
import {encrypt, storeExpiry} from "../../utility/encrypt";
import {mapActions} from "vuex";
import {EventActionEnum, EventCategoryEnum, EventLabelEnum} from "../../config/enums";

export default {
	data() {
		return {
			loginForm: {
				email: "",
				password: "",
				rememberMe: ""
			},
			rememberMe: false,
			loading: false,
			userTypes: [],
			multiple_accounts: false,
			gauth: null,
		};
	},
	mounted() {
		this.setConfig({guestShowPicture: true});
		this.startGoogleAuth();
	},
	computed: {
		userRoles() {
			return this.$store.getters["auth/getAuthUser"];
		},
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},
	methods: {
		...mapActions({
			setConfig: "config/setConfig",
			login: "auth/login"
		}),
		selectUserType(user_type) {
			this.$store.commit("auth/setUserType", user_type);
			// log with google
			this.$gtag.event(EventActionEnum.Login, {
				"event_category": this.userEventCategory,
				"event_label": EventLabelEnum.Auth,
				"value": "successful"
			});
			setTimeout(() => {
				this.$router.push({path: "/home"});
			}, 0);
			return (this.loading = false);
		},

		async handleLoginSuccess(data, email) {
			storeExpiry("auth_token", data.token, true);

			axios.defaults.headers.common["Authorization"] = `Bearer ${
				data.token
			}`;

			return await this.setUserInitial();
		},

		submit(e) {
			this.loading = true;
			axios
				.post("/api/auth/login", this.loginForm)
				.then(async ({data, message, status}) => {

					if (status) {
						return await this.handleLoginSuccess(data, this.loginForm.email);
					} else {
						toastr["error"](message);
						return (this.loading = false);
					}


				})
				.catch(({message}) => {
					toastr["error"](message);
					return (this.loading = false);
				});
		},

		async setUserInitial() {
			try {
				const data = await axios.get("/api/auth/user");
				const response = data.data;
				sessionStorage.setItem("_gml.rl", encrypt(response));

				this.$store.commit("auth/setAuthUserDetail", {
					id: response.id,
					first_name: response.first_name,
					last_name: response.last_name,
					email: response.email,
					avatar: response.avatar,
					plan_id: response.plan_id,
					roles: encrypt(response.roles),
					permissions: encrypt(response.permissions),
					badges: response.badges,
					favorites: response.favorites,
					favorite_playlists: response.favorite_playlists,
					groups: response.groups,
					classrooms: response.classrooms,
					activity: response.activity,
					subscription: response.subscription,
					user_options: response.user_options,
					unread_count: response.unread_count,
				});

				this.$store.commit("auth/setAuthenticated", true);

				this.$store.commit("auth/setSubscriptionActive", true);

				this.$store.commit("auth/setSubscriptionChecked", true);

				// detect if one email has multiple user types

				const defaultRoles = ["teacher", "student", "admin", "super-admin", "group-admin"];

				const buffer = [];

				const roles = response.roles.map(role => role.name);

				roles.forEach(role => {
					if (defaultRoles.includes(role)) {
						buffer.push(role);
					}
				});

				if (buffer.length > 1) {
					this.userTypes = buffer;
					this.multiple_accounts = true;
				} else {
					toastr["success"]("Login Successful");
					this.$store.commit("auth/setUserType", buffer[0]);
					// log a successful login
					this.$gtag.event(EventActionEnum.Login, {
						"event_category": EventCategoryEnum.Teacher,
						"event_label": EventLabelEnum.Auth,
						"value": "successful"
					});
					setTimeout(() => {
						this.$router.push({path: "/home"});
					}, 0);
					return (this.loading = false);
				}

			} catch (error) {
				this.loading = false;
				// log a failed login
				this.$gtag.event(EventActionEnum.Login, {
					"event_category": EventCategoryEnum.Guest,
					"event_label": EventLabelEnum.Auth,
					"value": "failed"
				});
				return toastr["error"]("Unable to login user, please try again");
			}
		},

		attachSignin(element) {
			this.gauth.attachClickHandler(element, {},
				function (googleUser) {
					this.onSignInGoogle(googleUser);
				}.bind(this), function (error) {
					this.onSignInGoogleFailed();
				}.bind(this));
		},

		async onSignInGoogle(user) {
			try {
				const profile = user.getBasicProfile();

				const name = profile.getName();
				const email = profile.getEmail();
				const avatar = profile.getImageUrl();

				const {status, message, data} = await axios.post("/api/auth/social", {
					user_type: "teacher",
					type: "google",
					data: {name, email, avatar}
				});

				if (status) {
					return await this.handleLoginSuccess(data, email);
				} else {
					toastr["error"](message);
					return (this.loading = false);
				}

			} catch (e) {
				console.log(e);
			}

		},

		async onSignInGoogleFailed() {
			this.loading = false;
			this.$gtag.event(EventActionEnum.Login, {
				"event_category": EventCategoryEnum.Guest,
				"event_label": EventLabelEnum.Auth,
				"value": "failed"
			});
			return toastr["error"]("Unable to login user, please try again");
		},

		startGoogleAuth() {
			window.gapi.load("auth2", function () {
				// Retrieve the singleton for the GoogleAuth library and set up the client.
				this.gauth = gapi.auth2.init({
					client_id: process.env.MIX_GAUTH_ID,
					cookiepolicy: "single_host_origin",
				});
				this.attachSignin(document.querySelector("#googleSignInCustom"));
			}.bind(this));
		},
	}
};
</script>

<style lang="scss" scoped>
.card {
	cursor: pointer;
}

p {
	font-size: $font-size-base;
	font-family: $font-family-base;
}

#customBtn {
	display: inline-block;
	background: white;
	color: #444444;
	width: 190px;
	border-radius: 5px;
	border: thin solid #888888;
	box-shadow: 1px 1px 1px grey;
	white-space: nowrap;
}

#customBtn:hover {
	cursor: pointer;
}

span.label {
	font-family: serif;
	font-weight: normal;
}

span.icon {
	background: url('/identity/sign-in/g-normal.png') transparent 5px 50% no-repeat;
	display: inline-block;
	vertical-align: middle;
	width: 42px;
	height: 42px;
}

span.buttonText {
	display: inline-block;
	vertical-align: middle;
	padding-left: 42px;
	padding-right: 42px;
	font-size: 14px;
	font-weight: bold;

	/* Use the Roboto font that is loaded in the <head> */
	font-family: 'Roboto', sans-serif;
}
</style>
