<template>
	<div>
		<b-card :title="`Welcome to ${groupData.name }`" align="left">
			<p>
				Please your teacher account below
			</p>
			<br>
			<b-form
				@submit.prevent="registerTeacher">
				<br>

				<b-form-group id="first_name">
					<b-form-input
						id="first_name"
						v-model="registerForm.first_name"
						class="mb-2 mr-sm-2 mb-sm-0"
						dusk="first_name"
						placeholder="First Name"
						required/>
				</b-form-group>

				<b-form-group id="last_name">
					<b-form-input
						id="last_name"
						v-model="registerForm.last_name"
						class="mb-2 mr-sm-2 mb-sm-0"
						dusk="last_name"
						placeholder="Last Name"
						required/>
				</b-form-group>

				<b-form-group id="email">
					<b-form-input
						id="email"
						v-model="registerForm.email"
						autocomplete="username"
						class="mb-2 mr-sm-2 mb-sm-0"
						dusk="email"
						placeholder="Email"
						required/>
				</b-form-group>

				<b-form-group id="password">
					<b-form-input
						id="password"
						v-model="registerForm.password"
						autocomplete="new-password"
						dusk="password"
						placeholder="Password"
						required
						type="password"/>
				</b-form-group>

				<b-form-group id="password_confirmation">
					<b-form-input
						id="password_confirmation"
						v-model="registerForm.password_confirmation"
						autocomplete="new-password"
						dusk="password_confirmation"
						placeholder="Confirm Password"
						required
						type="password"/>
				</b-form-group>

				<b-button :disabled="register_loading"
						  :loading="register_loading"
						  block
						  type="submit"
						  variant="primary">
					<i v-if="register_loading"
					   class="fas fa-circle-notch fa-spin"/>
					Register
				</b-button>
				<br>

				<p>
					Already have an account?
					<router-link class="text-info"
								 dusk="login"
								 to="/login">
						<b>Sign In</b>
					</router-link>
				</p>
			</b-form>
		</b-card>
	</div>
</template>

<script>
import {mapActions} from "vuex";
import {encrypt, storeExpiry} from "../../utility/encrypt";

export default {
	data() {
		return {
			registerForm: {
				first_name: "",
				last_name: "",
				email: "",
				password: "",
				password_confirmation: ""
			},
			groupData: null,
			code_loading: false,
			register_loading: false,
			code: this.$route.params.code,
		};
	},
	created() {
		this.code != null && this.code !== "new" ? this.fetchGroup(this.code) : null;
		this.setConfig({guestShowPicture: true});
	},
	methods: {
		...mapActions({
			setConfig: "config/setConfig"
		}),
		async fetchGroup(code) {
			try {
				this.code_loading = true;
				const response = await axios.post("/api/public/group/check-code", {code});
				this.code_loading = false;
				this.groupData = response.data;
			} catch (error) {
				this.code_loading = false;
				return toastr["error"]("Unable to find any groups with that code");
			}
		},
		async registerTeacher() {
			try {
				this.register_loading = true;

				const teacherData = {...this.registerForm, group_code: this.groupData.code};

				const response = await axios.post("/api/auth/teacher", teacherData);

				localStorage.setItem("auth_type", encrypt("single"));

				storeExpiry("auth_token", response.data.token, false);
				axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;


				return await this.setUserInitial();
			} catch (error) {
				this.register_loading = false;
				return toastr["error"]("Unable to create teacher account");
			}
		},
		async setUserInitial() {
			try {
				const data = await axios.get("/api/auth/user");
				const response = data.data;
				sessionStorage.setItem("_gml.rl", encrypt(response));
				this.$store.commit("auth/setAuthUserDetail", {
					id: response.id,
					username: response.username,
					first_name: response.first_name,
					last_name: response.last_name,
					email: response.email,
					avatar: response.avatar,
					plan_id: response.plan_id,
					roles: encrypt(response.roles),
					permissions: encrypt(response.permissions),
					badges: response.badges,
					favorites: response.favorites,
					favorite_playlists: response.favorite_playlists,
					groups: response.groups,
					classrooms: response.classrooms,
					activity: response.activity,
					subscription: response.subscription,
					user_options: response.user_options
				});
				this.$store.commit("auth/setAuthenticated", true);

				//check if subscription is active
				if (["expired",
					"incomplete",
					"incomplete_expired",
					"past_due",
					"unpaid"
				].includes(response.subscription.status)) {
					this.$store.commit("auth/setSubscriptionActive", false);
					router.replace("/profile/settings");
				} else {
					this.$store.commit("auth/setSubscriptionActive", true);
				}

				this.$store.commit("auth/setSubscriptionChecked", true);

				const defaultRoles = ["group-admin", "teacher", "student", "admin", "super-admin"];

				const buffer = [];

				const roles = response.roles.map(role => role.name);

				roles.forEach(role => {
					if (defaultRoles.includes(role)) {
						buffer.push(role);
					}
				});

				toastr["success"]("Registration Successful");
				this.$store.commit("auth/setUserType", buffer[0]);
				setTimeout(() => {
					this.$router.push({path: "/home"});
				}, 0);

				return this.register_loading = false;
			} catch (error) {
				this.register_loading = false;
				return toastr["error"]("Unable to register user, please try again");
			}
		}
	}
};
</script>
