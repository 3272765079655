import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import {decrypt, encrypt} from "../utility/encrypt";
import {ability as appAbility, abilityPlugin} from "./../config/ability";

import config from "./modules/config";
import auth from "./modules/auth";
import lesson from "./modules/lesson";
import playlist from "./modules/playlist";

Vue.use(Vuex);

export const ability = appAbility;

const debug = process.env.MIX_NODE_ENV !== "production";

const vuexSessionStorage = new VuexPersist({
	key: "_gml.we",
	saveState: (key, state) => {
		return localStorage.setItem(key, encrypt(state));
		// return sessionStorage.setItem(key, encrypt(state));
	},
	// restoreState: key => decrypt(sessionStorage.getItem(key)),
	restoreState: key => decrypt(localStorage.getItem(key)),
	reducer: state => ({
		auth: state.auth,
		config: state.config,
		lesson: state.lesson,
		playlist: state.playlist
	}),
});

export const store = new Vuex.Store({
	modules: {
		auth,
		config,
		lesson,
		playlist,
		// search,
	},

	plugins: [vuexSessionStorage.plugin, abilityPlugin],

	strict: debug,

});
