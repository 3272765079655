<template>
	<div id="nav-user-logo">
		<div v-if="image === '' || image === '/images/default-profile.png'"
			:class="{ sm: !isLarge, lg: isLarge }"
			class="nav-user-text">
			<div>{{ initials }}</div>
		</div>
		<img v-else
			:class="{ sm: !isLarge, lg: isLarge }"
			:src="image !== '' ? image : getAuthUserAvatar()"
			alt="avatar">
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	props: {
		isLarge: {type: Boolean, default: false},
		image: {type: String, default: ""},
	},
	data() {
		return {
			initials: "",
		};
	},
	beforeMount() {
		this.initials = this.prepareInitials();
	},
	methods: {

		...mapGetters({
			getAuthUserFullName: "auth/getAuthUserFullName",
			getAuthUserAvatar: "auth/getAuthUserAvatar",
			user: "auth/getAuthUser",
			userType: "auth/getUserType",
		}),
		prepareInitials() {
			const isNotStudent = this.userType() !== "student";
			const nameArray = isNotStudent ? this.getAuthUserFullName().split(" ") : this.user().username.split(/(?=[A-Z])/).map(s => s.toLowerCase());
			if (nameArray.length === 1) {
				return nameArray[0].charAt(0).toUpperCase();
			}
			if (nameArray.length > 1) {
				return `${nameArray[0].charAt(0).toUpperCase()}${nameArray[1].charAt(0).toUpperCase()}`;
			}
			return isNotStudent ? this.getAuthUserFullName().charAt(0).toUpperCase() : this.user().username.charAt(0).toUpperCase();
		},
	}
};
</script>

<style lang="scss" scoped>
#nav-user-logo {
	display: inline;
	text-align: center;
}

.nav-user-text {
	color: whitesmoke;
	background: $blue;
}

.sm {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
	border-radius: 50%;
}

.lg {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 150px;
	height: 150px;
	font-size: 50px;
	border-radius: 50%;
}
</style>
