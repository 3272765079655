<template>
	<div class="page-section">
		<div class="container">
			<br>
			<div class="row">
				<div class="col-md-12">
					<h4>Bookmarked Lessons</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div v-if="user && user.favorites && user.favorites[0]">
						<thumbnailIndex v-if="user.favorites"
							:lessons="user.favorites"
							grid />
					</div>
					<div v-else>
						<p>Nothing here</p>
					</div>
				</div>
			</div>
			<br>
			<div class="row">
				<div class="col-md-12">
					<h4>Bookmarked Playlists</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div v-if="user && user.favorite_playlists && user.favorite_playlists[0]">
						<thumbnailIndex :playlists="user.favorite_playlists"
							grid />
					</div>
					<div v-else>
						<p>Nothing here yet</p>
					</div>
				</div>
			</div>
			<br>
			<div class="row">
				<div class="col-md-12">
					<span class="float-right">
						<router-link to="/profile/overview"
							class="pull-right">
							<button class="btn btn-md btn-primary">Back</button>
						</router-link>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import thumbnailIndex from "../../components/lesson-playlist/thumbnailIndex.vue";
import {mapState} from "vuex";

export default {
	name: "ProfileFavorites",

	components: {
		thumbnailIndex
	},

	computed: {
		...mapState({
			user: "auth",
		})
	},
};
</script>

<style scoped>
.panel {
	padding: 30px;
	margin: 10px 0;
	background-color: #ffffff;
	border-radius: 10px;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.clearfix::after {
	display: table;
	clear: both;
	content: "";
}

/* Grid layout */
.grid {
	display: grid;
	grid-auto-columns: 1fr 200px;
}

.content {
	grid-column: 1;
}

.grid > * {
	border: 0;

	/* demo only */
}

/* Product Grid */

body {
	background: #ffffff;
}

h1 {
	font-size: 20px;
	font-weight: 500;
}

ul.rig {
	margin-left: -2.5%;
	font-size: 0;
	list-style: none;

	/* should match li left margin */
}

ul.rig li {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	height: 200px;
	padding: 10px;
	margin: 0 0 2.5% 2.5%;
	font-size: 16px;
	font-size: 1rem;
	vertical-align: top;
	cursor: pointer;
	background: #ffffff;
	border: 1px solid #dddddd;
	box-shadow: 0 0 0 #000000;
}

ul.rig li img {
	margin: 0 0 10px;
}

ul.rig li h3 {
	margin: 0 0 10px;
	font-size-adjust: inherit;
}

ul.rig li p {
	font-size: 1em;
	line-height: 1.5em;
	color: #000000;
}

/* class for 2 columns */

ul.rig.columns-2 li {
	width: 47.5%;

	/* this value + 2.5 should = 50% */
}

/* class for 3 columns */

ul.rig.columns-3 li {
	width: 30.83%;

	/* this value + 2.5 should = 33% */
}

/* class for 4 columns */

ul.rig.columns-4 li {
	width: 22.5%;

	/* this value + 2.5 should = 25% */
}

@media (max-width: 680px) {
	ul.grid-nav li {
		display: inline-block;
		margin: 0 0 5px;
	}

	ul.grid-nav li a {
		display: inline-block;
		margin: 0 0 5px;
	}

	ul.rig {
		margin-left: 0;
	}

	ul.rig li {
		width: 100% !important;

		/* over-ride all li styles */
		margin: 0 0 10px;
	}
}
</style>
