<template>
	<b-card align="left"
		title="Reset Password">
		<div v-if="!showMessage">
			<b-form @submit.prevent="submit">
				<br>

				<b-form-group id="email">
					<b-form-input id="email"
						v-model="resetForm.email"
						dusk="email"
						placeholder="Email"
						required
						type="email" />
				</b-form-group>

				<b-form-group id="password">
					<b-form-input
						id="password"
						v-model="resetForm.password"
						dusk="password"
						placeholder="Password"
						required
						type="password" />
				</b-form-group>

				<b-form-group id="password_confirmation">
					<b-form-input
						id="password_confirmation"
						v-model="resetForm.password_confirmation"
						dusk="password_confirmation"
						placeholder="Confirm Password"
						required
						type="password" />
				</b-form-group>

				<br>

				<b-button block
					dusk="submit"
					type="submit">
					<i v-if="loading"
						class="fas fa-circle-notch fa-spin" />
					Reset Password
				</b-button>
			</b-form>
		</div>

		<div v-else>
			<h4 v-if="status"
				class="alert alert-success"
				v-text="message" />
			<h4 v-else
				class="alert alert-danger"
				v-text="message" />
		</div>

		<br>

		<div class="form-group">
			<div class="col-sm-12">
				<p>
					Back to Login?
					<router-link class="text-info m-l-5"
						dusk="login"
						to="/login">
						<b>Sign In</b>
					</router-link>
				</p>
			</div>
		</div>
	</b-card>
</template>

<script>
export default {
	data() {
		return {
			resetForm: {
				email: "",
				password: "",
				password_confirmation: "",
				token: this.$route.params.token
			},
			message: "",
			status: true,
			showMessage: false,
			loading: false
		};
	},
	mounted() {
		axios
			.post("/api/auth/validate-password-reset", {
				token: this.resetForm.token
			})
			.then(response => {
				this.showMessage = false;
			})
			.catch(({message}) => {
				this.message = message;
				this.showMessage = true;
				this.status = false;
			});
	},
	methods: {
		submit(e) {
			this.loading = true;
			axios
				.post("/api/auth/reset", this.resetForm)
				.then(({message}) => {
					this.message = message;
					this.showMessage = true;
					this.status = true;
					this.loading = false;
				})
				.catch(({message}) => {
					toastr["error"](message);
					this.loading = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
p {
	font-size: $font-size-base;
	font-family: $font-family-base;
}
</style>
