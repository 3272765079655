<template>
	<div class="eg-slideshow">
		<b-modal id="tutorialModal"
				 ref="tutorialModal"
				 :title="!childWindow && !fullScreen ?`Projector Tab` : `Presenter Mode`"
				 hide-footer>
			<ul v-if="!childWindow && fullScreen">
				<li>Use the arrow keys to navigate the slides</li>
				<li>Press P to open presentation in a new tab</li>
				<li>Press F to enter full-screen mode</li>
				<li>Press Q to go back to lesson</li>
			</ul>
			<ul v-else-if="!childWindow && !fullScreen">
				<li>Use the arrow keys to navigate the slides</li>
				<li>Press F to enter full-screen mode</li>
				<li>Press Q to go back to lesson</li>
			</ul>
			<ul v-else>
				<li>Use the arrow keys to navigate the slides</li>
				<li>Press P to stop presentation mode</li>
				<li>Press F to enter full-screen mode</li>
				<li>Press Q to go back to lesson</li>
			</ul>
		</b-modal>
		<slide v-for="content in contents"
			   :key="content.order">
			<div v-if="fullScreen"
				 class="viewer">
				<div class="lesson-content-page page-section container-fluid">
					<div class="row">
						<div class="col-lg-8 offset-lg-2 lesson-content h-100">
							<div v-if="content.caption == null || content.caption === ''"
								 class="display-content text-center">
								<div v-if="content.type === 'Video'"
									 class="embed-responsive embed-responsive-16by9">
									<iframe ref="video"
											:src="content.link"
											allowfullscreen
											class="embed-responsive-item"/>
								</div>
								<div v-else-if="content.type === 'Comic' || content.type === 'Image'">
									<img :src="content.link"
										 alt="Content Image">
								</div>
								<br>
								<div
									class="content-text"
									v-html="content.text"/>
							</div>
							<div v-else class="display-content text-center">
								<div v-if="content.type === 'Video'"
									 class="embed-responsive embed-responsive-16by9 split-content">
									<iframe ref="video"
											:src="content.link"
											allowfullscreen
											class="embed-responsive-item"/>
								</div>
								<div v-else-if="content.type === 'Comic' || content.type === 'Image'"
									 class="split-content">
									<img :src="content.link"
										 alt="Content Image">
								</div>
								<div v-else
									 class="content-text split-content"
									 v-html="content.text"/>
								<div class="split-caption">
									<p v-html="content.caption"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- presenter div -->
			<div v-if="parentWindow"
				 class="viewer">
				<div class="lesson-content-page page-section container-fluid">
					<div class="row">
						<div class="col-lg-8 offset-lg-2 lesson-content h-100">
							<div v-if="content.caption == null || content.caption === ''"
								 class="display-content text-center">
								<div v-if="content.type === 'Video'"
									 class="embed-responsive embed-responsive-16by9">
									<iframe ref="video"
											:src="content.link"
											allowfullscreen
											class="embed-responsive-item"/>
								</div>
								<div v-else-if="content.type === 'Comic' || content.type === 'Image'">
									<img :src="content.link"
										 alt="Content Image">
								</div>
								<br>
								<div
									class="content-text"
									v-html="content.text"/>
							</div>
							<div v-else class="display-content text-center">
								<div v-if="content.type === 'Video'"
									 class="embed-responsive embed-responsive-16by9 split-content">
									<iframe ref="video"
											:src="content.link"
											allowfullscreen
											class="embed-responsive-item"/>
								</div>
								<div v-else-if="content.type === 'Comic' || content.type === 'Image'"
									 class="split-content">
									<img :src="content.link"
										 alt="Content Image">
								</div>
								<div v-else
									 class="content-text split-content"
									 v-html="content.text"/>
								<div class="split-caption">
									<p v-html="content.caption"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- viewers div -->
			<div v-if="childWindow"
				 class="presenter">
				<div class="lesson-content-page page-section container-fluid">
					<div class="row">
						<div class="col-md-12 lesson-content h-80">
							<div v-if="content.caption == null || content.caption === ''"
								 class="display-content text-center">
								<div v-if="content.type === 'Video'"
									 class="embed-responsive embed-responsive-16by9">
									<iframe ref="video"
											:src="content.link"
											allowfullscreen
											class="embed-responsive-item"/>
								</div>

								<div v-else-if="content.type === 'Comic' || content.type === 'Image'"
									 class="text-center">
									<img :src="content.link"
										 alt="Content Image">
								</div>

								<div v-else
									 class="content-text"
									 v-html="content.text"/>
							</div>
							<div v-else class="display-content text-center">
								<div v-if="content.type === 'Video'"
									 class="embed-responsive embed-responsive-16by9 split-content">
									<iframe ref="video"
											:src="content.link"
											allowfullscreen
											class="embed-responsive-item"/>
								</div>
								<div v-else-if="content.type === 'Comic' || content.type === 'Image'"
									 class="split-content">
									<img :src="content.link"
										 alt="Content Image">
								</div>
								<div v-else
									 class="content-text split-content"
									 v-html="content.text"/>
								<div class="split-caption">
									<p v-html="content.caption"/>
								</div>
							</div>
							<div class="clearfix">
								<span class="float-left">
									<p style="font-size: 16px;">Slide {{
											content.order === contents.length ? content.order - 1 : content.order
										}} / {{ contents.length - 1 }}</p>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</slide>
	</div>
</template>

<script>
import {Slide, Slideshow} from "eagle.js";
import axios from "axios";
import helper from "../../utility";

export default {
	name: "PresenterView",
	mixins: [Slideshow],

	components: {
		"slide": Slide,
	},

	props: {
		onStartExit: {
			type: Function,
			default: () => null
		},
		onEndExit: {
			type: Function,
			default: () => null
		},
		embedded: {type: Boolean, default: false},
		backBySlide: {type: Boolean, default: true},
		mouseNavigation: {type: Boolean, default: false},
		keyboardNavigation: {type: Boolean, default: true},
	},

	data() {
		return {
			lesson: null,
			fullPageWidth: null,
			fullPageHeight: null,
			parentHeight: null,
			parentWidth: null,
			contents: [{title: "Loading", order: 1}],
			parentWindow: null,
			childWindow: null,
			fullScreen: true,
			listener: null,
		};
	},

	async mounted() {
		await this.fetchData();
		this.findSlides();
		this.$refs["tutorialModal"].show();
		this.listener = document.addEventListener("keyup", async (e) => {
			// if user presses esc
			if (e.code === "KeyQ") {
				await this.endSlideShow();
			}
			if (e.code === "KeyF") {
				await this.setupFullScreen();
			}
		});
		if (this.parentWindow != null) {
			this.childWindow = null;
		}
	},

	updated() {
		if (this.childWindow || this.parentWindow) {
			this.fullScreen = false;
		} else {
			this.fullScreen = true;
		}
	},

	destroyed() {
		document.removeEventListener("keyup", this.listener);
	},

	methods: {
		async setupFullScreen() {
			if (document.fullscreenEnabled) {
				const bodyElement = document.querySelector("body");
				await bodyElement.requestFullscreen();
			} else {
				toastr["error"]("Your device does not support full-screen");
			}
		},
		fetchData() {
			const {lesson_id} = this.$route.params;
			return axios
				.get(`/api/lesson/${lesson_id}`)
				.then(({data}) => {
					this.setLesson(data);
				})
				.catch(error => {
					this.$router.go(-1);
					toastr.error("Error fetching lesson");
				});
		},

		setLesson(data) {
			// Split the content lines as well
			this.lesson = {
				id: data.id,
				title: data.title,
				cover: data.cover,
				hero: data.hero,
				type: data.hero,
				classification: data.hero,
				author: data.author,
				focus: data.focus,
				standards: data.standards,
				overview: helper.splitText(data.overview),
				content: data.content,
				playlists: data.playlists,
				links: data.links,
				tags: data.tags
			};
			this.contents = [...this.lesson.content, {
				text: "End of slide show",
				order: this.lesson.content.length + 1
			}];
		},

		async endSlideShow() {
			try {
				await document.exitFullscreen();
				return this.$router.replace({path: `/lesson/${this.lesson.id}/content`});
			} catch (e) {
				return this.$router.replace({path: `/lesson/${this.lesson.id}/content`});
			}
		},
	}
};
</script>

<style scoped>
::-webkit-scrollbar {
	/* width: 0px; Remove scrollbar space */
	background: transparent; /* Optional: just make scrollbar invisible */
}
</style>

<style lang='scss'>
p {
	font-size: 20px;
}

.eg-slideshow {
	font-family: $font-family-base;
	font-size: $font-size-base;
	line-height: $line-height-base;
	background-color: #ffffff !important;

	.eg-slide {
		position: relative;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100vw;

		.page-section {
			position: relative;
			left: 0;
			min-width: 100vw;
			max-height: 90vh;

			.lesson-content {
				padding: $container-padding;

				@include breakpoint($below-sm) {
					padding: 10px;
				}

				.display-content {
					height: 80vh;
					overflow-y: scroll;
					overflow-wrap: break-word;
				}

				.content-text {
					margin-bottom: 10px;
				}
			}

			.lesson-sidebar {
				padding: $container-padding;
				padding-left: 20px;
				overflow: scroll;
				overflow-wrap: break-word;
				border-left: 1px solid #f5f6f9;

				@include breakpoint($below-sm) {
					padding-right: 5px;
					padding-left: 10px;
				}
			}

			#upnext > div {
				margin: 0;
			}

			.split-content {
				max-height: 60vh;
				overflow-y: scroll;
				border-bottom: #f5f6f9 solid 1px;
			}

			.split-caption {
				overflow-y: scroll;
				max-height: 30vh;
				margin-top: 5px;
			}
		}
	}
}

iframe {
	background: none;
	border: none;
}

#slide-count {
	position: absolute;
	display: inline-table;
	color: #000061;
}
</style>
