<template xmlns:v-clipboard="http://www.w3.org/1999/xhtml">
	<div v-if="playlist">
		<div class="page-section">
			<div class="row">
				<div class="col-md-9 lesson-overview-text">
					<h4>Description</h4>
					<div v-html="playlist.description"/>
					<ul v-if="playlist.tags[0]"
						class="tags">
						<li v-for="tag in playlist.tags"
							:key="tag.id"
							class="tag">
							{{ tag.name }}
						</li>
					</ul>
				</div>
				<div class="col-md-3 lesson-sidebar">
					<span class="sidebar-item">
						<i class="fas fa-share-alt icon"/>
						<a class="underline text-info pl-2"
						   href="#"
						   @click="sharePlaylist()">Share Playlist</a>
					</span>

					<b-modal ref="sharemodalRef"
							 centered
							 hide-footer
							 hide-header
							 size="lg">
						<div class="text-center">
							<img :src="playlist.cover"
								 class="img-responsive rounded"
								 height="100%"
								 width="100%">
							<br>
							<br>
							<h4>Hi, check out this content on Get Media L.I.T!"</h4>
							<div v-html="playlist.description"/>
							<br>
							<button
								class="btn btn-lg btn-primary"
								data-placement="bottom"
								data-toggle="tooltip"
								title="Share on Twitter"
								@click="sharePlaylist('twitter')">
								<i class="fab fa-twitter"/>
								Twitter
							</button>
							<button
								class="btn btn-lg btn-primary"
								data-placement="bottom"
								data-toggle="tooltip"
								title="Share on Facebook"
								@click="sharePlaylist('facebook')">
								<i class="fab fa-facebook-f"/>
								Facebook
							</button>
							<button
								class="btn btn-lg btn-primary"
								data-placement="bottom"
								data-toggle="tooltip"
								title="Share on Linkedin"
								@click="sharePlaylist('linkedin')">
								<i class="fab fa-linkedin-in"/>
								LinkedIn
							</button>
							<button
								v-clipboard:copy="shareText + ' \n ' + decodeURIComponent(shareLink)"
								v-clipboard:error="onCopyError"
								v-clipboard:success="onCopy"
								class="btn btn-lg btn-primary"
								data-placement="bottom"
								data-toggle="tooltip"
								title="Copy to Clipboard">
								<i class="fas fa-copy"/>
								Copy
							</button>
						</div>
					</b-modal>

					<span class="sidebar-item">
						<i :class="status.icon"
						   class="icon"/>
						<a
							class="underline text-info pl-2"
							href="#"
							@click="setFavorite(playlist.id)">{{ status.text }}</a>
					</span>
					<br>

					<button v-if="user_type === 'teacher'"
							class="btn btn-success btn-block"
							type="button"
							@click="assignToClass">
						<i class="fas fa-plus"/> Assign to Class
					</button>

					<button
						class="btn btn-block btn-info"
						style="color: whitesmoke;"
						@click="routeToShowPlaylistLesson(playlist.id, playlist.lessons[0].id, 1)">
						<i class="fas fa-play icon"/>
						Start Playlist
					</button>

					<button v-if="$can('view', 'admin') && !playlist.is_custom"
							class="btn btn-block btn-warning"
							style="color: whitesmoke;"
							@click="routeToEditPlaylist(playlist.id)">
						<i class="fas fa-pencil-alt icon"/>
						Edit Playlist
					</button>
				</div>
			</div>
		</div>
		<br>
		<div class="page-section">
			<h4>Lessons</h4>
			<div class="row">
				<div class="col-md-12">
					<thumbnailIndex
						v-if="playlist.lessons"
						:assign-to-class-visible="false"
						:lessons="playlist.lessons"
						:playlist-id="playlist.id"
						grid/>
					<div v-else
						 class="text-center">
						<h4>This playlist has no lessons yet</h4>
					</div>
				</div>
			</div>
		</div>
		<assign-to-class type="playlist"/>
	</div>
</template>

<script>
import axios from "axios";
import thumbnailIndex from "../../components/lesson-playlist/thumbnailIndex.vue";
import {mapActions, mapGetters} from "vuex";
import EventBus from "../../utility/eventbus";
import assignToClass from "../../components/classroom/assign-to-classroom";
import {EventActionEnum, EventLabelEnum} from "../../config/enums";

export default {
	name: "ShowPlaylist",

	components: {
		thumbnailIndex,
		assignToClass
	},

	data() {
		return {
			user: null,
			status: {
				loading: false,
				text: "Add to Favorites",
				icon: "far fa-heart",
				added: false
			},
			shareTitle: "Get Media L.I.T!",
			shareText: "Hey, Check out this content on Get Media L.I.T!",
			shareLink: encodeURIComponent(document.location.toString()),
		};
	},

	computed: {
		playlist() {
			return this.$store.getters["playlist/getPlaylist"];
		},

		showPlaylist() {
			return +this.$route.params.playlist_id === +this.playlist.id;
		},
		user_type() {
			return this.$store.getters["auth/getUserType"];
		},
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},

	created() {
		this.init();
	},

	methods: {
		...mapActions({
			refreshAuth: "auth/refreshAuth",
		}),

		...mapGetters({
			getAuthUser: "auth/getAuthUser",
			getPlaylist: "playlist/getPlaylist",
		}),

		init() {
			this.user = this.getAuthUser();
			this.checkFavorite();
			// log with GA
			this.$gtag.event(EventActionEnum.View, {
				"event_category": this.userEventCategory,
				"event_label": EventLabelEnum.Playlist,
				"value": this.playlist.name,
			});
		},

		checkFavorite() {
			if (this.playlist) {
				if (
					this.user.favorite_playlists &&
					this.user.favorite_playlists[0] !== undefined
				) {
					const inFavorite = this.user.favorite_playlists.some(
						playlist =>
							playlist.id === this.$route.params.playlist_id
					);
					if (inFavorite) {
						this.status.text = "Added to Favorites";
						this.status.icon = "fas fa-heart";
						this.status.added = true;
					} else {
						this.status.text = "Add to Favorites";
						this.status.icon = "far fa-heart";
						this.status.added = false;
					}
				}
			}
		},

		setFavorite(id) {
			switch (this.status.added) {

				case true:
					return axios
						.delete(`/api/user/${this.user.id}/playlists/${id}`)
						.then(() => {
							this.refreshAuth()
								.then(() => {
									this.status.text = "Add to Favorites";
									this.status.icon = "far fa-heart";
									return (this.status.added = false);
								});
						})
						.catch(({message}) => {
							toastr["error"](message);
						});

				case false:
					return axios
						.put(`/api/user/${this.user.id}/playlists`, {
							playlist: id
						})
						.then(() => {
							this.refreshAuth()
								.then(() => {
									this.status.text = "Added to Favorites";
									this.status.icon = "fas fa-heart";
									return (this.status.added = true);
								});
						})
						.catch(({message}) => {
							toastr["error"](message);
						});

				default:
					return null;
			}
		},

		routeToShowPlaylistLesson(playlist, lesson, lesson_sequence) {
			return this.$router.push({
				name: "playlistShowLesson",
				params: {
					playlist_id: playlist,
					lesson_id: lesson,
					lesson_sequence: lesson_sequence
				}
			});
		},

		routeToEditPlaylist(playlist) {
			if (playlist.is_custom) {
				return this.$router.push({
					path: `custom/playlists/${playlist.id}`
				});
			}
			return this.$router.push({
				name: "adminEditPlaylist",
				params: {
					playlist_id: playlist,
				}
			});
		},

		showModal() {
			this.$refs.myModalRef.show();
		},

		hideModal() {
			this.$refs.myModalRef.hide();
			this.$refs.sharemodalRef.hide();
		},

		sharePlaylist(type) {
			this.$refs.sharemodalRef.show();
			switch (type) {
				case "facebook":
					window.open(
						`https://www.facebook.com/sharer/sharer.php?u=${
							this.shareLink
						}`,
						"_blank"
					);
					break;
				case "twitter":
					window.open(
						`https://twitter.com/home?status=${this.shareText} ${
							this.shareLink
						}`,
						"_blank"
					);
					break;
				case "linkedin":
					window.open(
						`https://www.linkedin.com/sharing/share-offsite/?url=${
							this.shareLink
						}&title=${this.shareTitle}"
						&summary=${this.shareText}&source=`,
						"_blank"
					);
					break;
				default:
					break;
			}
		},

		onCopy: function (e) {
			toastr.success("Copied to Clipboard");
		},

		onCopyError: function (e) {
			toastr.error("Could not copy to Clipboard");
		},

		assignToClass() {
			if (this.lesson) {
				EventBus.$emit("ASSIGN-TO-CLASSROOM", {...this.lesson});
			} else {
				EventBus.$emit("ASSIGN-TO-CLASSROOM", {...this.playlist});
			}

		}
	}
};
</script>

<style lang="scss" scoped>
ul {
	width: 100%;
	margin-bottom: 20px;
	overflow: hidden;
	list-style-type: circle !important;
}

li {
	display: inline;
	float: left;
}

.double li {
	width: 50%;
}

.lesson-overview-text {
	padding: $container-padding;

	div {
		margin-bottom: 30px;
	}
}

.lesson-content-page {
	.display-content {
		margin-top: 20px;
	}
}

.lesson-sidebar {
	padding: $container-padding;
	padding-left: 20px;
	border-left: 1px solid #f5f6f9;

	.sidebar-item {
		display: block;
		padding: 10px;
	}
}

.lesson-thumbnail {
	position: relative;
	display: inline-block;
	flex: 0 0 auto;
	width: 220px;
	margin-right: 10px;
	margin-left: 10px;
	cursor: pointer;

	.lesson-image {
		width: 100%;
		height: $lesson-thumb-image-height;
		margin-bottom: 20px;
		background-size: cover;

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: $lesson-thumb-image-height;
			background-color: #000000;
			border: $active-lesson-image-border;
			border-radius: 5px;
			opacity: 0;
		}

		.hover-content {
			position: relative;
			z-index: 10;
			display: none;
			line-height: $lesson-thumb-image-height;
			text-align: center;
		}
	}

	&:hover {
		.lesson-image {
			border: $active-lesson-image-border;

			.overlay {
				opacity: 0.4;
				transition: opacity 0.25s ease-in;
			}

			.hover-content {
				display: block;
			}
		}
	}
}

.tags {
	padding: 0;
	margin: 0;
	overflow: hidden;
	list-style: none;
}

.tags li {
	float: left;
}

.tag {
	position: relative;
	display: inline-block;
	height: 26px;
	padding: 0 20px 0 23px;
	margin: 0 10px 10px 0;
	line-height: 26px;
	color: #999999;
	text-decoration: none;
	background: #eeeeee;
	border-radius: 3px 0 0 3px;
	-webkit-transition: color 0.2s;
}

.tag::before {
	position: absolute;
	top: 10px;
	left: 10px;
	width: 6px;
	height: 6px;
	content: "";
	background: #ffffff;
	border-radius: 10px;
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
}

.tag::after {
	position: absolute;
	top: 0;
	right: 0;
	content: "";
	background: #ffffff;
	border-top: 13px solid transparent;
	border-bottom: 13px solid transparent;
	border-left: 10px solid #eeeeee;
}

.tag:hover {
	color: white;
	background-color: $dark-blue;
}

.tag:hover::after {
	border-left-color: $dark-blue;
}
</style>
