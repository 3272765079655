import VueRouter from "vue-router";
import helper from "../utility";
import axios from "axios";

import {readExpiry, storeExpiry,} from "../utility/encrypt";

let routes = [
	// protected routes - default layout
	{
		path: "/",
		component: require("../layouts/default-page.vue").default,
		meta: {requiresAuth: true},
		children: [
			// default
			{
				path: "/",
				component: require("../pages/home.vue").default,
				name: "home",
			},
			//home
			{
				path: "/home",
				component: require("../pages/home.vue").default,
				name: "homeLink"
			},
			// dashboard
			{
				path: "/dashboard",
				component: require("../pages/dashboard.vue").default,
			},
			// search
			{
				path: "/search",
				component: require("../components/search/searchResults.vue").default,
				props: route => ({query: route.query.q}),
				name: "searchResults"
			},
			// profile
			{
				path: "/profile",
				component: require("../pages/profile/profile.vue").default,
				children: [
					{
						path: "",
						component: require("../pages/profile/profileOverview.vue").default,
						name: "profileOverview"
					},
					{
						path: "overview",
						component: require("../pages/profile/profileOverview.vue").default,
						name: "profileOverviewLink"
					},
					{
						path: "settings",
						component: require("../pages/profile/profileSettings.vue").default,
						name: "profileSettings"
					},
					{
						path: "favorites",
						component: require("../pages/profile/profileFavorites.vue").default,
						name: "profileFavorites"
					}
				]
			},
			// lesson
			{
				path: "/lesson",
				component: require("../pages/lessons/lesson.vue").default,
				name: "lesson",
				children: [
					{
						path: "explore",
						component: require("../pages/lessons/allLessons.vue").default,
						name: "allLessons"
					},
					{
						path: "create",
						component: require("../pages/lessons/createLesson.vue").default,
						name: "createLesson"
					},
					{
						path: "content/create",
						component: require("../pages/lessons/createLessonContent.vue").default,
						name: "createLessonContent"
					},
					{
						path: ":lesson_id",
						component: require("../pages/lessons/showLesson.vue").default,
						name: "showLesson"
					},
					{
						path: ":lesson_id/content",
						component: require("../pages/lessons/showLessonContent.vue").default,
						name: "showLessonContent"
					},
				]
			},
			// playlist
			{
				path: "/playlist",
				component: require("../pages/playlists/playlist.vue").default,
				name: "playlist",
				children: [
					{
						path: "featured",
						component: require("../pages/playlists/featuredPlaylists.vue").default,
						name: "featuredPlaylists"
					},
					{
						path: "create",
						component: require("../pages/playlists/createPlaylist.vue").default,
						name: "createPlaylist"
					},
					{
						path: "custom",
						component: require("../pages/playlists/customPlaylist.vue").default,
						name: "customPlaylist"
					},
					{
						path: ":playlist_id",
						component: require("../pages/playlists/showPlaylist.vue").default,
						name: "showPlaylist"
					},
					{
						path: ":playlist_id/lesson",
						component: require("../pages/lessons/lesson.vue").default,
						name: "playlistLesson",
						children: [
							{
								path: ":lesson_sequence",
								component: require("../pages/lessons/showLesson.vue").default,
								name: "playlistShowLesson"
							},
							{
								path: ":lesson_sequence/content",
								component: require("../pages/lessons/showLessonContent.vue").default,
								name: "playlistShowLessonContent",
								props: route => ({
									lessonSequence: +route.params.lesson_sequence
								})
							}
						]
					}
				]
			},
			// admin
			{
				path: "/admin",
				component: require("../layouts/admin-layout.vue").default,
				redirect: "/admin/overview",
				name: "admin",
				children: [
					{
						path: "overview",
						component: require("../pages/admin/overview.vue").default,
						name: "overview",
					},
					{
						path: "users",
						component: require("../pages/admin/general.vue").default,
						name: "users",
					},
					{
						path: "users/:user_id",
						component: require("../pages/admin/_users.vue").default,
						name: "user_id",
					},
					{
						path: "lessons",
						component: require("../pages/admin/general.vue").default,
						name: "adminAllLessons"
					},
					{
						path: "lessons/:lesson_id",
						component: require("../pages/admin/_lessons.vue").default,
						name: "adminEditLesson"
					},
					{
						path: "playlists",
						component: require("../pages/admin/general.vue").default,
						name: "adminAllPlaylists"
					},
					{
						path: "playlists/:playlist_id",
						component: require("../pages/admin/_playlists.vue").default,
						name: "adminEditPlaylist"
					},
					{
						path: "contents",
						component: require("../pages/admin/general.vue").default,
						name: "adminAllContent"
					},
					{
						path: "contents/:content_id",
						component: require("../pages/admin/_contents.vue").default,
						name: "adminEditContent"
					},
					{
						path: "media",
						component: require("../pages/admin/media.vue").default,
						name: "adminMedia",
					},
					{
						path: "authorizations",
						component: require("../pages/admin/authorizations.vue").default,
						name: "adminAuthorizations"
					},
					{
						path: "authorizations/:role_id",
						component: require("../pages/admin/_roles.vue").default,
						name: "adminEditAuthorization"
					},
					{
						path: "bulk-uploads",
						component: require("../pages/admin/bulk-uploads.vue").default,
						name: "adminBulkUploads"
					},
					{
						path: "plans",
						component: require("../pages/admin/plans.vue").default,
						name: "adminPlans"
					},
					{
						path: "plans/:plan_id",
						component: require("../pages/admin/_plans.vue").default,
						name: "adminEditPlans"
					},
					{
						path: "groups",
						component: require("../pages/admin/groups.vue").default,
						name: "adminGroups"
					},
					{
						path: "groups/:group_id",
						component: require("../pages/admin/_group.vue").default,
						name: "adminEditGroup"
					},
					{
						path: "announcements",
						component: require("../pages/admin/announcements.vue").default,
						name: "adminAnnouncements"
					},
					{
						path: "announcements/:announcement_id",
						component: require("../pages/admin/_announcements.vue").default,
						name: "adminEditAnnouncements"
					}
				]
			},
			// student
			{
				path: "/student",
				component: require("../layouts/student-layout.vue").default,
				children: [
					{
						path: "class",
						component: require("../pages/student/classrooms.vue").default,
						name: "student-class"
					},
					{
						path: "class/:id",
						component: require("../pages/student/classroomDetails.vue").default,
						name: "student-details"
					}
				],
			},
			// teacher
			{
				path: "/teacher",
				component: require("../layouts/teacher-layout.vue").default,
				redirect: "/teacher/overview",
				name: "teacher",
				children: [
					{
						path: "overview",
						component: require("../pages/teacher/overview.vue").default
					},
					{
						path: "assignments",
						component: require("../pages/teacher/assignments.vue").default
					},
					{
						path: "assignments/:type/:id/class/:class_id",
						component: require("../pages/teacher/assignmentDetails.vue").default
					},
					{
						path: "classes",
						component: require("../pages/teacher/classes.vue").default,
					},
					{
						path: "classes/:id",
						component: require("../pages/teacher/classDetails.vue").default,
					},
					{
						path: "classes/:id/edit",
						component: require("../pages/teacher/editClassroom.vue").default,
					},
					{
						path: "custom/playlists",
						component: require("../pages/teacher/custom-playlists.vue").default,
					},
					{
						path: "custom/playlists/:id",
						component: require("../pages/teacher/create-custom-playlist.vue").default,
					},
					{
						path: "students",
						component: require("../pages/teacher/students.vue").default,
					},
					{
						path: "custom-lessons",
						component: require("../pages/teacher/custom-lessons.vue").default,
					}
				]
			},
			// principal
			{
				path: "/principal",
				component: require("../layouts/principal-layout.vue").default,
				redirect: "/principal/overview",
				name: "principal",
				children: [
					{
						path: "overview",
						component: require("../pages/principal/overview.vue").default
					},
					{
						path: "teachers",
						component: require("../pages/principal/teachers.vue").default
					},
					{
						path: "students",
						component: require("../pages/principal/students.vue").default
					},
					{
						path: "classes",
						component: require("../pages/principal/classes.vue").default
					},
					{
						path: "classes/:id",
						component: require("../pages/principal/_class.vue").default
					},
					{
						path: "classes/:id/edit",
						component: require("../pages/teacher/editClassroom.vue").default,
					},
					{
						path: "announcements",
						component: require("../pages/admin/announcements.vue").default,
					},
					{
						path: "announcements/:announcement_id",
						component: require("../pages/admin/_announcements.vue").default,
						name: "principalEditAnnouncements"
					},
					{
						path: "custom-lessons",
						component: require("../pages/principal/custom-lessons.vue").default,
						name: "principalCustomLessons"
					},
					{
						path: "custom-lessons/:custom_lesson_id",
						component: require("../pages/principal/_custom-lesson.vue").default,
						name: "singlePrincipalCustomLesson"
					}
				],
			},
			//  District
			{
				path: "/district",
				component: require("../layouts/district-layout.vue").default,
				redirect: "/district/overview",
				name: "district",
				children: [
					{
						path: "overview",
						component: require("../pages/district/overview.vue").default
					}
				],
			},
			// Announcements
			{
				path: "/announcements",
				component: require("../pages/announcements/announcements.vue").default,
				name: "announcements"
			}
		]
	},
	// unprotected routes - public layout
	{
		path: "/",
		component: require("../layouts/public-layout.vue").default,
		meta: {requiresGuest: true},
		name: "publicLayout",
		children: [
			{
				path: "/explore",
				component: require("../pages/public/explore.vue").default,
				name: "explore"
			},
		],
	},
	// guest routes
	{
		path: "/",
		component: require("../layouts/guest-page.vue").default,
		meta: {requiresGuest: true},
		name: "guestPage",
		children: [
			// login
			{
				path: "/login",
				component: require("../pages/auth/login.vue").default,
				name: "login"
			},
			// login student
			{
				path: "/login/student",
				component: require("../pages/auth/login-student.vue").default,
				name: "student-login"
			},
			// forgot password
			{
				path: "/password",
				component: require("../pages/auth/password.vue").default,
				name: "forgotPassword"
			},
			// register
			{
				path: "/register",
				alias: "/sign-up",
				component: require("../pages/auth/register.vue").default,
				props: true,
				name: "register"
			},
			// confirm-register
			{
				path: "/confirm-register",
				component: require("../pages/auth/confirm-register.vue").default,
				name: "confirm-register"
			},
			// account activation
			{
				path: "/auth/:token/activate",
				component: require("../pages/auth/activate.vue").default,
				name: "accountActivation"
			},
			// reset password
			{
				path: "/password/reset/:token",
				component: require("../pages/auth/reset.vue").default,
				name: "passwordReset"
			},
			// social login
			{
				path: "/auth/social",
				component: require("../pages/auth/social-auth.vue").default,
				name: "socialLogin"
			},
			// select plan
			{
				path: "/select-plan",
				component: require("../pages/auth/select-plan.vue").default,
				name: "selectPlan"
			},
			// join class
			{
				path: "/join-class/:code",
				component: require("../pages/student/join-class.vue").default,
				name: "joinClass"
			},
			// join group
			{
				path: "/join-group/:code",
				component: require("../pages/groups/join-group.vue").default,
				name: "joinGroup"
			}
		]
	},
	// presenter
	{
		path: "/presenter/:lesson_id",
		component: require("../pages/lessons/presenter.vue").default,
		name: "showLessonPresenter",
		meta: {requiresAuth: true}
	},
	// error layout
	{
		path: "/500",
		component: require("../layouts/error-page.vue").default,
		children: [
			{
				path: "/",
				component: require("../pages/errors/500.vue").default,
				name: "500"
			}
		]
	},
	// error page - 403
	{
		path: "/403",
		component: require("../layouts/error-page.vue").default,
		children: [
			{
				path: "/",
				component: require("../pages/errors/403.vue").default,
				name: "403"
			}
		]
	},
	// falback route - 404
	{
		path: "*",
		component: require("../layouts/error-page.vue").default,
		children: [
			{
				path: "/",
				component: require("../pages/errors/404.vue").default,
				name: "404"
			}
		]
	}
];

const router = new VueRouter({
	routes,
	linkActiveClass: "active",
	mode: "history",
	scrollBehavior() {
		return {x: 0, y: 0};
	}
});

router.beforeEach(async (to, from, next) => {
	// For Protected Routes
	if (to.matched.some(m => m.meta.requiresAuth)) {
		try {
			const token = readExpiry("auth_token");

			// if token is available and not expired
			if (token.response != null && token.expired === false) {
				return next();
			}

			// if token is available and expired
			if (token.response != null && token.expired === true) {
				// attempt to refresh token
				const response = await axios.put("/api/auth/refresh-token");
				storeExpiry("auth_token", response.data.token, true);
				axios.defaults.headers.common[
					"Authorization"
					] = `Bearer ${response.data.token}`;
				return next();
			}
			// if token is unavailable
			if (token.response == null) {
				// resort to defacto auth checker
				return helper.check().then(response => {
					if (!response) {
						return next({path: "/login"});
					}
					return next();
				});
			}
		} catch (e) {
			return next({path: "/login"});
		}
	}

	// Unprotected Routes
	if (to.matched.some(m => m.meta.requiresGuest)) {
		return next();
	}
	// Default Route Action
	return next();
});

export default router;
