<template>
	<div>
		<h2>Create Content</h2>

		<div class="page-section">
			<div class="row">
				<div class="col-md-12 lesson-text">
					<form enctype="multipart/form-data"
						  @submit.prevent="saveContent">
						<b-modal
							v-if="mediaManager"
							id="modal-center"
							v-model="mediaManager"
							size="xl"
							hide-footer
							lazy
							centered
							title="Media Manager">
							<div class="row">
								<div class="col-md-12">
									<media-manager :modal-mode="true"/>
								</div>
							</div>
						</b-modal>
						<div class="row col-md-12"
							 style="padding-right: 0;">
							<div class="form-group col-md-6">
								<label for="order"
									   class="control-label">Position</label>
								<input
									id="order"
									v-model="contentForm.order"
									type="number"
									class="form-control"
									placeholder="Content order in lesson">
							</div>

							<div class="form-group col-md-6"
								 style="padding-right: 0;">
								<label for="title"
									   class="control-label">Content Title</label>
								<input
									id="title"
									v-model="contentForm.title"
									type="text"
									class="form-control"
									placeholder="Enter content title">
							</div>
						</div>

						<div class="col-md-12 form-group">
							<label for="type"
								   class="control-label">Type</label>
							<multiselect
								id="type"
								:allow-empty="false"
								v-model="contentForm.type"
								:options="content_types"
								:searchable="false"
								:show-labels="false"
								name="type"
								placeholder="Select Content Type"/>
						</div>

						<div class="col-md-12 form-group"
							 hidden>
							<input id="author_id"
								   v-model="contentForm.author_id"
								   type="text">
						</div>

						<div
							v-if="contentForm.type === ContentTypeEnum.COMIC ||
								contentForm.type === ContentTypeEnum.IMAGE"
							class="col-md-12 form-group"
							height="600px"
							width="100%">
							<img
								v-if="contentForm.link"
								:src="contentForm.link"
								class="img-responsive"
								width="100%"
								height="100%">
						</div>

						<div v-if="contentForm.type === ContentTypeEnum.AUDIO"
							 class="col-md-12 form-group">
							<audio v-if="contentForm.link"
								   id="content_audio"
								   controls>
								<source :src="contentForm.link">
							</audio>
						</div>

						<!-- Video preview -->
						<div
							v-if="contentForm.type === ContentTypeEnum.VIDEO && contentForm.link"
							class="col-md-12 text-center form-group">
							<div class="embed-responsive embed-responsive-21by9">
								<iframe :src="contentForm.link"/>
							</div>
						</div>

						<!-- Upload button -->

						<div
							v-if="contentForm.type === ContentTypeEnum.COMIC || contentForm.type ===
								ContentTypeEnum.IMAGE || contentForm.type === ContentTypeEnum.AUDIO"
							class="col-md-12 form-group">
							<b-button
								class="m-1"
								variant="primary"
								@click="DispatchMediaEvent(`CONTENT_${contentForm.type.toUpperCase()}`)">
								Upload {{ contentForm.type || "" }}
							</b-button>
						</div>

						<!-- Video Link -->
						<div v-if="contentForm.type === ContentTypeEnum.VIDEO"
							 class="col-md-12 form-group">
							<label class="control-label">Video Embed Link</label>
							<input
								v-validate="{ url: {require_protocol: true }}"
								id="video"
								v-model="contentForm.link"
								name="video"
								type="text"
								class="form-control">

							<span class="error">{{ errors.first("video") }}</span>
						</div>

						<!-- Content Text Textarea -->
						<div v-if="contentForm.type === ContentTypeEnum.TEXT"
							 class="col-md-12 form-group">
							<label class="control-label">Text</label>
							<textarea
								v-validate
								id="text"
								v-model="contentForm.text"
								class="form-control"
								name="text"
								rows="5"
								placeholder="Add the text content"/>
						</div>

						<!-- Content Notes Textarea -->
						<div class="col-md-12 form-group">
							<label class="control-label">Content Notes</label>

							<wysiwyg
								v-validate
								id="notes"
								v-model="contentForm.notes"
								name="notes"
								placeholder="Add the text content"/>
						</div>
						<br>

						<div class="col-md-12 form-group">
							<button id="saveContent"
									type="submit"
									class="btn btn-success">
								<i v-if="isSaving"
								   class="fas fa-circle-notch fa-spin"/>
								<span v-else>Create Content</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {ability} from "../../config/ability";
import {ContentTypeEnum} from "../../config/enums";
import eventbus from "../../utility/eventbus";
import mediaManager from "../../components/utility/media-manager.vue";
import {mapState} from "vuex";

export default {
	name: "CreateLessonContent",

	components: {
		Multiselect,
		mediaManager
	},

	beforeRouteEnter(to, from, next) {
		if (ability.can("create", "contents")) {
			return next();
		}
		return next({path: "/401"});
	},

	data() {
		return {
			isSaving: false,
			contentForm: {
				lesson: "",
				title: "",
				type: "",
				order: "",
				author_id: this.author_id,
				text: "",
				notes: "",
				link: ""
			},
			ContentTypeEnum: ContentTypeEnum,
			content_types: [
				ContentTypeEnum.VIDEO,
				ContentTypeEnum.IMAGE,
				ContentTypeEnum.COMIC,
				ContentTypeEnum.AUDIO,
				ContentTypeEnum.TEXT
			],
			mediaManager: false,
			request_id: null
		};
	},

	computed: {
		...mapState("auth", {
			author_id: "id",
		}),

		upload_accept() {
			switch (this.contentForm.type) {
				case this.ContentTypeEnum.AUDIO:
					return "audio/*";
				case this.ContentTypeEnum.COMIC:
					return "image/*";
				case this.ContentTypeEnum.IMAGE:
					return "image/*";
				default:
					return "";
			}
		}
	},

	mounted() {
		eventbus.$on("media-manager-complete", this.MediaEventHandler);
	},

	destroyed() {
		eventbus.$off("media-manager-complete");
	},

	methods: {
		MediaEventHandler(data) {
			switch (data.response_id) {
				case "CONTENT_IMAGE":
					if ((data.response_id = this.request_id)) {
						this.contentForm.link = data.url;
						this.mediaManager = null;
					}
					break;
				case "CONTENT_COMIC":
					if ((data.response_id = this.request_id)) {
						this.contentForm.link = data.url;
						this.mediaManager = null;
					}
					break;
				default:
					break;
			}
		},

		DispatchMediaEvent(type) {
			this.mediaManager = !this.mediaManager;
			this.request_id = type;
			setTimeout(() => {
				eventbus.$emit("media-manager", type);
			}, 1000);
		},

		onContentImageChange: function (e) {
			if (this.contentForm.type !== "Video") {
				let files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;
				this.createImage(files[0]);
			}
		},

		createImage(file) {
			let reader = new FileReader();
			let vm = this;
			reader.onload = e => {
				vm.contentForm.link = e.target.result;
			};
			reader.readAsDataURL(file);
		},

		setAuthor: function () {
			this.contentForm.author_id = this.author_id;
		},

		saveContent: function () {
			this.isSaving = true;
			this.setAuthor();
			axios
				.post("/api/content", this.contentForm)
				.then(response => {
					toastr["success"](response.message);
					this.isSaving = false;
					this.$emit("content-added", response.data);
				})
				.catch(response => {
					toastr["error"](response.message);
					this.isSaving = false;
				});
		}
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
#saveContent {
	min-width: 200px;
	text-align: center;

	i.fas.fa-circle-notch {
		margin-right: 10px;
	}
}
</style>
