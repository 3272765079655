<template>
	<div
		v-if="user"
		class="profile">
		<div class="profile-section page-section position-relative">
			<div class="avatar">
				<profileImage :is-large="true" />
			</div>

			<div class="user-details">
				<div v-if="user.user_type !== 'student'">
					<span class="name">{{ user.first_name }}</span> <span class="name font-weight-bold">{{ user.last_name }}</span>
				</div>
				<div v-else>
					<span class="name font-weight-bold">{{ user.username }}</span>
				</div>
				<div v-if="user.user_type !== 'student'">
					{{ user.email }}
				</div>

				<div v-if="user.badges">
					<span
						v-for="(badge, index) in user.badges"
						:key="index"
						class="badges">{{ badge.name }}</span>
				</div>

				<router-link to="/profile/settings">
					<div class="mt-3">
						<a
							href=""
							class="underline">Account Settings</a>
					</div>
				</router-link>
			</div>
		</div>

		<div
			class="profile-section page-section">
			<h4 class="section-heading">
				Favorites:
			</h4>
			<div v-if="user.favorites[0]">
				<lessonIndexScroll
					:lessons="user.favorites"
					class="lesson-index"
					view-all-url="/profile/favorites" />
			</div>
			<div v-else>
				<p>Favorite some lessons to see them appear here</p>
			</div>
		</div>
	</div>
</template>

<script>
import lessonIndexScroll from "../../components/lesson-playlist/thumbnailIndex.vue";
import profileImage from "../../components/profile/profile-image";
import {mapState} from "vuex";

export default {
	name: "ProfileOverview",

	components: {
		lessonIndexScroll,
		profileImage
	},

	computed: {
		...mapState({
			user: "auth",
		})
	},
};
</script>

<style lang="scss" scoped>

.profile {
	& > div {
		margin-bottom: 35px;
	}

	.avatar {
		position: relative;
		display: inline-flex;
		margin: 20px;

		.image {
			width: 150px;
			height: 150px;
			border-radius: 50%;
		}

		.upload-stack {
			position: absolute;
			right: -10px;
			bottom: 0;
			cursor: pointer;

			.upload-background {
				top: 7px;
				color: $theme;
			}

			.upload-icon {
				color: white;
			}
		}
	}

	.user-details {
		position: absolute;
		top: 50%;
		display: inline;
		margin-left: 20px;
		transform: translateY(-50%);

		.name {
			font-size: 200%;
		}

		.badges {
			&::after {
				content: ', ';
			}

			&:last-of-type {
				&::after {
					content: '';
				}
			}
		}
	}

	.lesson-index {
		width: 104%;
		margin-right: -$padding;
		margin-left: -$padding;
	}
}

</style>
