import {decrypt, encrypt} from "../../utility/encrypt";
import {EventCategoryEnum} from "../../config/enums";

const state = {
	authenticated: false,
	auth_token: localStorage.getItem("auth_token") || "",
	status: "",
	first_name: "",
	last_name: "",
	username: "",
	email: "",
	avatar: "",
	avatar_url: "",
	favorites: "",
	id: "",
	favorite_playlists: "",
	roles: "",
	groups: "",
	classrooms: "",
	permissions: "",
	subscription: "",
	user_options: "",
	subscriptionActive: false,
	subscriptionChecked: false,
	user_type: "",
	unread_count: 0,
};

const mutations = {
	setNotificationsCount: (state, count) => {
		state.unread_count = count;
	},
	setAuthenticated: (state, authenticated) => {
		state.authenticated = authenticated;
	},

	setAuthSuccess: (state, auth_token) => {
		state.auth_token = auth_token;
	},

	setAuthError: (state) => {
		state.authenticated = false;
		state.auth_token = "";
	},

	setSubscriptionActive: (state, subscriptionActive) => {
		state.subscriptionActive = subscriptionActive;
	},

	setSubscriptionChecked: (state, subscriptionChecked) => {
		state.subscriptionChecked = subscriptionChecked;
	},

	setAuthUserDetail(state, auth) {
		for (let key of Object.keys(auth)) {
			state[key] = auth[key];
		}
		if ("avatar" in auth)
			state.avatar =
				state.avatar !== null
					? state.avatar
					: "/images/default-profile.png";
		if ("avatar_url" in auth)
			state.avatar =
				state.avatar_url !== null
					? state.avatar_url
					: "/images/default-profile.png";
	},

	setUserType: (state, type) => {
		state.user_type = type;
	},

	setUserOptions(state, {option, value}) {
		if (state.user_options !== "" && state.user_options != null) {
			// noinspection JSValidateTypes
			state.user_options[option] = {option, value};
		}
	},

	resetAuthUserDetail(state) {
		for (let key of Object.keys(state)) {
			state[key] = "";
		}
		state.authenticated = false;
		state.subscriptionActive = false;
		state.subscriptionChecked = false;
	}
};

const actions = {
	deleteFavoritePlaylists: ({commit}, playlistId) => {
		commit("deleteFavoritePlaylists", playlistId);
	},

	setUserOptions: async ({commit, state}, {option, value}) => {
		commit("setUserOptions", {option, value});
		return await axios.put(`/api/user/${state.id}/options`, {
			option,
			value
		});
	},

	setSubscriptionChecked: ({commit}, data) => {
		commit("setSubscriptionChecked", data);
	},

	checkAuth: async ({commit, state}) => {
		if (state.authenticated) {
			return true;
		} else {
			try {
				const response = await axios.get("/api/auth/user");
				const {
					id,
					first_name,
					last_name,
					email,
					avatar,
					plan_id,
					roles,
					permissions,
					badges,
					favorites,
					favorite_playlists,
					subscription,
					groups,
					classrooms,
					activity,
					user_options
				} = response.data;
				commit("setAuthUserDetail", {
					id,
					first_name,
					last_name,
					email,
					avatar,
					plan_id,
					roles: encrypt(roles),
					permissions: encrypt(permissions),
					badges,
					favorites,
					favorite_playlists,
					subscription,
					groups,
					classrooms,
					activity,
					user_options
				});

				commit("setAuthenticated", true);
				commit("setSubscriptionActive", true);
				commit("setSubscriptionChecked", true);
			} catch (e) {
				console.log("check auth failed");
				commit("setAuthenticated", false);
			}

		}
	},
	refreshAuth: async ({commit, state}) => {
		try {
			const response = await axios.get("/api/auth/user");
			const {
				id,
				first_name,
				last_name,
				email,
				avatar,
				plan_id,
				roles,
				permissions,
				badges,
				favorites,
				favorite_playlists,
				subscription,
				groups,
				classrooms,
				activity,
				user_options
			} = response.data;
			commit("setAuthUserDetail", {
				id,
				first_name,
				last_name,
				email,
				avatar,
				plan_id,
				roles: encrypt(roles),
				permissions: encrypt(permissions),
				badges,
				favorites,
				favorite_playlists,
				subscription,
				groups,
				classrooms,
				activity,
				user_options
			});

			commit("setAuthenticated", true);
			commit("setSubscriptionActive", true);
			commit("setSubscriptionChecked", true);
		} catch (e) {
			console.log("refresh token failed");
			commit("setAuthenticated", false);
		}
	}
};

const getters = {
	unreadNotificationsCount: state => {
		return state.unread_count;
	},
	compareUserTypeWithAuthUser: state => userType => {
		if (state.roles !== "") {
			let roles = decrypt(state.roles);
			if (Array.isArray(roles)) {
				roles = roles.map(role => role.name);
				return roles.includes(userType);
			}
			return roles.name === userType;
		}
		return false;
	},
	getUserType: state => {
		return state.user_type;
	},
	getEventCategoryForUserType: state => {
		switch (state.user_type) {
			case "admin" :
				return EventCategoryEnum.Admin;
			case "group-admin" :
				return EventCategoryEnum.GroupAdmin;
			case "super-admin":
				return EventCategoryEnum.SuperAdmin;
			case "teacher":
				return EventCategoryEnum.Teacher;
			default:
				return EventCategoryEnum.Guest;
		}
	},
	getAuthUser: state => {
		return state;
	},

	getAuthenticated: state => {
		return state.authenticated;
	},

	getAuthUserDetail: state => name => {
		return state[name];
	},

	getAuthUserFullName: state => {
		if (state["first_name"] != null && state["last_name"] != null) {
			return state["first_name"] + " " + state["last_name"];
		}
		return state.username;
	},

	getAuthUserAvatar: state => {
		return state["avatar"];
	},

	getFavorites: state => {
		return state.favorites;
	},

	getFavoritePlaylists: state => {
		return state.favorite_playlists;
	},

	getUserOptions: state => {
		return state.user_options;
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
