<template>
	<div class="page">
		<div class="row">
			<div class="col-md-12">
				<div class="clearfix">
					<h4>
						<span class="float-left">
							Your Students
						</span>
					</h4>
					<span class="float-right"/>
				</div>
			</div>
		</div>
		<br>
		<div v-if="studentData.data != null" class="card">
			<div class="card-body">
				<div v-if="studentData.data.length > 0" class="table-responsive">
					<table class="table table-hover">
						<thead class="thead-light">
						<tr>
							<th>Avatar</th>
							<th>Username</th>
							<th>Date Registered</th>
							<th>Action</th>
						</tr>
						</thead>

						<tbody>
						<tr v-for="(u, index) in studentData.data" :key="index">
							<td>
								<img
									:src="u.avatar != null ? u.avatar : '/images/default-profile.png'"
									alt="avatar"
									class="img-thumbnail"
									height="75px"
									width="75px">
							</td>
							<td>{{ u.username || "n/a" }}</td>
							<td>{{ u.created_at | date }}</td>
							<td>
								<button
									class="btn btn-warning btn-md"
									type="button"
									@click="resetPassword(u.user.id)">
									<span><i class="fas fa-sync"/></span> Reset Password
								</button>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
				<empty v-else/>
			</div>
		</div>
		<div class="clearfix m-1">
			<span class="float-left"/>
			<span class="float-right">
				<pagination v-if="studentData"
							:links="studentData.links"
							:meta="studentData.meta"
							@first="(url) => updateStudentTable(url)"
							@last="(url) => updateStudentTable(url)"
							@next="(url) => updateStudentTable(url)"
							@prev="(url) => updateStudentTable(url)"/>
			</span>
		</div>
	</div>
</template>

<script>
import empty from "../../components/utility/empty.vue";
import pagination from "../../components/utility/pagination.vue";
import {EventActionEnum, EventLabelEnum} from "../../config/enums";

export default {
	components: {
		empty,
		pagination
	},
	data() {
		return {
			studentData: {
				data: [],
			},
			loading: true,
		};
	},
	mounted() {
		this.fetchStudents();
	},
	computed: {
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},
	methods: {
		async fetchStudents() {
			try {
				this.loading = true;
				const response = await axios.get("/api/teacher/students");
				this.loading = false;
				this.studentData = response;
				// log with GA
				this.$gtag != null ? this.$gtag.event(EventActionEnum.View, {
					"event_category": this.userEventCategory,
					"event_label": EventLabelEnum.Students,
					"value": "All Students"
				}) : null;
			} catch (e) {
				this.loading = false;
				return toastr["error"]("Unable to fetch students, please try again later.");
			}
		},
		async updateStudentTable(url) {
			try {
				this.loading = true;
				const response = await axios.get(url);
				this.loading = false;
				this.studentData = response;
			} catch (e) {
				this.loading = false;
				return toastr["error"]("Unable to fetch students, please try again later.");
			}
		},
		async resetPassword(id) {
			try {
				if (confirm("Are you sure you want to reset this student's password?")) {
					this.loading = true;
					await axios.get(`/api/teacher/students/${id}/reset-password`);
					this.loading = false;
					return toastr["success"]("Password reset successful");
				}
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to reset student password");
			}
		},
	}
};
</script>

<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}
</style>
