<template>
	<div class="page-section">
		<div class="row">
			<div class="col-12">
				<h4>Showing results for "{{ $route.query.q }}"</h4>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-12">
				<div class="grid">
					<div v-if="isLoading">
						<h5>{{ status }}...</h5>
					</div>
					<div v-else>
						<thumbnailIndex v-if="results[0] != null" :lessons="results"
										grid/>
						<p v-else>No results for this term.</p>
					</div>
				</div>
			</div>
		</div>
		<br>
		<div v-if="links && links !== ''" class="row">
			<div class="col-md-12">
				<ul class="pagination float-right">
					<li v-if="links.first"
						class="page_item"
						@click="updateResults(links.first)">
						<a class="page-link"
						   href="#">First</a>
					</li>
					<li v-if="links.prev"
						class="page-item"
						@click="updateResults(links.prev)">
						<a class="page-link"
						   href="#">Previous</a>
					</li>
					<li class="page-item disabled">
						<a class="page-link"
						   href="#">{{ `Page ${meta.current_page} of ${meta.last_page}` }}</a>
					</li>
					<li v-if="links.next"
						class="page-item"
						@click="updateResults(links.next)">
						<a class="page-link"
						   href="#">Next</a>
					</li>
					<li v-if="links.last"
						class="page_item"
						@click="updateResults(links.last)">
						<a class="page-link"
						   href="#">Last</a>
					</li>
				</ul>
			</div>
		</div>
		<br>
	</div>
</template>

<script>
import axios from "axios";
import thumbnailIndex from "../lesson-playlist/thumbnailIndex.vue";

export default {
	name: "SearchResults",

	components: {
		thumbnailIndex
	},

	props: {
		query: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			results: "",
			links: "",
			meta: "",
			isLoading: true,
			status: "Loading",
		};
	},

	watch: {
		query: {
			handler(val) {
				this.fetchResults(val);
			},
			deep: true
		},
	},

	mounted() {
		this.fetchResults(this.$route.query.q);
	},

	methods: {
		fetchResults(query) {
			this.isLoading = true;
			this.status = "Loading..";
			return axios
				.get(`/api/search/lesson/${query}`)
				.then(({data, links, meta}) => {
					this.results = data;
					this.links = links;
					this.meta = meta;
					if (data.length < 1) {
						this.isLoading = true;
						this.status = "Nothing found";
					}
				})
				.catch(error => {
					this.results = null;
				}).finally(() => {
					this.isLoading = false;
				});
		},
		updateResults(url) {
			this.isLoading = true;
			this.status = "Loading..";
			return axios
				.get(url)
				.then(({data, links, meta}) => {
					this.results = data;
					this.links = links;
					this.meta = meta;
					this.isLoading = false;
					if (data.length < 1) {
						this.isLoading = true;
						this.status = "Nothing found";
					}
				})
				.catch(error => {
					this.results = null;
					this.isLoading = true;
					this.status = "Nothing found";
				});
		}
	}
};
</script>
