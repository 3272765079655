<template>
	<div>
		<div class="page">
			<!-- page title -->
			<div class="row">
				<div class="col-md-12">
					<div class="clear-fix">
						<span class="float-left">
							<h3>Bulk Operations</h3>
						</span>
					</div>
					<br>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<b-card no-body>
						<b-tabs card>
							<!-- Upload tab -->
							<b-tab title="Upload">
								<b-card-text>
									<p>
										Upload collections in bulk to the server using a Google drive spreadsheet or
										local file.
									</p>
									<div class="row">
										<!-- Google Sheet Upload -->
										<div class="col-sm-12">
											<b-form>
												<b-form-group>
													<label class="mr-2">Google Sheet URL: </label>
													<b-form-input id="googleSheetURL"
														v-model="uploadData.googleSheetUrl"
														:disabled="uploading === true || (fileList.length > 0)"
														class="mr-3"
														placeholder="https://docs.google.com/spreadsheets/d/XXXXXXXXXXXX/view?usp=sharing" />
												</b-form-group>
												<b-form-group>
													<label class="mr-2">Sheet No:</label>
													<b-form-input id="sheetNumber"
														v-model="uploadData.sheetNumber"
														class="mr-3"
														placeholder="1" />
												</b-form-group>
												<b-form-group>
													<label class="mr-2">Type:</label>
													<b-form-select id="type"
														v-model="uploadData.file_type"
														class="mr-3">
														<b-form-select-option value="xlsx">
															xlsx / xls
														</b-form-select-option>
														<b-form-select-option value="csv">
															csv
														</b-form-select-option>
													</b-form-select>
												</b-form-group>
												<b-form-group>
													<label class="mr-2">Collection:</label>
													<b-form-select id="type"
														v-model="uploadData.type"
														class="mr-3">
														<b-form-select-option value="lesson">
															Lessons
														</b-form-select-option>
														<b-form-select-option value="content">
															Contents
														</b-form-select-option>
														<b-form-select-option value="playlist">
															Playlists
														</b-form-select-option>
														<b-form-select-option value="playlist-lesson">
															Playlist Lessons
														</b-form-select-option>
														<b-form-select-option value="district">
															Districts
														</b-form-select-option>
														<b-form-select-option value="school">
															Schools
														</b-form-select-option>
														<b-form-select-option value="teacher">
															Teachers
														</b-form-select-option>
														<b-form-select-option value="admins">
															Principals / District Admins
														</b-form-select-option>
													</b-form-select>
												</b-form-group>
											</b-form>
											<br>
										</div>

										<!-- File Upload -->
										<div :class="{ 'col-md-6 col-sm-12': fileList.length > 0 && !uploading && !gsheetsUpload,
											'hidden': fileList.length > 0 && uploading && !gsheetsUpload,
											'col-md-12 col-sm-12': (fileList.length <= 0 && !uploading) || gsheetsUpload && uploading
										}">
											<div id="fileform"
												ref="fileform"
												:class="{'dragged': dragEnter, 'notdragged': !dragEnter,
													'd-flex flex-column justify-content-center text-center' : true,
													'disabled' : gsheetsUpload}">
												<div id="form"
													class>
													<div class="align-self-center">
														<p>
															Drop the file here or click to upload. Compatible Formats
															are: .xlsx, .xls and .csv
														</p>
													</div>
												</div>
											</div>
										</div>

										<!-- File Preview -->
										<div v-if="!gsheetsUpload"
											:class="{ 'col-md-6 col-sm-12': fileList.length > 0 && !uploading,
												'hidden-md hidden-sm hidden-lg': fileList.length <= 0 && !uploading,
												'col-md-12 col-sm-12 col-lg-12' : fileList.length > 0 && uploading}">
											<div v-if="uploading === false">
												<div v-if="previewFileList[0]">
													<div v-for="(file,index) in previewFileList"
														:key="index">
														<div class="display-content"
															style="height: 100%;">
															<div>
																<b-card class="overflow-hidden"
																	no-body>
																	<div class="row">
																		<div class="col-md-12 col-sm-12 col-lg-12">
																			<b-card-body title="File Details">
																				<b-card-text>
																					<p>Name: {{ file.name }}</p>
																					<p>Size: {{ file.size }}</p>
																					<button
																						:class="{'disabled': uploading}"
																						:disabled="uploading === true"
																						class="btn btn-sm btn-danger form-control"
																						@click="deleteFile(index)">
																						<i class="fas fa-trash" /> Delete
																					</button>
																				</b-card-text>
																			</b-card-body>
																		</div>
																	</div>
																</b-card>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div v-else>
												<b-card class="overflow-hidden"
													no-body>
													<b-row no-gutters>
														<b-col md="12">
															<b-card-body title="File Upload">
																<b-card-text>
																	<ul>
																		<li v-for="(resumable,index) in fileList"
																			:key="index"
																			style="margin-top: 5px; list-style: none;">
																			<div>
																				<b>{{ resumable.file.name }}</b>
																				<div class="progress mt-2">
																					<div
																						:style="{width:`${Math.round(resumable.progress()) * 100}%`}"
																						class="progress-bar progress-bar-striped progress-bar-animated">
																						{{
																							Math.round(resumable.progress()) * 100
																						}}%
																					</div>
																				</div>
																				<span
																					v-if="resumable.status === 'success' "
																					class="badge badge-success">successful</span>
																				<span
																					v-else-if="resumable.status === 'error'"
																					class="badge badge-danger">failed</span>
																				<button
																					v-if="resumable.status !== 'success' && resumable.status !== 'error'"
																					class="btn btn-sm btn-danger mt-1"
																					@click="cancelUpload(resumable)">
																					<i class="fas fa-times" /> Cancel
																				</button>
																			</div>
																		</li>
																	</ul>
																	<button v-if="done"
																		class="btn btn-sm btn-primary"
																		@click="reset">
																		<i class="fas fa-check" /> Done
																	</button>
																</b-card-text>
															</b-card-body>
														</b-col>
													</b-row>
												</b-card>
											</div>
										</div>

										<!-- Upload Button-->
										<b-button
											:disabled="uploading === true || (!gsheetsUpload && fileList.length <= 0)"
											class="btn btn-primary btn-sm form-control mt-4"
											variant="primary"
											@click="upload">
											<div v-if="uploading">
												<span class="spinner-border spinner-border-sm" />
												Loading..
											</div>
											<div v-else>
												<i class="fas fa-file-upload" />
												Upload File
											</div>
										</b-button>
									</div>
								</b-card-text>
							</b-tab>

							<!-- Reports tab-->
							<b-tab title="Reports">
								<b-card-text>
									<p>Download reports from collections</p>
									<div class="row">
										<div class="col-sm-12">
											<b-form>
												<b-form-group id="input-group-2"
													description="Report file type to download"
													label="Type:"
													label-for="type-2">
													<b-form-select id="type-2"
														v-model="reportData.file_type"
														class="mr-3">
														<b-form-select-option value="xlsx">
															xlsx / xls
														</b-form-select-option>
														<b-form-select-option value="csv">
															csv
														</b-form-select-option>
													</b-form-select>
												</b-form-group>

												<b-form-group id="input-group-2"
													description="Collection for the report"
													label="Collection:"
													label-for="collection-2">
													<b-form-select id="collection-2"
														v-model="reportData.collection"
														class="mr-3">
														<b-form-select-option value="lesson">
															Lessons
														</b-form-select-option>
														<b-form-select-option value="content">
															Contents
														</b-form-select-option>
														<b-form-select-option value="playlist">
															Playlists
														</b-form-select-option>
														<b-form-select-option value="playlist-lesson">
															Playlist Lessons
														</b-form-select-option>
														<b-form-select-option value="district">
															Districts
														</b-form-select-option>
														<b-form-select-option value="school">
															Schools
														</b-form-select-option>
														<b-form-select-option value="teacher">
															Teachers
														</b-form-select-option>
														<b-form-select-option value="admins">
															Principals / District Admins
														</b-form-select-option>
													</b-form-select>
												</b-form-group>

												<b-form-group id="input-group-2"
													description="Date time of the first report entry"
													label="From:"
													label-for="input-2">
													<b-form-datepicker
														id="datepicker-full-width"
														v-model="reportData.from"
														calendar-width="100%"
														class="mb-2"
														menu-class="w-100" />
												</b-form-group>

												<b-form-group
													id="input-group-1"
													description="Date time of the last report entry"
													label="To:"
													label-for="input-1">
													<b-form-datepicker
														id="datepicker-full-width"
														v-model="reportData.to"
														calendar-width="100%"
														class="mb-2"
														menu-class="w-100" />
												</b-form-group>
											</b-form>
										</div>
										<br>
										<!-- Report Button-->
										<b-button :disabled="downloading"
											class="btn btn-primary btn-sm form-control mt-4"
											variant="primary"
											@click="download">
											<div v-if="downloading">
												<span class="spinner-border spinner-border-sm" />
												Loading..
											</div>
											<div v-else>
												<i class="fas fa-file-upload" />
												Download Report
											</div>
										</b-button>
									</div>
								</b-card-text>
							</b-tab>
						</b-tabs>
					</b-card>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			dragAndDropCapable: false,
			fileList: [],
			previewFileList: [],
			uploading: false,
			downloading: false,
			reportData: {
				from: null,
				to: null,
				collection: "lesson",
				file_type: "xlsx"
			},
			uploadData: {
				googleSheetUrl: "",
				type: "lesson",
				file_type: "xlsx",
				sheetNumber: 1,
			},
			serverMessage: null,
			loading: false,
			response_id: null,
			dragEnter: false,
			done: false,
			lessonReport: null,
			contentReport: null,
			modalData: null,
			modalType: "lesson",
			modalFailedData: null,
			modalFailedType: "lesson"
		};
	},

	computed: {
		gsheetsUpload() {
			return this.googleSheetUrl !== "";
		},
		user() {
			return this.$store.getters["auth/getAuthUser"];
		}
	},

	async mounted() {
		if (!this.$resumable.support) {
			alert(
				"Your browser does not support our uploader, please upgrade your browser"
			);
		}
		this.setupResumables();
		this.setupDragandDrop();
		//await this.fetchData();
	},

	beforeDestroy() {
		this.$resumable.cancel();
		this.reset();
	},

	methods: {
		setModal(modalData, modalType) {
			this.modalData = JSON.parse(modalData);
			this.modalType = modalType;
			this.$bvModal.show("details-modal");
		},

		setFailedModal(modalData, modalType) {
			this.modalFailedData = JSON.parse(modalData);
			this.modalFailedType = modalType;
			this.$bvModal.show("failed-details-modal");
		},

		cancelUpload(resumable) {
			this.$resumable.removeFile(resumable);
			this.reset();
		},

		async upload() {
			try {
				this.uploading = true;
				this.totalBytes = 0;
				this.uploadedBytes = 0;
				let response = null;
				// an uploaded file
				if (this.fileList != null && this.fileList.length > 0 && !this.gsheetsUpload) {
					// upload files
					let formData = new FormData();
					formData.append("collection", this.uploadData.type);
					formData.append("file_type", this.uploadData.file_type);
					formData.append("author_id", this.user.id);
					this.fileList.forEach((resumable, index) => {
						formData.append(`file${index}`, resumable.file);
					});
					formData.append("sheet_no", this.uploadData.sheetNumber);

					response = await axios({
						url: `${process.env.MIX_HEAVYLIFTING_URL}/v1/tasks/bulk-upload/file`,
						method: "post",
						onUploadProgress: function (progressEvent) {
							this.uploadedBytes = progressEvent.loaded;
							this.totalBytes = progressEvent.total;
						}.bind(this),
						data: formData,
					});

				}
				// if google sheet
				if (this.gsheetsUpload) {
					// send upload data to server
					response = await axios({
						url: `${process.env.MIX_HEAVYLIFTING_URL}/v1/tasks/bulk-upload/link`,
						method: "post",
						data: {
							file_type: this.uploadData.file_type,
							collection: this.uploadData.type,
							author_id: this.user.id,
							file_url: this.uploadData.googleSheetUrl,
							sheet_no: this.uploadData.sheetNumber,
						}
					});
				}


				this.uploading = false;
				if (response == null) {
					return toastr.error("Select a file or Paste a file link for upload");
				} else {
					this.done = true;
					if (response.code === 200) {
						this.reset();
						return toastr.success(response.message);
					} else {
						return toastr.error(response.message);
					}
				}


			} catch (error) {
				this.done = true;
				return toastr.error("Unable to complete your upload, please try again later");
			}
		},

		async download() {
			try {
				this.downloading = true;

				// generate the report and get the url
				const response = await axios({
					url: `${process.env.MIX_HEAVYLIFTING_URL}/v1/reports`,
					method: "post",
					data: {
						from: this.reportData.from,
						to: this.reportData.to,
						collection: this.reportData.collection,
						fileType: this.reportData.file_type,
					},
				});

				if (response.data) {
					// download the report
					toastr.success("file downloading");
					return window.open(`${process.env.MIX_HEAVYLIFTING_URL}/v1/reports/download/${response.data}`, "_blank");
				}
				throw Error("unable to generate report");
			} catch (e) {
				return toastr.error("Unable to complete your upload, please try again later");
			} finally {
				this.downloading = false;
			}
		},

		attachResumableFile: async function (resumable) {
			const {name, size} = resumable.file;
			let filesizeMB = (size / (1024 * 1024)).toFixed(2);
			if (filesizeMB > 100) {
				resumable.cancel();
				return toastr.error(
					`File: ${resumable.file.name} is too large (Max 100Mb)`
				);
			}

			switch (name.split(".")[1]) {
			case "xlsx":
				this.previewFileList = [
					await this.prepareFileDetails(resumable.file)
				];
				break;
			case "csv":
				this.previewFileList = [
					await this.prepareFileDetails(resumable.file)
				];
				break;
			default:
				break;
			}
			this.fileList = [resumable];
		},

		// method to hook resumables.js and events to media manager
		setupResumables() {
			try {
				// assign node to browse event
				this.$resumable.assignBrowse(
					document.getElementById("fileform"),
					false
				);
				//assign node to drop event
				this.$resumable.assignDrop(document.getElementById("fileform"));

				// when file added
				this.$resumable.on(
					"fileAdded",
					function (file, event) {
						this.attachResumableFile({...file, status: null});
						this.dragEnter = false;
					}.bind(this)
				);
			} catch (error) {
				return null;
			}
		},

		setupDragandDrop() {
			//Determine if drag and drop functionality is capable in the browser
			this.dragAndDropCapable = this.determineDragAndDropCapable();
			// If drag and drop capable, then we continue to bind events to our elements.
			if (this.dragAndDropCapable) {
				if (this.$refs.fileform) {
					// add an event for drag enter to the form
					this.$refs.fileform.addEventListener(
						"dragenter",
						function (e) {
							this.dragEnter = true;
						}.bind(this)
					);

					// add an event for drag leave to the form
					this.$refs.fileform.addEventListener(
						"dragleave",
						function (e) {
							e.preventDefault();
							this.dragEnter = false;
							return null;
						}.bind(this)
					);

					// add an event for drag leave to the form
					this.$refs.fileform.addEventListener(
						"dragover",
						function (e) {
							this.dragEnter = true;
						}.bind(this)
					);
				}
			}
		},

		prepareFileDetails(file) {
			return new Promise(
				async function (resolve, reject) {
					const {name, size, type} = file;
					return resolve({
						name,
						size: `${(size / 1024 / 1024).toFixed(2)} MB`,
						extension: name.split(".")[1],
						data: await this.createFileAsDataUrl(file)
					});
				}.bind(this)
			);
		},

		determineDragAndDropCapable() {
			let div = document.createElement("div");
			return (
				("draggable" in div ||
					("ondragstart" in div && "ondrop" in div)) &&
				"FormData" in window &&
				"FileReader" in window
			);
		},

		createFileAsDataUrl(file) {
			return new Promise((resolve, reject) => {
				let reader = new FileReader();
				reader.onload = e => {
					return resolve(e.target.result);
					//vm.onChanged("file");
				};
				reader.readAsDataURL(file);
				reader.onerror = e => {
					return reject(() => {
						throw `${reader.error}`;
					});
				};
			});
		},

		deleteFile(num) {
			this.previewFileList = this.previewFileList.filter(
				(element, index) => index !== num
			);
			this.fileList = this.fileList.filter((element, index) => {
				if (index !== num) {
					return true;
				} else {
					this.$resumable.files = this.$resumable.files.filter(
						file =>
							element.uniqueIdentifier !== file.uniqueIdentifier
					);
					return false;
				}
			});
		},

		async fetchData() {
			try {
				this.loading = true;
				const responses = await Promise.all([
					axios.get("/api/bulk-upload/lesson"),
					axios.get("/api/bulk-upload/content")
				]);
				this.loading = false;
				this.lessonReport = responses[0];
				this.contentReport = responses[1];
			} catch (error) {
				toastr.error(
					"Could not fetch bulk upload reports, try again later"
				);
			}
		},

		async updateData(type, url) {
			try {
				this.loading = true;
				const response = await axios.get(url);
				this.loading = false;
				if (type === "lesson") {
					this.lessonReport = response;
				} else {
					this.contentReport = response;
				}
			} catch (error) {
				toastr.error(
					"Could not fetch bulk upload reports, try again later"
				);
			}
		},

		reset() {
			this.cleanup();
			// setTimeout(() => {
			// 	this.setupDragandDrop();
			// }, 0);
		},

		cleanup() {
			this.fileList = [];
			this.previewFileList = [];
			this.$resumable.files = [];
			this.uploading = false;
			this.done = false;
			this.totalBytes = 0;
			this.uploadedBytes = 0;
			this.uploadData = {
				googleSheetUrl: "",
				type: "lesson",
				file_type: "xlsx",
				sheetNumber: 1,
			};
			this.reportData = {
				from: null,
				to: null,
				collection: "lesson",
				file_type: "xlsx"
			};
		}
	}
};
</script>


<style lang='scss' scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}

.white-text {
	color: white;
}

strong {
	font-size: 2em;
}

progress {
	display: block;
	width: 100%;
	margin: auto;
	margin-top: 20px;
	margin-bottom: 20px;
}

.selected-card {
	border: 2px #084a99 solid;
}

/* Container needed to position the overlay. Adjust the width as needed */
.img-card {
	position: relative;
	width: 100%;
	max-width: 100px;
}

/* Make the image to responsive */
.image {
	width: 100px;
	height: 100px;
}

.overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	transition: 0.3s ease;
}

.img-card:hover .overlay {
	opacity: 1;
}

.icon {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 100px;
	color: white;
	text-align: center;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.tab-pane:focus {
	border: none !important;
	outline: none !important;
}

#googleSheetURL {
	width: 45%;
}

#sheetNumber {
	width: 40px;
}

#fileform {
	height: 150px;
	min-height: 100%;
	cursor: pointer;
	background: white;
	border-radius: 5px;

	&.disabled {
		background: #e9ecef;
	}
}

.notdragged {
	border: 2px dashed #0087f7;
}

.dragged {
	border: 2px dashed green;
}

#uploadFiles {
	margin: 10px;
	text-decoration: underline;
}

#uploadFiles:hover {
	color: #084a99;
	cursor: pointer;
}

.clickable {
	cursor: pointer;
}

.page-item {
	cursor: pointer;
}
</style>


