<template>
	<div>
		<input :id="`styled-radio-${id}`"
			:checked="value === label"
			:value="label"
			class="styled-checkbox"
			type="radio"
			@change="handleChange">
		<label :for="`styled-radio-${id}`">{{ label }}</label>
	</div>
</template>

<script>
export default {
	name: "CustomRadio",
	props: {
		value: {
			type: String,
			default: ""
		},
		label: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			id: 0
		};
	},
	beforeMount() {
		// give this checkbox a custom id to avoid css clashes
		this.id = Math.random() * 100;
	},
	methods: {
		handleChange(e) {
			this.$emit("input", e.target.value);
		}
	}
};
</script>

<style lang="scss" scoped>
.styled-checkbox {
	position: absolute; // take it out of document flow
	opacity: 0; // hide it

	& + label {
		position: relative;
		padding: 0;
		cursor: pointer;
	}

	// Box.
	& + label::before {
		display: inline-block;
		width: 20px;
		height: 20px;
		margin-right: 10px;
		vertical-align: text-top;
		content: "";
		background: white;
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
	}

	// Box hover
	&:hover + label::before {
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
	}

	// Box focus
	&:focus + label::before {
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
	}

	// Box checked
	&:checked + label::before {
		background: $green;
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
	}

	// Disabled state label.
	&:disabled + label {
		color: #b8b8b8;
		cursor: auto;
	}

	// Disabled box.
	&:disabled + label::before {
		background: #dddddd;
		box-shadow: none;
	}

	// Checkmark. Could be replaced with an image
	&:checked + label::after {
		position: absolute;
		top: 9px;
		left: 5px;
		width: 2px;
		height: 2px;
		content: "";
		background: white;
		/* stylelint-disable declaration-colon-newline-after, value-list-comma-newline-after */
		box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
			4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
		transform: rotate(45deg);
	}
}
</style>
