<template>
	<div>
		<div class="page">
			<div>
				<div class="row">
					<div class="col-md-6">
						<!-- page title -->
						<h3>{{ pageTitle }}</h3>
					</div>
				</div>
				<br>
				<div>
					<!-- breadcrumb links -->
					<b-breadcrumb :items="breadcrumbitems"/>
				</div>

				<div style="padding-top: 20px; background: white;">
					<div class="row">
						<div class="col-md-12 lesson-text">
							<div>
								<!-- Role Name-->
								<div class="col-md-12 form-group">
									<label for="title"
										   class="control-label">Role Title:</label>
									<input
										v-validate
										id="title"
										v-model="roleForm.name"
										name="title"
										type="text"
										class="form-control">
								</div>

								<!-- Role Description -->
								<div class="col-md-12 form-group">
									<label for="description"
										   class="control-label">Role Description:</label>
									<textarea
										v-validate
										id="description"
										v-model="roleForm.description"
										rows="5"
										class="form-control"
										name="description"/>
								</div>

								<!-- Role Users -->
								<div class="col-md-12 form-group">
									<label for="users"
										   class="control-label">Role Users:</label>
									<multiselect
										v-validate
										id="users"
										v-model="roleForm.users"
										:searchable="true"
										:options="usersList"
										:internal-search="false"
										:options-limit="15"
										:clear-on-select="false"
										:close-on-select="true"
										:loading="isLoadingUsers"
										:multiple="true"
										name="users"
										class="contents_multi"
										placeholder="Type to search for users"
										track-by="id"
										@input="onChanged('users')"
										@search-change="getUsersAsync">
										<template #tag="{ option }">
											<button type="button"
													class="btn btn-primary mb-2 mr-2">
												{{ option.first_name }} {{ option.last_name }}
												<span
													class="badge badge-light"
													@click="remove('user',option)">&times;</span>
											</button>
										</template>
										<template #option="{ option }">
											<div class="option__block row align-content-center">
												<div class="option__preview col-md-4">
													<img
														:src="option.avatar || '/images/default-profile.png'"
														width="100px"
														height="100px">
												</div>
												<div class="option__title col-md-3">
													{{ option.first_name }} {{ option.last_name }}
												</div>
												<div class="col-md-3">
													{{ option.email }}
												</div>
											</div>
										</template>
										<template #noResult>
											<span>No results found.</span>
										</template>
									</multiselect>
								</div>

								<!-- Role Permissions -->
								<div class="col-md-12 form-group">
									<label for="permissions"
										   class="control-label">Role Permissions:</label>
									<multiselect
										v-validate
										id="permissions"
										v-model="roleForm.permissions"
										:searchable="true"
										:options="permissionsList"
										:internal-search="false"
										:options-limit="15"
										:clear-on-select="false"
										:close-on-select="true"
										:loading="isLoadingPermissions"
										:multiple="true"
										name="permissions"
										placeholder="Type to search for permissions"
										track-by="id"
										@input="onChanged('permissions')"
										@search-change="getPermissionsAsync">
										<template #tag="{ option }">
											<button type="button"
													class="btn btn-success mb-2 mr-2">
												{{ option.name }}
												<span
													class="badge badge-light"
													@click="remove('permission',option)">&times;</span>
											</button>
										</template>
										<template #option="{ option }">
											<div class="option__block row align-content-center">
												<div class="col-md-12">
													{{ option.name }}
												</div>
											</div>
										</template>
										<template #noResult>
											<span>No results found.</span>
										</template>
									</multiselect>
								</div>
								<!-- Page action buttons -->
								<div class="col-md-12 form-group">
									<button
										v-if="changed || !role_id"
										:disabled="isSaving"
										type="button"
										class="btn btn-success"
										@click="saveRole">
										<i v-if="isSaving"
										   class="fas fa-circle-notch fa-spin"/>
										<span>Save</span>
									</button>
									<button v-if="changed"
											type="button"
											class="btn btn-warning"
											@click="discardChanges">
										<span>Discard</span>
									</button>
									<button v-if="role_id"
											type="button"
											class="btn btn-danger"
											@click="deleteRole">
										<i v-if="isDeleting"
										   class="fas fa-circle-notch fa-spin"/>
										<span>Delete</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axiosDebounce from "../../utility/debounce";
import helper from "../../utility";
import {clearTimeout, setTimeout} from "timers";

export default {
	components: {
		Multiselect
	},

	data: function () {
		return {
			breadcrumbitems: [
				{
					text: "Authorizations",
					href: "/admin/authorizations"
				},
				{
					text: "All",
					active: true
				}
			],
			pageTitle: "",
			role_id: null,
			roleForm: {
				name: "",
				description: "",
				users: [],
				permissions: []
			},
			role: {
				name: "",
				description: "",
				users: [],
				permissions: []
			},
			isSaving: false,
			isDeleting: false,
			isLoadingUsers: false,
			isLoadingPermissions: false,
			searchTimer: "",
			usersList: [],
			permissionsList: [],
			selectedUsers: [],
			selectedPermissions: [],
			changed: false
		};
	},
	watch: {
		roleForm: {
			handler: function (val) {
				let dirty = false;
				return Object.entries(val).forEach(([key, value]) => {
					this.changed =
						JSON.stringify(this.role[key]) ===
						JSON.stringify(value) && dirty === false
							? (dirty = false)
							: (dirty = true);
				});
			},
			deep: true
		}
	},

	async beforeMount() {
		if (this.$route.params.role_id === "new") {
			this.pageTitle = "Create new Role";
			this.breadcrumbitems[1].text = "New";
		} else {
			await this.fetchRole(this.$route.params.role_id);
			this.role_id = this.$route.params.role_id;
		}
	},
	methods: {
		fetchRole(id) {
			return axios
				.get(`/api/role/${id}`)
				.then(({data}) => {
					const {id, name, description, users, permissions} = data;
					this.pageTitle = `Role: ${name}`;
					this.breadcrumbitems[1].text = name;
					this.role = {...helper.deepClone(data)};
					this.roleForm = {...helper.deepClone(data)};
					this.selectedUsers = users;
					this.selectedPermissions = permissions;
				})
				.catch(error => {
					toastr["error"]("An error occurred, Try again");
				});
		},
		// Save or update role
		saveRole() {
			try {
				this.isSaving = true;
				if (this.$route.params.role_id === "new") {
					// save as new role
					delete this.roleForm.id;
					return axios
						.post("/api/role", this.roleForm)
						.then(({data}) => {
							toastr["success"]("Save successful");
							this.isSaving = false;
							this.$router.replace({
								path: `/admin/authorizations/${data.id}`
							});
						});
				} else {
					delete this.roleForm.id;
					// update existing role
					return axios
						.put(`/api/role/${this.role_id}`, this.roleForm)
						.then(response => {
							toastr["success"]("Save successful");
							this.isSaving = false;
							this.fetchRole(this.role_id);
						});
				}
			} catch (error) {
				this.isSaving = false;
				return toastr["error"]("An error occurred while saving role");
			}
		},

		// Reset input changes
		discardChanges() {
			this.roleForm = {...helper.deepClone(this.role)};
			return (this.changed = false);
		},

		// Delete lesson
		deleteRole(id) {
			this.isDeleting = true;
			try {
				return axios.delete(`/api/role/${this.role_id}`).then(data => {
					this.$router.replace({
						path: "/admin/authorizations"
					});
					this.isSaving = false;
				});
			} catch (error) {
				this.isDeleting = false;
				return toastr["error"](
					"An error occurred while deleting lesson"
				);
			}
		},

		// Manually handle some input changes
		onChanged(field) {
			this.$validator.flag(field, {
				dirty: true
			});
		},

		getUsersAsync(query) {
			clearTimeout(this.searchTimer);
			this.searchTimer = setTimeout(this.searchUsers(query), 500);
		},

		getPermissionsAsync(query) {
			clearTimeout(this.searchTimer);
			this.searchTimer = setTimeout(this.searchPermissions(query), 500);
		},

		searchUsers(query) {
			this.isLoadingUsers = true;
			axiosDebounce({
				method: "get",
				url: `/api/search/user/${query}`,
				timeout: 60000
			})
				.then(({data}) => {
					this.isLoadingUsers = false;
					this.usersList = [...data];
				})
				.catch(error => {
					this.isLoadingUsers = false;
					return null;
				});
		},

		searchPermissions(query) {
			this.isLoadingPermissions = true;
			axiosDebounce({
				method: "get",
				url: `/api/search/permission/${query}`,
				timeout: 60000
			})
				.then(({data}) => {
					this.isLoadingPermissions = false;
					this.permissionsList = [...data];
				})
				.catch(error => {
					this.isLoadingPermissions = false;
					return null;
				});
		},

		remove(type, value) {
			switch (type) {
				case "permission":
					this.roleForm.permissions = this.roleForm.permissions.filter(
						element => element.id !== value.id
					);
					break;
				case "user":
					this.roleForm.users = this.roleForm.users.filter(
						element => element.id !== value.id
					);
					break;
				default:
					break;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}

#collapseAddNewContent,
#collapseAddNewPlaylist {
	margin-top: 20px;
	margin-bottom: 20px;
}

#addContentButton,
#addPlaylistButton {
	font-size: small;

	&.closeup::before {
		content: "\F057";
	}

	&.expand::before {
		content: "\F055";
	}

	&::before {
		display: inline-block;
		margin-right: 5px;
		font-family: "Font Awesome 5 Free", serif;
		font-style: normal;
		font-weight: 900;
		font-variant: normal;
		color: $white;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
	}
}

.option__block {
	.multiselect__tag {
		height: fit-content;
		margin-right: 0;
	}

	padding: 5px;
	margin: 10px 10px;
	border: 1px solid #ced4da;
	border-radius: 3px;
}

.option__preview {
	white-space: normal;
}

.multiselect__option {
	color: black;
	background: #ced4da;

	.multiselect__option--highlight .multiselect__option--selected {
		color: black;
		background: #ced4da;
	}
}

.multiselect__option--selected.multiselect__option--highlight {
	color: black;
	background: #ced4da;
}
</style>

