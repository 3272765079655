<template>
	<div>
		<!-- filters -->
		<div class="row">
			<div
				class="col-md-12">
				<div
					v-if="!(classification == null || competencies == null || category == null) && filter_loading === false"
					class="container">
					<div class="row mt-1">
						<div class="col-md-12">
							<div class="input-group">
								<input ref="searchLessonValue"
									   type="text"
									   class="form-control"
									   placeholder="Search Lessons">
								<div class="input-group-append">
									<button class="btn btn-success"
											@click="searchLessons">
										<i class="fas fa-search"/> Search
									</button>
									<button class="btn btn-success"
											@click="fetchAllLessons()">
										<i class="fas fa-sync"/> Refresh
									</button>
								</div>
								<br>
							</div>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-md-3">
							<h6>S.E.L Competencies</h6>
							<div class="mt-3">
								<custom-checkbox v-for="(co, index) in Object.entries(competencies)"
												 :key="index"
												 :label="co[0]"
												 v-model="competencies[co[0]]"/>
							</div>
						</div>

						<div class="col-md-3">
							<h6>21st Century Skills:</h6>
							<div class="mt-3">
								<custom-checkbox v-for="(t, index) in Object.entries(category)"
												 :key="index"
												 :label="t[0]"
												 v-model="category[t[0]]"/>
							</div>
						</div>

						<div class="col-md-3">
							<h6>L.I.T Category:</h6>
							<div class="mt-3">
								<custom-checkbox v-for="(c, index) in Object.entries(classification)"
												 :key="index"
												 :label="c[0]"
												 v-model="classification[c[0]]"/>
							</div>
						</div>

						<div class="col-md-3"/>
					</div>
					<div class="row mt-1">
						<div class="col-sm-8"/>
						<div class="col-sm-2"
							 style="text-align: end;">
							<p class="filter-toggle"
							   @click="resetFilters">
								Reset Filters
							</p>
						</div>
						<div class="col-sm-2"
							 style="text-align: end;">
							<p class="filter-toggle"
							   @click="applyFilters">
								Apply Filters
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- lessons table -->
		<div class="row" v-if="!loading">
			<div class="col-md-12 table-responsive">
				<table class="table table-hover">
					<thead class="thead-light">
					<tr>
						<th>Cover</th>
						<th>Title</th>
						<th>
							Overview
						</th>
						<th>
							Focus
						</th>
						<th/>
					</tr>
					</thead>
					<tbody v-if="lessons && !loading && lessons.length > 1">
					<tr
						v-for="(lesson,index) in lessons"
						:key="index">
						<td>
							<img :src="lesson.cover"
								 class="img-thumbnail"
								 width="75px"
								 height="75px">
						</td>
						<td>{{ lesson.title }}</td>
						<td>
								<textarea cols="35"
										  rows="3"
										  :value="lesson.overview"
										  disabled/>
						</td>
						<td>
								<textarea cols="35"
										  rows="3"
										  :value="lesson.focus"
										  disabled/>
						</td>
						<td>
							<p class="text-link" @click="addToPlaylist(lesson)">
								Add to Playlist
							</p>
						</td>
					</tr>
					</tbody>
					<tbody v-else-if="loading">
					Loading lessons...
					</tbody>
					<tbody v-else>
					No lessons
					</tbody>
				</table>
			</div>
			<div class="col-md-12">
				<div class="clearfix">
					<span class="float-left"/>
					<span class="float-right">
						<ul v-if="pagination"
							class="pagination">
							<li class="page-item">
								<a
									v-if="pagination.previous"
									class="page-link"
									@click="updateLessonsTable(pagination.previous)">Previous</a>
							</li>
							<li class="page-item">
								<a
									v-if="pagination.first"
									class="page-link"
									@click="updateLessonsTable(pagination.first)">First</a>
							</li>
							<li class="page-item">
								<a
									class="page-link disabled"
									href="#">{{ `${pagination.current_page}/${pagination.total_pages}` }}</a>
							</li>
							<li class="page-item">
								<a
									v-if="pagination.last"
									class="page-link"
									@click="updateLessonsTable(pagination.last)">Last</a>
							</li>
							<li class="page-item">
								<a
									v-if="pagination.next"
									class="page-link"
									@click="updateLessonsTable(pagination.next)">Next</a>
							</li>
						</ul>
					</span>
				</div>
			</div>
		</div>
		<div v-else
			 class="text-center">
			<div class="spinner-border text-muted"/>
		</div>
	</div>
</template>

<script>
import eventbus from "../../utility/eventbus.js";
import customCheckbox from "../forms/custom-checkbox.vue";

export default {
	components: {
		customCheckbox
	},
	data() {
		return {
			lessons: null,
			pagination: null,
			loading: true,
			classification: null,
			category: null,
			competencies: null,
			filter_loading: true,
			filters: null,
		};
	},
	async mounted() {
		await Promise.all([
			this.fetchAllLessons(),
			this.fetchFilters()
		]);

	},
	updated() {
		this.lessons === null ? this.fetchAllLessons() : null;
	},
	methods: {
		parseCategoryObjects(categoryObject) {
			let resultString = "";
			Object.entries(categoryObject).forEach(value => {
				if (value[1]) {
					resultString =
						resultString === ""
							? value[0]
							: `${resultString}-${value[0]}`;
				}
			});
			return resultString;
		},
		async resetFilters() {
			const {classification, category, competencies} = this.filters;
			this.classification = {};
			this.category = {};
			this.competencies = {};

			classification.map(val => {
				this.classification[val] = true;
			});
			category.map(val => {
				this.category[val] = true;
			});
			competencies.map(val => {
				this.competencies[val] = true;
			});
		},
		async applyFilters() {
			try {
				const query = {};
				const classificationValue = this.parseCategoryObjects(
					this.classification
				);
				const categoryValue = this.parseCategoryObjects(this.category);
				const competencyValue = this.parseCategoryObjects(this.competencies);
				const searchValue = this.$refs.searchLessonValue.value;

				// handle for classification
				if (classificationValue !== "") {
					query["classification"] = classificationValue;
				}
				// handle for category
				if (categoryValue !== "") {
					query["category"] = categoryValue;
				}
				// handle for competency
				if (competencyValue !== "") {
					query["competencies"] = competencyValue;
				}
				// handle for searchinput
				if (searchValue != null && searchValue !== "") {
					query["title"] = searchValue;
				}

				this.loading = true;
				const {status, data, message, links, meta} = await axios.get(
					`/api/lesson?${helpers.generateQueryString(query)}`
				);
				this.loading = false;
				this.lessons = data;
				this.pagination = {
					first: links.first,
					last: links.last,
					previous: links.prev,
					next: links.next,
					current_page: meta.current_page,
					total_pages: meta.last_page
				};
			} catch (error) {
				this.loading = false;
				toastr["error"]("Unable to apply filters");
			}
		},
		async fetchFilters() {
			try {
				const {status, data} = await axios.get("/api/lesson/filters");
				if (status) {
					this.filter_loading = false;
					this.filters = data;
					this.resetFilters();
				}
			} catch (error) {
				toastr["error"]("Unable to fetch filters");
			}
		},
		fetchAllLessons() {
			return axios
				.get("/api/lesson")
				.then(({data, links, meta}) => {
					this.lessons = data;
					this.loading = false;
					this.pagination = {
						first: links.first,
						last: links.last,
						previous: links.prev,
						next: links.next,
						current_page: meta.current_page,
						total_pages: meta.last_page
					};
				})
				.catch(({message}) => {
					this.loading = false;
					toastr["error"]("Could not fetch lessons");
				});
		},
		updateLessonsTable(url) {
			this.loading = true;
			return axios
				.get(url)
				.then(({data, meta, links}) => {
					this.lessons = data;
					this.loading = false;
					this.pagination = {
						first: links.first,
						last: links.last,
						previous: links.prev,
						next: links.next,
						current_page: meta.current_page,
						total_pages: meta.last_page
					};
				})
				.catch(error => {
					toastr["error"]("Could not fetch lessons");
				});
		},
		searchLessons() {
			this.loading = true;
			axiosDebounce({
				method: "get",
				url: `/api/search/lesson/${this.$refs.searchLessonValue.value}`,
				timeout: 60000
			})
				.then(({data, links, meta}) => {
					this.lessons = data;
					this.loading = false;
					this.pagination = {
						first: links.first,
						last: links.last,
						previous: links.prev,
						next: links.next,
						current_page: meta.current_page,
						total_pages: meta.last_page
					};
				})
				.catch(error => {
					this.loading = false;
					toastr["error"]("Could not find a lesson");
				});
		},
		addToPlaylist(data) {
			eventbus.$emit("ADD-TO-PLAYLIST", data);
			return toastr["success"]("Lesson Added");
		}
	}
};
</script>

<style lang="scss" scoped>
.filter-toggle {
	font-size: 14px;
	color: $green;
	cursor: pointer;
}

.text-link {
	color: #084a99;
	font-size: medium;
}

.text-link:hover {
	cursor: pointer;
}

.table-responsive {
	width: 100%;
	height: 400px;
	margin-bottom: 10px;
	overflow-x: scroll;
}
</style>
