<template>
	<div class="page-section">
		<br>
		<!-- class details -->
		<div v-if="classData" class="card">
			<div class="card-body">
				<h6>Classroom Information</h6>
				<ul>
					<li>Name: {{ classData.name }}</li>
					<li>
						Code: {{ classData.code }}
					</li>
					<li>Admin: {{ `${classData.admin.first_name} ${classData.admin.last_name}` }}</li>
				</ul>
				<template v-if="classData.group != null">
					<h6>School Information</h6>
					<ul>
						<li>Name: {{ classData.group.name }}</li>
						<li>Address: {{ classData.group.address }} {{ classData.group.address2 }}</li>
						<li>City: {{ classData.group.city }}</li>
						<li>State: {{ classData.group.state }}</li>
					</ul>
				</template>
			</div>
		</div>
		<br>
		<!-- assignment details -->
		<div class="card">
			<b-tabs card>
				<!-- lesson tab -->
				<b-tab title="Lesson Assignments" active>
					<b-card-text>
						<!-- table -->
						<div v-if="lessonData != null" class="table-responsive">
							<table class="table table-hover">
								<thead class="thead-light">
								<tr>
									<th>Cover</th>
									<th>Title</th>
									<th>Classification</th>
									<th>Date Created</th>
									<th/>
								</tr>
								</thead>

								<tbody>
								<tr
									v-for="(l,index) in lessonData.data"
									:key="index">
									<td>
										<img
											:src="l.lesson.cover || '/images/default-profile.png'"
											class="img-thumbnail"
											width="75px"
											height="75px">
									</td>
									<td>{{ l.lesson.title }}</td>
									<td>{{ l.lesson.classification }}</td>
									<td>
										{{ new Date(l.created_at).toLocaleString() }}
									</td>
									<td>
										<a href="#" @click="routeToResource('lesson', l.lesson.id)">
											View
										</a>
									</td>
								</tr>
								</tbody>
							</table>
						</div>
						<!--						<empty v-else />-->
						<!-- pagination -->
						<div class="clearfix">
							<span class="float-right">
								<pagination v-if="lessonData"
											:links="lessonData.links"
											:meta="lessonData.meta"
											@first="(url) => updateAssignmentTable('lesson', url)"
											@prev="(url) => updateAssignmentTable('lesson', url)"
											@last="(url) => updateAssignmentTable('lesson', url)"
											@next="(url) => updateAssignmentTable('lesson', url)"/>
							</span>
							<span class="float-left"/>
						</div>
					</b-card-text>
				</b-tab>
				<!-- playlist tab -->
				<b-tab title="Playlist Assignments">
					<b-card-text>
						<!-- table -->
						<div v-if="playlistData && playlistData.data.length > 0" class="table-responsive">
							<table class="table table-hover">
								<thead class="thead-light">
								<tr>
									<th>Cover</th>
									<th>Title</th>
									<th>Date Created</th>
									<th/>
								</tr>
								</thead>
								<tbody>
								<tr
									v-for="(p,index) in playlistData.data"
									:key="index">
									<td>
										<img
											:src="p.playlist.cover || '/images/default-profile.png'"
											class="img-thumbnail"
											width="75px"
											height="75px">
									</td>
									<td>{{ p.playlist.name }}</td>
									<td>
										{{ new Date(p.created_at).toLocaleString() }}
									</td>
									<td>
										<a href="#" @click="routeToResource('playlist', p.playlist.id)">
											View
										</a>
									</td>
								</tr>
								</tbody>
							</table>
						</div>
						<empty v-else/>
						<!-- pagination -->
						<div class="clearfix">
							<span class="float-right">
								<pagination v-if="playlistData"
											:links="playlistData.links"
											:meta="playlistData.meta"
											@first="(url) => updateAssignmentTable('playlist', url)"
											@prev="(url) => updateAssignmentTable('playlist', url)"
											@last="(url) => updateAssignmentTable('playlist', url)"
											@next="(url) => updateAssignmentTable('playlist', url)"/>
							</span>
							<span class="float-left"/>
						</div>
					</b-card-text>
				</b-tab>
			</b-tabs>
		</div>
	</div>
</template>

<script>
import pagination from "../../components/utility/pagination.vue";
import empty from "../../components/utility/empty.vue";
import {EventActionEnum, EventLabelEnum} from "../../config/enums";

export default {
	components: {
		pagination,
		empty
	},
	data() {
		return {
			classData: null,
			loading: true,
			class_id: this.$route.params.id,
			lessonData: null,
			playlistData: null,
		};
	},
	async mounted() {
		await Promise.all([
			this.fetchClass(),
			this.fetchAssignments(),
		]);

	},
	computed: {
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},
	// updated() {
	// 	this.classData == null ? this.fetchClass() : null;
	// 	this.playlistData == null || this.lessonData == null ? this.fetchAssignments() : null;
	// },
	methods: {
		async fetchClass() {
			try {
				const response = await axios.get(`/api/classroom/${this.class_id}`);
				this.loading = false;
				this.classData = response.data;
				// log with GA
				this.$gtag.event(EventActionEnum.View, {
					"event_category": this.userEventCategory,
					"event_label": EventLabelEnum.Classroom,
					"value": `${this.classData.group.name}-${this.classData.name}`,
				});
			} catch (error) {
				console.log(error);
				this.loading = false;
				return toastr["error"]("Unable to fetch classes, please try again later");
			}
		},
		async fetchAssignments() {
			try {
				const responses = await Promise.all([
					axios.get(`/api/student/classroom/${this.class_id}/assignments/lesson`),
					axios.get(`/api/student/classroom/${this.class_id}/assignments/playlist`),
				]);

				this.lessonData = responses[0];
				this.playlistData = responses[1];
				this.$forceUpdate();
			} catch (error) {
				console.log(error);
				return toastr["error"]("Unable to fetch assignments, please try again later");
			}
		},
		async updateAssignmentTable(type, url) {
			try {
				this.loading = true;
				let response = await axios.get(url);
				this.loading = false;
				if (type === "lesson") {
					this.lessonData = response;
				} else {
					this.playlistData = response;
				}
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch data, please try again");
			}
		},
		routeToResource(type, id) {
			this.$router.push({path: `/${type}/${id}`}, async () => {
				await axios.get(`/api/student/assignment/${type}/${id}/view`);
			});
		}
	}
};
</script>

<style lang="scss" scoped>
h3 {
	padding-left: 10px;
}

.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}
</style>
<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}
</style>
