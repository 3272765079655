<template>
	<div>
		<div class="page-section">
			<!--			header section-->
			<div class="container mb-4 mt-3">
				<div class="row">
					<div class="col-sm-3">
						<h4>Announcements</h4>
					</div>
					<div class="col-sm-9"/>
				</div>
			</div>
			<!--			body section-->
			<div class="container">
				<!--				announcements-->
				<div class="row">
					<div class="col-md-12">
						<div v-if="announcements != null && announcements[0] != null && loading === false"
							 class="accordion"
							 role="tablist">
							<b-card v-for="(announcement,index) in announcements"
									:key="index"
									class="mb-1"
									no-body>
								<b-card-header class="p-1"
											   header-tag="header"
											   role="tab"
											   @click="toogleVisible(`announcement-${index}`, announcement)">
									<div class="row">
										<div class="col-md-10">
											<span v-if="announcement.notification.group_id == null"
												  class="badge badge-primary mr-1">App</span>
											<span v-else class="badge badge-success mr-1">{{
													announcement.notification.group.name
												}}</span>
											{{ announcement.notification.title }}
										</div>
										<div class="col-md-2">
											<div class="clearfix">
												<span class="float-left"/>
												<span class="float-right">
													<i v-if="announcement.is_read || viewed.includes(announcement.id)"
													   class="fas fa-check-circle text-success"/>
												</span>
											</div>
										</div>
									</div>
								</b-card-header>
								<b-collapse :id="`announcement-${index}`"
											:ref="`announcement-${index}`"
											accordion="my-accordion"
											role="tabpanel">
									<b-card-body>
										<h5>{{ announcement.notification.title }}</h5>
										<div class="row">
											<div class="col-md-12" v-html="announcement.notification.message"/>
										</div>
										<div class="row">
											<div class="col-md-12">
												<div class="clearfix">
													<span v-if="announcement.read_at != null" class="float-left">
														Read: {{ new Date(announcement.read_at).toLocaleDateString() }}
													</span>
													<span class="float-right">
														Created: {{
															new Date(announcement.notification.created_at).toLocaleDateString()
														}}
													</span>
												</div>
											</div>
										</div>
									</b-card-body>
								</b-collapse>
							</b-card>
						</div>
						<div v-else-if="loading" class="text-center">
							<div class="spinner-border text-muted"/>
						</div>
						<div v-else>
							<p>No Data</p>
						</div>
					</div>
				</div>
				<!--				pagination-->
				<div class="row">
					<div class="col-md-12">
						<div class="clearfix">
							<span class="float-left"/>
							<span class="float-right">
								<pagination v-if="meta && links"
											:links="links"
											:meta="meta"
											@first="(url) => updateAnnouncementsTable(url)"
											@last="(url) => updateAnnouncementsTable(url)"
											@next="(url) => updateAnnouncementsTable(url)"
											@prev="(url) => updateAnnouncementsTable(url)"/>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {EventActionEnum, EventLabelEnum} from "../../config/enums";
import pagination from "../../components/utility/pagination.vue";

export default {
	name: "Announcements",
	components: {
		pagination,
	},
	data() {
		return {
			announcements: null,
			links: null,
			meta: null,
			loading: true,
			viewed: []
		};
	},
	async mounted() {
		await this.fetchAllAnnouncements();
	},
	updated() {
		this.announcements === null ? this.fetchAllAnnouncements() : null;
	},
	computed: {
		user() {
			return this.$store.getters["auth/getAuthUser"];
		},
		unreadNotificationsCount() {
			return this.$store.getters["auth/unreadNotificationsCount"];
		},
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},
	methods: {
		async fetchAllAnnouncements() {
			try {
				this.loading = true;
				const {data, meta, links} = await axios.get("/api/notifications");
				this.loading = false;
				this.announcements = data;
				this.links = links;
				this.meta = meta;
				// log with GA
				this.$gtag.event(EventActionEnum.View, {
					"event_category": this.userEventCategory,
					"event_label": EventLabelEnum.Announcements,
					"value": "Personal Announcements",
				});
			} catch (e) {
				this.loading = false;
				toastr["error"]("Could not fetch announcements");
			}
		},
		async updateAnnouncementsTable(url) {
			try {
				this.loading = true;
				const {data, meta, links} = await axios.get(url);
				this.loading = false;
				this.announcements = data;
				this.links = links;
				this.meta = meta;
			} catch (e) {
				this.loading = false;
				toastr["error"]("Could not fetch announcements");
			}
		},
		async readAnnouncement(id) {
			await axios.put(`/api/notifications/${id}/read`);
			this.viewed.push(id);
			this.$store.commit("auth/setNotificationsCount", this.unreadNotificationsCount - 1);
		},
		toogleVisible(id, announcement) {
			if (!(announcement.is_read || this.viewed.includes(id))) {
				// background operation
				this.readAnnouncement(announcement.id);
			}
			this.$refs[id][0].toggle();
		}
	}
};
</script>

<style scoped>

</style>
