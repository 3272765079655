<template>
	<b-card align="left"
			dusk="Register"
			title="Complete registration to access your new library of comics-centered lesson plans">
		<div v-if="!live"
			 id="site-down">
			<h1>Oh oh! You've caught us at a bad time.</h1>
			<h3>
				We're doing some maintenence on the site right now.
				Please check back later. Or signup to get notified when we're back up!
			</h3>

			<form id="lit-landing"
				  class="form">
				<input
					class="form-control"
					dusk="Email"
					placeholder="Email"
					style="margin-bottom: 20px;"
					type="email">
				<input class="button btn-primary form-control"
					   type="submit">
			</form>
		</div>

		<b-form v-if="live"
				@submit.prevent="submit">
			<br>
			<b-form-group id="first_name">
				<b-form-input
					id="first_name"
					v-model="registerForm.first_name"
					class="mb-2 mr-sm-2 mb-sm-0"
					dusk="first_name"
					placeholder="First Name"
					required/>
			</b-form-group>

			<b-form-group id="last_name">
				<b-form-input
					id="last_name"
					v-model="registerForm.last_name"
					class="mb-2 mr-sm-2 mb-sm-0"
					dusk="last_name"
					placeholder="Last Name"
					required/>
			</b-form-group>

			<b-form-group id="email">
				<b-form-input
					id="email"
					v-model="registerForm.email"
					autocomplete="username"
					class="mb-2 mr-sm-2 mb-sm-0"
					dusk="email"
					placeholder="Email"
					required/>
			</b-form-group>

			<b-form-group id="password">
				<b-form-input
					id="password"
					v-model="registerForm.password"
					autocomplete="new-password"
					dusk="password"
					placeholder="Password"
					required
					type="password"/>
			</b-form-group>

			<b-form-group id="password_confirmation">
				<b-form-input
					id="password_confirmation"
					v-model="registerForm.password_confirmation"
					autocomplete="new-password"
					dusk="password_confirmation"
					placeholder="Confirm Password"
					required
					type="password"/>
			</b-form-group>

			<!--			<p v-if="selected_plan != null">-->
			<!--				You are signing up for the <b>{{ selected_plan.name }}</b> plan.-->
			<!--			</p>-->

			<b-form-group v-if="!trial && selected_plan != null"
						  id="payment_form"
						  dusk="payment">
				<p>
					Your card will be charged<b>${{ selected_plan.price.toFixed(2) }}</b> and you will gain full access
					the platform content.
				</p>
				<card
					:class="{ complete }"
					:options="stripeOptions"
					:stripe="stripe_key"
					class="stripe-card form-control"
					@change="complete = $event.complete"/>
			</b-form-group>

			<b-form-group v-if="enteringCoupon && !trial"
						  id="coupon">
				<b-input
					id="coupon"
					v-model="registerForm.coupon"
					dusk="coupon"
					placeholder="Coupon Code"
					type="text"/>
			</b-form-group>

			<p v-if="!enteringCoupon && !trial">
				Got a coupon code?
				<a dusk="enter_coupon"
				   href="#"
				   @click="enterCoupon">
					<b class="text-info m-l-5">Enter it</b>
				</a>
			</p>

			<b-button :disabled="!complete && !trial"
					  block
					  dusk="submit"
					  type="submit"
					  variant="primary">
				<i v-if="loading"
				   class="fas fa-circle-notch fa-spin"/>
				Register
			</b-button>
			<br>

			<p>
				Already have an account?
				<router-link class="text-info"
							 dusk="login"
							 to="/login">
					<b>Sign In</b>
				</router-link>
			</p>
		</b-form>
	</b-card>
</template>

<script>
import {Card, createToken} from "vue-stripe-elements-plus";
import {encrypt, storeExpiry} from "../../utility/encrypt";
import {EventActionEnum, EventCategoryEnum, EventLabelEnum, PlanTypeEnum} from "../../config/enums";
import {mapActions} from "vuex";

export default {
	name: "Register",

	components: {
		Card
	},

	data() {
		return {
			live: true,
			loading: false,
			complete: true,
			selected_plan: null,
			student: false,
			trial: false,
			stripeOptions: {},
			stripe_key: window.stripe_key,
			registerForm: {
				email: "",
				password: "",
				password_confirmation: "",
				first_name: "",
				last_name: "",
				plan_id: "",
				payment_token: "",
				coupon: "",
			},
			enteringCoupon: false,
			plans: []
		};
	},

	mounted() {
		this.setConfig({guestShowPicture: true});
		this.fetchPlans();
	},

	methods: {
		...mapActions({
			setConfig: "config/setConfig",
			checkAuth: "auth/checkAuth",
		}),

		fetchPlans() {
			this.loading = true;
			axios
				.get("api/plan")
				.then(({data}) => {
					this.loading = false;
					this.plans = data;
					if (!this.handleRouteQuery()) {
						this.$router.push("/select-plan");
					}
				})
				.catch(error => {
					this.loading = false;
					return toastr["error"](
						"An error occurred, please try again"
					);
				});
		},

		handleRouteQuery() {
			const {plan} = this.$route.query;
			const {plans} = this;
			switch (plan) {
				case PlanTypeEnum.FREE_TRIAL:
					this.trial = true;
					this.selected_plan = plans[1];
					return true;
				case PlanTypeEnum.TEACHER_FREE_TRIAL:
					this.trial = true;
					this.selected_plan = plans[1];
					return true;
				case PlanTypeEnum.INDIVIDUAL_MONTHLY:
					this.selected_plan = plans[2];
					return true;
				case PlanTypeEnum.INDIVIDUAL_YEARLY:
					this.selected_plan = plans[3];
					return true;
				case PlanTypeEnum.SCHOOL:
					this.selected_plan = plans[4];
					return true;
				case PlanTypeEnum.DISTRICT:
					return false;
				case PlanTypeEnum.STUDENT_FREE_TRIAL:
					this.trial = true;
					this.student = true;
					this.selected_plan = plans[6];
					return true;
				default:
					return false;
			}
		},

		enterCoupon() {
			this.enteringCoupon = true;
		},

		submit(e) {
			this.loading = true;
			this.registerForm.plan_id = this.selected_plan.id;
			if (this.trial) {
				axios
					.post("/api/auth/register", this.registerForm)
					.then(async ({data, message}) => {
						storeExpiry("auth_token", data.token, true);
						axios.defaults.headers.common[
							"Authorization"
							] = `Bearer ${data.token}`;

						return await this.setUserInitial();
					})
					.catch(({error}) => {
						toastr["error"](error);
						this.loading = false;
					});
			} else {
				createToken()
					.then(data => {
						this.registerForm.payment_token = data.token.id;

						axios
							.post("/api/auth/register", this.registerForm)
							.then(({data, message}) => {
								localStorage.setItem(
									"auth_type",
									encrypt("single")
								);
								storeExpiry(
									"auth_token",
									data.token,
									false
								);
								axios.defaults.headers.common[
									"Authorization"
									] = `Bearer ${data.token}`;
								toastr["success"](message);
								this.$router.push("/home");
							})
							.catch(({error}) => {
								toastr["error"](error);
								this.loading = false;
							});
					})
					.catch(error => {
						toastr["error"](
							"An error occurred, Please try again later"
						);
						this.loading = false;
					});
			}
		},

		async setUserInitial() {
			try {
				const data = await axios.get("/api/auth/user");
				const response = data.data;
				sessionStorage.setItem("_gml.rl", encrypt(response));
				this.$store.commit("auth/setAuthUserDetail", {
					id: response.id,
					first_name: response.first_name,
					last_name: response.last_name,
					email: response.email,
					avatar: response.avatar,
					plan_id: response.plan_id,
					roles: encrypt(response.roles),
					permissions: encrypt(response.permissions),
					badges: response.badges,
					favorites: response.favorites,
					favorite_playlists: response.favorite_playlists,
					groups: response.groups,
					classrooms: response.classrooms,
					activity: response.activity,
					subscription: response.subscription,
					user_options: response.user_options
				});
				this.$store.commit("auth/setAuthenticated", true);

				//check if subscription is active
				if (["expired",
					"incomplete",
					"incomplete_expired",
					"past_due",
					"unpaid"
				].includes(response.subscription.status)) {
					this.$store.commit("auth/setSubscriptionActive", false);
					router.replace("/profile/settings");
				} else {
					this.$store.commit("auth/setSubscriptionActive", true);
				}

				this.$store.commit("auth/setSubscriptionChecked", true);

				const defaultRoles = ["teacher", "student", "admin", "super-admin"];

				const buffer = [];

				const roles = response.roles.map(role => role.name);

				roles.forEach(role => {
					if (defaultRoles.includes(role)) {
						buffer.push(role);
					}
				});

				toastr["success"]("Registration Successful");
				this.$store.commit("auth/setUserType", buffer[0]);
				this.$gtag.event(EventActionEnum.Signup, {
					"event_category": EventCategoryEnum.Guest,
					"event_label": EventLabelEnum.Auth,
					"value": "successful"
				});
				setTimeout(() => {
					this.$router.push({path: "/home"});
				}, 0);

				return this.loading = false;
			} catch (error) {
				this.loading = false;
				this.$gtag.event(EventActionEnum.Signup, {
					"event_category": EventCategoryEnum.Guest,
					"event_label": EventLabelEnum.Auth,
					"value": "failed"
				});
				return toastr["error"]("Unable to register user, please try again");
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.stripe-card.complete {
	border-color: green;
}

p {
	font-size: $font-size-base;
	font-family: $font-family-base;
}
</style>
