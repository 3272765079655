<template>
	<div>
		<div class="page-section">
			<div style="padding-top: 10px; background: white;">
				<div class="row">
					<div class="col-md-12">
						<h3 style="margin-left: 15px;">
							{{ pageTitle !== "" ? pageTitle : `Create Custom Playlist` }}
						</h3>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12 lesson-text">
						<form enctype="multipart/form-data" @submit.prevent="submitPrevent">
							<!-- Playlist image -->
							<div class="col-md-12 form-group">
								<img v-if="playlistForm.cover"
									 :src="playlistForm.cover"
									 class="img-responsive"
									 width="200"
									 alt="image here"
									 @change="onChanged('cover')">
							</div>
							<!-- Playlist image upload button -->
							<div class="col-md-12 form-group">
								<b-button class="m-1"
										  variant="primary"
										  @click="dispatchMediaEvent('PLAYLIST_COVER')">
									<i class="fas fa-upload"/> Upload Cover Image
								</b-button>
							</div>

							<!-- Playlist Cover -->
							<div class="col-md-12 form-group">
								<input v-validate
									   id="cover"
									   name="cover"
									   hidden
									   type="text"
									   class="form-control">
							</div>

							<!-- playlist title -->
							<div class="col-md-12 form-group">
								<label for="title" class="control-label">Playlist Title</label>
								<input id="title"
									   v-model="playlistForm.name"
									   placeholder="Input a title for your playlist"
									   type="text"
									   class="form-control"
									   @change="onChanged('name')">
							</div>

							<!-- playlist description-->
							<div class="col-md-12 form-group">
								<label for="overview" class="control-label">Playlist Description</label>
								<textarea id="overview"
										  v-model="playlistForm.description"
										  placeholder="Input a short description of your playlist"
										  rows="5"
										  class="form-control"
										  @change="onChanged('description')"/>
							</div>

							<!-- playlist lessons -->
							<div class="col-md-12  form-group">
								<label>Playlist Lessons</label>
								<div v-if="playlistForm.lessons.length > 0">
									<small>You can rearrange the order of selected lessons</small>
									<br>
									<SlickList v-model="playlistForm.lessons"
											   class="list root"
											   lock-axis="y"
											   :press-delay="200"
											   helper-class="list-item">
										<SlickItem
											v-for="(lesson,index) in playlistForm.lessons"
											:key="index"
											:index="index"
											class="list-item shadow-sm">
											<div class="clearfix">
												<span class="float-left">
													<i class="list-bar fas fa-bars"/>
													<img :src="lesson.cover"
														 class="list-img img-thumbnail"
														 width="75px"
														 height="60px"
														 alt="image here">
													<h6 data-toggle="tooltip"
														class="list-text"
														:title="lesson.overview">
														{{ lesson.title }}
													</h6>
												</span>
												<span class="list-close-span float-right">
													<i class="list-close fas fa-times" @click="deleteLesson(lesson)"/>
												</span>
											</div>
										</SlickItem>
									</SlickList>
								</div>
								<br>
								<b-button class="m-1"
										  variant="primary"
										  @click="lessonsModal = true">
									<i class="fas fa-plus"/> Add Lessons
								</b-button>
							</div>

							<!-- playlist tags-->
							<div class="row col-md-12 form-group">
								<div class="form-group col-md-12">
									<label class="control-label">Playlist Tags</label>
									<div class="chips chips-initial input-field">
										<div v-for="(tag,index) in playlistForm.tags"
											 :key="`${tag}-${index}`"
											 class="chip">
											{{ tag }}
											<span class="closebtn"
												  @click="deleteTag(tag)">&times;</span>
										</div>
									</div>
								</div>
								<br>
								<div class="form-group col-md-8">
									<input
										ref="newTag"
										type="text"
										class="form-control"
										placeholder="Enter Tag"
										@keyup="addTagEnter">
								</div>
								<div class="form-group col-md-4"
									 style="padding-right: 0;">
									<button class="btn btn-primary btn-block btn-md"
											type="button"
											@click="addTag">
										<i class="fas fa-plus-circle icon"/>Add Tag
									</button>
								</div>
							</div>
							<!-- page action buttons -->
							<div class="col-md-12 form-group">
								<button
									v-if="changed || !playlist_id"
									type="button"
									class="btn btn-success"
									@click="savePlaylist">
									<span>Save</span>
								</button>
								<button v-if="changed"
										type="button"
										class="btn btn-warning"
										@click="discardChanges">
									<span>Discard</span>
								</button>
								<button v-if="playlist_id"
										type="button"
										class="btn btn-danger"
										@click="deletePlaylist">
									<span>Delete</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- Media manager -->
		<b-modal
			id="modal-center"
			v-model="mediaManager"
			size="xl"
			hide-footer
			lazy
			centered
			title="Media Manager">
			<div class="row">
				<div class="col-md-12">
					<media-manager :modal-mode="true"/>
				</div>
			</div>
		</b-modal>
		<!-- lessons modal -->
		<b-modal
			id="modal-tall"
			:cancel-disabled="true"
			v-model="lessonsModal"
			size="xl"
			centered
			ok-only
			lazy
			title="Add Lessons to Custom Playlist">
			<select-lessons/>
		</b-modal>
	</div>
</template>

<script>
import eventbus from "../../utility/eventbus.js";
import mediaManager from "../../components/utility/media-manager.vue";
import selectLessons from "../../components/lesson-playlist/select-lessons.vue";
import {SlickItem, SlickList} from "vue-slicksort";
import helper from "../../utility";

export default {
	components: {
		mediaManager,
		selectLessons,
		SlickItem,
		SlickList
	},
	data: function () {
		return {
			pageTitle: "",
			playlist_id: null,
			playlistForm: {
				id: "",
				name: "",
				cover: "",
				author_id: "",
				description: "",
				lessons: [],
				tags: []
			},
			playlist: "",
			changed: false,
			isSaving: false,
			isDeleting: false,
			mediaManager: false,
			lessonsModal: false,
			request_id: "PLAYLIST-COVER"
		};
	},

	watch: {
		playlistForm: {
			handler() {
				if (
					JSON.stringify(this.playlistForm) ===
					JSON.stringify(this.playlist)
				) {
					return (this.changed = false);
				} else {
					return (this.changed = true);
				}
			},
			deep: true
		}
	},

	computed: {
		author_id() {
			return this.$store.getters["auth/getAuthUser"].id;
		}
	},

	mounted() {
		eventbus.$on("media-manager-complete", this.mediaEventHandler);
		eventbus.$on("ADD-TO-PLAYLIST", data =>
			this.playlistForm.lessons.push(data)
		);
		if (this.$route.params.id === "create") {
			this.playlistForm.author_id = this.author_id;
		} else {
			this.playlist_id = this.$route.params.id;
			this.fetchPlaylist(this.$route.params.id);
		}
	},

	destroyed() {
		eventbus.$off("media-manager-complete");
	},

	methods: {
		deleteLesson(lesson) {
			this.playlistForm.lessons = this.playlistForm.lessons.filter(
				element => element.id !== lesson.id
			);
		},
		mediaEventHandler(data) {
			switch (data.response_id) {
				case "PLAYLIST_COVER":
					if ((data.response_id = this.request_id)) {
						this.playlistForm.cover = data.url;
						this.mediaManager = null;
						this.onChanged("cover");
					}
					break;
				default:
					break;
			}
		},
		dispatchMediaEvent(type) {
			this.mediaManager = !this.mediaManager;
			this.request_id = type;
			setTimeout(() => {
				eventbus.$emit("media-manager", type);
			}, 1000);
		},
		fetchPlaylist(id) {
			id !== "create"
				? axios
					.get(`/api/playlist/${id}`)
					.then(({data}) => {
						const {
							id,
							name,
							cover,
							description,
							lessons,
							tags
						} = data;
						this.pageTitle = `Playlist: ${name}`;
						this.playlist = helper.deepClone({
							id,
							name,
							cover,
							description,
							lessons,
							tags: tags.map(tag => tag.slug)
						});
						this.playlistForm = helper.deepClone({
							id,
							name,
							cover,
							description,
							lessons,
							tags: tags.map(tag => tag.slug)
						});
					})
					.catch(({message}) => {
						toastr["error"](message);
					})
				: null;
		},
		submitPrevent(e) {
			return e.preventDefault();
		},
		deleteTag(tag) {
			this.playlistForm.tags = this.playlistForm.tags.filter(
				element => element !== tag
			);
		},
		addTag() {
			if (this.$refs.newTag.value !== "") {
				this.playlistForm.tags.push(this.$refs.newTag.value);
				return (this.$refs.newTag.value = "");
			}
		},
		addTagEnter(e) {
			if (e.keyCode === 13 && this.$refs.newTag.value !== "") {
				e.preventDefault();
				this.playlistForm.tags.push(this.$refs.newTag.value);
				return (this.$refs.newTag.value = "");
			}
			return null;
		},
		onPlaylistImageChange(e) {
			let files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;
			this.createImage(files[0]);
		},
		createImage(file) {
			let reader = new FileReader();
			let vm = this;
			reader.onload = e => {
				vm.playlistForm.cover = e.target.result;
			};
			reader.readAsDataURL(file);
		},
		// Save or update playlist
		savePlaylist() {
			this.isSaving = true;

			const {name, cover, description, lessons} = this.playlistForm;

			if (cover === "") {
				toastr["error"]("Include a playlist image");
				return (this.isSaving = false);
			}
			if (name === "") {
				toastr["error"]("Input a playlist title");
				return (this.isSaving = false);
			}
			if (description === "") {
				toastr["error"]("Input a playlist description");
				return (this.isSaving = false);
			}

			if (lessons[0] == null) {
				toastr["error"]("Assign some lessons to the playlist");
				return (this.isSaving = false);
			}

			delete this.playlistForm.id;
			this.playlistForm.is_custom = true;

			if (this.$route.params.id === "create") {
				this.playlistForm.author_id = this.author_id;
				return axios
					.post("/api/playlist", this.playlistForm)
					.then(({data}) => {
						toastr["success"]("Save successful");
						this.isSaving = false;
						this.$router.go(-1);
					})
					.catch(({message}) => {
						this.isSaving = false;
						return toastr["error"](message);
					});
			}

			// update existing lesson
			return axios
				.put(`/api/playlist/${this.playlist_id}`, this.playlistForm)
				.then(response => {
					toastr["success"]("Save successful");
					this.isSaving = false;
					this.$router.go();
				});
		},
		// reset input changes
		discardChanges() {
			if (this.$route.params.playlist_id === "new") {
				this.playlistForm = {
					name: "",
					cover: "",
					description: "",
					lessons: [],
					tags: []
				};
			} else {
				const {
					name,
					cover,
					description,
					lessons,
					tags
				} = this.playlist;
				this.playlistForm = {
					name,
					cover,
					description,
					lessons,
					tags
				};
				this.changed = false;
			}
		},
		deletePlaylist() {
			this.isDeleting = true;
			try {
				return axios
					.delete(`/api/playlist/${this.playlist_id}`)
					.then(data => {
						this.$router.go(-1);
						this.isDeleting = false;
					});
			} catch (error) {
				this.isDeleting = false;
				return toastr["error"](
					"An error occurred while deleting playlist"
				);
			}
		},
		// handle input changes
		onChanged(field) {
			if (this.playlistForm[`${field}`] === this.playlist[`${field}`]) {
				return this.changed === true ? null : (this.changed = false);
			} else {
				return (this.changed = true);
			}
		},

		clearAll() {
			this.lessonList = [];
		}
	}
};
</script>
<style lang="scss">
.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}
</style>
<style lang="scss" scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}

.list-item {
	z-index: 2;
	box-sizing: border-box;
	display: inline-block;
	width: 100%;
	padding: 5px;
	font-weight: 400;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border-bottom: 1px solid #efefef;
}

.list {
	max-height: 80vh;
	padding: 10px;
	overflow: auto;
	background-color: #f3f3f3;
	border: 1px solid #efefef;
	border-radius: 3px;
}

.list-bar {
	display: inline-block;
	margin: 0 10px;
	font-size: 20px;
	color: $gray-600;
	vertical-align: middle;
}

.list-img {
	display: inline-block;
	margin: 0 10px;
}

.list-text {
	display: inline-block;
	margin: 0;
	font-size: 20px;
	vertical-align: middle;
}

.list-close-span {
	display: inline-block;
	vertical-align: middle;
}

.list-close {
	display: inline-block;
	margin: 0 10px;
	font-size: 20px;
	color: $gray-600;
	vertical-align: middle;
	cursor: pointer;
}

.list,
pre {
	width: 100%;
}

ul {
	padding: 0;
	list-style-type: none;
}

.root {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	height: 100%;
	background-color: #f3f3f3;
}
</style>
