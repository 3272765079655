<template>
	<div id="profile"
		class="page">
		<router-view />
	</div>
</template>

<script>

export default {
	name: "Profile"
};

</script>
