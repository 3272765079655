
let call;
const axiosDebounce = (config = {}) => {
	if (call) {
		call.cancel("Only one request allowed at a time.");
	}
	call = window.axios.CancelToken.source();

	config.cancelToken = call.token;
	return window.axios(config);
};

export default axiosDebounce;
