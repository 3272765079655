<template>
	<div v-if="lesson != null"
		 class="page-section">
		<div class="row">
			<div class="col-lg-9 col-md-8 lesson-overview-text">
				<h4>Focus</h4>
				<p v-html="lesson.focus"/>

				<h4 v-if="!$can('view', 'student')">
					Overview
				</h4>
				<div style="max-height: 50vh; overflow-y: scroll;">
					<p
						v-html="lesson.overview"/>
				</div>
				<ul v-if="lesson.tags[0]"
					class="tags">
					<li v-for="tag in lesson.tags"
						:key="tag.id"
						class="tag">
						{{ tag.name }}
					</li>
				</ul>
				<div v-if="playlist != null"
					 class="clear-fix">
					<ul class="pagination float-right">
						<li
							v-if="previousLessonSequence"
							class="page-item"
							@click="changePlaylistLesson(previousLessonSequence)">
							<a class="page-link" href="#">
								Previous
							</a>
						</li>
						<li class="page-item disabled">
							<a class="page-link"
							   href="#">{{ `Lesson ${lessonSequence} of ${playlist_lessons.length}` }}</a>
						</li>
						<li
							v-if="nextLessonSequence"
							class="page-item"
							@click="changePlaylistLesson(nextLessonSequence)">
							<a class="page-link" href="#">
								Next
							</a>
						</li>
					</ul>
				</div>
			</div>
			<!--			lesson sidebar-->
			<div class="col-lg-3 col-md-4 lesson-sidebar">
				<span class="sidebar-item">
					<i class="fas fa-share-alt icon"/>
					<a class="underline pl-2"
					   href="#"
					   @click="shareLesson()">Share Lesson</a>
				</span>
				<!--Share Lesson Modal Component -->
				<b-modal ref="sharemodalRef"
						 centered
						 hide-footer
						 hide-header
						 size="lg">
					<div class="text-center">
						<img :src="lesson.cover"
							 class="img-responsive rounded"
							 height="100%"
							 width="100%">
						<br>
						<br>
						<h4>Hi, check out this lesson "{{ lesson.title }}" on Get Media L.I.T!</h4>
						<div v-html="lesson.focus"/>
						<br>
						<button
							class="btn btn-lg btn-primary"
							data-placement="bottom"
							data-toggle="tooltip"
							title="Share on Twitter"
							@click="shareLesson('twitter')">
							<i class="fab fa-twitter"/>
							Twitter
						</button>
						<button
							class="btn btn-lg btn-primary"
							data-placement="bottom"
							data-toggle="tooltip"
							title="Share on Facebook"
							@click="shareLesson('facebook')">
							<i class="fab fa-facebook-f"/>
							Facebook
						</button>
						<button
							class="btn btn-lg btn-primary"
							data-placement="bottom"
							data-toggle="tooltip"
							title="Share on Linkedin"
							@click="shareLesson('linkedin')">
							<i class="fab fa-linkedin-in"/>
							LinkedIn
						</button>
						<button
							v-clipboard:copy="shareText+ '\n' + shareLink"
							v-clipboard:error="onCopyError"
							v-clipboard:success="onCopy"
							class="btn btn-lg btn-primary"
							data-placement="bottom"
							data-toggle="tooltip"
							title="Copy to Clipboard">
							<i class="fas fa-copy"/>
							Copy
						</button>
					</div>
				</b-modal>

				<span class="sidebar-item">
					<i :class="status.icon"
					   class="icon"/>
					<a class="underline pl-2"
					   href="#"
					   @click="setFavorite()">{{ status.text }}</a>
				</span>

				<span v-if="!$can('view', 'student')"
					  class="sidebar-item">
					<i class="fas fa-sun icon"/>
					<a class="underline pl-2"
					   href="#"
					   @click="showModal">View Standards</a>
				</span>

				<b-modal v-if="!$can('view', 'student')"
						 id="standards-modal"
						 ref="myModalRef"
						 centered
						 hide-footer
						 hide-header
						 size="lg">
					<div class="d-block">
						<h5 class="text-center">
							Lesson Standards
						</h5>
						<br>
						<div v-html="lesson.standards"/>
						<br>
					</div>
					<button class="btn btn-md btn-info"
							@click="hideModal">
						Close
					</button>
				</b-modal>

				<span id="start-lesson"
					  class="sidebar-item">
					<!-- TODO: disable on no content-->
					<!--<button :disabled="lesson.content.length = 0"-->
					<button
						class="btn btn-block btn-success"
						style="color: whitesmoke;"
						@click="routeToViewLesson(lesson.id, lessonSequence)">
						<i class="fas fa-play icon"/>
						Start Lesson
					</button>
					<button v-if="$can('view', 'admin')"
							class="btn btn-block btn-warning"
							style="color: whitesmoke;"
							@click="routeToEditLesson(lesson.id)">
						<i class="fas fa-pencil-alt icon"/>
						Edit Lesson
					</button>
				</span>

				<span class="sidebar-item">
					<button v-if="user_type === 'teacher'"
							class="btn btn-primary btn-block"
							type="button"
							@click="assignToClass">
						<i class="fas fa-plus"/> Assign to Class
					</button>
				</span>
				<span v-if="lesson.hero"
					  class="sidebar-item">
					<img
						:src="'/images/hero/poses/' + lesson.hero + '0' + hero_select + '.png'"
						class="lesson-hero">
				</span>
			</div>
		</div>
		<assign-to-class type="lesson"/>
	</div>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import helper from "../../utility";
import EventBus from "../../utility/eventbus";
import assignToClass from "../../components/classroom/assign-to-classroom";
import {EventActionEnum, EventLabelEnum} from "../../config/enums";

export default {
	name: "ShowLesson",

	components: {
		assignToClass
	},

	data() {
		return {
			isPlaylist: this.$route.params.playlist_id != null,
			lesson: null,
			playlist: null,
			playlist_lessons: [],
			hero_select: (Math.floor(Math.random() * 5) + 1),
			lessonSequence: null,
			previousLessonSequence: null,
			nextLessonSequence: null,
			user: null,
			status: {
				text: "Add to Favorites",
				icon: "far fa-heart",
				added: false
			},
			shareTitle: "Get Media L.I.T!",
			shareText: `Hey, check out this lesson "${this.lesson != null ? this.lesson.title : "undefined"}" on Get Media L.I.T!`,
			shareLink: document.URL,
		};
	},

	head: {
		meta: function () {
			return [
				{p: "og:title", c: this.lesson.title},
				{p: "og:image", c: this.lesson.cover},
				{p: "og:description", c: this.lesson.focus}
			];
		}
	},

	computed: {
		user_type() {
			return this.$store.getters["auth/getUserType"];
		},
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},

	watch: {
		lessonSequence: {
			handler() {
				this.computeNextLessonSequence();
				this.computePreviousLessonSequence();
				this.computeShareLink();
			},
			deep: true,
			immediate: true,
		}
	},

	async mounted() {
		await this.fetchData();
		this.user = this.getAuthUser();
		this.checkFavorite();
	},

	updated() {
		if (this.lesson == null && this.playlist == null) {
			this.fetchData();
		}
		if (this.user == null) {
			this.user = this.getAuthUser();
		}
		this.checkFavorite();
	},

	methods: {
		...mapActions({
			refreshAuth: "auth/refreshAuth",
		}),

		...mapGetters({
			getAuthUser: "auth/getAuthUser",
		}),

		computeShareLink() {
			let url = document.location.toString().split("/");
			url.pop();
			url.push(this.lessonSequence);
			this.shareLink = url.join("/");
		},

		fetchData() {
			const {lesson_id, playlist_id, lesson_sequence} = this.$route.params;

			let url = `/api/lesson/${lesson_id}`;

			if (playlist_id != null) {
				url =
					`/api/playlist/${+playlist_id}`;
			}


			return axios
				.get(url)
				.then(({data}) => {
					if (this.isPlaylist) {
						this.playlist = data;
						this.playlist_lessons = data.lessons;
						this.lessonSequence = +lesson_sequence;
						this.setLesson(this.playlist_lessons[lesson_sequence - 1]);
						EventBus.$emit("SET-PLAYLIST", this.playlist);
					} else {
						this.setLesson(data);
						this.shareLink = this.shareLink + this.lesson.id;
					}

					this.shareText = `Hey, check out this lesson on Get Media L.I.T!: \n "${this.lesson.title}" \n`;
				})
				.catch(error => {
					this.$router.go(-1);
					toastr.error("Error fetching lesson");
				}).finally(() => {
					this.$emit("updateHead");
				});
		},

		changePlaylistLesson(index) {
			const lesson = this.playlist_lessons[index - 1];
			if (lesson != null) {
				this.lessonSequence = index;
				this.setLesson(lesson);
			}
		},

		computeNextLessonSequence() {
			if (this.playlist_lessons[+this.lessonSequence] != null && this.playlist != null) {
				this.nextLessonSequence = +this.lessonSequence + 1;
			} else {
				this.nextLessonSequence = null;
			}
		},


		computePreviousLessonSequence() {
			if (this.playlist != null && this.playlist_lessons[+this.lessonSequence - 1] != null) {
				this.previousLessonSequence = (+this.lessonSequence - 1);
			} else {
				this.previousLessonSequence = null;
			}
		},

		setLesson(data) {
			// Split the content lines as well
			if (data.content != null) {
				data.content = data.content.map((singleContent) => {
					return helper.splitText(singleContent.text);
				});
			}
			this.lesson = {
				id: data.id,
				title: data.title,
				cover: data.cover,
				hero: data.hero,
				type: data.hero,
				classification: data.hero,
				author: data.author,
				focus: data.focus,
				standards: data.standards,
				overview: helper.splitText(data.overview),
				content: data.content,
				playlists: data.playlists,
				links: data.links,
				tags: data.tags,
				group_id: data.group_id,
				group: data.group,
			};
			EventBus.$emit("SET-LESSON", this.lesson);
			// log with GA on the particular search
			this.$gtag.event(EventActionEnum.View, {
				"event_category": this.userEventCategory,
				"event_label": EventLabelEnum.Lesson,
				"value": data.title
			});
		},

		checkFavorite() {
			if (this.lesson) {
				if (
					this.user.favorites[0] !== null &&
					this.user.favorites[0] !== undefined
				) {
					const inFavorite = this.user.favorites.some(
						lesson => lesson.id === this.lesson.id
					);
					if (inFavorite) {
						this.status.text = "Added to Favorites";
						this.status.icon = "fas fa-heart";
						this.status.added = true;
					} else {
						this.status.text = "Add to Favorites";
						this.status.icon = "far fa-heart";
						this.status.added = false;
					}
				}
			}
		},

		setFavorite() {
			switch (this.status.added) {
				case true:
					return axios
						.delete(
							`/api/user/${this.user.id}/favorites/${
								this.lesson.id
							}`
						)
						.then(() => {
							this.refreshAuth()
								.then(() => {
									this.status.text = "Add to Favorites";
									this.status.icon = "far fa-heart";
									return (this.status.added = false);
								});
						})
						.catch(({message}) => {
							toastr["error"](message);
						});

				case false:
					return axios
						.put(`/api/user/${this.user.id}/favorites`, {
							lesson: this.lesson.id
						})
						.then(() => {
							this.refreshAuth()
								.then(() => {
									this.status.text = "Added to Favorites";
									this.status.icon = "fas fa-heart";
									return (this.status.added = true);
								});
						})
						.catch(({message}) => {
							toastr["error"](message);
						});
				default:
					return null;
			}
		},

		routeToLessonViaNavigation(lesson, sequence) {
			if (this.$route.params.playlist_id) {
				return this.$router.push({
					name: "playlistShowLesson",
					params: {
						playlist_id: this.$route.params.playlist_id,
						lesson_id: lesson,
						lesson_sequence: sequence
					}
				});
			}
		},

		async routeToViewLesson(lesson, sequence) {
			if (this.$route.params.playlist_id) {
				return this.$router.push({
					name: "playlistShowLessonContent",
					params: {
						playlist_id: this.$route.params.playlist_id,
						lesson_id: lesson,
						lesson_sequence: sequence
					}
				});
			} else {
				return this.$router.push({
					name: "showLessonContent",
					params: {lesson_id: lesson}
				});
			}
		},

		routeToEditLesson(lesson) {
			return this.$router.push({
				name: "adminEditLesson",
				params: {
					lesson_id: lesson,
				}
			});
		},

		showModal() {
			this.$refs.myModalRef.show();
		},

		hideModal() {
			this.$refs.myModalRef.hide();
			this.$refs.sharemodalRef.hide();
		},

		shareLesson(type) {
			this.$refs.sharemodalRef.show();
			switch (type) {
				case "facebook":
					window.open(
						`https://www.facebook.com/sharer/sharer.php?u=${
							this.shareLink + this.lesson.id
						}`,
						"_blank"
					);
					break;
				case "twitter":
					window.open(
						`https://twitter.com/share?text=${this.shareText}&url=${this.shareLink + this.lesson.id}`
						,
						"_blank"
					);
					break;
				case "linkedin":
					window.open(
						`https://www.linkedin.com/sharing/share-offsite/?url=${
							this.shareLink + this.lesson.id
						}&title=${this.shareTitle}&summary=${this.shareText}&source=`,
						"_blank"
					);
					break;
				default:
					break;
			}
		},

		onCopy: function (e) {
			toastr.success("Copied to Clipboard");
		},

		onCopyError: function (e) {
			toastr.error("Could not copy to Clipboard");
		},

		assignToClass() {
			EventBus.$emit("ASSIGN-TO-CLASSROOM", {...this.lesson});
		}
	}
};
</script>

<style lang="scss" scoped>
ul {
	width: 100%;
	margin-bottom: 20px;
	overflow: hidden;
	list-style-type: circle !important;
}

li {
	display: inline;
	float: left;
}

.double li {
	width: 50%;
}

.lesson-overview-text {
	@include breakpoint($sm) {
		padding: $container-padding;
	}

	div {
		margin-bottom: 30px;
	}
}

.lesson-content-page {
	.display-content {
		margin-top: 20px;
	}
}

.lesson-sidebar {
	padding: $container-padding;
	padding-left: 20px;

	@include breakpoint($sm) {
		border-left: 1px solid #f5f6f9;
	}

	.sidebar-item {
		display: block;
		padding: 10px;
	}

	@include breakpoint($xs) {
		#start-lesson {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 75px;
			background: white;

			button {
				display: block;
			}
		}
	}
}

.tags {
	padding: 0;
	margin: 0;
	overflow: hidden;
	list-style: none;
}

.tags li {
	float: left;
}

.tag {
	position: relative;
	display: inline-block;
	height: 26px;
	padding: 0 20px 0 23px;
	margin: 0 10px 10px 0;
	line-height: 26px;
	color: #999999;
	text-decoration: none;
	background: #eeeeee;
	border-radius: 3px 0 0 3px;
	-webkit-transition: color 0.2s;
}

.tag::before {
	position: absolute;
	top: 10px;
	left: 10px;
	width: 6px;
	height: 6px;
	content: "";
	background: #ffffff;
	border-radius: 10px;
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
}

.tag::after {
	position: absolute;
	top: 0;
	right: 0;
	content: "";
	background: #ffffff;
	border-top: 13px solid transparent;
	border-bottom: 13px solid transparent;
	border-left: 10px solid #eeeeee;
}

.tag:hover {
	color: white;
	background-color: $dark-blue;
}

.tag:hover::after {
	border-left-color: $dark-blue;
}
</style>
