<template>
	<div>
		<div class="page">
			<div class="row">
				<div class="col-md-12">
					<h3>All Group</h3>
					<br>
					<div class="clear-fix mb-5">
						<span class="float-left">
							<b-breadcrumb :items="breadcrumbitems"/>
						</span>
						<span class="float-right">
							<router-link to="/admin/groups/new"
										 tag="button"
										 class="btn btn-info">
								Create Group
							</router-link>
						</span>
					</div>
					<br>

					<!-- table -->
					<div>
						<table v-if="groups != null && !loading && groups.data.length > 0" class="table table-hover">
							<thead>
							<tr>
								<th>Code</th>
								<th>Name</th>
								<th>Address</th>
								<th>City</th>
								<th>State</th>
								<th>Description</th>
								<th>Type</th>
								<th/>
							</tr>
							</thead>

							<tbody>
							<tr
								v-for="(group,index) in groups.data"
								:key="index"
								style="cursor: pointer;">
								<td>{{ group.code || "n/a" }}</td>
								<td>{{ group.name }}</td>
								<td>{{ group.address }} {{ group.address2 }}</td>
								<td>{{ group.city }}</td>
								<td>{{ group.state }}</td>
								<td><textarea :value="group.description" disabled/></td>
								<td>{{ group.type }}</td>
								<td>
									<router-link tag="a" :to="`/admin/groups/${group.id}`">
										View
									</router-link>
									<br>
									<clipboard-link :copy="getInviteLink(group)" message="Invite Link"/>
								</td>
							</tr>
							</tbody>
						</table>
						<div v-else>
							<empty/>
						</div>
						<div class="clearfix">
							<span class="float-left"/>
							<span class="float-right">
								<pagination v-if="groups != null"
											:links="groups.links"
											:meta="groups.meta"
											@first="(url) => updateGroupTable(url)"
											@prev="(url) => updateGroupTable(url)"
											@last="(url) => updateGroupTable(url)"
											@next="(url) => updateGroupTable(url)"/>
							</span>
						</div>
					</div>
					<br>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axiosDebounce from "../../utility/debounce";
import pagination from "../../components/utility/pagination.vue";
import empty from "../../components/utility/empty.vue";
import clipboardLink from "../../components/utility/clipboard-link.vue";
import EventBus from "../../utility/eventbus";

export default {
	components: {
		pagination,
		empty,
		clipboardLink,
	},
	data() {
		return {
			breadcrumbitems: [
				{
					text: "Groups",
					href: "#"
				},
				{
					text: "All",
					active: true
				}
			],
			groups: null,
			loading: true,
			modalVisible: false,
		};
	},
	created() {
		EventBus.$on("GROUP-CREATED", () => {
			this.fetchGroups();
		});
		this.fetchGroups();
	},
	updated() {
		this.groups === null ? this.fetchGroups() : null;
	},
	methods: {
		async fetchGroups() {
			try {
				const response = await axios.get("/api/group");
				this.loading = false;
				this.groups = response;
			} catch (error) {
				this.loading = false;
				toastr["error"]("Unable to fetch groups");
			}
		},
		async updateGroupTable(url) {
			try {
				this.loading = true;
				const response = await axios.get(url);
				this.loading = false;
				this.groups = response;
			} catch (error) {
				toastr["error"]("Unable to fetch groups");
			}
		},
		getInviteLink(group) {
			return `${window.location.protocol}//${window.location.host}/join-group/${group.code}`;
		},
		async searchGroups() {
			try {
				this.loading = true;
				const response = await axiosDebounce({
					method: "get",
					url: `/api/search/content/${
						this.$refs.searchContentValue.value
					}`,
					timeout: 60000
				});
				this.loading = false;
				this.groups = response;
			} catch (error) {
				this.loading = false;
				toastr["error"]("Unable to get groups");
			}
		},
		createGroup() {
			EventBus.$emit("CREATE-GROUP");
		}
	}
};
</script>
<style lang="scss" scoped>
h3 {
	padding-left: 10px;
}

.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}
</style>
<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}
</style>

