<template>
	<div>
		<div class="page-section">
			<div class="container mb-4 mt-3">
				<div class="row">
					<div class="col-sm-3">
						<h4>Explore Lessons</h4>
					</div>
					<div class="col-sm-7"/>
					<div class="col-sm-2"
						 style="text-align: end;">
						<p :aria-expanded="filter_visible ? 'true' : 'false'"
						   :class="filter_visible ? null : 'collapsed'"
						   aria-controls="collapse-4"
						   class="filter-toggle"
						   @click="filter_visible = !filter_visible">
							{{ filter_visible ? "Collapse Filters" : "Expand Filters" }}
						</p>
					</div>
				</div>
			</div>

			<b-collapse id="collapse-4"
						v-model="filter_visible"
						class="mt-4">
				<div
					v-if="!(classification == null || competencies == null || category == null) && filter_loading === false"
					class="container collapse-body">
					<div class="row mt-4">
						<div class="col-md-12">
							<div class="input-group">
								<input ref="searchLessonValue"
									   class="form-control"
									   placeholder="Search Lessons"
									   type="text">
								<div class="input-group-append">
									<button class="btn btn-success"
											@click="searchLessons">
										Search
									</button>
								</div>
								<br>
							</div>
						</div>
					</div>
					<div class="row mt-4">
						<div class="col-md-3">
							<h6>S.E.L Competencies</h6>
							<div class="mt-3">
								<custom-checkbox v-for="(co, index) in Object.entries(competencies)"
												 :key="index"
												 v-model="competencies[co[0]]"
												 :label="co[0]"/>
							</div>
						</div>

						<div class="col-md-3">
							<h6>21st Century Skills:</h6>
							<div class="mt-3">
								<custom-checkbox v-for="(t, index) in Object.entries(category)"
												 :key="index"
												 v-model="category[t[0]]"
												 :label="t[0]"/>
							</div>
						</div>

						<div class="col-md-3">
							<h6>L.I.T Category:</h6>
							<div class="mt-3">
								<custom-checkbox
									v-for="(c, index) in Object.entries(classification)"
									:key="index"
									v-model="classification[c[0]]"
									:label="c[0]"/>
							</div>
						</div>

						<div class="col-md-3">
							<h6>Sort</h6>
							<div class="mt-3">
								<custom-radio v-model="sort"
											  label="Newest-Oldest"/>
								<custom-radio v-model="sort"
											  label="Oldest-Newest"/>
								<custom-radio v-model="sort"
											  label="A-Z"/>
								<custom-radio v-model="sort"
											  label="Z-A"/>
							</div>
						</div>
					</div>
					<div class="row mt-4">
						<div class="col-sm-10"/>
						<div class="col-sm-1"
							 style="text-align: end;">
							<p class="filter-toggle"
							   @click="resetFilters">
								Reset
							</p>
						</div>
						<div class="col-sm-1"
							 style="text-align: end;">
							<p class="filter-toggle"
							   @click="applyFilters">
								Apply
							</p>
						</div>
					</div>
				</div>
				<div v-else
					 class="text-center">
					<div class="spinner-border text-muted"/>
				</div>
			</b-collapse>
		</div>
		<br>
		<div class="page-section">
			<div class="container">
				<!-- lesson grid -->
				<div class="row">
					<div class="col-md-12">
						<div v-if="lessons != null && lessons[0] != null && loading === false">
							<thumbnailIndex :lessons="lessons"
											grid/>
						</div>
						<div v-else-if="loading === true"
							 class="text-center">
							<div class="spinner-border text-muted"/>
						</div>
						<div v-else>
							<p>No Data</p>
						</div>
					</div>
				</div>
				<!-- pagination -->
				<div class="row">
					<div class="col-md-12">
						<div class="clearfix">
							<span class="float-left"/>
							<span class="float-right">
								<pagination v-if="links && meta"
											:links="links"
											:meta="meta"
											@first="(url) => updateLessonsTable(url)"
											@last="(url) => updateLessonsTable(url)"
											@next="(url) => updateLessonsTable(url)"
											@prev="(url) => updateLessonsTable(url)"/>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<assign-to-class type="lesson"/>
	</div>
</template>

<script>
import thumbnailIndex from "../../components/lesson-playlist/thumbnailIndex.vue";
import assignToClass from "../../components/classroom/assign-to-classroom";
import customCheckbox from "../../components/forms/custom-checkbox.vue";
import helpers from "../../utility";
import CustomRadio from "../../components/forms/custom-radio.vue";
import {EventActionEnum, EventLabelEnum} from "../../config/enums";
import pagination from "../../components/utility/pagination.vue";

export default {
	components: {
		CustomRadio,
		thumbnailIndex,
		customCheckbox,
		assignToClass,
		pagination
	},
	data() {
		return {
			lessons: null,
			links: null,
			meta: null,
			loading: true,
			filter_visible: true,
			classification: null,
			category: null,
			competencies: null,
			filter_loading: true,
			filters: null,
			sort: "",
		};
	},
	async mounted() {
		await this.fetchAllLessons();
		await this.fetchFilters();
	},
	updated() {
		this.lessons === null ? this.fetchAllLessons() : null;
	},
	computed: {
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},
	methods: {
		parseCategoryObjects(categoryObject) {
			let resultString = "";
			Object.entries(categoryObject).forEach(value => {
				if (value[1]) {
					resultString =
						resultString === ""
							? value[0]
							: `${resultString}|${value[0]}`;
				}
			});
			return resultString;
		},
		setFilters() {
			const {classification, category, competencies} = this.filters;
			this.classification = {};
			this.category = {};
			this.competencies = {};

			classification.map(val => {
				this.classification[val] = true;
			});
			category.map(val => {
				this.category[val] = true;
			});
			competencies.map(val => {
				this.competencies[val] = true;
			});

			if (this.$refs.searchLessonValue != null) {
				this.$refs.searchLessonValue.value = "";
			}

			this.sort = "Newest-Oldest";
		},
		async resetFilters() {
			this.setFilters();
			this.loading = true;
			await this.fetchAllLessons();
		},
		async applyFilters() {
			try {
				const query = {};
				const classificationValue = this.parseCategoryObjects(
					this.classification
				);
				const categoryValue = this.parseCategoryObjects(this.category);
				const competencyValue = this.parseCategoryObjects(this.competencies);
				const searchValue = this.$refs.searchLessonValue.value;

				// handle for classification
				query["classification"] = classificationValue !== "" ? classificationValue : [];

				// handle for category
				if (categoryValue !== "") {
					query["category"] = categoryValue !== "" ? categoryValue : [];
				}
				// handle for competency
				if (competencyValue !== "") {
					query["competencies"] = competencyValue !== "" ? competencyValue : [];
				}
				// handle for search input
				if (searchValue != null && searchValue !== "") {
					query["title"] = searchValue;
					// log with GA on the particular search
					this.$gtag.event(EventActionEnum.View, {
						"event_category": this.userEventCategory,
						"event_label": EventLabelEnum.Lessons,
						"value": searchValue
					});
				}

				// handle for sorts
				if (this.sort != null && this.sort !== "") {
					query["sort"] = this.sort;
				}

				this.loading = true;
				const {status, data, message, links, meta} = await axios.get(
					`/api/lesson?${helpers.generateQueryString(query)}`
				);
				this.loading = false;
				this.lessons = data;
				this.links = links;
				this.meta = meta;
			} catch (error) {
				this.loading = false;
				toastr["error"]("Unable to apply filters");
			}
		},
		async fetchFilters() {
			try {
				const {status, data} = await axios.get("/api/lesson/filters");
				if (status) {
					this.filter_loading = false;
					this.filters = data;
					await this.setFilters();
				}
			} catch (error) {
				toastr["error"]("Unable to fetch filters");
			}
		},
		fetchAllLessons() {
			return axios
				.get("/api/lesson")
				.then(({data, links, meta}) => {
					this.lessons = data;
					this.loading = false;
					this.links = links;
					this.meta = meta;
				})
				.catch(({message}) => {
					this.loading = false;
					toastr["error"]("Could not fetch lessons");
				});
		},
		updateLessonsTable(url) {
			this.loading = true;
			return axios
				.get(url)
				.then(({data, meta, links}) => {
					this.lessons = data;
					this.loading = false;
					this.links = links;
					this.meta = meta;
				})
				.catch(error => {
					toastr["error"]("Could not fetch lessons");
				});
		},
		searchLessons() {
			this.loading = true;
			if (this.$refs.searchLessonValue.value === "") {
				return this.fetchAllLessons();
			} else {
				return this.applyFilters();
			}

		}
	}
};
</script>

<style lang="scss" scoped>
.filter-toggle {
	font-size: 14px;
	color: $green;
	cursor: pointer;
}

.collapse-body {
	border-top: solid 0.2px $gray-300;
}
</style>
