<template>
	<div class="page">
		<div class="row">
			<div class="col-md-12">
				<h3>Classes</h3>
				<div class="clear-fix mb-5">
					<span class="float-left">
						<p>
							All Classes in Group
						</p>
					</span>
					<span class="float-right">
						<!--						<button @click="createClassroom"-->
						<!--							type="button"-->
						<!--							class="btn btn-primary"><i class="fas fa-plus" /> New</button>-->
					</span>
				</div>
			</div>
			<div v-if="classData && classData.data.length > 0" class="table-responsive">
				<table class="table table-hover">
					<thead class="thead-light">
					<tr>
						<th>Name</th>
						<th>Code</th>
						<th>Admin</th>
						<th>Group</th>
						<th>Students</th>
						<th>Lesson Assignments</th>
						<th>Playlist Assignments</th>
						<th>Date Created</th>
						<th/>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(c, index) in classData.data" :key="index">
						<td>{{ c.name }}</td>
						<td>{{ c.code }}</td>
						<td>{{ `${c.admin.first_name} ${c.admin.last_name}` }}</td>
						<td>{{ c.group != null ? c.group.name : "No Group" }}</td>
						<td>{{ c.users.length > 1 ? c.users.length - 1 : c.users.length }}</td>
						<td>{{ c.lessons.length }}</td>
						<td>{{ c.playlists.length }}</td>
						<td>{{ new Date(c.created_at).toLocaleString() }}</td>
						<td>
							<router-link :to="`/principal/classes/${c.id}`">
								View
							</router-link>
							<br>
							<clipboard-link :copy="prepareShareLink(c.code)" message="Invite Link"/>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<empty v-else/>
			<!-- pagination -->
			<div class="clearfix">
				<span class="float-right">
					<pagination v-if="classData"
								:links="classData.links"
								:meta="classData.meta"
								@first="(url) => updateClassTable(url)"
								@prev="(url) => updateClassTable(url)"
								@last="(url) => updateClassTable(url)"
								@next="(url) => updateClassTable(url)"/>
				</span>
				<span class="float-left"/>
			</div>
		</div>
		<create-classroom/>
	</div>
</template>

<script>
import pagination from "../../components/utility/pagination.vue";
import empty from "../../components/utility/empty.vue";
import clipboardLink from "../../components/utility/clipboard-link.vue";
import createClassroom from "../../components/classroom/create-classroom";
import EventBus from "../../utility/eventbus";

export default {
	components: {
		pagination,
		empty,
		createClassroom,
		clipboardLink,
	},
	data() {
		return {
			classData: null,
			loading: true,
		};
	},
	mounted() {
		EventBus.$on("CLASSROOM-CREATED", () => {
			this.fetchData();
		});
		this.fetchData();
	},
	updated() {
		this.classData == null ? this.fetchData() : null;
	},
	methods: {
		async fetchData() {
			try {
				const response = await axios.get("/api/principal/classrooms");
				this.loading = false;
				this.classData = response;
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch classes, please try again later");
			}
		},
		async updateClassTable(url) {
			try {
				this.loading = true;
				const response = await axios.get(url);
				this.loading = false;
				this.classData = response;
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch classes, please try again later");
			}
		},
		prepareShareLink(code) {
			return `${window.location.protocol}//${window.location.host}/join-class/${code}`;
		},
		createClassroom() {
			EventBus.$emit("CREATE-CLASSROOM");
		}
	}
};
</script>

<style scoped>

</style>
