<template>
	<b-modal v-model="modalVisible"
			 centered
			 hide-footer
			 size="xl"
			 title="Assign to Class">
		<div class="row">
			<div class="col-md-12 col-sm-12">
				<b-card no-body>
					<b-tabs card>
						<b-tab title="Lessons">
							<b-card-text>
								<div class="row">
									<div class="col-md-12 col-sm-12 table-responsive">
										<div class="clearfix">
											<span class="float-left"/>
											<span class="float-right">
												<pagination v-if="lessons.links && lessons.meta"
															:links="lessons.links"
															:meta="lessons.meta"
															@first="(url) => updateTable('lessons',url)"
															@last="(url) => updateTable('lessons',url)"
															@next="(url) => updateTable('lessons',url)"
															@prev="(url) => updateTable('lessons',url)"/>
											</span>
										</div>
										<table class="table table-hover">
											<thead>
											<tr>
												<th>Cover</th>
												<th>Title</th>
												<th>Focus</th>
												<th>Classification</th>
												<th/>
											</tr>
											</thead>
											<tbody v-if="lessons.data[0] && loading.lessons === false">
											<tr v-for="(lesson,index) in lessons.data"
												:key="index">
												<td>
													<div style="width: 200px; height: 200px;">
														<img :src="lesson.cover"
															 alt="cover"
															 class="img-thumbnail img-fluid">
													</div>
												</td>
												<td>{{ lesson.title || "N/A" }}</td>
												<td>{{ lesson.focus || "N/A" }}</td>
												<td>{{ lesson.classification || "N/A" }}</td>
												<td>
													<button v-if="classData.lessons.find((l) => l.id === lesson.id)"
															:disabled="loading.unassign"
															class="btn btn-md btn-warning"
															style="margin-top: 5px;"
															type="button"
															@click="unassign('lesson',lesson.id)">
														Drop Assignment
													</button>
													<button
														v-else
														:disabled="loading.assign"
														class="btn btn-md btn-primary"
														style="margin-top: 5px;"
														type="button"
														@click="assign('lesson',lesson.id)">
														Assign Lesson
													</button>
												</td>
											</tr>
											</tbody>
											<tbody v-else-if="loading.lessons === true">
											<div class="spinner-border"/>
											</tbody>
											<tbody v-else>
											<empty/>
											</tbody>
										</table>
										<div class="clearfix">
											<span class="float-left"/>
											<span class="float-right">
												<pagination v-if="lessons.links && lessons.meta"
															:links="lessons.links"
															:meta="lessons.meta"
															@first="(url) => updateTable('lessons',url)"
															@last="(url) => updateTable('lessons',url)"
															@next="(url) => updateTable('lessons',url)"
															@prev="(url) => updateTable('lessons',url)"/>
											</span>
										</div>
									</div>
								</div>
							</b-card-text>
						</b-tab>
						<b-tab title="Playlists">
							<b-card-text>
								<div class="row">
									<div class="col-md-6 col-sm-12">
										<!-- search bar -->
										<div class="input-group mb-3">
											<input ref="searchplaylistvalue"
												   class="form-control"
												   placeholder="Search"
												   type="text">
											<div class="input-group-append"
												 @click="search('playlists')">
												<button class="btn btn-primary">
													<i class="fas fa-search"/>
												</button>
											</div>
											<div class="input-group-append"
												 @click="fetchLessonsAndPlaylists">
												<button class="btn btn-info">
													<i class="fas fa-sync"/>
												</button>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12 col-sm-12">
										<div class="clearfix">
											<span class="float-left"/>
											<span class="float-right">
												<pagination v-if="playlists.links && playlists.meta"
															:links="playlists.links"
															:meta="playlists.meta"
															@first="(url) => updateTable('playlists',url)"
															@last="(url) => updateTable('playlists',url)"
															@next="(url) => updateTable('playlists',url)"
															@prev="(url) =>updateTable('playlists',url)"/>
											</span>
										</div>
										<table class="table table-hover">
											<thead>
											<tr>
												<th>Cover</th>
												<th>Name</th>
												<th>Description</th>
												<th/>
											</tr>
											</thead>
											<tbody v-if="playlists.data[0] && loading.playlists === false">
											<tr v-for="(playlist,index) in playlists.data"
												:key="index">
												<td>
													<div style="width: 200px; height: 200px;">
														<img :src="playlist.cover"
															 alt="cover"
															 class="img-thumbnail img-fluid">
													</div>
												</td>
												<td>{{ playlist.name || "N/A" }}</td>
												<td>
														<textarea :value="playlist.description"
																  cols="50"
																  disabled
																  rows="5"/>
												</td>
												<td>
													<button v-if="classData.playlists.find((p) => p.id === playlist.id)"
															:disabled="loading.unassign"
															class="btn btn-md btn-warning"
															style="margin-top: 5px;"
															type="button"
															@click="unassign('playlist',playlist.id)">
														Drop Assignment
													</button>
													<button
														v-else
														:disabled="loading.assign"
														class="btn btn-primary"
														style="margin-top: 5px;"
														type="button"
														@click="assign('playlist',playlist.id)">
														Assign Playlist
													</button>
												</td>
											</tr>
											</tbody>
											<tbody v-else-if="loading.lessons === true">
											<div class="spinner-border"/>
											</tbody>
											<tbody v-else>
											<empty/>
											</tbody>
										</table>
										<div class="clearfix">
											<span class="float-left"/>
											<span class="float-right">
												<pagination v-if="playlists.links && playlists.meta"
															:links="playlists.links"
															:meta="playlists.meta"
															@first="(url) => updateTable('playlists',url)"
															@last="(url) => updateTable('playlists',url)"
															@next="(url) => updateTable('playlists',url)"
															@prev="(url) =>updateTable('playlists',url)"/>
											</span>
										</div>
									</div>
								</div>
							</b-card-text>
						</b-tab>
						<b-tab title="Custom playlists">
							<b-card-text>
								<div class="row">
									<div class="col-md-12 col-sm-12">
										<div class="clearfix">
											<span class="float-left"/>
											<span class="float-right">
												<pagination v-if="custom_playlists.links && custom_playlists.meta"
															:links="custom_playlists.links"
															:meta="custom_playlists.meta"
															@first="(url) => updateTable('custom_playlists',url)"
															@last="(url) => updateTable('custom_playlists',url)"
															@next="(url) => updateTable('custom_playlists',url)"
															@prev="(url) => updateTable('custom_playlists',url)"/>
											</span>
										</div>
										<table class="table table-hover">
											<thead>
											<tr>
												<th>Cover</th>
												<th>Name</th>
												<th>Description</th>
												<th/>
											</tr>
											</thead>
											<tbody
												v-if="custom_playlists.data[0] && loading.custom_playlists === false">
											<tr v-for="(custom_playlist,index) in custom_playlists.data"
												:key="index">
												<td>
													<div style="width: 200px; height: 200px;">
														<img :src="custom_playlist.cover"
															 alt="cover"
															 class="img-thumbnail">
													</div>
												</td>
												<td>{{ custom_playlist.name || "N/A" }}</td>
												<td>
														<textarea :value="custom_playlist.description"
																  cols="50"
																  disabled
																  rows="5"/>
												</td>
												<td>
													<button
														v-if="classData.playlists.find((p) => p.id === custom_playlist.id)"
														:disabled="loading.unassign"
														class="btn btn-md btn-warning"
														style="margin-top: 5px;"
														type="button"
														@click="unassign('playlist',custom_playlist.id)">
														Drop Assignment
													</button>
													<button
														v-else
														:disabled="loading.assign"
														class="btn btn-primary"
														style="margin-top: 5px;"
														type="button"
														@click="assign('playlist',custom_playlist.id)">
														Assign Playlist
													</button>
												</td>
											</tr>
											</tbody>
											<tbody v-else-if="loading.custom_playlists === true">
											<div class="spinner-border"/>
											</tbody>
											<tbody v-else>
											<empty/>
											</tbody>
										</table>
										<div class="clearfix">
											<span class="float-left"/>
											<span class="float-right">
												<pagination v-if="custom_playlists.links && custom_playlists.meta"
															:links="custom_playlists.links"
															:meta="custom_playlists.meta"
															@first="(url) => updateTable('custom_playlists',url)"
															@last="(url) => updateTable('custom_playlists',url)"
															@next="(url) => updateTable('custom_playlists',url)"
															@prev="(url) => updateTable('custom_playlists',url)"/>
											</span>
										</div>
									</div>
								</div>
							</b-card-text>
						</b-tab>
					</b-tabs>
				</b-card>
			</div>
		</div>
	</b-modal>
</template>

<script>
import Eventbus from "../../utility/eventbus";
import empty from "../utility/empty.vue";
import pagination from "../utility/pagination.vue";
import {EventActionEnum, EventLabelEnum} from "../../config/enums";

export default {
	components: {empty, pagination},
	props: {},
	data() {
		return {
			classData: null,
			class_id: null,
			modalVisible: false,
			playlists: {
				data: [],
				links: null,
				meta: null
			},
			lessons: {
				data: [],
				links: null,
				meta: null
			},
			custom_playlists: {
				data: [],
				links: null,
				meta: null
			},
			loading: {
				lessons: false,
				playlists: false,
				custom_playlists: false,
				assign: false,
				unassign: false
			}
		};
	},
	computed: {
		user() {
			return this.$store.getters["auth/getAuthUser"];
		}
	},
	async mounted() {
		Eventbus.$on("ASSIGN-PLAYLIST-LESSON-TO-CLASSROOM", async (id) => {
			this.class_id = id;
			this.modalVisible = true;
			await Promise.all([this.fetchClass(), this.fetchLessonsAndPlaylists()]);
		});
	},
	updated() {
		this.playlists.data == null || this.lessons.data == null || this.custom_playlists == null ? this.fetchLessonsAndPlaylists() : null;
		this.classData == null ? this.fetchClass() : null;
	},
	methods: {
		async fetchClass() {
			try {
				const response = await axios.get(`/api/classroom/${this.class_id}`);
				this.classData = response.data;
				// log with GA
				this.$gtag.event(EventActionEnum.View, {
					"event_category": this.userEventCategory,
					"event_label": EventLabelEnum.Classroom,
					"value": this.classData.name,
				});
			} catch (error) {
				return toastr["error"]("Unable to fetch class data, please try again later");
			}
		},
		async fetchLessonsAndPlaylists() {
			const lessons = axios.get("/api/lesson");
			const playlists = axios.get("/api/playlist");
			const custom_playlists = axios.get(
				"/api/teacher/custom-playlists"
			);
			this.loading = {
				lessons: true,
				playlists: true,
				custom_playlists: true,
				assign: false
			};
			const responses = await Promise.all([
				lessons,
				playlists,
				custom_playlists
			]);
			this.playlists = {
				data: responses[1].data,
				links: responses[1].links,
				meta: responses[1].meta
			};
			this.lessons = {
				data: responses[0].data,
				links: responses[0].links,
				meta: responses[0].meta
			};
			this.custom_playlists = {
				data: responses[2].data,
				links: responses[2].links,
				meta: responses[2].meta
			};
			this.loading = {
				lessons: false,
				playlists: false,
				custom_playlists: false,
				assign: false
			};
		},
		async updateTable(table, url) {
			try {
				this.loading[table] = true;
				const response = await axios.get(url);
				this.loading[table] = false;
				if (response.status === true) {
					this[table] = {
						data: response.data,
						meta: response.meta,
						links: response.links
					};
				} else {
					toastr["error"]("Unable to fetch data, try again later");
				}
			} catch (error) {
				this.loading[table] = false;
				return toastr["error"]("Unable to fetch data, try again later");
			}
		},
		async search(table) {
			try {
				this.loading[table] = true;
				const response = await axios.get(
					`/api/search/lesson/${this.refs[`search${table}value`]}`
				);
				this.loading[table] = false;
				if (response.status === true) {
					this[table] = {
						data: response.data,
						meta: response.meta,
						links: response.links
					};
				} else {
					toastr["error"]("Unable to fetch data, try again later");
				}
			} catch (error) {
				return toastr["error"]("Unable to fetch data, try again later");
			}
		},
		handleAssignUnassign(assign, type, id) {
			if (assign) {
				if (type === "lesson") {
					const lesson = this.lessons.data.filter(lesson => lesson.id === id)[0];
					this.classData.lessons.push(lesson);
				}
				if (type === "playlist") {
					const playlist = this.playlists.data.filter(playlist => playlist.id === id)[0];
					const custom_playlist = this.custom_playlists.data.filter(playlist => playlist.id === id)[0];
					playlist != null ? this.classData.playlists.push(playlist) : null;
					custom_playlist != null ? this.classData.playlists.push(custom_playlist) : null;
				}
			} else {
				if (type === "lesson") {
					this.classData.lessons = this.classData.lessons.filter((lesson) => lesson.id !== id);
				}
				if (type === "playlist") {
					this.classData.playlists = this.classData.playlists.filter((playlist) => playlist.id !== id);
				}
			}
		},
		async assign(type, id) {
			try {
				this.loading.assign = true;
				await axios.post(`/api/teacher/assignments/${type}/${id}/class/${this.class_id}`);
				toastr["success"]("Assigned to Class");
				this.handleAssignUnassign(true, type, id);
			} catch (error) {
				console.log(error);
				toastr["error"]("Unable to assign to class, try again later");
			} finally {
				this.loading.assign = false;
				// await Promise.all([this.fetchClass(), this.fetchLessonsAndPlaylists()]);
			}
		},
		async unassign(type, id) {
			try {
				this.loading.unassign = true;
				await axios.delete(`/api/teacher/assignments/${type}/${id}/class/${this.class_id}`);
				toastr["success"]("Assignment dropped");
				this.handleAssignUnassign(false, type, id);
			} catch (e) {
				console.log(error);
				toastr["error"]("Unable to drop assignment, try again later");
			} finally {
				this.loading.unassign = false;
				// await Promise.all([this.fetchClass(), this.fetchLessonsAndPlaylists()]);
			}
		}
	}
};
</script>

<style>
</style>
