<template>
	<div>
		<div
			v-if="item"
			class="lesson-thumbnail card">
			<div :style="{ backgroundImage: 'url(' + item.cover + ')'}"
				 class="lesson-image text-left"
				 @click="routeToViewItem()">
				<div class="overlay"/>
				<div class="hover-content">
					<i class="fas fa-play fa-lg play-icon"/>
				</div>
				<i
					v-if="!showingPlaylist"
					:class="type_icon"
					:title="lesson.type"
					class="type-icon fas"
					data-toggle="tooltip"/>
				<p v-if="!showingPlaylist"
				   class="classification">
					{{ lesson.classification }}
				</p>
				<i
					v-if="completed && user_type === 'student'"
					class="completed-icon fa-check-circle fas"
					data-toggle="tooltip"
					title="lesson completed"/>
			</div>
			<div class="lesson-details text-left pt-3" @click="routeToViewItem()">
				<h5 v-if="showingPlaylist"
					class="title pl-2">
					{{ item.name }}
				</h5>
				<h5 v-else
					class="title pl-2">
					{{ item.title }}
				</h5>
				<p class="excerpt pl-2">
					{{ excerpt }}
				</p>
			</div>
			<div class="clearfix">
				<div class="float-left">
					<button
						v-if="showingPlaylist"
						class="btn btn-primary btn-sm assign_button ml-2"
						type="button"
						@click="routeToViewItem()">
						<i class="fas fa-play-circle"/> View Playlist
					</button>
					<button
						v-else
						class="btn btn-primary btn-sm assign_button ml-2"
						type="button"
						@click="routeToViewItem()">
						<i class="fas fa-play-circle"/> View Lesson
					</button>
				</div>
				<div class="float-right"/>
			</div>
		</div>
	</div>
</template>

<script>
import EventBus from "../../utility/eventbus";

export default {
	name: "Thumbnail",

	props: {
		assignToClassVisible: {
			type: Boolean,
			default: true,
		},
		playlistId: {
			type: Number,
			default: null
		},
		playlist: {
			type: Object,
			default: null
		},
		lesson: {
			type: Object,
			default: null
		},
		lessonSequence: {
			type: Number,
			default: null
		}
	},

	data() {
		return {
			item: null,
			showingPlaylist: false,
			type_icon: "",
			completed: false
		};
	},

	computed: {
		excerpt: {
			cache: false,
			get() {
				if (this.playlist) {
					return this.playlist.description;
					//return this.playlist.description.substring(0, 115) + "...";
				} else {
					return this.lesson.focus;
					//return this.lesson.focus.substring(0, 115) + "...";
				}
			}
		},
		activity() {
			return this.$store.getters["getUserActivity"];
		},
		user_type() {
			return this.$store.getters["auth/getUserType"];
		}
	},

	mounted() {
		if (this.playlist) {
			this.showingPlaylist = true;
			this.item = this.playlist;
		} else {
			this.item = this.lesson;
			this.setLessonType();
		}
		this.isCompleted();
	},

	methods: {
		routeToViewItem() {
			if (this.showingPlaylist) {
				return this.$router.push({
					name: "showPlaylist",
					params: {
						playlist_id: this.playlist.id
					}
				});
			} else if (this.playlistId) {
				return this.$router.push({
					name: "playlistShowLesson",
					params: {
						playlist_id: this.playlistId,
						lesson_id: this.lesson.id,
						lesson_sequence: this.lessonSequence
					}
				});
			} else {
				return this.$router.push({
					name: "showLesson",
					params: {
						lesson_id: this.lesson.id
					}
				});
			}
		},

		setLessonType() {
			if (this.lesson) {
				switch (this.lesson.type) {
					case "Comic":
						this.type_icon = "fa-book-reader";
						break;
					case "Video":
						this.type_icon = "fa-video";
						break;
					case "Article":
						this.type_icon = "fa-file-alt";
						break;
					case "Activity":
						this.type_icon = "fa-paste";
						break;
					default:
						this.type_icon = "";
				}
			}
		},

		isCompleted() {
			if (Array.isArray(this.activity)) {
				let arr = [...this.activity].filter(
					activity => activity.lesson.id === this.lesson.id
				);
				if (arr[0] !== undefined && arr[0].completed_timestamp !== null) {
					this.completed = true;
				}
			}

		},
		assignToClass() {
			if (this.$props.lesson) {
				EventBus.$emit("ASSIGN-TO-CLASSROOM", {...this.$props.lesson});
			} else {
				EventBus.$emit("ASSIGN-TO-CLASSROOM", {...this.$props.playlist});
			}

		}
	}
};
</script>

<style lang="scss" scoped>
.lesson-thumbnail {
	position: relative;
	display: inline-block;
	flex: 0 0 auto;
	width: 220px;
	height: 315px;
	margin-bottom: 15px;
	cursor: pointer;

	.type-icon {
		margin: 15px;
		font-size: 20px;
		color: white;

		&::before {
			text-shadow: 3px 3px 16px #272634;
		}
	}

	.completed-icon {
		top: 98%;
		float: right;
		margin-top: 45px;
		margin-right: 20px;
		font-size: 20px;
		color: white;

		&::before {
			text-shadow: 3px 3px 16px #272634;
		}
	}

	&.playlist-thumbnail .play-icon {
		margin-top: 50px;
	}

	.classification {
		position: relative;
		top: -50px;
		left: 135px;
		width: 34%;
		padding: 2px;
		margin: auto 10px;
		font-size: 10px;
		font-weight: 600;
		color: #ffffff;
		text-align: center;
		text-transform: uppercase;
		background-color: $theme;
	}

	.lesson-image {
		width: 100%;
		height: $lesson-thumb-image-height;
		margin-bottom: 10px;
		background-size: cover;

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: $lesson-thumb-image-height;
			background-color: #000000;
			border: $active-lesson-image-border;
			border-radius: 5px;
			opacity: 0;
		}

		.hover-content {
			z-index: 10;
			display: none;
			margin: 40px;
			overflow: hidden;
			text-align: center;
		}
	}

	.lesson-details {
		.title {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.excerpt {
			display: -webkit-box;
			height: 40px;
			-webkit-box-orient: vertical;
			text-overflow: clip;
			overflow: scroll;
			word-wrap: break-word;
			font-size: 12.5px;
		}
	}

	&:hover {
		.lesson-image {
			border: $active-lesson-image-border;

			.overlay {
				opacity: 0.4;
				transition: opacity 0.25s ease-in;
			}

			.hover-content {
				display: block;
			}
		}

		.classification {
			/* left: 132px; */
			display: none;
		}

		.type-icon {
			display: none;
		}

		.completed-icon {
			display: none;
		}
	}

	.assign_button {
		position: absolute;
		bottom: 15px;
	}
}
</style>
