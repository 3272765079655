<template>
	<div class="h-100">
		<div align-v="center"
			 class="h-100">
			<div
				align="center">
				<div v-if="!live"
					 id="site-down">
					<h1>Oh oh! You've caught us at a bad time.</h1>

					<h3>
						We're doing some maintenance on the site right now.
						Please check back later. Or signup to get notified when we're back up!
					</h3>

					<form id="lit-landing"
						  class="form">
						<input class="form-control"
							   placeholder="Email"
							   style="margin-bottom: 20px;"
							   type="email">
						<input class="button btn-primary form-control"
							   type="submit">
					</form>
				</div>

				<div v-if="live"
					 id="plan-select">
					<h3>Get Started: Pick A Plan!</h3>
					<br>
					<div>
						<div id="plan_list">
							<!-- <div id="free_trial" class="plan">
								<div class="plan-text">
									<h4>Free Trial</h4>
									<p>{{ plans[1].description }}</p>
									<b>Free for {{ plans[1].pricing_period }} days!</b>
								</div>
								<router-link :to="{ name: 'register', query: { plan: 'free-trial' } }">
									<b-button variant="primary"
										type="submit"
										dusk="select_free_trial"
										block>
										Select
									</b-button>
								</router-link>
							</div> -->

							<div v-if="student_plan != null"
								 id="student"
								 class="plan">
								<!--<router-link :to="{ name: 'register', params: { selected_plan_id: plans[4].id } }">-->
								<div class="plan-text">
									<h4>student.</h4>
									<p>{{ student_plan.description }}</p>
									<!-- <b>Free for {{ plans[6].pricing_period }} days!</b> -->
								</div>
								<!-- <router-link :to="{ name: 'register', query: { plan: 'student-free-trial' } }">
									<b-button variant="primary"
										type="submit"
										dusk="select_free_trial"
										block>
										Select
									</b-button>
								</router-link> -->
								<router-link to="/join-class/new">
									<b-button block
											  dusk="select_free_trial"
											  type="submit"
											  variant="primary">
										Select
									</b-button>
								</router-link>
							</div>

							<div v-if="teacher_plan != null"
								 id="individual_teacher"
								 class="plan">
								<div class="plan-text">
									<h4>teacher.</h4>
									<p>{{ teacher_plan.description }}</p>
									<!-- <label class="switch">
										<input
											id="togBtn"
											v-model="period_toggle"
											dusk="individual_teacher_checkbox"
											type="checkbox">
										<div class="slider round"
											dusk="individual_teacher_slider">
											<span class="off">
												<b>${{ plans[2].price.toFixed(2) }} {{ plans[2].pricing_period }}</b>
											</span>
											<span class="on">
												<b>${{ plans[3].price.toFixed(2) }} {{ plans[3].pricing_period }}</b>
											</span>
										</div>
									</label> -->
								</div>
								<router-link :to="{ name: 'register', query: { plan: 'teacher-free-trial' } }">
									<b-button block
											  dusk="select_free_trial"
											  type="submit"
											  variant="primary">
										Select
									</b-button>
								</router-link>
							</div>

							<div id="school_district" class="plan">
								<!--<router-link :to="{ name: 'register', params: { selected_plan_id: plans[4].id } }">-->
								<div class="plan-text">
									<h4>school/district.</h4>
									<p>Access for schools and classrooms throughout a district</p>
									<!-- <b>Special Pricing</b> -->
								</div>
								<a href="mailto:hello@weirdenoughproductions.com"
								   target="_blank">
									<b-button block
											  type="submit"
											  variant="primary">Request Quote
									</b-button>
								</a>
							</div>
						</div>
						<br>
						<!--<p>Please Select from one of the following plans</p>-->
					</div>
				</div>

				<div class="mt-3">
					<p>
						<router-link
							id="back-to-login"
							class="text-info m-l-5"
							dusk="back-to-login"
							to="/login">
							<b>Already have an account? Log in.</b>
						</router-link>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {PlanTypeEnum} from "../../config/enums";
import {mapActions} from "vuex";

export default {
	name: "SelectPlan",

	data() {
		return {
			live: true,
			period_toggle: false,
			student_plan: null,
			teacher_plan: null,
		};
	},

	computed: {
		individual_plan() {
			if (this.period_toggle) {
				return PlanTypeEnum.INDIVIDUAL_YEARLY;
			} else {
				return PlanTypeEnum.INDIVIDUAL_MONTHLY;
			}
		}
	},

	mounted() {
		this.setConfig({guestShowPicture: false});
		if (this.live) {
			this.fetch();
		}
	},

	methods: {
		...mapActions({
			setConfig: "config/setConfig"
		}),

		fetch() {
			axios
				.get("api/plan")
				.then(({data}) => {
					data.filter((plan) => {
						if (plan.name === "Student Free Trial") {
							this.student_plan = plan;
						}
						if (plan.name === "Teacher Free Trial") {
							this.teacher_plan = plan;
						}
					});
				})
				.catch(({message}) => {
					toastr["error"]("Could not load plans");
				});
		},

		selectPlan($plan_id) {
			this.selected_plan_id = $plan_id;
		},

		submit(e) {
		}
	}
};
</script>

<style lang="scss" scoped>
#site-down input {
	-webkit-flex-grow: 1;
	flex-grow: 1;
	width: 100%;
	max-width: 100%;
	min-height: 40px;
	padding: 5px 14px;
	font-size: 15px;
	color: #373a3c;
	vertical-align: middle;
	background-color: transparent;
	border: 1px solid #818a91;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
}

#plan_select {
	width: max-content;
}

.plan {
	display: inline-block;
	width: 210px;
	height: 210px;
	margin: 5px 5px;
}

#plan_list {
	display: inline-block;
}

#plan_list > div {
	padding: 40px 10px;
	color: #ffffff;
	background-position: center;
	transition: all 0.5s ease-in-out;

	&.card-body {
		padding: 0;
	}

	.plan-text {
		min-height: 100px;
	}

	&#teacher_free_trial,
	&#student_free_trial {
		background: url("/images/plans/free-trial.png");
		background-position: center;
		background-size: cover;
	}

	&#individual_teacher {
		background: url("/images/plans/individual-teacher.png");
		background-position: center;
		background-size: cover;

		.switch {
			position: relative;
			display: inline-block;
			width: 170px;
			height: 34px;
		}

		.switch input {
			display: none;
		}

		.slider {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			cursor: pointer;
			background-color: #ca2222;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		.slider::before {
			position: absolute;
			bottom: 4px;
			left: 4px;
			width: 26px;
			height: 26px;
			content: "";
			background-color: white;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		input:checked + .slider {
			background-color: #2ab934;
		}

		input:focus + .slider {
			box-shadow: 0 0 1px #2196f3;
		}

		input:checked + .slider::before {
			-webkit-transform: translateX(135px);
			-ms-transform: translateX(135px);
			transform: translateX(135px);
		}

		.on {
			display: none;
		}

		.on,
		.off {
			position: absolute;
			top: 50%;
			left: 40%;
			font-size: 13px;
			color: white;
			transform: translate(-30%, -50%);
		}

		input:checked + .slider .on {
			display: block;
		}

		input:checked + .slider .off {
			display: none;
		}

		/* Rounded sliders */
		.slider.round {
			border-radius: 34px;
		}

		.slider.round::before {
			border-radius: 50%;
		}
	}

	&#school_district {
		// background: url("/images/plans/school-district.png");
		background: url("/images/plans/free-trial.png");
		background-position: center;
		background-size: cover;
	}

	&#student {
		background: url("/images/plans/school-district.png");
		background-position: center;
		background-size: cover;
	}

	a b,
	form b {
		display: block;
		font-size: 18px;
	}

	a p,
	form p {
		min-height: 40px;
	}
}
</style>

<style lang="scss" scoped>
p {
	font-size: $font-size-base;
	font-family: $font-family-base;
}
</style>
