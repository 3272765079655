<template>
	<div>
		<div class="page-section">
			<div class="row">
				<div class="col-md-12">
					<br>
					<div>
						<!-- breadcrumb links -->
						<b-breadcrumb :items="breadcrumbitems"/>
					</div>
					<ul class="nav nav-tabs"
						role="tablist">
						<li class="nav-item">
							<a
								id="details-tab"
								class="nav-link active"
								data-toggle="tab"
								href="#details"
								role="tab"
								aria-controls="details"
								aria-selected="true">Profile Details</a>
						</li>
						<li class="nav-item">
							<a
								id="subcription-tab"
								class="nav-link"
								data-toggle="tab"
								href="#subscription"
								role="tab"
								aria-controls="subscription"
								aria-selected="false">Subscription</a>
						</li>
					</ul>
					<div id="profileSettingsTabContent"
						 class="tab-content">
						<div
							id="details"
							class="tab-pane fade show active"
							role="tabpanel"
							aria-labelledby="details-tab">
							<div>
								<div style="padding-top: 20px; background: white;">
									<div class="row">
										<div class="col-md-12 lesson-text">
											<form enctype="multipart/form-data">
												<!-- user avatar -->
												<div class="col-md-12 text-center form-group">
													<img
														:src="display_avatar ? display_avatar : '/images/default-profile.png'"
														class="img-responsive"
														width="200">
												</div>
												<!-- user avatar upload button -->
												<div
													v-if="$can('update', 'user' + user_id ) || !$can('manage', 'all')"
													class="col-md-12 text-center form-group">
													<label for="avatar"
														   class="btn btn-md btn-primary">
														<div class="image-upload">
															<input
																v-validate
																id="avatar"
																ref="avatar"
																:disabled="isSaving"
																name="avatar"
																type="file"
																class="form-control"
																accept="image/*"
																hidden
																@change="onUserAvatarChange">
														</div>
														<i class="fas fa-upload icon"/>
														Upload Cover Image
													</label>
												</div>
												<!-- user first name -->
												<div class="col-md-12 form-group">
													<label for="first_name"
														   class="control-label">First Name</label>
													<input
														id="first_name"
														v-model="userForm.first_name"
														:disabled="!$can('update', 'user' + user_id ) || !$can('manage', 'all')"
														type="text"
														class="form-control"
														@change="onChanged('first_name')">
												</div>
												<!-- user last name -->
												<div class="col-md-12 form-group">
													<label for="last_name"
														   class="control-label">Last Name</label>
													<input
														id="last_name"
														v-model="userForm.last_name"
														:disabled="!$can('update', 'user' + user_id ) || !$can('manage', 'all')"
														type="text"
														class="form-control"
														@change="onChanged('last_name')">
												</div>
												<!-- user email -->
												<div class="col-md-12 form-group">
													<label for="email"
														   class="control-label">Email</label>
													<input
														id="email"
														v-model="userForm.email"
														:disabled="!$can('update', 'user' + user_id ) || !$can('manage', 'all')"
														class="form-control"
														@change="onChanged('email')">
												</div>
												<!-- user roles-->
												<div class="row col-md-12 form-group">
													<div class="row col-md-12 form-group">
														<div class="form-group col-md-12">
															<label for="roles"
																   class="control-label">Roles</label>
															<multiselect
																v-validate
																id="roles"
																:multiple="true"
																:searchable="true"
																:loading="isLoadingRoles"
																v-model="display_roles"
																:internal-search="false"
																:options="roleList"
																:options-limit="5"
																:clear-on-select="false"
																:close-on-select="true"
																:disabled="!$can('update', 'roles')"
																name="roles"
																placeholder="Type to search for content"
																track-by="id"
																label="display_name"
																@input="onChanged('roles')"
																@search-change="getRolesAsync"/>
														</div>
													</div>
												</div>
												<!-- page  action buttons -->
												<div v-if="$can('update', 'users')"
													 class="col-md-12 form-group">
													<button
														v-if="changed || !user_id"
														type="button"
														class="btn btn-success"
														@click="saveUser">
														<span>Save</span>
													</button>
													<button v-if="changed"
															type="button"
															class="btn btn-warning"
															@click="discardChanges">
														<span>Discard</span>
													</button>
													<button v-if="user_id"
															type="button"
															class="btn btn-danger"
															@click="deleteUser">
														<span>Delete</span>
													</button>
													<button v-if="user_id"
															type="button"
															:disabled="isResetting"
															class="btn btn-warning"
															@click="resetPassword">
														<span>Reset Password</span>
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							v-if="$can('update', 'subscriptions')"
							id="subscription"
							class="tab-pane fade"
							role="tabpanel"
							aria-labelledby="subscription-tab">
							<subscriptions :user="user"
										   :admin="true"/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal id="new-password-modal" hide-footer>
			<template #modal-title>
				Password Reset
			</template>
			<div v-if="resetPasswordData">
				<h4>{{ resetPasswordData.message }}</h4>
				<button
					v-if="resetPasswordData"
					v-clipboard:copy="resetPasswordData.data"
					v-clipboard:success="onCopy"
					v-clipboard:error="onCopyError"
					class="btn btn-sm btn-primary"
					data-toggle="tooltip"
					data-placement="bottom"
					title="Copy to Clipboard">
					<i class="fas fa-copy"/>
					Copy Password
				</button>
			</div>
		</b-modal>
	</div>
</template>

<script>
import debounce from "../../utility/debounce";
import Multiselect from "vue-multiselect";
import subscriptions from "../../components/subscriptions/subscriptions.vue";
import helpers from "../../utility";
import {mapActions, mapGetters} from "vuex";

export default {
	components: {
		Multiselect,
		subscriptions
	},

	data: function () {
		return {
			breadcrumbitems: [
				{
					text: "Users",
					href: "#"
				},
				{
					text: "All",
					active: true
				}
			],
			user_id: null,
			userForm: {
				first_name: "",
				last_name: "",
				avatar: "",
				email: "",
				roles: []
			},
			display_roles: null,
			display_avatar: null,
			user: "",
			changed: false,
			isSaving: false,
			isDeleting: false,
			isResetting: false,
			isLoadingRoles: false,
			roleList: [],
			resetPasswordData: null,
		};
	},

	beforeMount() {
		if (this.$route.params.user_id === "new") {
			this.pageTitle = "Create new user";
			this.breadcrumbitems[1].text = "New";
		} else {
			// fetch the lesson here;
			this.fetchUser(this.$route.params.user_id);
			this.user_id = this.$route.params.user_id;
		}
	},

	updated() {
		this.display_avatar = this.userForm.avatar;
	},

	methods: {
		...mapActions({
			setAuthUserDetail: "auth/setAuthUserDetail"
		}),

		...mapGetters({
			getAuthUserDetail: "auth/getAuthUserDetail",
		}),

		getRolesAsync: function (query) {
			clearTimeout(this.searchTimer);
			this.searchTimer = setTimeout(this.roleSearch(query), 500);
		},

		roleSearch(query) {
			if (query) {
				this.isLoadingRoles = true;
				debounce({
					method: "get",
					url: `/api/search/role/${query}`,
					timeout: 60000
				})
					.then(({data}) => {
						this.roleList = data.map(function (role) {
							return {
								display_name: role.display_name,
								id: role.id
							};
						});
						this.isLoadingRoles = false;
					})
					.catch(error => {
						return null;
					});
			}
		},

		fetchUser(id) {
			axios
				.get(`/api/user/${id}`)
				.then(({data}) => {
					const {first_name, last_name, roles} = data;
					this.breadcrumbitems[1].text = `${first_name} ${last_name}`;
					this.user = helpers.deepClone(data);
					this.userForm = helpers.deepClone(data);
					this.display_roles = roles;
				})
				.catch(({message}) => {
					toastr["error"](message);
				});
		},

		onUserAvatarChange(e) {
			let files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;
			this.createImage(files[0]);
		},

		createImage(file) {
			let reader = new FileReader();
			let vm = this;
			reader.onload = e => {
				vm.userForm.avatar = e.target.result;
				vm.display_avatar = e.target.result;
				vm.onChanged("avatar");
			};
			reader.readAsDataURL(file);
		},

		// save or update user
		saveUser() {
			this.isSaving = true;

			if (this.fields.roles.dirty) {
				this.userForm.roles = this.display_roles.map(function (roles) {
					return roles.id;
				});
			} else {
				delete this.userForm.roles;
			}

			if (!this.fields.avatar.dirty) {
				delete this.userForm.avatar;
			}

			try {
				if (this.$route.params.user_id === "new") {
					// save as new user
					delete this.userForm.id;

					axios
						.post("/api/user", this.userForm)
						.then(({data, message}) => {
							toastr["success"]("Save successful");
							this.isSaving = false;
							this.$router.replace({
								path: `/admin/users/${data.id}`
							});
						});
				} else {
					// update existing user
					delete this.userForm.id;

					axios
						.put(`/api/user/${this.user_id}`, this.userForm)
						.then(response => {
							toastr["success"]("Save successful");
							this.refreshAuth();
							this.isSaving = false;
							this.$router.push({
								path: `/admin/users/${this.user_id}`
							});
						});
				}
			} catch (error) {
				this.isSaving = false;
				return toastr["error"]("An error occurred while saving user");
			}
		},

		refreshAuth() {
			if (this.getAuthUserDetail("id") === this.user_id) {
				this.setAuthUserDetail({
					first_name: response.data.first_name,
					last_name: response.data.last_name,
					email: response.data.email,
					avatar: response.data.avatar,
					plan_id: response.data.plan_id,
					roles: encrypt(response.data.roles, "custom"),
					badges: response.data.badges,
					favorites: response.data.favorites,
					id: response.data.id,
					favorite_playlists: response.favorite_playlists
				});
			}
		},

		// reset input changes
		discardChanges() {
			if (this.$route.params.user_id === "new") {
				this.userForm = {
					first_name: "",
					last_name: "",
					avatar: "",
					email: "",
					roles: []
				};
			} else {
				const {
					first_name,
					last_name,
					avatar,
					email,
					roles
				} = this.user;
				this.userForm = {
					first_name,
					last_name,
					avatar,
					email,
					roles
				};
				this.changed = false;
			}
		},

		deleteUser() {
			if (confirm("Warning: The user data will be lost forever")) {
				this.isDeleting = true;
				try {
					return axios.delete(`/api/user/${this.user_id}`).then(data => {
						this.isDeleting = false;
						return this.$router.replace({
							path: "/admin/users"
						});
					});
				} catch (error) {
					this.isDeleting = false;
					return toastr["error"]("An error occurred while deleting user");
				}
			}
		},

		async resetPassword() {
			if (confirm("Warning: This would reset the password without notifying the user")) {
				try {
					this.isResetting = true;
					const response = await axios.post(`/api/admin/reset-password/${this.user_id}`);
					this.isResetting = false;
					this.resetPasswordData = response;
					this.$bvModal.show("new-password-modal");
				} catch (error) {
					this.isResetting = false;
					return toastr["error"]("Unable to reset user password");
				}
			}
		},

		// handle input changes
		onChanged(field) {
			if (this.userForm[`${field}`] === this.user[`${field}`]) {
				return this.changed === true ? null : (this.changed = false);
			} else {
				return (this.changed = true);
			}
		},
		onCopy: function (e) {
			toastr.success("Copied to Clipboard");
		},

		onCopyError: function (e) {
			toastr.error("Could not copy to Clipboard");
		},
	}
};
</script>

<style lang="scss">
.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}
</style>

<style lang="scss" scoped>
#profileSettingsTabContent {
	padding: 20px;
	border-right: 1px solid #dee2e6;
	border-bottom: 1px solid #dee2e6;
	border-left: 1px solid #dee2e6;
}
</style>

<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}
</style>
