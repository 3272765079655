<template>
	<div>
		<div class="page">
			<div class="row">
				<div class="col-md-12">
					<h3>Overview</h3>
					<br>
				</div>
			</div>
			<div v-if="loading === false"
				 class="row">
				<div class="col-md-3"
					 style="cursor: pointer;"
					 @click="routeToPage('/teacher/classes')">
					<div class="card bg-danger">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ dashboardData["classroomCount"] || 0 }}</strong> Classes
							</h5>
						</div>
					</div>
				</div>
				<div class="col-md-3"
					 style="cursor: pointer;"
					 @click="routeToPage('/teacher/students')">
					<div class="card bg-dark">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ dashboardData["studentCount"] || 0 }}</strong> Students
							</h5>
						</div>
					</div>
				</div>
				<div class="col-md-3"
					 style="cursor: pointer;"
					 @click="routeToPage('/teacher/assignments')">
					<div class="card bg-success">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ dashboardData["lessonAssignmentsCount"] || 0 }}</strong> Lesson Assignments
							</h5>
						</div>
					</div>
				</div>
				<div class="col-md-3"
					 style="cursor: pointer;"
					 @click="routeToPage('/teacher/assignments')">
					<div class="card bg-info">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ dashboardData["playlistAssignmentsCount"] || 0 }}</strong> Playlist
								Assignments
							</h5>
						</div>
					</div>
				</div>
			</div>
			<br>
			<div class="row">
				<div class="col-md-12">
					<div class="card-deck">
						<div v-if="loading === false"
							 class="card">
							<div class="card-body">
								<h5 class="card-title">
									Recently Registered Students
								</h5>
								<div v-if="dashboardData != null && dashboardData['recentStudents'].length > 0"
									 class="table-responsive">
									<table class="table table-hover">
										<thead class="thead-light">
										<tr>
											<th>Avatar</th>
											<th>Username</th>
											<th>Class</th>
											<th>Date Registered</th>
										</tr>
										</thead>

										<tbody>
										<tr
											v-for="(studentData,index) in dashboardData['recentStudents']"
											:key="index"
											style="cursor: pointer;"
											@click="routeToPage(`/teacher/students/${studentData.user_id}`)">
											<td>
												<img
													:src="studentData.user.avatar_url != null ? studentData.user.avatar_url : '/images/default-profile.png'"
													class="img-thumbnail"
													width="75px"
													height="75px"
													alt="cover">
											</td>
											<td>{{ studentData.user.username }}</td>
											<td>{{ studentData.classroom.name }}</td>
											<td>{{ studentData["created_at"] | timeago }}</td>
										</tr>
										</tbody>
									</table>
								</div>
								<empty v-else/>
							</div>
						</div>
						<div v-if="loading === false"
							 class="card">
							<div class="card-body">
								<h5 class="card-title">
									Recently Created Classes
								</h5>
								<div v-if="dashboardData['recentClassrooms'].length > 0" class="table-responsive">
									<table class="table table-hover">
										<thead class="thead-light">
										<tr>
											<th>Name</th>
											<th>Code</th>
											<th>School</th>
											<th>Date Registered</th>
										</tr>
										</thead>
										<tbody>
										<tr
											v-for="(classData,index) in dashboardData['recentClassrooms']"
											:key="index"
											style="cursor: pointer;"
											@click="routeToPage(`/teacher/classes/${classData.id}`)">
											<td>{{ classData.name }}</td>
											<td>{{ classData.code }}</td>
											<td>{{ classData.group != null ? classData.group.name : "No Group" }}</td>
											<td>{{ classData["created_at"] | timeago }}</td>
										</tr>
										</tbody>
									</table>
								</div>
								<empty v-else/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<br>
			<div class="row">
				<div class="col-md-12">
					<div class="card-deck">
						<div v-if="loading === false"
							 class="card">
							<div class="card-body">
								<h5 class="card-title">
									Recent Lesson Assignments
								</h5>
								<div v-if="dashboardData['recentLessonAssignments'].length > 0"
									 class="table-responsive">
									<table class="table table-hover">
										<thead class="thead-light">
										<tr>
											<th>Cover</th>
											<th>Class</th>
											<th>Title</th>
											<th>Classification</th>
											<th>Date Created</th>
										</tr>
										</thead>

										<tbody>
										<tr
											v-for="(lessonData,index) in dashboardData['recentLessonAssignments']"
											:key="index"
											style="cursor: pointer;"
											@click="routeToPage(`/teacher/assignments/lesson/${lessonData.lesson.id}/class/${lessonData.classroom.id}`)">
											<td>
												<img
													:src="lessonData.lesson.cover != null ? lessonData.lesson.cover : '/images/default-profile.png'"
													class="img-thumbnail"
													width="75px"
													height="75px"
													alt="cover">
											</td>
											<td>{{ lessonData.classroom.name }}</td>
											<td>{{ lessonData.lesson.title }}</td>
											<td>{{ lessonData.lesson.classification }}</td>
											<td>
												{{ lessonData["created_at"] | timeago }}
											</td>
										</tr>
										</tbody>
									</table>
								</div>
								<empty v-else/>
							</div>
						</div>
						<div v-if="loading === false"
							 class="card">
							<div class="card-body">
								<h5 class="card-title">
									Recent Playlist Assignments
								</h5>
								<div v-if="dashboardData['recentPlaylistAssignments'].length > 0"
									 class="table-responsive">
									<table class="table table-hover">
										<thead class="thead-light">
										<tr>
											<th>Cover</th>
											<th>Class</th>
											<th>Title</th>
											<th>Date Created</th>
										</tr>
										</thead>

										<tbody>
										<tr
											v-for="(playlistData,index) in dashboardData['recentPlaylistAssignments']"
											:key="index"
											style="cursor: pointer;"
											@click="routeToPage(`/teacher/assignments/playlist/${playlistData.playlist.id}/class/${playlistData.classroom.id}`)">
											<td>
												<img
													:src="playlistData.playlist.cover != null ? playlistData.playlist.cover : '/images/default-profile.png'"
													class="img-thumbnail"
													width="75px"
													height="75px"
													alt="cover">
											</td>
											<td>{{ playlistData.classroom.name }}</td>
											<td>{{ playlistData.playlist.name }}</td>
											<td>
												{{ playlistData["created_at"] | timeago }}
											</td>
										</tr>
										</tbody>
									</table>
								</div>
								<empty v-else/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import empty from "../../components/utility/empty.vue";
import {EventActionEnum, EventLabelEnum} from "../../config/enums";

export default {
	components: {
		empty,
	},
	data() {
		return {
			dashboardData: null,
			loading: true
		};
	},
	mounted() {
		this.fetchData();
	},
	updated() {
		this.dashboardData === null ? this.fetchData() : null;
	},
	computed: {
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},
	methods: {
		async fetchData() {
			try {
				const response = await axios.get("/api/teacher/dashboard");
				this.loading = false;
				this.dashboardData = response.data;
				// log with GA
				this.$gtag.event(EventActionEnum.View, {
					"event_category": this.userEventCategory,
					"event_label": EventLabelEnum.Dashboard,
					"value": "Dashboard"
				});
			} catch (error) {
				console.log(error);
				this.loading = false;
				return toastr["error"]("Unable to fetch data, please try again");
			}
		},
		routeToPage(route) {
			return this.$router.push({
				path: route
			});
		}
	}
};
</script>

<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}

.white-text {
	color: white;
}

strong {
	font-size: 2em;
}

.card .table-responsive {
	width: 100%;
	height: 300px;
	overflow-x: scroll;
}
</style>

