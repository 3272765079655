<template>
	<div>
		<div class="page">
			<div class="row">
				<div class="col-md-12">
					<!--					tab navigation nav-->
					<ul
						id="authorizationsTabs"
						class="nav nav-tabs"
						role="tablist">
						<li class="nav-item">
							<a id="plans-tab"
								aria-controls="plans"
								aria-selected="true"
								class="nav-link active"
								data-toggle="tab"
								href="#plans"
								role="tab">Plans</a>
						</li>
					</ul>
					<!--					tab contents-->
					<div id="authorizationTabContent"
						class="tab-content">
						<!--						roles content-->
						<div
							id="plans"
							class="tab-pane fade show active"
							role="tabpanel">
							<h3>All Plans</h3>
							<br>
							<div class="row">
								<div class="col-md-12">
									<div class="input-group">
										<input
											ref="searchPlans"
											class="form-control"
											placeholder="Search Plans"
											type="text">
										<div class="input-group-append">
											<button class="btn btn-success"
												@click="search">
												Search
											</button>
											<button class="btn btn-info"
												@click="() => {plansModal.status = true}">
												Create Plan
											</button>
										</div>
										<br>
									</div>
								</div>
							</div>
							<br>
							<!-- table -->
							<div>
								<table class="table table-hover">
									<thead>
										<tr>
											<th>Name</th>
											<th>Description</th>
											<th>Price</th>
											<th>Users Count</th>
											<th>Pricing Period</th>
											<th>Operation</th>
										</tr>
									</thead>
									<tbody
										v-if="
											plansData.plans &&
												!loading &&
												plansData.plans.length > 0
										">
										<tr
											v-for="(plan,
												index) in plansData.plans"
											:key="index">
											<td>{{ plan.name || "n/a" }}</td>
											<td>
												{{ plan.description || "n/a" }}
											</td>
											<td>
												{{
													plan.price != null
														? `$${plan.price}`
														: "$0"
												}}
											</td>
											<td>{{ plan.user_count || 0 }}</td>
											<td>
												{{
													plan.pricing_period || "n/a"
												}}
											</td>
											<td>
												<a href="#"
													@click="viewPlan(plan.id)">View</a>
												<br>
												<a
													href="#"
													@click="deletePlan(plan.id)">Delete</a>
											</td>
										</tr>
									</tbody>
									<tbody v-else-if="loading">
										Loading Plans..
									</tbody>
									<tbody v-else>
										No plans created yet
									</tbody>
								</table>
								<div class="clearfix">
									<span class="float-left" />
									<div class="float-right">
										<pagination v-if="links && meta"
											:links="links"
											:meta="meta"
											@first="(url) => updateTable('plans',url)"
											@last="(url) => updateTable('plans',url)"
											@next="(url) => updateTable('plans',url)"
											@prev="(url) => updateTable('plans',url)" />
									</div>
								</div>
							</div>
							<br>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- The Create Plans Modal -->
		<b-modal v-model="plansModal.status"
			:busy="plansModal.loading"
			centered
			title="Create New Plan"
			@close="modalReset"
			@ok="createPlan">
			<div>
				<div class="form-group">
					<label for="name">Plan Title:</label>
					<input id="name"
						v-model="plansModal.name"
						class="form-control"
						placeholder="Input plan title"
						type="text">
				</div>
				<div class="form-group">
					<label for="description">Plan Description:</label>
					<textarea id="description"
						v-model="plansModal.description"
						class="form-control"
						placeholder="Input plan description" />
				</div>
			</div>
		</b-modal>
	</div>
</template>
<script>
import axios from "axios";
import pagination from "../../components/utility/pagination.vue";

export default {
	components: {
		pagination
		// Multiselect
	},
	data() {
		return {
			plansData: {
				plans: null,
			},
			plansModal: {
				status: false,
				name: "",
				description: "",
				loading: false
			},
			links: null,
			meta: null,
			loading: true
		};
	},
	created() {
		this.fetchData();
	},
	methods: {
		// works
		async fetchData() {
			try {
				this.loading = true;
				const response = await axios.get("/api/plan?admin=true");
				this.loading = false;
				this.plansData.plans = response.data;
				this.links = response.links;
				this.meta = response.meta;
			} catch (error) {
				this.loading = false;
				toastr["error"]("unable to fetch plans");
			}
		},
		modalReset() {
			return (this.plansModal = {
				name: "",
				description: "",
				status: false,
				loading: false
			});
		},
		createPlan(e) {
			e.preventDefault();
			let {name, description, loading} = this.plansModal;
			loading = true;
			if (name !== "" && description !== "") {
				return axios
					.post("/api/plan", {name, description})
					.then(({status}) => {
						if (status) {
							this.modalReset();
							this.fetchData();
							return toastr["success"]("Plan Created");
						} else {
							return toastr["error"]("unable to create plan");
						}
					})
					.catch(e => {
						return toastr["error"]("An error occurred, try again");
					});
			}
			return toastr["error"]("Check your input entries");
		},
		deletePlan(id) {
			if (confirm("You can't undo this delete")) {
				return axios
					.delete(`/api/plan/${id}`)
					.then(({status}) => {
						if (status) {
							toastr["success"]("Deleted successfully");
							return this.fetchData();
						} else {
							return toastr["error"]("unable to delete plan");
						}
					})
					.catch(() => {
						return toastr["error"]("An error occurred, try again");
					});
			} else {
				return null;
			}
		},
		viewPlan(id) {
			return this.$router.push({path: `/admin/plans/${id}`});
		},
		updateTable(tab, url) {
			this.loading = true;
			switch (tab) {
			case "plans":
				return axios
					.get(url)
					.then(({data, links, meta}) => {
						this.plansData.plans = data;
						this.loading = false;
						this.links = links;
						this.meta = meta;
					})
					.catch(() => {
						return toastr["error"]("Could not fetch plans");
					});
			default:
				break;
			}
		},
		async search() {
			try {
				this.loading = true;
				if (this.$refs.searchPlans.value === "") {
					return this.fetchData();
				}
				const response = await axios.get(
					`/api/search/plan/${this.$refs.searchPlans.value}`
				);
				const {data, meta, links} = response;
				this.loading = false;
				this.plansData.plans = data;
				this.links = links;
				this.meta = meta;
			} catch (error) {
				return toastr["error"]("Could not fetch plans");
			}
		}
	}
};
</script>
<style lang="scss" scoped>
h3 {
	padding-left: 10px;
}

.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}
</style>
<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}

#authorizationTabContent {
	padding: 20px;
	border-right: 1px solid #dee2e6;
	border-bottom: 1px solid #dee2e6;
	border-left: 1px solid #dee2e6;
}
</style>
