<template>
	<b-card
		title="Registration Successful!"
		align="left">
		<p>
			You have successfully signed up to Get Media L.I.T.
			Please check your email to activate your account.
		</p>

		<p>
			Back to Login? <router-link
				to="/login"
				class="text-info m-l-5">
				<b>Sign In</b>
			</router-link>
		</p>
	</b-card>
</template>

<script>
export default {
	data() {
		return {
		};
	},
	mounted(){

	}
};
</script>

<style lang="scss">
p {
	font-size: $font-size-base;
	font-family: $font-family-base;
}
</style>
