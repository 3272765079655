<template>
	<div class="playlist container">
		<h2
			v-if="playlist && !(this.$route.name ==='createPlaylist' || this.$route.name === 'customPlaylist' || this.$route.name === 'featuredPlaylists')"
			:class="titleClass">
			<span>Playlist:</span>
			<router-link :to="'/playlist/' + playlist.id">
				{{ playlist.name }}
			</router-link>
		</h2>

		<div v-if="showPlaylist"
			 class="content-container">
			<router-view/>
		</div>
	</div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import helper from "../../utility";

export default {
	name: "Playlist",

	data() {
		return {
			titleClass: "page-title"
		};
	},

	computed: {
		...mapState({
			playlist: "playlist",
		}),

		showPlaylist() {
			let routeName = this.$route.name;
			if (routeName === "customPlaylist" || routeName === "featuredPlaylists" || routeName === "createPlaylist") {
				return true;
			}
			return +this.$route.params.playlist_id === +this.playlist.id;
		}
	},

	mounted() {
		this.checkTitle();
		this.fetch();
	},

	updated() {
		this.checkPlaylist();
	},

	methods: {
		...mapActions({
			fetchPlaylist: "playlist/fetchPlaylist",
		}),

		fetch() {
			let routeName = this.$route.name;
			// Fetch playlist from api if it isn't present in the vuex
			// or the wrong playlist is in the vuex.
			if (!(routeName === "createLesson" || routeName === "createPlaylist" || routeName === "customPlaylist" || routeName === "featuredPlaylists")
			) {
				axios
					.get("/api/playlist/" + +this.$route.params.playlist_id)
					.then(({data}) => {

						// Split the description and divide into paragraphs
						data.description = helper.splitText(
							data.description
						);

						// Store playlist in the vuex.
						this.$store.commit("playlist/setPlaylist", {
							id: data.id,
							cover: data.cover,
							description: data.description,
							lessons: [...data.lessons],
							name: data.name,
							tags: data.tags
						});
					})
					.catch(error => {
						toastr.error("Error fetching playlist");
					});
			}
		},

		checkPlaylist() {
			const currentPlaylist = this.$store.getters["playlist/getPlaylist"];
			if (currentPlaylist.id !== +this.$route.params.playlist_id) {
				this.fetch();
			}
		},

		checkTitle() {
			//Use big title for show Playlist and small title for others
			if (this.$route.name === "showPlaylist") {
				this.titleClass = "page-title";
			} else {
				this.titleClass = "page-title small";
			}
		},
	}
};
</script>
