<template>
	<div class="page">
		<div class="row">
			<div class="col-md-12">
				<h3>Classes</h3>
				<div class="clear-fix mb-5">
					<span class="float-left">
						<p style="padding-left: 10px;">
							All Created Classes
						</p>
					</span>
					<span class="float-right">
						<button @click="createClassroom"
								type="button"
								class="btn btn-primary"><i class="fas fa-plus"/> New</button>
					</span>
				</div>
				<div v-if="classData && classData.data.length > 0" class="table-responsive">
					<table class="table table-hover">
						<thead class="thead-light">
						<tr>
							<th>Name</th>
							<th>Code</th>
							<th>Admin</th>
							<th>Group</th>
							<th>Students</th>
							<th>Lesson Assignments</th>
							<th>Playlist Assignments</th>
							<th>Date Created</th>
							<th/>
						</tr>
						</thead>
						<tbody>
						<tr v-for="(c, index) in classData.data" :key="index">
							<td>{{ c.name }}</td>
							<td>{{ c.code }}</td>
							<td>{{ `${c.admin.first_name} ${c.admin.last_name}` }}</td>
							<td>{{ c.group != null ? c.group.name : "No Group" }}</td>
							<td>{{ c.users.length > 1 ? c.users.length - 1 : c.users.length }}</td>
							<td>{{ c.lessons.length }}</td>
							<td>{{ c.playlists.length }}</td>
							<td>{{ c["created_at"] | date }}</td>
							<td>
								<router-link :to="`/teacher/classes/${c.id}`">
									View
								</router-link>
								<br>
								<clipboard-link :copy="prepareShareLink(c.code)" message="Invite Link"/>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
				<empty v-else/>
				<!-- pagination -->
				<div class="clearfix">
					<span class="float-right">
						<pagination v-if="classData"
									:links="classData.links"
									:meta="classData.meta"
									@first="(url) => updateClassTable(url)"
									@prev="(url) => updateClassTable(url)"
									@last="(url) => updateClassTable(url)"
									@next="(url) => updateClassTable(url)"/>
					</span>
					<span class="float-left"/>
				</div>
			</div>
		</div>
		<create-classroom/>
	</div>
</template>

<script>
import pagination from "../../components/utility/pagination.vue";
import empty from "../../components/utility/empty.vue";
import clipboardLink from "../../components/utility/clipboard-link.vue";
import createClassroom from "../../components/classroom/create-classroom";
import EventBus from "../../utility/eventbus";
import {EventActionEnum, EventLabelEnum} from "../../config/enums";

export default {
	components: {
		pagination,
		empty,
		clipboardLink,
		createClassroom,
	},
	data() {
		return {
			classData: null,
			loading: true,
		};
	},
	mounted() {
		EventBus.$on("CLASSROOM-CREATED", () => {
			this.fetchClasses();
		});
		this.fetchClasses();
	},
	updated() {
		this.classData == null ? this.fetchClasses() : null;
	},
	computed: {
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},
	methods: {
		async fetchClasses() {
			try {
				const response = await axios.get("/api/teacher/classrooms");
				this.loading = false;
				this.classData = response;
				// log with GA
				this.$gtag.event(EventActionEnum.View, {
					"event_category": this.userEventCategory,
					"event_label": EventLabelEnum.Classrooms,
					"value": "All classrooms"
				});
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch classes, please try again later");
			}
		},
		async updateClassTable(url) {
			try {
				this.loading = true;
				const response = await axios.get(url);
				this.loading = false;
				this.classData = response;
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch classes, please try again later");
			}
		},
		prepareShareLink(code) {
			return `${window.location.protocol}//${window.location.host}/join-class/${code}`;
		},
		createClassroom() {
			EventBus.$emit("CREATE-CLASSROOM");
		}
	}
};
</script>

<style lang="scss" scoped>
h3 {
	padding-left: 10px;
}

.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}
</style>
<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}
</style>

