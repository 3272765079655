const state = {
	page_title: "",
	page_background: "/images/default-background.png",
	guestShowPicture: true
};

const mutations = {
	setConfig(state, config) {
		for (let key of Object.keys(config)) {
			state[key] = config[key];
		}
	},
};

const actions = {
	setConfig({ commit }, data) {
		commit("setConfig", data);
	},
};

const getters = {
	getConfig: state => {
		return state;
	},
	getConfigVar: state => name => {
		return state[name];
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
