<template>
	<div id="admin-wrapper"
		class="wrapper">
		<div class="sidenav">
			<router-link to="/teacher/overview">
				Overview
			</router-link>
			<router-link to="/teacher/assignments">
				Assignments
			</router-link>
			<router-link to="/teacher/classes">
				Classes
			</router-link>
			<router-link to="/teacher/students">
				Students
			</router-link>
			<router-link to="/teacher/custom/playlists">
				Custom Playlists
			</router-link>
			<router-link to="/teacher/custom-lessons">
				Custom Lessons
			</router-link>
		</div>

		<!-- Page content -->
		<div class="main">
			<div v-if="showBackButton" class="clearfix">
				<div class="float-left">
					<button class="btn btn-sm btn-primary" @click="$router.go(-1);">
						<i class="fas fa-arrow-left" /> Back
					</button>
				</div>
				<div class="float-right" />
			</div>
			<br>
			<router-view />
		</div>
	</div>
</template>

<script>
import {ability} from "../config/ability";

export default {
	beforeRouteEnter(to, from, next) {
		if (ability.can("view", "teacher-dashboard")) {
			return next();
		}
		return next({path: "/403"});
	},
	data() {
		return {
			showBackButton: false,
		};
	},
	mounted() {
		this.toggleBackButton();
	},
	updated() {
		this.toggleBackButton();
	},
	methods: {
		toggleBackButton() {
			const baseRoutes = [
				"/teacher/overview",
				"/teacher/assignments",
				"/teacher/classes",
				"/teacher/custom/playlists",
				"/teacher/students",
				"/teacher/custom-lessons"
			];
			this.showBackButton = !baseRoutes.includes(this.$route.path);
		},
	}
};
</script>

<style lang="scss" scoped>
.sidenav {
	/* Set the width of the sidebar */
	position: fixed;

	/* Stay on top */
	top: 0;

	/* Stay at the top */
	left: 0;

	/* Fixed Sidebar (stay in place on scroll) */
	z-index: 1;

	/* Full-height: remove this if you want "auto" height */
	width: 200px;
	height: 100%;

	/* Disable horizontal scroll */
	padding-top: 20px;
	margin-top: 130px;

	/* Black */
	overflow-x: hidden;
	background-color: #2bb5dd;
}

/* The navigation menu links */
.sidenav a {
	display: block;
	padding: 6px 8px 6px 16px;
	font-size: 20px;
	color: #f5f5f5;
	text-decoration: none;
}

.sidenav a.router-link-exact-active {
	color: #2bb5dd;
	background-color: white;
}

.sidenav a.active {
	color: #2bb5dd;
	background-color: white;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
	color: #2bb5dd;
	background-color: white;
}

/* Style page content */
.main {
	padding-left: 200px;

	/* Same as the width of the sidebar */
	// padding: 0px 10px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
	.sidenav {
		padding-top: 15px;
	}

	.sidenav a {
		font-size: 18px;
	}
}

.page-wrapper .container-fluid {
	padding-top: 0 !important;
}
</style>
