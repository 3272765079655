<template>
	<div class="page">
		<div class="row">
			<div class="col-md-12">
				<div class="clearfix">
					<h3>
						<span class="float-left">
							Edit Classroom
						</span>
					</h3>
					<span class="float-right"/>
				</div>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-md-12">
				<form>
					<div class="form-group">
						<label for="name">Name:</label>
						<input type="text"
							   class="form-control"
							   placeholder="Enter Classroom Name"
							   v-model="classroomData.name"
							   id="name">
					</div>
					<div class="form-group">
						<label for="type">Type:</label>
						<input type="text"
							   class="form-control"
							   placeholder="Enter Classroom type"
							   v-model="classroomData.type"
							   id="type">
					</div>
					<div class="form-group">
						<label for="code">Code:</label>
						<input type="text"
							   class="form-control"
							   placeholder="Enter Classroom code"
							   v-model="classroomData.code"
							   id="code">
					</div>
					<div class="form-group">
						<label>Select Group:</label>
						<group-multiselect @updatedData="setParentGroup"/>
					</div>
					<div class="form-group" v-if="user_type === 'group-admin'">
						<label>Select Teacher:</label>
						<user-multiselect @updatedData="setAdmin"/>
					</div>
					<div class="form-group">
						<div class="btn-group">
							<button type="button"
									:disabled="loading"
									@click="updateClass"
									class="btn btn-success">
								Save
							</button>
							<button v-if="changed === true"
									type="button"
									:disabled="loading"
									@click="discardChanges"
									class="btn btn-warning">
								Discard
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>


<script>
import groupMultiselect from "../../components/forms/groups-multiselect.vue";
import userMultiselect from "../../components/forms/users-multiselect.vue";
import EventBus from "../../utility/eventbus";

export default {
	components: {
		groupMultiselect,
		userMultiselect,
	},
	name: "EditClassroom",
	data() {
		return {
			classroomData: {
				name: "",
				type: "",
				group_id: "",
				code: "",
			},
			classroom: null,
			changed: false,
			classroom_id: this.$route.params.id,
			loading: false,
		};
	},
	async mounted() {
		await this.fetchClass();
		EventBus.$emit("SET-INITIAL-GROUP", this.classroomData.group);
		EventBus.$emit("SET-INITIAL-USER", this.classroomData.admin);
	},
	watch: {
		classroomData: {
			handler(val) {
				this.changed = (JSON.stringify(this.classroomData) !== JSON.stringify(this.classroom));
			},
			deep: true,
		}
	},
	computed: {
		user_type() {
			return this.$store.getters["auth/getUserType"];
		}
	},
	methods: {
		async fetchClass() {
			try {
				this.loading = true;
				const response = await axios.get(`/api/classroom/${this.classroom_id}`);
				this.loading = false;
				this.classroomData = response.data;
				this.classroom = response.data;
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch class data, please try again later");
			}
		},
		validateData() {
			const {name, type, code, group_id} = this.classroomData;

			if (name === "") {
				toastr["error"]("Please input a name for your classroom");
				return false;
			}

			if (code === "") {
				toastr["error"]("Please input a code for your classroom");
				return false;
			}

			if (type === "") {
				toastr["error"]("Please input a type for your classroom");
				return false;
			}

			if (group_id === "") {
				toastr["error"]("Please input a group for your classroom");
				return false;
			}

			return true;
		},

		discardChanges() {
			this.classroomData = this.classroom;
		},

		setParentGroup(data) {
			if (data != null) {
				this.classroomData.group_id = data.id;
			}
		},

		setAdmin(data) {
			if (data != null) {
				this.classroomData.admin_id = data.id;
			}
		},

		async updateClass() {
			try {
				if (this.validateData()) {
					this.loading = true;
					const response = await axios.put(`/api/classroom/${this.classroom_id}`, this.classroomData);
					this.loading = false;
					toastr["success"]("Classroom Updated");
					this.$router.go();
				}
			} catch (error) {
				this.loading = false;
				toastr["error"]("Unable to update classroom");
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}

#collapseAddNewContent,
#collapseAddNewPlaylist {
	margin-top: 20px;
	margin-bottom: 20px;
}

#addContentButton,
#addPlaylistButton {
	font-size: small;

	&.closeup::before {
		content: "\F057";
	}

	&.expand::before {
		content: "\F055";
	}

	&::before {
		display: inline-block;
		margin-right: 5px;
		font-family: "Font Awesome 5 Free", serif;
		font-style: normal;
		font-weight: 900;
		font-variant: normal;
		color: $white;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
	}
}

.option__block {
	.multiselect__tag {
		height: fit-content;
		margin-right: 0;
	}

	padding: 5px;
	margin: 10px 10px;
	border: 1px solid #ced4da;
	border-radius: 3px;
}

.option__title {
	a {
		color: white;
	}

	i {
		color: gray;

		&:hover {
			color: red;
		}
	}
}

.option__preview {
	white-space: normal;
}
</style>

<style lang="scss" scoped>
.contents_multi .multiselect__content-wrapper,
.contents_multi .multiselect__content {
	width: 650px !important;
	word-wrap: normal;
}
</style>

