<template>
	<div>
		<div class="page">
			<div class="row">
				<div class="col-md-12">
					<div class="clear-fix">
						<span class="float-left">
							<h3>Media</h3>
						</span>
						<span class="float-right" />
					</div>

					<br>
				</div>
			</div>
			<div>
				<media-manager />
			</div>
			<!-- <div v-if="mediaStatistics.imageCount && loading === false"
				class="row">
				<div class="col-md-3"
					style="cursor: pointer;"
					@click="routeToPage('/admin/media/images')">
					<div class="card bg-danger">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ mediaStatistics.imageCount }}</strong> Images
							</h5>
						</div>
					</div>
				</div>
				<div class="col-md-3"
					style="cursor: pointer;"
					@click="routeToPage('/admin/media/videos')">
					<div class="card bg-dark">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ mediaStatistics.videoCount }}</strong> Videos
							</h5>
						</div>
					</div>
				</div>
				<div class="col-md-3"
					style="cursor: pointer;"
					@click="routeToPage('/admin/contents')">
					<div class="card bg-success">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ mediaStatistics.docsCount }}</strong> Docs
							</h5>
						</div>
					</div>
				</div>
				<div class="col-md-3"
					style="cursor: pointer;"
					@click="routeToPage('/admin/playlists')">
					<div class="card bg-info">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ mediaStatistics.otherCount }}</strong> Others
							</h5>
						</div>
					</div>
				</div>
			</div>
			<br>
			<div class="row">
				<div class="col-md-12">
					<div class="card-deck">
						<div v-if="recentMedia && loading === false"
							class="card">
							<div class="card-body">
								<h5 class="card-title">
									Recently uploaded
								</h5>
								<div class="table-responsive">
									<table class="table table-hover">
										<thead class="thead-light">
											<tr>
												<th>Cover</th>
												<th>Hero</th>
												<th>Title</th>
												<th width="70px">
													Author
												</th>
											</tr>
										</thead>

										<tbody>
											<tr
												v-for="(lesson,index) in recentLessons"
												:key="index"
												style="cursor: pointer;"
												@click="viewLesson(lesson.id)">
												<td>
													<img :src="lesson.cover"
														class="img-thumbnail"
														width="100px"
														height="75px">
												</td>
												<td>{{ lesson.hero }}</td>
												<td>{{ lesson.title }}</td>
												<td width="70px">
													{{ `${lesson.author.first_name} ${lesson.author.last_name}` }}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>
<script>
import mediaManager from "../../components/utility/media-manager.vue";

export default {
	components: {
		"media-manager": mediaManager
	},
	data() {
		return {
			mediaStatistics: {},
			recentMedia: null,
			loading: true,
			isSaving: false
		};
	},
	created() {
		// this.fetchMediaCount();
		// this.fetchMediaRecents();
	},
	updated() {
		// this.mediaStatistics === null ? this.fetchMediaCount() : null;
		// this.recentMedia === null ? this.fetchMediaRecents() : null;
	},
	methods: {
		viewMedia(id) {
			return this.$router.push({
				path: `/admin/lessons/${id}`
			});
		},
		fetchMediaCount() {
			return axios
				.get("/api/media/count/all")
				.then(({data}) => {
					this.loading = false;
					this.countStatistics = {...data};
				})
				.catch(error => {
					this.loading = false;
					return toastr["error"]("Could not fetch count statistics");
				});
		},
		fetchMediaRecents() {
			const recentLessonResponse = axios.get(
				"/api/statistics/latest/lesson"
			);
			const recentUserResponse = axios.get("/api/statistics/latest/user");

			Promise.all([recentLessonResponse, recentUserResponse])
				.then(values => {
					const [recentLessonData, recentUserData] = values;
					this.recentLessons = recentLessonData.data;
					this.recentUsers = recentUserData.data;
					this.loading = false;
				})
				.catch(error => {
					this.loading = false;
					return toastr["error"](
						"Could not fetch Recent lessons and users"
					);
				});
		},
		routeToPage(page) {
			return this.$router.push({
				path: page
			});
		}
	}
};
</script>

<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}

.white-text {
	color: white;
}

strong {
	font-size: 2em;
}
</style>

