<template>
	<multiselect
		id="users"
		:searchable="true"
		:multiple="false"
		:taggable="false"
		:loading="loading"
		v-model="users"
		:internal-search="false"
		:options="options"
		:options-limit="10"
		track-by="id"
		name="users"
		placeholder="Search for Admin"
		@search-change="searchUsersAsync">
		<template #tag="{ option }">
			<div class="option__block row">
				<div class="ml-3">
					{{ option.first_name }} {{ option.last_name }}
				</div>
			</div>
		</template>
		<template #singleLabel="{ option }">
			<div class="option__block row">
				<div class="ml-3">
					{{ option.first_name }} {{ option.last_name }}
				</div>
			</div>
		</template>
		<template #option="{ option }">
			<div class="option__block row">
				<div class="ml-3">
					{{ option.first_name }} {{ option.last_name }}
				</div>
			</div>
		</template>
	</multiselect>
</template>

<script>
import multiselect from "vue-multiselect";
import axiosDebounce from "../../utility/debounce";
import EventBus from "../../utility/eventbus";

export default {
	components: {
		multiselect,
	},
	data() {
		return {
			loading: false,
			users: [],
			options: [],
			searchTimer: null,
		};
	},
	watch: {
		users: {
			handler(val) {
				this.$emit("updatedData", this.users);
			},
			deep: true
		}
	},
	mounted() {
		EventBus.$on("SET-INITIAL-USER", (data) => {
			if (data != null) {
				this.users = [data];
				this.options = [data];
				this.$forceUpdate();
			}
		});
	},
	methods: {
		searchUsersAsync(query) {
			if (this.searchTimer) {
				clearTimeout(this.searchTimer);
			}
			this.searchTimer = setTimeout(this.searchUsers(query), 500);
		},
		async searchUsers(query) {
			try {
				if (query) {
					this.loading = true;
					const response = await axiosDebounce({
						method: "get",
						url: `/api/search/user/${query}`,
						timeout: 60000
					});
					this.options = response.data;
					this.loading = false;
				}
			} catch (error) {
				this.loading = false;
				return null;
			}
		}
	},
};
</script>
