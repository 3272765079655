<template>
	<div>
		<!-- Notice for accounts that have lost access -->
		<div
			v-if="['expired', 'incomplete', 'incomplete_expired', 'past_due', 'unpaid'].includes(subscription.status)"
			class="alert alert-danger"
			role="alert">
			<b>You do not have an active subscription!</b>
		</div>

		<!-- Subscription Details Table -->
		<table class="table table-borderless">
			<!-- Show Status of Subscription -->
			<thead v-if="subscription"
				   :class="subscription.status">
			<tr>
				<th>
					<b>Subscription Status:</b>
				</th>
				<th class="capitalize">
					{{ subscription.name }}
				</th>
			</tr>
			</thead>

			<!-- Show full description of current status -->
			<tr>
				<td colspan="2">
					{{ subscription.description }}
				</td>
			</tr>

			<!-- Subscription Details for Subscriptions with access -->
			<tbody v-if="['active', 'trialing', 'canceled'].includes(subscription.status)">
			<tr>
				<td>
					<b>Subscription:</b>
				</td>
				<td>{{ subscription.plan_name }}</td>
			</tr>
			<tr>
				<td>
					<b>Started:</b>
				</td>
				<td>{{ subscription.started }}</td>
			</tr>
			<tr>
				<!-- Show Subscription's next charge date / end date -->
				<td v-if="subscription.status === 'active'">
					<b>Current Period Ends</b>
				</td>
				<td v-if="subscription.status === 'trialing'">
					<b>Trial Period Ends</b>
				</td>
				<td v-if="subscription.status === 'canceled'">
					<b>Plan Cancels On:</b>
				</td>
				<td>
					{{ subscription.current_period_end }}.
					<!-- Show coupons if they exist -->
					<span v-if="subscription.status === 'active' || subscription.status === 'trialing'">
							<br>
							<span v-if="subscription.discount">
								You have applied a coupon
								"{{ subscription.discount.name }}"
							</span>
							<br>
							Your card will be charged ${{ next_charge }} automatically.
						</span>
				</td>
			</tr>
			<tr>
				<td>
					<b>Card on File:</b>
				</td>
				<td>
					{{ subscription.card_brand }}:
					**** **** **** {{ subscription.card_last_four }}
				</td>
			</tr>
			</tbody>

			<!-- Subscription Details for Generic Trials -->
			<tbody v-if="subscription.status === 'trial_generic'">
			<tr>
				<td>
					<b>Trial Ends:</b>
				</td>
				<td>{{ subscription.trial_ends }}</td>
			</tr>
			</tbody>

			<!-- Show payment field for non paying customers and card updates -->
			<tr>
				<td colspan="2">
					<div
						v-if="(['trial_generic', 'expired', 'incomplete', 'incomplete_expired', 'past_due', 'unpaid']
							.includes(subscription.status) || changingCard ) && !closingAccount"
						class="form-group">
						<div v-if="!changingCard">
							<div>
								<b style="vertical-align: top;">Please select a plan:</b>

								<label class="switch">
									<input id="togBtn"
										   v-model="period_toggle"
										   type="checkbox">
									<div v-if="plans"
										 class="slider round">
										<span class="off">
											<b>${{ plans[2].price.toFixed(2) }} {{ plans[2].pricing_period }}</b>
										</span>
										<span class="on">
											<b>${{ plans[3].price.toFixed(2) }} {{ plans[3].pricing_period }}</b>
										</span>
									</div>
								</label>

								<a href="mailto:hello@weirdenoughproductions.com"
								   target="_blank">
									<button id="bulk-button"
											class="btn btn-primary"
											type="submit"
											block>
										<b>Bulk Plans</b>
									</button>
								</a>
							</div>

							<!-- Payment note describing payment to be made -->
							<p v-if="individual_plan">
								You are signing up for the
								<b>{{ individual_plan.name }}</b> plan. Your card will be charged
								<b>${{ individual_plan.price.toFixed(2) }}</b> and you will gain full access the
								platform content.
							</p>
						</div>

						<div>
							<label v-if="changingCard"
								   for="payment">
								<b>Please enter your new card details</b>
							</label>
							<label v-else
								   for="payment">
								<b>Please enter your card details:</b>
							</label>
							<card
								id="payment"
								:class="{ complete }"
								:stripe="stripe_key"
								:options="stripeOptions"
								class="stripe-card form-control"
								@change="complete = $event.complete"/>
						</div>

						<br>

						<div v-if="enteringCoupon && !changingCard"
							 class="form-group">
							<input
								id="coupon"
								v-model="coupon"
								type="text"
								placeholder="Coupon Code"
								class="form-control text-center"
								title="Confirm Text">
						</div>

						<br>

						<p v-if="!enteringCoupon && !changingCard">
							Got a coupon code?
							<a href="#"
							   @click="enterCoupon">
								<b class="text-info m-l-5">Enter it</b>
							</a>
						</p>
					</div>
				</td>
			</tr>
		</table>

		<div v-if="closingAccount"
			 class="alert alert-danger text-center"
			 role="alert">
			YOU ARE ABOUT TO CLOSE YOUR ACCOUNT. YOU WILL LOSE ALL YOUR DATA AND ACCESS TO ALL CONTENT ON THE
			PLATFORM.
			<br>TYPE
			<b>"DELETE MY ACCOUNT"</b>
			<br>AND CLICK DELETE ACCOUNT TO CONFIRM!
			<br>
			<br>
			<input
				id="confirm_text1"
				v-model="confrim_txt"
				name="confirm_text"
				class="form-control text-center"
				type="text"
				title="Confirm Text">
		</div>

		<div v-if="cancelingSubscription"
			 class="alert alert-danger text-center"
			 role="alert">
			YOU ARE ABOUT TO CANCEL YOUR SUBSCRIPTION. YOU WIL LOSE ALL ACCESS TO ALL CONTENT ON THE
			PLATFORM AFTER THE PERIOD END DATE.
			<br>TYPE
			<b>"CANCEL MY SUBSCRIPTION"</b>
			<br>AND CLICK CANCEL
			SUBSCRIPTION TO
			CONFIRM!
			<br>
			<br>
			<input
				id="confirm_text2"
				v-model="confrim_txt"
				name="confirm_text"
				class="form-control text-center"
				type="text"
				title="Confirm Text">
		</div>

		<!-- Buttons -->
		<div class="clearfix">
			<!-- Card Change -->
			<span v-if="changingCard && !closingAccount">
				<button :disabled="!complete"
						type="button"
						class="btn btn-success"
						@click="submitCardChange">
					<i v-if="isLoading.submitCardChange"
					   class="fas fa-circle-notch fa-spin"/>
					Submit
				</button>
				<button type="button"
						class="btn btn-secondary"
						@click="changeCard">Cancel</button>
			</span>

			<!-- Active Subscription -->
			<span v-if="['active', 'trialing'].includes(subscription.status)">
				<button
					v-if="!changingCard && !cancelingSubscription"
					type="button"
					class="btn btn-primary"
					@click="changeCard">Change Card</button>

				<span v-if="cancelingSubscription">
					<button
						type="button"
						class="btn btn-success"
						@click="cancelSubscriptionConfirm">Keep my Subscription</button>
					<button
						:disabled="!confirmed"
						type="button"
						class="btn btn-danger"
						@click="submitCancelSubscription">
						<i v-if="isLoading.submitCancelSubscription"
						   class="fas fa-circle-notch fa-spin"/>
						Cancel Subscription
					</button>
				</span>

				<button
					v-if="!cancelingSubscription && !changingCard"
					type="button"
					class="btn btn-secondary"
					@click="cancelSubscriptionConfirm">Cancel Subscription</button>
			</span>

			<!-- Inactive Subscription -->
			<span v-if="['trial_generic', 'expired'].includes(subscription.status) && !closingAccount">
				<button :disabled="!complete"
						type="button"
						class="btn btn-success"
						@click="subscribe">
					<i v-if="isLoading.subscribe"
					   class="fas fa-circle-notch fa-spin"/>
					Submit
				</button>
			</span>

			<!-- Canceled Subscription -->
			<span v-if="subscription.status === 'canceled' && !closingAccount">
				<button type="button"
						class="btn btn-success"
						@click="resumeSubscription">
					<i v-if="isLoading.resumeSubscription"
					   class="fas fa-circle-notch fa-spin"/>
					Resume Subscription
				</button>
			</span>

			<!-- Close Account -->
			<span
				v-if="( subscription.status !== 'active' && subscription.status !== 'trialing' ) &&
					!closingAccount">
				<button type="button"
						class="btn btn-danger"
						@click="closeAccountConfirm">Close my Account</button>
			</span>

			<!-- Account Close -->
			<span v-if="closingAccount">
				<button
					type="button"
					class="btn btn-primary btn-lg btn-block"
					@click="closeAccountConfirm">No I want to Keep My Account</button>
				<br>
				<button
					:disabled="!confirmed"
					type="button"
					class="btn btn-danger btn-lg"
					@click="submitCloseAccount">
					<i v-if="isLoading.submitCloseAccount"
					   class="fas fa-circle-notch fa-spin"/>
					Delete Account
				</button>
			</span>
		</div>
	</div>
</template>

<script>
import helper from "../../utility";
import {Card} from "vue-stripe-elements-plus";
import {mapActions} from "vuex";

export default {
	name: "Subscription",

	components: {
		Card
	},

	props: {
		user: {
			type: [Object, String],
			default: null
		},
		admin: {
			type: [Boolean],
			default: false
		}
	},

	data() {
		return {
			stripe_key: window.stripe_key,
			plan_id: 4,
			plans: null,
			plan: null,
			stripeOptions: {
				// see https://stripe.com/docs/stripe.js#element-options for details
			},
			complete: false,
			confrim_txt: null,
			coupon: null,
			enteringCoupon: false,
			changingCard: false,
			cancelingSubscription: false,
			closingAccount: false,
			isLoading: {
				submitCardChange: false,
				submitCancelSubscription: false,
				subscribe: false,
				resumeSubscription: false,
				submitCloseAccount: false
			},
			period_toggle: false
		};
	},

	computed: {
		subscription() {
			return this.user.subscription;
		},

		next_charge() {
			if (this.subscription.discount) {
				if (this.subscription.discount.amount_off) {
					return (
						this.subscription.amount -
						this.subscription.discount.amount_off
					).toFixed(2);
				} else {
					return (
						this.subscription.amount *
						((100 - this.subscription.discount.percent) / 100)
					).toFixed(2);
				}
			} else {
				return this.subscription.amount.toFixed(2);
			}
		},

		confirmed() {
			return (
				this.confrim_txt === "CANCEL MY SUBSCRIPTION" ||
				this.confrim_txt === "DELETE MY ACCOUNT"
			);
		},

		individual_plan() {
			if (this.plans) {
				if (this.period_toggle) {
					return this.plans[3];
				} else {
					return this.plans[2];
				}
			}
			return null;
		}
	},

	mounted() {
		this.getPlans();
	},

	methods: {
		...mapActions({
			resetAuthUserDetail: "auth/resetAuthUserDetail"
		}),

		getPlans() {
			axios
				.get("/api/plan")
				.then(({data}) => {
					this.plans = data;
				})
				.catch(({message}) => {
					toastr["error"](
						message,
						"Could not load plans:"
					);
				});
		},

		enterCoupon() {
			this.enteringCoupon = true;
		},

		changeCard() {
			if (this.changingCard) this.changingCard = false;
			else {
				this.changingCard = true;
			}
		},

		cancelSubscriptionConfirm() {
			if (this.cancelingSubscription) this.cancelingSubscription = false;
			else {
				this.cancelingSubscription = true;
			}
		},

		closeAccountConfirm() {
			if (this.closingAccount) this.closingAccount = false;
			else {
				this.closingAccount = true;
			}
		},

		submitCardChange() {
			this.isLoading.submitCardChange = true;
			createToken().then(data => {
				axios
					.post(`/api/user/${this.user.id}/card-change`, {
						payment_token: data.token.id
					})
					.then(({data, message}) => {
						toastr["success"](message);
						if (this.$props.admin === false) {
							this.$emit("refresh-auth", data);
						}
						this.isLoading.submitCardChange = false;
						this.$router.go();
					})
					.catch(({message}) => {
						toastr["error"](message);
						this.isLoading.submitCardChange = false;
					});
			});
		},

		subscribe() {
			this.isLoading.subscribe = true;
			createToken().then(data => {
				axios
					.post(`/api/user/${this.user.id}/subscribe`, {
						plan_id: this.individual_plan.id,
						coupon: this.coupon,
						payment_token: data.token.id
					})
					.then(({message}) => {
						toastr["success"](message);
						this.isLoading.subscribe = false;
						this.$router.go();
					})
					.catch(({message}) => {
						toastr["error"](message);
						this.isLoading.subscribe = false;
					});
			});
		},

		resumeSubscription() {
			this.isLoading.resumeSubscription = true;
			axios
				.get(`/api/user/${this.user.id}/resume-subscription`)
				.then(({message}) => {
					toastr["success"](message);
					this.isLoading.resumeSubscription = false;
					this.$router.go();
				})
				.catch(({message}) => {
					toastr["error"](message);
					this.isLoading.resumeSubscription = false;
				});
		},

		submitCancelSubscription() {
			this.isLoading.submitCancelSubscription = true;
			axios
				.get(`/api/user/${this.user.id}/cancel-subscription`)
				.then(({message}) => {
					toastr["success"](message);
					this.isLoading.submitCancelSubscription = false;
					this.$router.go();
				})
				.catch(({message}) => {
					toastr["error"](message);
					this.isLoading.submitCancelSubscription = false;
				});
		},

		submitCloseAccount() {
			this.isLoading.submitCloseAccount = true;
			axios
				.get(`/api/user/${this.user.id}/close-account`)
				.then(response => {
					if (this.$props.admin === false) {
						helper
							.logout()
							.then(() => {
								this.resetAuthUserDetail();
								localStorage.clear();
								sessionStorage.clear();
								this.isLoading.submitCloseAccount = false;
								this.$router.replace("/login");
							})
							.catch(() => {
								this.resetAuthUserDetail();
								localStorage.clear();
								sessionStorage.clear();
								this.isLoading.submitCloseAccount = false;
								this.$router.replace("/login");
							});
					} else {
						this.isLoading.submitCloseAccount = false;
						toastr["success"]("User account closed");
						return this.$router.replace({
							path: "/admin/users"
						});
					}
				})
				.catch(({message}) => {
					toastr["error"](message);
					this.isLoading.submitCloseAccount = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.alert {
	width: 100%;
}

.capitalize {
	text-transform: capitalize;
}

.badges {
	&::after {
		content: ", ";
	}

	&:last-of-type {
		&::after {
			content: "";
		}
	}
}

.stripe-card.complete {
	border-color: green;
}

.switch {
	position: relative;
	display: inline-block;
	width: 170px;
	height: 34px;
	margin-left: 25px;
}

.switch input {
	display: none;
}

.slider {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	cursor: pointer;
	background-color: #ca2222;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider::before {
	position: absolute;
	bottom: 4px;
	left: 4px;
	width: 26px;
	height: 26px;
	content: "";
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #2ab934;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider::before {
	-webkit-transform: translateX(135px);
	-ms-transform: translateX(135px);
	transform: translateX(135px);
}

.on {
	display: none;
}

.on,
.off {
	position: absolute;
	top: 50%;
	left: 40%;
	font-size: 13px;
	color: white;
	transform: translate(-30%, -50%);
}

input:checked + .slider .on {
	display: block;
}

input:checked + .slider .off {
	display: none;
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round::before {
	border-radius: 50%;
}

#bulk-button {
	width: 170px;
	vertical-align: top;
	border-radius: 150px;
}
</style>
