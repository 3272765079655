import helper from "../../utility";
import {LessonTypeEnum} from "../../config/enums";

const lessonTypeEnum = LessonTypeEnum;


const state = {
	endpoint: "",
	id: "",
	title: "",
	cover: "",
	author: "",
	focus: "",
	hero: "",
	type: "",
	type_icon: "",
	classification: "",
	standards: "",
	overview: "",
	content: "",
	playlists: "",
	links: "",
	tags: "",
};

const mutations = {
	setLesson: (state, lesson) => {
		for (let key of Object.keys(lesson)) {
			state[key] = lesson[key];
		}
		setLessonTypeIcon();
	},
};

const actions = {
	setLesson: ({commit}, lesson) => {
		commit("setLesson", lesson);
	},

	fetchLesson: async ({commit}, payload) => {

		let fetch_lesson_endpoint =
			"/api/lesson/" + payload.lesson_id;

		if (+payload.playlist_id) {
			fetch_lesson_endpoint =
				"/api/playlist/" + payload.playlist_id + "/" + payload.lesson_sequence;
		}

		return axios
			.get(fetch_lesson_endpoint)
			.then(({data}) => {
				// Split the content lines as well
				if (data.content) {
					let index = 0;
					for (let c of data.content) {
						data.content[index++].text = helper.splitText(c.text);
					}
				}

				commit("setLesson", {
					id: data.id,
					title: data.title,
					cover: data.cover,
					hero: data.hero,
					type: data.hero,
					classification: data.hero,
					author: data.author,
					focus: data.focus,
					standards: data.standards,
					overview: helper.splitText(data.overview),
					content: data.content,
					playlists: data.playlists,
					links: data.links,
					tags: data.tags
				});

				if (+payload.playlist_id) {
					commit("setLesson", {lesson_sequence: payload.lesson_sequence});
				}

			})
			.catch(error => {
				toastr.error("Error fetching lesson");
			});
	},
};

const getters = {
	getLesson: state => {
		return state;
	},
};

function setLessonTypeIcon() {
	switch (state.type) {
		case lessonTypeEnum.COMIC:
			state.type_icon = "fa-book-reader";
			break;
		case lessonTypeEnum.VIDEO:
			state.type_icon = "fa-video";
			break;
		case lessonTypeEnum.ARTICLE:
			state.type_icon = "fa-file-alt";
			break;
		case lessonTypeEnum.ACTIVITY:
			state.type_icon = "fa-paste";
			break;
		default:
			state.type_icon = "";
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
