<template>
	<div id="page"
		class="row align-middle">
		<div id="image"
			class="col-8">
			<img src="/images/404.png">
		</div>
		<div id="text"
			class="col-4">
			<h1 id="fade">
				404
			</h1>
			<h1>Oops.. it's gone!</h1>
			<p>It looks the page you're trying to reach does not exist...</p>
			<router-link to="/">
				<button class="btn btn-md btn-success"
					@click="backToHome">
					Return Home
				</button>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: "Error404",
	methods: {
		backToHome() {
			return this.$router.replace("/home");
		}
	}
};
</script>

<style lang="scss" scoped>
#page {
	height: 100vh;
	padding-top: 80px;

	#image {
		text-align: right;

		img {
			height: 80vh;
		}
	}

	#text {
		height: 80vh;
		padding-top: 50px;
		text-align: left;

		#fade {
			margin-bottom: -150px;
			font-family: sans-serif;
			font-size: 270px;
			color: rgba(125, 125, 125, 0.05);
		}

		h1 {
			font-size: 40px;
		}
	}
}
</style>
