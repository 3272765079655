// noinspection JSValidateTypes

import Vue from "vue";
// Services
import "animate.css";

//Vue Router
import VueRouter from "vue-router";
//CASL
import { abilitiesPlugin } from "@casl/vue";
import { ability } from "./ability";
//Form
import Form from "../utility/form";
// Quill for RichTextEditors
import VueQuill from "vue-quill";
import "../../sass/quill.scss";
//Resumable
import Resumable from "./resumable/install";
//Loading
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
//BootstrapVue
import BootstrapVue from "bootstrap-vue";
//wysiwyg
import wysiwyg from "vue-wysiwyg";
//VeeValidate
import VeeValidate from "vee-validate";
//Clipboard
import VueClipboard from "vue-clipboard2";
//GTM
import VueGtm from "vue-gtm";
import router from "./routes";
// vue-head
import VueHead from "vue-head";
import VueMeta from "vue-meta";
import Eagle, { Presenter } from "eagle.js";
import VueGtag from "vue-gtag";
import * as Sentry from "@sentry/vue";
import "please-wait/build/please-wait.css";
import "spinkit/spinkit.css";
import NProgress from "vue-nprogress";
import filters from "./vue-filters";

window.Vue = Vue;

// register filters
Object.keys(filters).forEach((key) => Vue.filter(key, filters[key]));

Vue.use(VueRouter);

Vue.use(VueHead);

Vue.use(VueMeta);

Vue.use(VueQuill);

Vue.use(abilitiesPlugin, ability);

window.Form = Form;

Vue.use(VueQuill);

Vue.use(Resumable);

Vue.use(Loading, {
	canCancel: false, // default false
	color: "#084A99",
	loader: "bars",
	width: 50,
	height: 50,
	backgroundColor: "#f7f8fa",
	opacity: 1,
	container: null,
});

Vue.use(BootstrapVue);

Vue.use(wysiwyg, { hideModules: { code: true } });

Vue.use(VeeValidate);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

if (window.GTM_ID) {
	Vue.use(VueGtm, {
		id: window.GTM_ID, // Your GTM ID
		enabled: process.env.MIX_NODE_ENV === "production", // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
		loadScript: true,
		debug: process.env.MIX_NODE_ENV !== "production", // Whether or not display console logs debugs (optional)
		vueRouter: router, // Pass the router instance to automatically sync with router (optional)
	});
}

//Toastr
window.toastr = require("toastr");
toastr.options = {
	positionClass: "toast-top-right",
	closeButton: true,
	closeDuration: 100,
	preventDuplicates: true,
	progressBar: true,
	timeOut: 2500,
};

//Lodash
window._ = require("lodash");

//Popper
window.Popper = require("popper.js").default;

//Bootstrap & Jquery
try {
	window.$ = window.jQuery = require("jquery");
	require("bootstrap");
} catch (e) {
	// eslint-disable-next-line no-console
	console.error("Error in requiring jquery");
}

// register sentry
Sentry.init({
	Vue,
	dsn: process.env.MIX_SENTRY_DSN,
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
	],
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

// setup axios here
import("./axios");

// register the presenter mode
Eagle.use(Presenter);
Vue.use(Eagle);

// register the progress bar
Vue.use(NProgress, {
	latencyThreshold: 200, // Number of ms before progressbar starts showing, default: 100,
	router: true, // Show progressbar when navigating routes, default: true
	http: true, // Show progressbar when doing Vue.http, default: true
	showSpinner: false,
});

// register vuegtag
Vue.use(
	VueGtag,
	{
		config: {
			id: process.env.MIX_GA_ID,
			appName: `GML-${process.env.MIX_NODE_ENV}`,
			pageTrackerScreenviewEnabled: true,
		},
	},
	router
);
