import {UserTypeEnum} from "./enums";

// Format:  action - entity in plural
// e.g read - lessons

// allowed values for actions = view,read,update,delete,create

const rules = {
	[UserTypeEnum.SUPER_ADMIN]: [
		["view", ["admin", "presenter", "home", "all-lessons", "all-playlists"]],
		[
			["create", "read", "update", "delete"],
			[
				"users",
				"roles",
				"lessons",
				"playlists",
				"content",
				"subscriptions",
				"lesson-notes",
				"groups",
				"authorizations",
				"uploads",
				"plans",
				"announcements"
			],
		],
	],
	[UserTypeEnum.ADMIN]: [
		["view", ["admin", "presenter", "home", "all-lessons", "all-playlists"]],
		[
			["create", "read", "update", "delete"],
			[
				"users",
				"roles",
				"lessons",
				"playlists",
				"content",
				"subscriptions",
				"lesson-notes",
				"groups",
				"authorizations",
				"uploads",
				"plans",
				"announcements"
			],
		],
	],
	[UserTypeEnum.GROUP_ADMIN]: [
		["view", ["presenter", "group-dashboard", "home", "all-lessons", "all-playlists"]],
		[
			["create", "read", "update", "delete"],
			["lessons", "playlists", "content", "lesson-notes", "group-announcements"]
		]
	],
	[UserTypeEnum.EDITOR]: [
		["view", ["presenter", "editor-dashboard", "home", "all-lessons", "all-playlists"]],
		[
			["create", "read", "update", "delete"],
			["lessons", "playlists", "content", "lesson-notes"]
		]
	],
	[UserTypeEnum.STUDENT]: [
		["view", ["class", "student-dashboard", "student", "home"]],
		["read", ["assignments", "lessons", "playlists", "content"]],
		["update", ["assignments", "subscriptions"]],
	],
	[UserTypeEnum.TEACHER]: [
		["view", ["class", "teacher-dashboard", "home", "presenter", "all-lessons", "all-playlists"]],
		["read", ["assignments", "lessons", "playlists", "content"]],
		["update", ["assignments", "subscriptions"]],
	],
	[UserTypeEnum.GUEST]: [],
};

export default rules;
