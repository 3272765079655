<template>
	<div>
		<div class="row">
			<div class="col-md-12 col-sm-12">
				<b-card no-body>
					<b-tabs v-model="tabIndex" card>
						<!-- upload tab -->
						<b-tab title="UPLOAD">
							<b-card-text>
								<div class="row">
									<!-- upload section -->
									<div
										v-if="uploading !== true"
										:class="{
											'col-md-6 col-sm-12': fileList.length > 0 && uploading === false,
											'hidden-md hidden-sm hidden-lg': fileList.length > 0 && uploading === true,
											'col-md-12 col-sm-12': fileList.length <= 0 && uploading === false
										}">
										<div
											id="fileForm"
											ref="fileform"
											:class="{'dragged': dragEnter, 'notdragged': !dragEnter, 'd-flex flex-column justify-content-center text-center':true}"
											style="min-height: 400px;">
											<div id="form"
												 class>
												<div class="align-self-center">
													<p>Drop the files here or click to upload</p>
												</div>
											</div>
										</div>
										<br>
										<button
											v-if="fileList.length > 0"
											:class="{'btn btn-primary form-control': uploading === false, 'btn disabled btn-primary form-control': uploading === true}"
											:disabled="uploading === true"
											@click="uploadFiles">
											<template v-if="uploading">
												<span class="spinner-border spinner-border-sm"/>
												Loading..
											</template>
											<template v-else>
												Upload File(s)
											</template>
										</button>
									</div>

									<!-- preview section -->
									<div
										:class="{
											'col-md-6 col-sm-12': fileList.length > 0 && uploading === false,
											'hidden-md hidden-sm hidden-lg': fileList.length <= 0 && uploading === false,
											'col-md-12 col-sm-12 col-lg-12' : fileList.length > 0 && uploading === true
										}">
										<div v-if="uploading === false" style="height: 400px; overflow-y: scroll;">
											<div v-for="(file,index) in previewFileList"
												 :key="index">
												<div class="display-content"
													 style="height: 100%;">
													<!-- if file is an image -->
													<div v-if="file.type === 'image' ">
														<b-card class="overflow-hidden"
																no-body>
															<div class="row">
																<div class="col-md-12 col-sm-12 col-lg-12">
																	<b-card-body title="File Details">
																		<b-card-text>
																			<p>Name: {{ file.name }}</p>
																			<p>Size: {{ file.size }}</p>
																			<p>
																				Dimensions: {{ file.dimension.width }} x
																				{{ file.dimension.height }}
																			</p>
																			<button class="btn btn-sm btn-primary"
																					@click="deleteImage(index)">
																				<i class="fas fa-trash"/> Delete
																			</button>
																		</b-card-text>
																	</b-card-body>
																</div>
															</div>
														</b-card>
													</div>
												</div>
											</div>
										</div>
										<div v-else>
											<b-card class="overflow-hidden"
													no-body>
												<b-row no-gutters>
													<b-col md="12">
														<b-card-body title="File Upload">
															<b-card-text>
																<div>
																	<p>Upload Status</p>
																	<div class="progress mt-2">
																		<div
																			:style="{width:`${totalBytes !== 0 ? Math.round(uploadedBytes/totalBytes) * 100 : 0}%`}"
																			class="progress-bar progress-bar-striped progress-bar-animated">
																			{{
																				totalBytes !== 0 ? Math.round(uploadedBytes
																					/ totalBytes) * 100 : 0
																			}}%
																		</div>
																	</div>
																	<!-- <span
																		v-if="resumable.status === 'success' "
																		class="badge badge-success">successful</span>
																	<span v-else-if="resumable.status === 'error'"
																		class="badge badge-danger">failed</span>
																	<span v-else
																		class="badge badge-default">
																		<div class="spinner-border spinner-border-sm" />
																	</span>
																	<button
																		v-if="resumable.status !== 'success' && resumable.status !== 'error'"
																		class="btn btn-sm"
																		@click="cancelUpload(resumable)">
																		&times;
																	</button> -->
																</div>
																<br>
																<button v-if="done"
																		class="btn btn-sm btn-primary"
																		@click="reset">
																	<i class="fas fa-check"/> Done
																</button>
															</b-card-text>
														</b-card-body>
													</b-col>
												</b-row>
											</b-card>
										</div>
									</div>
								</div>
							</b-card-text>
						</b-tab>

						<!-- photos tab -->
						<b-tab title="IMAGES">
							<div class="row">
								<div class="col-md-7 col-sm-12">
									<!-- search bar -->
									<div class="input-group mb-3">
										<input ref="searchImagesValue"
											   class="form-control"
											   placeholder="Search"
											   type="text">
										<div class="input-group-append">
											<button class="btn btn-primary"
													@click="searchMedia('images')">
												<i class="fas fa-search"/>
											</button>
										</div>
										<div class="input-group-append">
											<button class="btn btn-info"
													@click="fetchMedia(['images'])">
												<i class="fas fa-sync"/>
											</button>
										</div>
									</div>
								</div>
								<div class="col-md-5 col-sm-12">
									<!-- pagination -->
									<ul v-if="images.pagination"
										class="pagination">
										<li class="page-item">
											<a
												v-if="images.pagination.previous"
												class="page-link"
												@click="updateMediaTable('images',images.pagination.previous)">Previous</a>
										</li>
										<li class="page-item">
											<a
												v-if="images.pagination.first"
												class="page-link"
												@click="updateMediaTable('images',images.pagination.first)">First</a>
										</li>
										<li class="page-item">
											<a
												class="page-link disabled"
												href="#">{{
													`${images.pagination.current_page}/${images.pagination.total_pages}`
												}}</a>
										</li>
										<li class="page-item">
											<a
												v-if="images.pagination.last"
												class="page-link"
												@click="updateMediaTable('images',images.pagination.last)">Last</a>
										</li>
										<li class="page-item">
											<a
												v-if="images.pagination.next"
												class="page-link"
												@click="updateMediaTable('images',images.pagination.next)">Next</a>
										</li>
									</ul>
								</div>
							</div>

							<div class="row">
								<div class="col-md-7">
									<!-- main card -->
									<b-card-text style="height: 400px; overflow-y: scroll;">
										<div
											v-if="images.data[0] && imageTabLoading === false"
											class="d-flex flex-wrap align-content-start rounded"
											style="background-color: #f8f9fa;">
											<div
												v-for="(photo,index) in images.data"
												:key="index"
												:class="{
													rounded:true,
													'selected-card' : photo.url === (selectedMedia !== null ? selectedMedia.url: false)
												}"
												style="margin: 2.5px;"
												@click="selectCard(photo)">
												<div class="img-card">
													<img :src="photo.url"
														 class="image img-thumbnail">
												</div>
											</div>
										</div>
										<div v-else-if="imageTabLoading === true"
											 class="text-center">
											<div class="spinner-border"/>
										</div>
										<div v-else>
											No Images found, try refreshing.
										</div>
									</b-card-text>
								</div>
								<div class="col-md-5">
									<b-card v-if="selectedMedia !== null"
											class="overflow-hidden"
											no-body>
										<b-row no-gutters>
											<b-col md="12">
												<b-card-body title="File Details">
													<b-card-text>
														<b-card-img :src="selectedMedia.url"
																	height="300"
																	width="300"/>
														<p>Name: {{ selectedMedia.file_name }}</p>
														<p>
															Size: {{
																`${(selectedMedia.size / 1024 / 1024).toFixed(2)} MB`
															}}
														</p>
														<p>Created: {{ selectedMedia.created_at.date }}</p>
														<button class="btn btn-sm btn-primary"
																@click="deleteMedia(selectedMedia.id)">
															<i class="fas fa-trash"/> Delete
														</button>
													</b-card-text>
												</b-card-body>
											</b-col>
										</b-row>
									</b-card>
									<b-card v-else>
										Select an image for preview
									</b-card>
								</div>
							</div>

							<div v-if="modalMode && selectedMedia !== null"
								 class="row">
								<hr>
								<div class="col-md-12">
									<div class="clearfix">
										<span class="float-left">
											<button class="btn btn-warning"
													type="button"
													@click="discardSelection">Clear Selection</button>
											<button class="btn btn-success"
													type="button"
													@click="chooseSelection">Select</button>
										</span>
									</div>
								</div>
							</div>
						</b-tab>
					</b-tabs>
				</b-card>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import axiosDebounce from "../../utility/debounce";
import helpers from "../../utility";
import eventbus from "../../utility/eventbus.js";

export default {

	props: {
		modalMode: {
			type: Boolean,
			default: false
		},
		collection: {
			type: String,
			default: "lesson"
		},
		public: {
			type: Boolean,
			default: true
		}
	},

	data() {
		return {
			dragAndDropCapable: false,
			fileList: [],
			previewFileList: [],
			uploading: false,
			serverMessage: null,
			images: {
				data: [],
				pagination: null
			},
			selectedMedia: null,
			imageTabLoading: false,
			response_id: null,
			dragEnter: false,
			done: false,
			totalBytes: 0,
			uploadedBytes: 0,
			tabIndex: 0,
		};
	},

	computed: {
		user() {
			return this.$store.getters["auth/getAuthUser"];
		}
	},

	mounted() {
		if (!this.$resumable.support) {
			alert(
				"Your browser does not support our uploader, please upgrade your browser"
			);
		}
		this.setupResumables();
		this.setupDragandDrop();
		this.fetchMedia(["images"]);
		eventbus.$on("media-manager", this.MediaEventHandler);
	},

	beforeDestroy() {
		eventbus.$off("media-manager");
		this.cleanup();
	},

	methods: {
		async uploadFiles() {
			try {
				this.uploading = true;
				this.totalBytes = 0;
				this.uploadedBytes = 0;
				if (this.fileList !== null && this.fileList.length > 0) {
					//upload file(s)
					let formData = new FormData();
					formData.append("collection", this.$props.collection);
					formData.append("user_id", this.user.id);
					formData.append("type", "image");
					formData.append("is_public", this.$props.public ? "true" : "false");
					this.fileList.forEach((resumable, index) => {
						formData.append(`file${index}`, resumable.file);
					});
					const response = await axios({
						//url: "http://localhost:8085/v1/media/uploads",
						url: `${process.env.MIX_HEAVYLIFTING_URL}/v1/media/uploads`,
						method: "post",
						onUploadProgress: function (progressEvent) {
							this.uploadedBytes = progressEvent.loaded;
							this.totalBytes = progressEvent.total;
						}.bind(this),
						data: formData,
					});
					this.done = true;
					if (response.code === 200) {
						this.tabIndex = 1;
						this.fetchMedia(["images"]);
						toastr.success(response.message);
					} else {
						toastr.error(response.message);
					}
				} else {
					this.uploading = false;
					return toastr.error("You have not selected any files");
				}
			} catch (error) {
				this.done = true;
				return toastr.error("Unable to complete your upload, please try again later");
			}
		},

		cancelUpload(resumable) {
			this.$resumable.removeFile(resumable);
			this.fileList = this.fileList.filter(element => {
				if (resumable.uniqueIdentifier === element.uniqueIdentifier) {
					return false;
				}
			});
		},

		async attachResumableFile(resumable) {
			const {name, size, type} = resumable.file;
			let filesizeMB = (size / (1024 * 1024)).toFixed(2);
			if (filesizeMB > 100) {
				resumable.cancel();
				return toastr.error(
					`File: ${resumable.file.name} is too large (Max 100Mb)`
				);
			}

			switch (type.split("/")[0]) {
				case "image":
					// eslint-disable-next-line
					this.previewFileList.push(
						await this.prepareImageDetails(resumable.file)
					);
					break;

				default:
					break;
			}
			this.fileList.push(resumable);
		},

		// method to hook resumables.js and events to media manager
		setupResumables() {
			try {
				// assign node to browse event
				this.$resumable.assignBrowse(
					document.getElementById("fileForm"),
					false
				);
				//assign node to drop event
				this.$resumable.assignDrop(document.getElementById("fileForm"));

				// when file added
				this.$resumable.on(
					"fileAdded",
					function (file, event) {
						this.attachResumableFile({...file, status: null});
						this.dragEnter = false;
					}.bind(this)
				);
			} catch (error) {
				return null;
			}
		},

		MediaEventHandler(id) {
			return (this.response_id = id);
		},

		chooseSelection() {
			eventbus.$emit("media-manager-complete", {
				...this.selectedMedia,
				response_id: this.response_id
			});
			return (this.response_id = null);
		},

		discardSelection() {
			return (this.selectedMedia = null);
		},

		selectCard(media) {
			this.selectedMedia = media;
		},

		setupDragandDrop() {
			//Determine if drag and drop functionality is capable in the browser
			this.dragAndDropCapable = this.determineDragAndDropCapable();
			// If drag and drop capable, then we continue to bind events to our elements.
			if (this.dragAndDropCapable) {
				if (this.$refs.fileform != null) {
					// add an event for drag enter to the form
					this.$refs.fileform.addEventListener(
						"dragenter",
						function (e) {
							this.dragEnter = true;
						}.bind(this)
					);

					// add an event for drag leave to the form
					this.$refs.fileform.addEventListener(
						"dragleave",
						function (e) {
							e.preventDefault();
							this.dragEnter = false;
							return null;
						}.bind(this)
					);

					// add an event for drag leave to the form
					this.$refs.fileform.addEventListener(
						"dragover",
						function (e) {
							this.dragEnter = true;
						}.bind(this)
					);
				}
			}
		},

		prepareImageDetails(file) {
			return new Promise(
				async function (resolve, reject) {
					const {name, size, type} = file;
					return resolve({
						name,
						size: `${(size / 1024 / 1024).toFixed(2)} MB`,
						type: type.split("/")[0],
						extension: type.split("/")[1],
						data: await this.createFileAsDataUrl(file),
						dimension: await this.getImageDimensions(file)
					});
				}.bind(this)
			);
		},

		determineDragAndDropCapable() {
			let div = document.createElement("div");
			return (
				("draggable" in div ||
					("ondragstart" in div && "ondrop" in div)) &&
				"FormData" in window &&
				"FileReader" in window
			);
		},

		createFileAsDataUrl(file) {
			return new Promise((resolve, reject) => {
				let reader = new FileReader();
				reader.onload = e => {
					return resolve(e.target.result);
				};
				reader.readAsDataURL(file);
				reader.onerror = e => {
					return reject(() => {
						throw `${reader.error}`;
					});
				};
			});
		},

		getImageDimensions(file) {
			return new Promise((resolve, reject) => {
				let url = URL.createObjectURL(file);
				let image = new Image();
				image.onload = () => {
					return resolve({
						height: image.height,
						width: image.width
					});
				};
				image.onerror = e => {
					alert(
						`an error occurred while uploading file:${file.name}`
					);
					return reject(() => {
						throw `${image.error}`;
					});
				};
				image.src = url;
			});
		},

		deleteImage(num) {
			this.previewFileList = this.previewFileList.filter(
				(element, index) => index !== num
			);
			this.fileList = this.fileList.filter((element, index) => {
				if (index !== num) {
					return true;
				} else {
					this.$resumable.files = this.$resumable.files.filter(
						file =>
							element.uniqueIdentifier !== file.uniqueIdentifier
					);
					return false;
				}
			});
		},

		fetchMedia(types) {
			types.forEach(type => {
				switch (type) {
					case "images":
						this.imageTabLoading = true;
						axios
							.get(`/api/media/list?collection=${this.collection}&type=image&visibility=${this.$props.public ? "public" : "private"}`)
							.then(({data, links, meta}) => {
								this.imageTabLoading = false;
								this.images.data = data;
								this.images.pagination = {
									first: links.first,
									last: links.last,
									previous: links.previous,
									next: links.next,
									current_page: meta.current_page,
									total_pages: helpers.paginationTotalPages(
										meta.last_page,
										meta.per_page,
										meta.total
									)
								};
							})
							.catch(error => {
								this.imageTabLoading = false;
								toastr.error(
									`Could not fetch ${type}, Try again`
								);
							});
						break;
					default:
						break;
				}
			});
		},

		updateMediaTable(table, url) {
			switch (table) {
				case "images":
					this.imageTabLoading = true;
					axios
						.get(url)
						.then(({data, meta, links}) => {
							this.images.data = data;
							this.imageTabLoading = false;
							this.images.pagination = {
								first: links.first,
								last: links.last,
								previous: links.previous,
								next: links.next,
								current_page: meta.current_page,
								total_pages: meta.last_page
							};
						})
						.catch(error => {
							toastr.error(
								"An error occurred, while fetching images"
							);
						});
					break;

				default:
					break;
			}
		},

		searchMedia(table) {
			switch (table) {
				case "images":
					this.imageTabLoading = true;
					axiosDebounce({
						method: "get",
						url: `/api/media/search/images?q=${this.$refs.searchImagesValue.value}`,
						timeout: 60000
					})
						.then(({data, meta, links}) => {
							this.lessons = data;
							this.imageTabLoading = false;
							this.pagination = {
								first: links.first,
								last: links.last,
								previous: links.previous,
								next: links.next,
								current_page: meta.current_page,
								total_pages: helpers.paginationTotalPages(
									meta.last_page,
									meta.per_page,
									meta.total
								)
							};
						})
						.catch(error => {
							this.imageTabLoading = false;
							toastr.error(
								"Error occurred while searching for images"
							);
						});
					break;

				default:
					break;
			}
		},

		deleteMedia(id) {
			this.imageTabLoading = true;
			axios
				.delete(`/api/media/delete/${id}`)
				.then(() => {
					this.fetchMedia(["images"]);
					this.modalShow = false;
					this.discardSelection();
					return toastr.success("Deleted");
				})
				.catch(e => {
					return toastr.error("Error occurred while deleting");
				});
		},

		reset() {
			this.cleanup();
			setTimeout(() => {
				this.setupResumables();
				this.setupDragandDrop();
			}, 0);
		},

		cleanup() {
			this.fileList = [];
			this.previewFileList = [];
			this.$resumable.files = [];
			this.uploading = false;
			this.done = false;
			this.totalBytes = 0;
			this.uploadedBytes = 0;
		}
	}
};
</script>

<style lang='scss' scoped>
progress {
	display: block;
	width: 100%;
	margin: auto;
	margin-top: 20px;
	margin-bottom: 20px;
}

.selected-card {
	border: 2px #084a99 solid;
}

/* Container needed to position the overlay. Adjust the width as needed */
.img-card {
	position: relative;
	width: 100%;
	max-width: 100px;
}

/* Make the image to responsive */
.image {
	width: 100px;
	height: 100px;
}

.overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	transition: 0.3s ease;
}

.img-card:hover .overlay {
	opacity: 1;
}

.icon {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 100px;
	color: white;
	text-align: center;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.tab-pane:focus {
	border: none !important;
	outline: none !important;
}

#fileForm {
	cursor: pointer;
	background: white;
	border-radius: 5px;
}

.notdragged {
	border: 2px dashed #0087f7;
}

.dragged {
	border: 2px dashed green;
}

#uploadFiles {
	margin: 10px;
	text-decoration: underline;
}

#uploadFiles:hover {
	color: #084a99;
	cursor: pointer;
}
</style>


