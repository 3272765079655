<template>
	<div class="page">
		<div class="row">
			<div class="col-md-12">
				<h3>Assignments</h3>
				<p style="padding-left: 10px;">
					All Lesson and Playlist assignments by their Classes
				</p>
				<br>

				<div>
					<b-card no-body>
						<b-tabs card>
							<!-- lesson tab -->
							<b-tab active title="Lesson Assignments">
								<b-card-text>
									<!-- table -->
									<div v-if="lessonData && lessonData.data.length > 0" class="table-responsive">
										<table class="table table-hover">
											<thead class="thead-light">
											<tr>
												<th>Cover</th>
												<th>Class</th>
												<th>Title</th>
												<th>Classification</th>
												<th>Date Created</th>
												<th/>
											</tr>
											</thead>

											<tbody>
											<tr
												v-for="(l,index) in lessonData.data"
												:key="index">
												<td>
													<img
														:src="l.lesson.cover || '/images/default-profile.png'"
														class="img-thumbnail"
														height="75px"
														width="75px"
														alt="image here">
												</td>
												<td>{{ l.classroom.name }}</td>
												<td>{{ l.lesson.title }}</td>
												<td>{{ l.lesson.classification }}</td>
												<td>
													{{ l["created_at"] | date }}
												</td>
												<td>
													<router-link
														:to="`/teacher/assignments/lesson/${l.lesson.id}/class/${l.classroom_id}`">
														View
													</router-link>
												</td>
											</tr>
											</tbody>
										</table>
									</div>
									<empty v-else/>
									<!-- pagination -->
									<div class="clearfix">
										<span class="float-right">
											<pagination v-if="lessonData"
														:links="lessonData.links"
														:meta="lessonData.meta"
														@first="(url) => updateAssignmentTable('lesson', url)"
														@last="(url) => updateAssignmentTable('lesson', url)"
														@next="(url) => updateAssignmentTable('lesson', url)"
														@prev="(url) => updateAssignmentTable('lesson', url)"/>
										</span>
										<span class="float-left"/>
									</div>
								</b-card-text>
							</b-tab>
							<!-- playlist tab -->
							<b-tab title="Playlist Assignments">
								<b-card-text>
									<!-- table -->
									<div v-if="playlistData && playlistData.data.length > 0" class="table-responsive">
										<table class="table table-hover">
											<thead class="thead-light">
											<tr>
												<th>Cover</th>
												<th>Class</th>
												<th>Title</th>
												<th>Date Created</th>
												<th/>
											</tr>
											</thead>
											<tbody>
											<tr
												v-for="(p,index) in playlistData.data"
												:key="index">
												<td>
													<img
														:src="p.playlist.cover || '/images/default-profile.png'"
														class="img-thumbnail"
														height="75px"
														width="75px"
														alt="image here">
												</td>
												<td>{{ p.classroom.name }}</td>
												<td>{{ p.playlist.name }}</td>
												<td>
													{{ p["created_at"] | date }}
												</td>
												<td>
													<router-link
														:to="`/teacher/assignments/playlist/${p.playlist.id}/class/${p.classroom_id}`"
														tag="a">
														View
													</router-link>
												</td>
											</tr>
											</tbody>
										</table>
									</div>
									<empty v-else/>
									<!-- pagination -->
									<div class="clearfix">
										<span class="float-right">
											<pagination v-if="playlistData"
														:links="playlistData.links"
														:meta="playlistData.meta"
														@first="(url) => updateAssignmentTable('playlist', url)"
														@last="(url) => updateAssignmentTable('playlist', url)"
														@next="(url) => updateAssignmentTable('playlist', url)"
														@prev="(url) => updateAssignmentTable('playlist', url)"/>
										</span>
										<span class="float-left"/>
									</div>
								</b-card-text>
							</b-tab>
						</b-tabs>
					</b-card>
				</div>
				<br>
			</div>
		</div>
	</div>
</template>
<script>
import pagination from "../../components/utility/pagination.vue";
import empty from "../../components/utility/empty.vue";
import {EventActionEnum, EventLabelEnum} from "../../config/enums";

export default {
	components: {
		pagination,
		empty
	},
	data() {
		return {
			lessonData: null,
			playlistData: null,
			loading: true
		};
	},
	mounted() {
		this.fetchAssignments();
	},
	updated() {
		(this.lessonData == null || this.playlistData == null) ? this.fetchAssignments() : null;
	},
	computed: {
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},
	methods: {
		async fetchAssignments() {
			try {
				const responses = await Promise.all([
					axios.get("/api/teacher/assignments/lesson"),
					axios.get("/api/teacher/assignments/playlist"),
				]);
				this.loading = false;
				this.lessonData = {
					data: responses[0].data,
					meta: responses[0].meta,
					links: responses[0].links,
				};
				this.playlistData = {
					data: responses[1].data,
					meta: responses[1].meta,
					links: responses[1].links,
				};
				// log with GA
				this.$gtag.event(EventActionEnum.View, {
					"event_category": this.userEventCategory,
					"event_label": EventLabelEnum.Assignments,
					"value": "Assignments"
				});
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch data, please try again");
			}
		},
		viewAssignment(type, id) {
			return this.$router.push({
				path: `/teacher/assignments/${type}/${id}`
			});
		},
		async updateAssignmentTable(type, url) {
			try {
				this.loading = true;
				let response = await axios.get(url);
				this.loading = false;
				if (type === "lesson") {
					this.lessonData = response;
				} else {
					this.playlistData = response;
				}
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch data, please try again");
			}
		},
	}
};
</script>
<style lang="scss" scoped>
h3 {
	padding-left: 10px;
}

.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}
</style>
<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}
</style>

