<template>
	<div>
		<div class="page">
			<div>
				<div class="row">
					<div class="col-md-6">
						<h3>{{ pageTitle }}</h3>
					</div>
				</div>
				<br>
				<div>
					<b-breadcrumb :items="breadcrumbitems"/>
				</div>

				<div style="padding-top: 20px; background: white;">
					<div class="row">
						<div class="col-md-12 lesson-text">
							<div>
								<div class="col-md-12 form-group">
									<label for="title"
										   class="control-label">Plan Name:</label>
									<input v-validate
										   id="title"
										   v-model="planData.name"
										   name="title"
										   type="text"
										   class="form-control">
								</div>
								<div class="col-md-12 form-group">
									<label for="description"
										   class="control-label">Plan Description</label>
									<textarea v-validate
											  id="description"
											  v-model="planData.description"
											  row="5"
											  class="form-control"
											  name="description"/>
								</div>
								<div class="col-md-12 form-group">
									<label for="price"
										   class="control-label">Price</label>
									<input
										v-validate
										id="price"
										v-model="planData.price"
										name="price"
										type="number"
										class="form-control">
								</div>
								<div class="col-md-12 form-group">
									<label for="title"
										   class="control-label">Pricing Period:</label>
									<input
										v-validate
										id="title"
										v-model="planData.pricing_period"
										name="title"
										type="text"
										class="form-control">
								</div>
								<!-- Role Users -->
								<div class="col-md-12 form-group">
									<label for="users"
										   class="control-label">Plan Users:</label>
									<multiselect
										v-validate
										id="users"
										v-model="planData.users"
										:searchable="true"
										:options="usersList"
										:internal-search="false"
										:options-limit="15"
										:clear-on-select="false"
										:close-on-select="true"
										:loading="isLoadingUsers"
										:multiple="true"
										name="users"
										class="contents_multi"
										placeholder="Type to search for users"
										track-by="id"
										@search-change="getUsersAsync">
										<template
											#tag="{ option }">
											<button
												type="button"
												class="btn btn-primary mb-2 mr-2">
												{{ option.first_name }}
												{{ option.last_name }}
												<span
													class="badge badge-light"
													@click="remove(option)">&times;</span>
											</button>
										</template>
										<template
											#option="{ option }">
											<div
												class="option__block row align-content-center">
												<div
													class="option__preview col-md-4">
													<img
														:src="
															option.avatar ||
																'/images/default-profile.png'
														"
														width="100px"
														height="100px">
												</div>
												<div
													class="option__title col-md-3">
													{{ option.first_name }}
													{{ option.last_name }}
												</div>
												<div class="col-md-3">
													{{ option.email }}
												</div>
											</div>
										</template>
										<span slot="noResult">No results found.</span>
									</multiselect>
								</div>
								<div class="col-md-12 form-group">
									<button
										v-if="changed || !plan_id"
										:disabled="isSaving"
										type="button"
										class="btn btn-success"
										@click="savePlan">
										<i
											v-if="isSaving"
											class="fas fa-circle-notch fa-spin"/>
										<span>Save</span>
									</button>
									<button
										v-if="changed"
										type="button"
										class="btn btn-warning"
										@click="discardChanges">
										<span>Discard</span>
									</button>
									<button
										v-if="plan_id"
										type="button"
										class="btn btn-danger"
										@click="deletePlan">
										<i
											v-if="isDeleting"
											class="fas fa-circle-notch fa-spin"/>
										<span>Delete</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axiosDebounce from "../../utility/debounce";
import helper from "../../utility";
import {clearTimeout, setTimeout} from "timers";

export default {
	components: {
		Multiselect
	},

	data() {
		return {
			breadcrumbitems: [
				{
					text: "Plans",
					href: "/admin/plans"
				},
				{
					text: "All",
					active: true
				}
			],
			pageTitle: "",
			plan_id: null,
			planData: {},
			plan: {},
			isSaving: false,
			isDeleting: false,
			isLoadingUsers: false,
			usersList: [],
			selectedUsers: [],
			isLoading: false,
			changed: false
		};
	},

	watch: {
		planData: {
			handler: function (val) {
				this.changed =
					JSON.stringify(val) !== JSON.stringify(this.plan);
			},
			deep: true
		}
	},

	async beforeMount() {
		if (this.$route.params.plan_id === "new") {
			this.pageTitle = "Create new Plan";
			this.breadcrumbitems[1].text = "New";
		} else {
			await this.fetchPlan(this.$route.params.plan_id);
			this.plan_id = this.$route.params.plan_id;
		}
	},

	methods: {
		async fetchPlan(id) {
			try {
				this.isLoading = true;
				const response = await axios.get(`/api/plan/${id}?admin=true`);
				this.isLoading = false;
				if (response.status) {
					const {name, users} = response.data;
					this.pageTitle = `Plan: ${name}`;
					this.breadcrumbitems[1].text = name;
					this.planData = {...response.data};
					this.plan = {...response.data};
					this.selectedUsers = users;
				} else {
					toastr["error"]("An error occurred, Try again");
				}
			} catch (error) {
				this.isLoading = false;
				toastr["error"]("An error occurred, Try again");
			}
		},

		async savePlan() {
			try {
				this.isSaving = true;
				const response = await axios.put(
					`/api/plan/${this.plan_id}`,
					this.planData
				);
				this.isSaving = false;
				if (response.status) {
					toastr["success"]("Save successful");
					this.fetchPlan(this.plan_id);
				} else {
					toastr["error"]("An error occurred, Try again");
				}
			} catch (error) {
				this.isSaving = false;
				toastr["error"]("An error occurred, Try again");
			}
		},

		// Reset input changes
		discardChanges() {
			this.planData = {...helper.deepClone(this.plan)};
			return (this.changed = false);
		},

		// Delete plan
		deletePlan(id) {
			this.isDeleting = true;
			try {
				return axios.delete(`/api/plan/${this.plan_id}`).then(data => {
					this.$router.replace({
						path: "/admin/plans"
					});
					this.isDeleting = false;
				});
			} catch (error) {
				this.isDeleting = false;
				return toastr["error"]("An error occurred while deleting plan");
			}
		},

		getUsersAsync(query) {
			clearTimeout(this.searchTimer);
			this.searchTimer = setTimeout(this.searchUsers(query), 500);
		},

		searchUsers(query) {
			this.isLoadingUsers = true;
			axiosDebounce({
				method: "get",
				url: `/api/search/user/${query}`,
				timeout: 60000
			})
				.then(({data}) => {
					this.isLoadingUsers = false;
					this.usersList = [...data];
				})
				.catch(error => {
					this.isLoadingUsers = false;
					return null;
				});
		},

		remove(value) {
			this.planData.users = this.planData.users.filter(
				e => e.id !== value.id
			);
		}
	}
};
</script>

<style></style>
