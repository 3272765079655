<template>
	<div>
		<div class="page">
			<div class="row">
				<div class="col-md-12">
					<h3>Group Custom Lessons</h3>
					<br>
					<!-- breadcrumb -->
					<div class="row">
						<div class="col-md-4">
							<b-breadcrumb :items="breadcrumbitems"/>
						</div>
						<div class="col-md-4"/>
					</div>

					<!-- table -->
					<div>
						<table class="table table-hover">
							<thead>
							<tr>
								<th>Cover</th>
								<th>Hero</th>
								<th>Title</th>
								<th>Focus</th>
								<th>Group</th>
								<th width="70px">
									Author
								</th>
							</tr>
							</thead>
							<tbody v-if="lessons && !loading && lessons.length > 0">
							<tr
								v-for="(lesson,index) in lessons"
								:key="index"
								style="cursor: pointer;"
								@click="viewLesson(lesson.id)">
								<td>
									<img :src="lesson.cover"
										 class="img-thumbnail"
										 height="75px"
										 width="100px"
										 alt="image here">
								</td>
								<td>{{ lesson.hero }}</td>
								<td>{{ lesson.title }}</td>
								<td>{{ lesson.focus }}</td>
								<td>{{ lesson.group.name }}</td>
								<td width="70px">
									{{ `${lesson.author.first_name} ${lesson.author.last_name}` }}
								</td>
							</tr>
							</tbody>
							<tbody v-else-if="loading">
							Loading lessons...
							</tbody>
							<tbody v-else>
							Your admin has not created any lessons.
							</tbody>
						</table>
						<div class="clearfix">
							<span class="float-left"/>
							<span class="float-right">
								<pagination v-if="links && meta"
											:links="links"
											:meta="meta"
											@first="(url) => updateLessonsTable(url)"
											@last="(url) => updateLessonsTable(url)"
											@next="(url) => updateLessonsTable(url)"
											@prev="(url) => updateLessonsTable(url)"/>
							</span>
						</div>
					</div>
					<br>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import pagination from "../../components/utility/pagination.vue";

export default {
	components: {
		pagination,
	},
	data() {
		return {
			breadcrumbitems: [
				{
					text: "Lessons",
					href: "#"
				},
				{
					text: "All",
					active: true
				}
			],
			lessons: null,
			links: null,
			meta: null,
			loading: true
		};
	},
	mounted() {
		this.fetchLessons();
	},
	updated() {
		this.lessons === null ? this.fetchLessons() : null;
	},
	methods: {
		fetchLessons() {
			return axios
				.get("/api/teacher/custom-lessons")
				.then(({data, links, meta}) => {
					this.lessons = data;
					this.loading = false;
					this.links = links;
					this.meta = meta;
				})
				.catch(error => {
					this.loading = false;
					toastr["error"]("Could not fetch lessons");
				});
		},
		viewLesson(id) {
			return this.$router.push({
				path: `/lesson/${id}`
			});
		},
		updateLessonsTable(url) {
			this.loading = true;
			return axios
				.get(url)
				.then(({data, links, meta}) => {
					this.lessons = data;
					this.loading = false;
					this.links = links;
					this.meta = meta;
				})
				.catch(error => {
					toastr["error"]("Could not fetch lessons");
				});
		}
	}
};
</script>
<style lang="scss" scoped>
h3 {
	padding-left: 10px;
}

.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}
</style>
<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}
</style>

