<template>
	<div class="page">
		<div class="row">
			<div class="col-md-12">
				<h3>Custom Playlists</h3>
				<div class="clear-fix mb-5">
					<span class="float-left">
						<p style="padding-left: 10px;">
							All your created playlists
						</p>
					</span>
					<span class="float-right">
						<router-link
							tag="button"
							to="/teacher/custom/playlists/create"
							class="btn btn-primary"><i class="fas fa-plus"/> New</router-link>
					</span>
				</div>
				<div v-if="playlistData != null && playlistData.data.length > 0" class="table-responsive">
					<table class="table table-hover">
						<thead class="thead-light">
						<tr>
							<th>Cover</th>
							<th>Title</th>
							<th>Description</th>
							<th>No of Lessons</th>
							<th>Date Created</th>
							<th/>
						</tr>
						</thead>
						<tbody>
						<tr v-for="(p, index) in playlistData.data" :key="index">
							<td>
								<img
									:src="p.cover || '/images/default-profile.png'"
									class="img-thumbnail"
									width="75px"
									height="75px"
									alt="image here">
							</td>
							<td>{{ p.name }}</td>
							<td>
									<textarea cols="50"
											  rows="5"
											  :value="p.description"
											  disabled/>
							</td>
							<td>{{ p.lessons.length }}</td>
							<td>{{ p["created_at"] | date }}</td>
							<td>
								<router-link :to="`/playlist/${p.id}`">
									View
								</router-link>
								<br>
								<router-link :to="`/teacher/custom/playlists/${p.id}`" class="text-link">
									Edit
								</router-link>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
				<empty v-else/>
				<!-- pagination -->
				<div class="clearfix">
					<span class="float-right">
						<pagination v-if="playlistData"
									:links="playlistData.links"
									:meta="playlistData.meta"
									@first="(url) => updatePlaylistTable(url)"
									@prev="(url) => updatePlaylistTable(url)"
									@last="(url) => updatePlaylistTable(url)"
									@next="(url) => updatePlaylistTable(url)"/>
					</span>
					<span class="float-left"/>
				</div>
			</div>
		</div>
		<assign-playlist-to-classroom-modal/>
	</div>
</template>

<script>
import pagination from "../../components/utility/pagination.vue";
import empty from "../../components/utility/empty.vue";
import assignPlaylistToClassroomModal from "../../components/classroom/assign-to-classroom";
import {EventActionEnum, EventLabelEnum} from "../../config/enums";

export default {
	components: {
		pagination,
		empty,
		assignPlaylistToClassroomModal
	},
	data() {
		return {
			playlistData: null,
			loading: true,
		};
	},
	mounted() {
		this.fetchPlaylists();
	},
	updated() {
		this.playlistData == null ? this.fetchPlaylists() : null;
	},
	computed: {
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},
	methods: {
		async fetchPlaylists() {
			try {
				const response = await axios.get("/api/teacher/custom-playlists");
				this.loading = false;
				this.playlistData = response;
				// log with GA
				this.$gtag.event(EventActionEnum.View, {
					"event_category": this.userEventCategory,
					"event_label": EventLabelEnum.Playlist,
					"value": `Custom Playlist/${this.playlistData.name}`
				});
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch playlists, please try again later");
			}
		},
		async updatePlaylistTable(url) {
			try {
				this.loading = true;
				const response = await axios.get(url);
				this.loading = false;
				this.playlistData = response;
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch playlists, please try again later");
			}
		},
		assignToClass(data) {

		},
		deletePlaylist(data) {

		}
	}
};
</script>

<style lang="scss" scoped>
h3 {
	padding-left: 10px;
}

.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}
</style>
<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}

.text-link {
	margin: 0;
	color: #084a99;
}

.text-link:hover {
	cursor: pointer;
}
</style>

