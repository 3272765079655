<template>
	<div class="page">
		<div class="row">
			<div class="col-md-6">
				<h3>{{ pageTitle }}</h3>
			</div>
		</div>
		<br>
		<div>
			<b-breadcrumb :items="breadcrumbitems"/>
		</div>
		<div class="row">
			<div class="col-md-12">
				<form>
					<div class="form-group">
						<label for="name">Name:</label>
						<input type="text"
							   class="form-control"
							   placeholder="Enter Group Name"
							   v-model="groupData.name"
							   id="name">
					</div>
					<div class="form-group">
						<label for="type">Type:</label>
						<input type="text"
							   class="form-control"
							   placeholder="School,District"
							   v-model="groupData.type"
							   id="type">
					</div>
					<div class="form-group">
						<label for="code">Code:</label>
						<input type="text"
							   class="form-control"
							   placeholder="Enter Group Code"
							   v-model="groupData.code"
							   id="code">
					</div>
					<div class="form-group">
						<label for="description">Description:</label>
						<textarea type="text"
								  rows="5"
								  class="form-control"
								  placeholder="Group Description"
								  v-model="groupData.description"
								  id="description"/>
					</div>
					<div class="form-group">
						<label for="state">State:</label>
						<input type="text"
							   class="form-control"
							   placeholder="Enter State"
							   v-model="groupData.state"
							   id="state">
						<!-- <select v-model="groupData.state"
							class="form-control"
							id="state">
							<option value=""
								disabled
								selected>
								Select a State
							</option>
							<option v-for="(state,index) in Object.keys(stateAndCities).sort()"
								:key="index"
								:value="state">
								{{ state }}
							</option>
						</select> -->
					</div>
					<div class="form-group">
						<label for="city">City:</label>
						<input type="text"
							   class="form-control"
							   placeholder="Enter City"
							   v-model="groupData.city"
							   id="city">
						<!-- <select v-model="groupData.city"
							class="form-control"
							id="state">
							<option value=""
								disabled
								selected>
								Select a city
							</option>
							<option v-for="(city,index) in stateAndCities[groupData.state].sort()"
								:key="index"
								:value="city">
								{{ city }}
							</option>
						</select> -->
					</div>
					<div class="form-group">
						<label for="address">Address:</label>
						<input type="text"
							   class="form-control"
							   placeholder="Address line 1"
							   id="address"
							   v-model="groupData.address">
					</div>
					<div class="form-group">
						<label for="address2">Address Line 2:</label>
						<input type="text"
							   class="form-control"
							   placeholder="Address line 2"
							   id="address2"
							   v-model="groupData.address2">
					</div>
					<div class="form-group">
						<label for="zip">Zip:</label>
						<input type="text"
							   class="form-control"
							   placeholder="Zip code"
							   id="zip"
							   v-model="groupData.zip">
					</div>
					<div class="form-group">
						<label>Select Parent Group:</label>
						<group-multiselect
							@updatedData="setParentGroup"/>
					</div>
					<div class="form-group">
						<label>Select Group Admin:</label>
						<user-multiselect
							@updatedData="setAdminUser"/>
					</div>
					<div class="form-group">
						<div class="btn-group">
							<button type="button"
									:disabled="loading"
									@click="saveGroup"
									class="btn btn-success">
								Save
							</button>
							<button v-if="group_id !== 'new' && changed === true"
									type="button"
									:disabled="loading"
									@click="discardChanges"
									class="btn btn-warning">
								Discard
							</button>
							<button v-if="group_id !== 'new'"
									type="button"
									:disabled="loading"
									@click="deleteGroup"
									class="btn btn-danger">
								Delete
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import {stateAndCities} from "../../config/constants";
import groupMultiselect from "../../components/forms/groups-multiselect.vue";
import userMultiselect from "../../components/forms/users-multiselect.vue";
import EventBus from "../../utility/eventbus";

export default {
	components: {
		groupMultiselect,
		userMultiselect
	},
	data() {
		return {
			breadcrumbitems: [
				{
					text: "Groups",
					href: "/admin/groups"
				},
				{
					text: "All",
					active: true
				}
			],
			pageTitle: "",
			stateAndCities,
			groupData: {
				name: "",
				code: "",
				address: "",
				address2: "",
				state: "",
				city: "",
				zip: "",
				description: "",
				type: "",
				admin_id: null,
				group_id: null
			},
			group: null,
			changed: false,
			group_id: this.$route.params.group_id,
			loading: false,
		};
	},
	mounted() {
		this.setTitleAndBreadcrumbs();
		if (this.group_id !== "new") {
			this.fetchGroup();
		}
	},
	watch: {
		groupData: {
			handler(val) {
				if (this.group_id !== "new") {
					this.changed = (JSON.stringify(this.groupData) !== JSON.stringify(this.group));
				}
			},
			deep: true,
		}
	},
	methods: {
		async fetchGroup() {
			try {
				this.loading = true;
				const response = await axios.get(`/api/group/${this.group_id}`);
				this.loading = false;
				this.group = response.data;
				this.groupData = response.data;
				this.setTitleAndBreadcrumbs();
				if (this.group.super_group != null) {
					EventBus.$emit("SET-INITIAL-GROUP", this.group.super_group);
				}

				if (this.group.admin != null) {
					EventBus.$emit("SET-INITIAL-USER", this.group.admin);
				}

			} catch (error) {
				this.loading = false;
				toastr["error"]("Unable to fetch group data");
			}
		},
		setTitleAndBreadcrumbs() {
			if (this.group_id === "new") {
				this.pageTitle = "Create New Group";
				this.breadcrumbitems[1].text = "New";
			} else {
				this.pageTitle = this.groupData.name;
				this.breadcrumbitems[1].text = this.groupData.name;
			}
		},
		setAdminUser(data) {
			if (data != null) {
				this.groupData.admin_id = data.id;
			}
		},
		setParentGroup(data) {
			if (data != null) {
				this.groupData.group_id = data.id;
			}
		},
		validateData() {
			const {name, code, address, address2, state, city} = this.groupData;

			if (name === "") {
				toastr["error"]("Please input a name for your group");
				return false;
			}

			if (code === "") {
				toastr["error"]("Please input a code for your group");
				return false;
			}

			if (address === "") {
				toastr["error"]("Please input the group address");
				return false;
			}

			if (state === "") {
				toastr["error"]("Please select a state");
				return false;
			}

			if (city === "") {
				toastr["error"]("Please select a city");
				return false;
			}

			return true;
		},
		discardChanges() {
			this.groupData = this.group;
		},
		async deleteGroup() {
			try {
				this.loading = true;
				const value = await this.$bvModal.msgBoxConfirm("Are you sure?");
				const response = await axios.delete(`/api/group/${this.group_id}`);
				this.loading = false;

				toastr["success"]("Group Deleted");
				this.$router.go(-1);

			} catch (error) {
				this.loading = false;
				toastr["error"]("Unable to delete group");
			}
		},
		async saveGroup() {
			try {
				if (this.validateData()) {
					let response;
					this.loading = true;
					if (this.group_id === "new") {
						response = await axios.post("/api/group", this.groupData);
					} else {
						response = await axios.put(`/api/group/${this.group_id}`, this.groupData);
					}
					this.loading = false;

					if (this.group_id === "new") {
						toastr["success"]("Group Created");
						this.$router.go(-1);
					} else {
						toastr["success"]("Group Updated");
						this.$router.go(0);
					}
				}
			} catch (error) {
				this.loading = false;
				toastr["error"]("Unable to save group");
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}

#collapseAddNewContent,
#collapseAddNewPlaylist {
	margin-top: 20px;
	margin-bottom: 20px;
}

#addContentButton,
#addPlaylistButton {
	font-size: small;

	&.closeup::before {
		content: "\F057";
	}

	&.expand::before {
		content: "\F055";
	}

	&::before {
		display: inline-block;
		margin-right: 5px;
		font-family: "Font Awesome 5 Free", serif;
		font-style: normal;
		font-weight: 900;
		font-variant: normal;
		color: $white;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
	}
}

.option__block {
	.multiselect__tag {
		height: fit-content;
		margin-right: 0;
	}

	padding: 5px;
	margin: 10px 10px;
	border: 1px solid #ced4da;
	border-radius: 3px;
}

.option__title {
	a {
		color: white;
	}

	i {
		color: gray;

		&:hover {
			color: red;
		}
	}
}

.option__preview {
	white-space: normal;
}
</style>

<style lang="scss" scoped>
.contents_multi .multiselect__content-wrapper,
.contents_multi .multiselect__content {
	width: 650px !important;
	word-wrap: normal;
}
</style>

