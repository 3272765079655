import helper from "../../utility";

const state = {
	id: "",
	name: "",
	cover: "",
	description: "",
	lessons: "",
	tags: "",
};

const mutations = {
	setPlaylist: (state, playlist) => {
		for (let key of Object.keys(playlist)) {
			state[key] = playlist[key];
		}
	},
};

const actions = {
	setPlaylist: ({commit}, playlist) => {
		commit("setPlaylist", playlist);
	},

	fetchPlaylist: async ({commit}, playlist_id) => {

		axios
			.get("/api/playlist/" + playlist_id)
			.then(({data}) => {

				// Split the description and divide into paragraphs
				data.description = helper.splitText(
					data.description
				);

				// Store playlist in the vuex.
				commit("setPlaylist", {
					id: data.id,
					cover: data.cover,
					description: data.description,
					lessons: [...data.lessons],
					name: data.name,
					tags: data.tags
				});
			})
			.catch(error => {
				toastr.error("Error fetching playlist");
			});

	},
};

const getters = {
	getPlaylist: state => {
		return state;
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
