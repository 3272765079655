<template>
	<div
		id="default-page"
		:style="'background: url('+ page_background +')'">
		<app-header/>

		<div class="page-wrapper">
			<div :class="admin_route"
				 class="container-fluid">
				<router-view/>
			</div>
			<app-footer/>
		</div>
	</div>
</template>

<script>
import AppHeader from "../components/layout/header.vue";
import AppFooter from "../components/layout/footer.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import EventBus from "../utility/eventbus";

export default {
	name: "DefaultPage",

	components: {
		AppHeader,
		AppFooter
	},

	data() {
		return {
			admin_route: "",
		};
	},

	computed: {
		...mapState("auth", {
			authenticated: "authenticated",
			subscriptionActive: "subscriptionActive",
			subscriptionChecked: "subscriptionChecked",
			userOptions: "user_options",
		}),

		...mapState("config", {
			page_background: "page_background",
		}),

		user() {
			return this.$store.getters["auth/getAuthUser"];
		}
	},

	async mounted() {
		await this.checkAuth();
		this.route_handler();
		if (this.userOptions === "" || this.userOptions == null || this.userOptions.first_login != null && this.userOptions.first_login === true) {
			await this.setUserOptions({option: "first_login", value: false});
		}

		EventBus.$on("REFRESH-USER", () => {
			this.refreshAuth();
		});

	},

	updated() {
		this.route_handler();
	},

	methods: {
		...mapGetters({
			getConfigVar: "config/getConfigVar",
			getAuthUserDetail: "auth/getAuthUserDetail",
			getUserOptions: "auth/getUserOptions"
		}),

		...mapActions({
			checkAuth: "auth/checkAuth",
			refreshAuth: "auth/refreshAuth",
			setSubscriptionChecked: "auth/setSubscriptionChecked",
			setUserOptions: "auth/setUserOptions",
		}),

		route_handler() {
			if (!this.user.authenticated) {
				this.$router.replace({path: "/login"});
			}
			if (this.$route.fullPath.includes("admin")) {
				this.admin_route = "admin_container";
			} else {
				this.admin_route = "";
			}
		}
	}
};
</script>


<style lang="scss" scoped>
.page-wrapper {
	min-height: 100vh;
	padding-top: $header-height + 50px;
	padding-bottom: $footer-height + 50px;

	.container-fluid {
		padding-top: 50px;

		@include breakpoint($below-sm) {
			padding-top: $header-height;
		}
	}
}
</style>
