<template>
	<div class="container text-center">
		<img height="200"
			width="200"
			src="/images/empty.jpg"
			class="img-fluid mx-auto d-block">
		<br>
		<h6>{{ message }}</h6>
	</div>
</template>

<script>
export default {
	props: {
		message: {
			type: String,
			default: "No Data"
		},
	}
};
</script>

<style>

</style>
