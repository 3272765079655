<!--suppress ALL -->
<template>
	<div v-if="home"
		 id="home-page"
		 class="home"
		 dusk="home-page">
		<router-link v-if="home.lesson"
					 :to="'lesson/' + home.lesson.id">
			<div id="featured-page-section"
				 class="page-section"
				 style="border-radius: 5px;">
				<div class="overlay" style="border-radius: 5px;"/>
				<div :style="{ backgroundImage: 'url(' + home.lesson.cover +')', backgroundSize: 'cover', backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat' }"
					 class="featured"
					 style="border-radius: 5px;">
					<div class="content">
						<i class="fas fa-play fa-lg play-icon"/>
						<h1>{{ home.lesson.title }}</h1>
						<p>{{ home.lesson.focus }}</p>
					</div>
				</div>
			</div>
		</router-link>

		<br>

		<!--		featured playlist group-->
		<div v-if="home.featured[0] != null">
			<playlist-group-card :playlists="home.featured"
								 class="page-section"
								 title="Featured"/>
		</div>
		<!--		other tagged playlist group-->
		<div v-if="Object.entries(home.playlists)[0] != null">
			<div v-for="([title,playlists], index) in Object.entries(home.playlists)"
				 :key="index">
				<playlist-group-card
					:playlists="playlists"
					:title="title"/>
				<br>
			</div>
		</div>

		<div
			v-if="$can('view', 'all-lessons')"
			class="row">
			<div class="col-md-4 offset-md-4 col-sm-6 offset-sm-3">
				<button class="btn btn-primary btn-block"
						type="button"
						@click="routeToAllLessonsPage">
					Explore Lessons
				</button>
			</div>
		</div>

		<b-modal v-model="tutorialModal"
				 @ok="routeToTutorial">
			<div id="tutorial-modal"
				 class="row"
				 style="padding: 10px;">
				<div class="row d-flex" style="display: inline; vertical-align: middle; text-align: center;">
					<div class="col-md-4">
						<img alt="iris"
							 src="/images/hero/iris_excited.png"
						>
					</div>
					<div class="col-md-8 d-flex flex-wrap align-content-center justify-content-start">
						<div>
							<h4>Welcome to Get Media L.I.T</h4>
							<p>Checkout out our tutorial to get you started</p>
						</div>
						<div class="form-group form-check">
							<label class="form-check-label">
								<input v-model="tutorialCheckboxValue"
									   class="form-check-input"
									   type="checkbox"
									   @click="deactivateTutorialModal"> Do not show this again
							</label>
						</div>
					</div>
				</div>
			</div>

			<template #modal-footer="{ ok, cancel }">
				<b-button size="sm"
						  variant="primary"
						  @click="ok()">
					Proceed to tutorial
				</b-button>
				<b-button size="sm"
						  variant="primary"
						  @click="cancel()">
					Skip tutorial
				</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import playlistGroupCard from "./playlists/playlist-group-card";
import {ability} from "../config/ability";
import {mapActions} from "vuex";

export default {
	components: {
		playlistGroupCard,
	},

	data() {
		return {
			tutorialModal: false,
			endpoint: "home",
			tutorialCheckboxValue: false,
			home: {
				featured: [],
				playlists: [],
				lesson: null,
			}
		};
	},
	computed: {
		user() {
			return this.$store.getters["auth/getAuthUser"];
		},
		activity() {
			return this.$store.getters["auth/getUserActivity"];
		}
	},
	watch: {
		activity: {
			handler() {
				this.$forceUpdate();
			},
			deep: true
		}
	},
	beforeRouteEnter(to, from, next) {
		if (ability.can("view", "home") &&
			ability.can("read", "playlists") &&
			ability.can("read", "lessons") &&
			ability.can("read", "content")) {
			return next();
		}
		return next({path: "/login"});
	},

	mounted() {
		this.fetch();
		this.setConfig({page_background: "/images/default-background.png"});
		if (this.user.user_options == null) {
			this.tutorialModal = true;
		} else {
			this.tutorialModal = this.user.user_options.tutorial.value != null ? this.user.user_options.tutorial.value : true;
		}
	},

	methods: {
		...mapActions({
			setConfig: "config/setConfig",
			setUserOptions: "auth/setUserOptions"
		}),

		fetch() {
			if (this.endpoint) {
				axios
					.get("api/" + this.endpoint)
					.then(({data}) => {
						this.home = data;
					})
					.catch(({message}) => {
						toastr["error"](message);
					});
			}
		},

		async deactivateTutorialModal() {
			this.tutorialModal = false;
			this.tutorialCheckboxValue = true;
			await this.$store.dispatch("auth/setUserOptions", {
				option: "tutorial",
				value: false
			});
		},

		routeToAllLessonsPage() {
			return this.$router.push("/lesson/explore");
		},
		routeToTutorial() {
			this.deactivateTutorialModal();
			return window.open("https://getmedialit.com/lit-getting-started/", "_blank");
		}
	}
};
</script>


<style lang="scss" scoped>
$featured-image-height: 450px;

$featured-image-height: 450px;

#tutorial-modal {
	p {
		font-size: 15px;
	}
}

.home {
	& > div {
		margin-bottom: 35px;
	}

	$padding: $page-section-padding;

	#featured-page-section {
		padding: 0;
	}

	.overlay {
		float: left;
		width: 100%;
		height: $featured-image-height;
		background-color: #000000;
		opacity: 0.33;
	}

	.featured {
		height: $featured-image-height;
		padding: 50px;
		cursor: pointer;
		background-size: cover;

		.play-icon {
			margin-bottom: 20px;
		}

		.content {
			position: relative;
			z-index: 10;
			color: white;
			text-align: center;
			transform: translateY(-55%);
		}

		&:hover {
			border: $active-lesson-image-border;

			~ .overlay {
				opacity: 0.4;
				transition: opacity 0.25s ease-in;
			}
		}
	}
}
</style>
