<template>
	<div>
		<div class="page-section">
			<h4 class="m-2">
				Your Classrooms
			</h4>
			<br>

			<div v-if="classData && classData.data.length > 0" class="card-columns">
				<div v-for="(c,index) in classData.data"
					 :key="index"
					 class="card">
					<div class="card-body">
						<h4 class="card-title">
							{{ c.name }}
						</h4>
						<p v-if="c.group != null" class="card-text">
							{{ c.group.name }}
						</p>
						<router-link :to="`/student/class/${c.id}`"
									 class="card-link"
									 tag="a">
							View Class
						</router-link>
					</div>
				</div>
			</div>
			<empty v-else/>
			<!-- pagination -->
			<div class="clearfix m-1">
				<span class="float-left"/>
				<span class="float-right">
					<pagination v-if="classData"
								:links="classData.links"
								:meta="classData.meta"
								@first="(url) => updateClassTable(url)"
								@last="(url) => updateClassTable(url)"
								@next="(url) => updateClassTable(url)"
								@prev="(url) => updateClassTable(url)"/>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import pagination from "../../components/utility/pagination.vue";
import empty from "../../components/utility/empty.vue";
import {EventActionEnum, EventLabelEnum} from "../../config/enums";

export default {
	components: {
		pagination,
		empty
	},
	data() {
		return {
			loading: true,
			classData: null,
		};
	},
	mounted() {
		this.fetchClasses();
	},
	updated() {
		this.classData == null ? this.fetchClasses() : null;
	},
	computed: {
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},
	methods: {
		async fetchClasses() {
			try {
				const response = await axios.get("/api/student/classroom");
				this.loading = false;
				this.classData = response;
				// log with GA
				this.$gtag.event(EventActionEnum.View, {
					"event_category": this.userEventCategory,
					"event_label": EventLabelEnum.Classrooms,
					"value": "all classrooms"
				});
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch classes, please try again later");
			}
		},
		async updateClassTable(url) {
			try {
				this.loading = true;
				const response = await axios.get(url);
				this.loading = false;
				this.classData = response;
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unabke to fetch classes, please try again later");
			}
		},
	}
};
</script>

<style>

</style>
