<template>
	<div class="page">
		<div class="row">
			<div class="col-md-12">
				<div class="clearfix">
					<span class="float-left">
						<h3>Classroom Details</h3>
					</span>
					<span class="float-right" />
				</div>
			</div>
		</div>
		<br>
		<!-- class details -->
		<div v-if="classData" class="card">
			<div class="card-body">
				<h6>Classroom Information</h6>
				<ul>
					<li>Name: {{ classData.name }}</li>
					<li>
						Code: {{ classData.code }}
						<clipboard-link style="margin-left: 20px;"
							:copy="prepareShareLink(classData.code)"
							message="Copy Invite Link" />
					</li>
					<li>Admin: {{ `${classData.admin.first_name} ${classData.admin.last_name}` }}</li>
				</ul>
				<template v-if="classData.group != null">
					<h6>School Information</h6>
					<ul>
						<li>Name: {{ classData.group.name }}</li>
						<li>Address: {{ classData.group.address }} {{ classData.group.address2 }}</li>
						<li>City: {{ classData.group.city }}</li>
						<li>State: {{ classData.group.state }}</li>
					</ul>
				</template>
				<div class="clearfix">
					<span class="float-left">
						<button
							@click="editClassroom(classData.id)"
							type="button"
							class="btn btn-info btn-sm">
							Edit Class
						</button>
						<button
							@click="deleteClassroom(classData.id)"
							type="button"
							class="btn btn-danger btn-sm">
							Delete Class
						</button>
					</span>
					<span class="float-right" />
				</div>
			</div>
		</div>
		<br>
		<div v-if="students" class="card">
			<div class="card-body">
				<h6>Classroom Members</h6>
				<br>
				<div v-if="students.length > 0" class="table-responsive">
					<table class="table table-hover">
						<thead class="thead-light">
							<tr>
								<th>Avatar</th>
								<th>Username</th>
								<th>Date Registered</th>
								<th>Action</th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="(u, index) in students" :key="index">
								<td>
									<img
										:src="u.user.avatar || '/images/default-profile.png'"
										class="img-thumbnail"
										width="75px"
										height="75px">
								</td>
								<td>{{ u.user.username || 'n/a' }}</td>
								<td>{{ new Date(u.created_at).toLocaleString() }}</td>
								<td>
									<button
										@click="resetPassword(u.user.id)"
										type="button"
										class="btn btn-warning btn-sm">
										<i class="fas fa-sync" />
									</button>
									<button
										@click="removeStudent(u.user.id)"
										type="button"
										class="btn btn-danger btn-sm">
										<i class="far fa-trash-alt" />
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<empty v-else />
			</div>
		</div>
		<br>
	</div>
</template>

<script>
import pagination from "../../components/utility/pagination.vue";
import empty from "../../components/utility/empty.vue";
import clipboardLink from "../../components/utility/clipboard-link.vue";

export default {
	components: {
		clipboardLink,
		empty
	},
	data() {
		return {
			classData: null,
			students: null,
			loading: true,
			class_id: this.$route.params.id,
		};
	},
	mounted() {
		this.fetchClass();
	},
	watch: {
		classData: {
			handler(newVal) {
				if (newVal != null) {
					this.students = this.classData.users.filter((user) => {
						return user.role !== "Teacher";
					});
				}
			},
			deep: true,
		},
	},
	updated() {
		this.classData == null ? this.fetchClass() : null;
	},
	methods: {
		async fetchClass() {
			try {
				this.loading = true;
				const response = await axios.get(`/api/classroom/${this.class_id}`);
				this.loading = false;
				this.classData = response.data;
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch classes, please try again later");
			}
		},
		prepareShareLink(code) {
			return `${window.location.protocol}//${window.location.host}/join-class/${code}`;
		},
		async resetPassword(id) {
			try {
				if (confirm("Are you sure you want to reset this student's password?")) {
					this.loading = true;
					await axios.get(`/api/teacher/students/${id}/reset-password`);
					this.loading = false;
					return toastr["success"]("Password reset successful");
				}
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to reset student password");
			}
		},
		async removeStudent(id) {
			try {
				if (confirm("Are you sure you want to remove student from this class?")) {
					this.loading = true;
					await axios.post(`/api/classroom/${this.class_id}/remove_users`, {users: [id]});
					this.loading = false;
					toastr["success"]("Student Removed from classroom");
					return this.$router.go(0);
				}
			} catch (e) {
				this.loading = false;
				return toastr["error"]("Unable to remove student from classroom");
			}
		},
		async deleteClassroom(id) {
			try {
				if (confirm("Are you sure?")) {
					this.loading = true;
					await axios.delete(`/api/classroom/${id}`);
					this.loading = false;
					toastr["success"]("Classroom deleted");
					return this.$router.go(-1);
				}
			} catch (e) {
				return toastr["error"]("Unable to delete classroom");
			}
		},
		async editClassroom(id) {
			return this.$router.push({
				path: `/principal/classes/${id}/edit`
			});
		}
	}
};
</script>

<style lang="scss" scoped>
h3 {
	padding-left: 10px;
}

.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}
</style>
<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}
</style>
