<template>
	<div>
		<div class="page">
			<div class="row">
				<div class="col-md-12">
					<h3>Overview</h3>
					<br>
				</div>
			</div>
			<!--			Statistics cards-->
			<div v-if="loading === false"
				 class="row">
				<div class="col-md-3"
					 style="cursor: pointer;"
					 @click="routeToPage('/principal/classes')">
					<div class="card bg-danger">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ dashboardData.classroomCount || 0 }}</strong> Classes
							</h5>
						</div>
					</div>
				</div>
				<div class="col-md-3"
					 style="cursor: pointer;"
					 @click="routeToPage('/principal/students')">
					<div class="card bg-dark">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ dashboardData.studentCount || 0 }}</strong> Students
							</h5>
						</div>
					</div>
				</div>
				<div class="col-md-3"
					 style="cursor: pointer;"
					 @click="routeToPage('/principal/teachers')">
					<div class="card bg-success">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ dashboardData.teacherCount || 0 }}</strong> Teachers
							</h5>
						</div>
					</div>
				</div>
				<div class="col-md-3"
					 style="cursor: pointer;"
					 @click="routeToPage('/principal/custom-lessons')">
					<div class="card bg-success">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ dashboardData.lessonCount || 0 }}</strong> Custom Lessons
							</h5>
						</div>
					</div>
				</div>
			</div>
			<br>
			<!--			recent students and teachers-->
			<div class="row">
				<div class="col-md-12">
					<div class="card-deck">
						<div v-if="loading === false"
							 class="card">
							<div class="card-body">
								<h5 class="card-title">
									Recently Registered Students
								</h5>
								<div v-if="dashboardData.recentStudents.length > 0" class="table-responsive">
									<table class="table table-hover">
										<thead class="thead-light">
										<tr>
											<th>Username</th>
											<th>Date Created</th>
										</tr>
										</thead>

										<tbody>
										<tr
											v-for="(s,index) in dashboardData.recentStudents"
											:key="index"
											style="cursor: pointer;"
											@click="routeToPage(`/teacher/students/${s.id}`)">
											<td>{{ s.user.username }}</td>
											<td>
												{{ new Date(s.created_at).toLocaleString() }}
											</td>
										</tr>
										</tbody>
									</table>
								</div>
								<empty v-else/>
							</div>
						</div>
						<div v-if="loading === false"
							 class="card">
							<div class="card-body">
								<h5 class="card-title">
									Recently Registered Teachers
								</h5>
								<div v-if="dashboardData.recentTeachers.length > 0" class="table-responsive">
									<table class="table table-hover">
										<thead class="thead-light">
										<tr>
											<th>Avatar</th>
											<th>Full Name</th>
											<th>Email Address</th>
											<th>Date Created</th>
										</tr>
										</thead>

										<tbody>
										<tr
											v-for="(t,index) in dashboardData.recentTeachers"
											:key="index"
											style="cursor: pointer;"
											@click="routeToPage(`/principal/teachers/${t.id}`)">
											<td>
												<img
													:src="t.user.avatar_url || '/images/default-profile.png'"
													class="img-thumbnail"
													width="75px"
													height="75px">
											</td>
											<td>{{ t.user.first_name }} {{ t.user.last_name }}</td>
											<td>{{ t.user.email }}</td>
											<td>
												{{ new Date(t.created_at).toLocaleString() }}
											</td>
										</tr>
										</tbody>
									</table>
								</div>
								<empty v-else/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<br>
			<!--			recent classrooms-->
			<div class="row">
				<div class="col-md-12">
					<div class="card-deck">
						<div v-if="loading === false"
							 class="card">
							<div class="card-body">
								<h5 class="card-title">
									Recently Created Classrooms
								</h5>
								<div v-if="dashboardData.recentClassrooms.length > 0" class="table-responsive">
									<table class="table table-hover">
										<thead class="thead-light">
										<tr>
											<th>Name</th>
											<th>Code</th>
											<th>Admin</th>
											<th>Group</th>
											<th>Date Created</th>
										</tr>
										</thead>

										<tbody>
										<tr
											v-for="(c,index) in dashboardData.recentClassrooms"
											:key="index"
											style="cursor: pointer;"
											@click="routeToPage(`/principal/classes/${c.id}`)">
											<td>{{ c.name }}</td>
											<td>{{ c.code }}</td>
											<td>{{ `${c.admin.first_name} ${c.admin.last_name}` }}</td>
											<td>{{ c.group != null ? c.group.name : "No Group" }}</td>
											<td>{{ new Date(c.created_at).toLocaleString() }}</td>
										</tr>
										</tbody>
									</table>
								</div>
								<empty v-else/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import empty from "../../components/utility/empty.vue";
import {EventActionEnum, EventLabelEnum} from "../../config/enums";

export default {
	components: {
		empty,
	},
	data() {
		return {
			dashboardData: null,
			loading: true
		};
	},
	created() {
		this.fetchData();
	},
	updated() {
		this.dashboardData === null ? this.fetchData() : null;
	},
	computed: {
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},
	methods: {
		async fetchData() {
			try {
				const response = await axios.get("/api/principal/dashboard");
				this.loading = false;
				this.dashboardData = response.data;
				// log with GA
				this.$gtag.event(EventActionEnum.View, {
					"event_category": this.userEventCategory,
					"event_label": EventLabelEnum.Dashboard,
					"value": "Dashboard"
				});
			} catch (error) {
				this.loading = false;
				return toastr["error"]("Unable to fetch data, please try again");
			}
		},
		routeToPage(route) {
			return this.$router.push({
				path: route
			});
		}
	}
};
</script>

<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}

.white-text {
	color: white;
}

strong {
	font-size: 2em;
}

.card .table-responsive {
	width: 100%;
	height: 300px;
	overflow-x: scroll;
}
</style>

