<template>
	<div>
		<h2>Create Playlist</h2>

		<div class="page-section">
			<div class="row">
				<div class="col-md-12 playlist-text">
					<form enctype="multipart/form-data"
						@submit.prevent="submitPrevent">
						<div class="col-md-12 form-group">
							<label for="title"
								class="control-label">Playlist Name</label>
							<input
								id="title"
								v-model="playlistForm.name"
								type="text"
								class="form-control"
								placeholder="Enter title">
						</div>

						<div class="col-md-12 form-group"
							hidden>
							<input id="author_id"
								v-model="playlistForm.author_id"
								type="text">
						</div>

						<div class="col-md-12 form-group">
							<img
								v-if="playlistForm.cover"
								:src="playlistForm.cover"
								class="img-responsive"
								width="200">
						</div>

						<div class="col-md-12 form-group">
							<label for="playlist_cover"
								class="btn btn-md btn-primary">
								<div class="image-upload">
									<input
										id="playlist_cover"
										ref="file"
										type="file"
										hidden
										@change="onPlaylistImageChange">
								</div>
								<i class="fas fa-upload icon" />
								Upload Cover Image
							</label>
						</div>

						<div class="col-md-12 form-group">
							<label for="overview"
								class="control-label">Description</label>
							<textarea
								id="overview"
								v-model="playlistForm.description"
								rows="4"
								class="form-control"
								placeholder="Enter overview" />
						</div>

						<div v-if="showAddLessonsForm"
							class="addLessonForm">
							<div class="col-md-12 form-group"
								style="margin-bottom: 0.5rem;">
								<label for="lessons"
									class="control-label d-block">Playlist Lessons</label>
							</div>

							<div class="row col-md-12 form-group"
								style="padding-right: 0;">
								<div class="form-group col-md-12">
									<multiselect
										id="lessons"
										:multiple="true"
										:searchable="true"
										:loading="isLoading"
										v-model="playlistForm.lessons"
										:internal-search="false"
										:options="lessonList"
										:options-limit="15"
										:clear-on-select="false"
										:close-on-select="false"
										placeholder="Type to search for lesson"
										track-by="id"
										label="title"
										@search-change="getLessonsAsync" />
								</div>

								<!--TODO: Add Lesson Creation form using circular references-->
								<!--<div-->
								<!--class="form-group col-md-4"-->
								<!--style="padding-right: 0">-->
								<!--<button-->
								<!--id="addLessonButton"-->
								<!--class="btn btn-primary btn-block btn-lg "-->
								<!--type="button"-->
								<!--data-toggle="collapse"-->
								<!--data-target="#collapseAddNewLesson"-->
								<!--aria-expanded="false"-->
								<!--aria-controls="collapseExample"-->
								<!--@click="toggleAddLesson">-->
								<!--<i class="fas fa-plus-circle icon"/>Add New Lesson to Playlist-->
								<!--</button>-->
							</div>

							<!--<div-->
							<!--id="collapseAddNewLesson"-->
							<!--class="offset-md-1 col-md-10 collapse">-->
							<!--<div class="card">-->
							<!--<div class="card-body">-->
							<!--<h5 class="card-title">Add New Lesson to Playlist</h5>-->
							<!--<CreateLesson-->
							<!--show-add-playlist-form="false"-->
							<!--name="PlaylistLesson"-->
							<!--@lesson_created="onNewLessonCreated"/>-->
							<!--</div>-->
							<!--</div>-->
							<!--</div>-->
						</div>

						<!-- tags section -->
						<div class="row col-md-12 form-group">
							<div class="form-group col-md-12">
								<label for="tags"
									class="control-label">Playlist Tags</label>
								<div class="chips chips-initial input-field">
									<div
										v-for="(tag,index) in playlistForm.tags"
										:key="`${tag}-${index}`"
										class="chip">
										{{ tag }}
										<span class="closebtn"
											@click="deleteTag(tag)">&times;</span>
									</div>
								</div>
							</div>
							<br>
							<div class="form-group col-md-8">
								<input
									ref="newTag"
									type="text"
									class="form-control"
									placeholder="Enter Tag"
									@keyup="addTagEnter">
							</div>
							<div class="form-group col-md-4"
								style="padding-right: 0;">
								<button class="btn btn-primary btn-block btn-md"
									type="button"
									@click="addTag">
									<i class="fas fa-plus-circle icon" />Add Tag
								</button>
							</div>
						</div>

						<div class="col-md-12 form-group">
							<button id="savePlaylist"
								type="button"
								class="btn btn-success"
								@click="savePlaylist">
								<i v-if="isSaving"
									class="fas fa-circle-notch fa-spin" />
								<span v-else>Create Playlist</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { ability } from "../../config/ability";
import { mapState } from "vuex";

export default {
	name: "CreatePlaylist",

	components: {
		Multiselect
	},

	beforeRouteEnter(to, from, next) {
		if (ability.can("create", "playlists")) {
			return next();
		}
		return next({ path: "/403" });
	},

	props: {
		showAddLessonsForm: {
			type: Boolean,
			default: true
		}
	},

	data: function() {
		return {
			playlistForm: new Form({
				autoReset: false,
				name: "",
				cover: "",
				author_id: this.author_id,
				description: "",
				lessons: [],
				tags: []
			}),
			isLoading: false,
			isSaving: false,
			searchTimer: "",
			lessonList: []
		};
	},

	computed: {
		...mapState("auth", {
			author_id: "id",
		}),
	},

	methods: {
		submitPrevent(e) {
			return e.preventDefault();
		},
		deleteTag(tag) {
			this.playlistForm.tags = this.playlistForm.tags.filter(
				element => element !== tag
			);
		},
		addTag(event) {
			this.playlistForm.tags.push(this.$refs.newTag.value);
			return (this.$refs.newTag.value = "");
		},
		addTagEnter(e) {
			if (e.keyCode === 13) {
				e.preventDefault();
				this.playlistForm.tags.push(this.$refs.newTag.value);
				return (this.$refs.newTag.value = "");
			}
			return null;
		},
		getLessonsAsync: function(query) {
			clearTimeout(this.searchTimer);
			this.searchTimer = setTimeout(this.lessonSearch(query), 500);
		},

		lessonSearch(query) {
			if (query) {
				this.isLoading = true;
				axios.get("/api/search/lesson/" + query).then(({ data }) => {
					this.lessonList = data.map(function(lesson) {
						return { title: lesson.title, id: lesson.id };
					});
					this.isLoading = false;
				});
			}
		},

		clearAll() {
			this.lessonList = [];
		},

		onPlaylistImageChange: function(e) {
			let files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;
			this.createImage(files[0]);
		},

		createImage(file) {
			let reader = new FileReader();
			let vm = this;
			reader.onload = e => {
				vm.playlistForm.cover = e.target.result;
			};
			reader.readAsDataURL(file);
		},

		// toggleAddLesson: function(){
		// 	$("#collapseAddNewLesson").toggleClass("show");
		//
		// 	let buttonText = $("#addLessonButton");
		// 	if (buttonText.html().indexOf("Add New Lesson to Playlist") >= 0){
		// 		buttonText.html("<i class=\"fas fa-times-circle icon\"/>Cancel");
		// 	} else if (buttonText.html().indexOf("Cancel") >= 0){
		// 		buttonText.html("<i class=\"fas fa-plus-circle icon\"/>Add New Lesson to Playlist");
		// 	}
		// },

		onNewLessonAdded: function(newLesson) {
			let mappedLesson = { title: newLesson.title, id: newLesson.id };
			this.playlistForm.lesson.push(mappedLesson);
		},

		setAuthor: function() {
			this.playlistForm.author_id = this.author_id;
		},

		unMapArrays() {
			if (this.playlistForm.lessons !== null) {
				this.playlistForm.lessons = this.playlistForm.lessons.map(function(
					lesson
				) {
					return lesson.id;
				});
			}
		},

		savePlaylist: function() {
			this.isSaving = true;
			this.setAuthor();
			this.unMapArrays();
			this.playlistForm
				.post("/api/playlist")
				.then(response => {
					toastr["success"](response.message);
					this.isSaving = false;
					this.$emit("playlist-added", response.data);
				})
				.catch(response => {
					toastr["error"](response.message);
					this.isSaving = false;
				});
		}
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
#collapseAddNewLesson {
	margin-top: 20px;
	margin-bottom: 20px;
}

#addLessonButton {
	min-height: 100%;
	font-size: small;
}

.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}

#savePlaylist {
	min-width: 200px;
	text-align: center;

	i.fas.fa-circle-notch {
		margin-right: 10px;
	}
}
</style>
