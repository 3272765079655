<template>
	<div>
		<div class="page-section">
			<div class="container mb-4 mt-3">
				<div class="row">
					<div class="col-sm-3">
						<h4>Featured Playlists</h4>
					</div>
					<div class="col-sm-9"/>
				</div>
			</div>

			<div
				class="container mb-3">
				<div class="row mt-4">
					<div class="col-md-12">
						<div class="input-group">
							<input ref="searchPlaylistValue"
								   class="form-control"
								   placeholder="Search Featured Playlists"
								   type="text">
							<div class="input-group-append">
								<button class="btn btn-success"
										@click="searchPlaylists">
									Search
								</button>
							</div>
							<br>
						</div>
					</div>
				</div>
			</div>
		</div>
		<br>
		<div class="page-section">
			<div class="container">
				<!-- lesson grid -->
				<div class="row">
					<div class="col-md-12">
						<div v-if="playlists !== null && loading === false">
							<thumbnailIndex :playlists="playlists"
											grid/>
						</div>
						<div v-else-if="loading === true"
							 class="text-center">
							<div class="spinner-border text-muted"/>
						</div>
						<div v-else>
							<p>No Data</p>
						</div>
					</div>
				</div>
				<!-- pagination -->
				<div class="row">
					<div class="col-md-12">
						<div class="clearfix">
							<span class="float-left"/>
							<span class="float-right">
								<pagination v-if="links && meta"
											:links="links"
											:meta="meta"
											@first="(url) => updatePlaylistsTable(url)"
											@last="(url) => updatePlaylistsTable(url)"
											@next="(url) => updatePlaylistsTable(url)"
											@prev="(url) => updatePlaylistsTable(url)"/>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<assign-to-class type="playlist"/>
	</div>
</template>

<script>
import thumbnailIndex from "../../components/lesson-playlist/thumbnailIndex.vue";
import assignToClass from "../../components/classroom/assign-to-classroom";
import axiosDebounce from "../../utility/debounce";
import {EventActionEnum, EventLabelEnum} from "../../config/enums";
import pagination from "../../components/utility/pagination.vue";

export default {
	components: {
		thumbnailIndex,
		assignToClass,
		pagination
	},
	data() {
		return {
			playlists: null,
			links: null,
			meta: null,
			loading: true,
		};
	},
	mounted() {
		this.fetchAllPlaylists();
	},
	updated() {
		this.playlists === null ? this.fetchAllPlaylists() : null;
	},
	computed: {
		userEventCategory() {
			return this.$store.getters["auth/getEventCategoryForUserType"];
		}
	},
	methods: {
		fetchAllPlaylists() {
			return axios
				.get("/api/playlist")
				.then(({data, links, meta}) => {
					this.playlists = data;
					this.loading = false;
					this.links = links;
					this.meta = meta;
					// log with GA on the particular search
					this.$gtag.event(EventActionEnum.View, {
						"event_category": this.userEventCategory,
						"event_label": EventLabelEnum.Playlists,
						"value": "Featured Playlists"
					});
				})
				.catch(({message}) => {
					this.loading = false;
					toastr["error"]("Could not fetch Playlists");
				});
		},
		updatePlaylistsTable(url) {
			this.loading = true;
			return axios
				.get(url)
				.then(({data, meta, links}) => {
					this.playlists = data;
					this.loading = false;
					this.links = links;
					this.meta = meta;
				})
				.catch(error => {
					toastr["error"]("Could not fetch Playlists");
				});
		},
		searchPlaylists() {
			this.loading = true;
			if (this.$refs.searchPlaylistValue.value !== "") {
				axiosDebounce({
					method: "get",
					url: `/api/search/playlist/${this.$refs.searchPlaylistValue.value}`,
					timeout: 60000
				})
					.then(({data, links, meta}) => {
						this.playlists = data;
						this.loading = false;
						this.links = links;
						this.meta = meta;
					})
					.catch(error => {
						this.loading = false;
						toastr["error"]("Could not find a Playlist");
					});
			} else {
				this.fetchAllPlaylists();
			}

		}
	}
};
</script>

<style lang="scss" scoped>
.filter-toggle {
	font-size: 14px;
	color: $green;
	cursor: pointer;
}

.collapse-body {
	border-top: solid 0.2px $gray-300;
}
</style>
