<template>
	<div>
		<div class="page">
			<div class="row">
				<div class="col-md-12">
					<h3>All {{ resource }}s</h3>
					<br>
					<!-- breadcrumb -->
					<div class="row">
						<div class="col-md-4">
							<b-breadcrumb :items="breadcrumbitems" />
						</div>
						<div class="col-md-4" />
						<div class="col-md-4">
							<div class="input-group">
								<div class="input-group-prepend" />
								<input
									ref="searchValue"
									class="form-control"
									placeholder="Search"
									type="text"
									@keyup.enter="searchResources()">
								<div class="input-group-append">
									<button class="btn btn-success"
										@click="searchResources()">
										Search
									</button>
									<button class="btn btn-info"
										@click="viewResource('new')">
										Create {{ resource }}
									</button>
								</div>
								<br>
							</div>
						</div>
					</div>

					<!-- table -->
					<div>
						<table class="table table-hover">
							<thead>
								<tr>
									<th><input v-model="bulkAllToggle" type="checkbox"></th>
									<th v-for="header in tableHeaders"
										:key="header">
										{{ header }}
									</th>
								</tr>
							</thead>
							<tbody v-if="resources && !loading && resources.length > 0">
								<tr
									v-for="(item,index) in resources"
									:key="index"
									style="cursor: pointer;">
									<td>
										<input :id="`item-${index}`"
											:ref="`item-${index}`"
											v-model="checkedItems"
											:value="item.id"
											type="checkbox">
									</td>
									<td v-for="data in tableData"
										:key="data"
										@click="viewResource(item.id)">
										<!-- handle for images -->
										<div v-if="data === 'cover'">
											<img :src="item[`${data}`]"
												alt=""
												class="img-thumbnail"
												height="75px"
												width="100px">
										</div>
										<div v-if="data === 'avatar'">
											<img
												:src="item[`${data}`] ? item[`${data}`] : '/images/default-profile.png'"
												class="img-thumbnail"
												height="75px"
												width="100px">
										</div>
										<!-- handle for authors full names -->
										<div
											v-else-if="data === 'author'">
											{{ item[`${data}`].first_name }} {{ item[`${data}`].last_name }}
										</div>
										<div
											v-else-if="resource === 'user' && data === 'name'">
											{{ item["first_name"] }} {{ item["last_name"] }}
										</div>

										<!-- handle unpublished lessons -->
										<div
											v-else-if="resource === 'lesson' && data === 'title' && item.published_at === null">
											{{ item[`${data}`] }}
											<span id="draft">
												(Draft)
											</span>
										</div>
										<!-- <div v-else-if="data === 'roles'">{{item.roles[0]}}</div> -->
										<div v-else>
											{{ item[`${data}`] }}
										</div>
									</td>
								</tr>
							</tbody>
							<tbody v-else-if="loading">
								Loading resources...
							</tbody>
							<tbody v-else>
								<br>
								No {{ resource }} found
							</tbody>
						</table>
						<div class="clearfix">
							<span class="float-left">
								<BulkActionsButtonGroup
									v-if="bulkVisible"
									:busy="loading"
									:delete-action="true"
									:publish-action="resource === 'lesson'"
									:unpublish-action="resource === 'lesson'"
									@bulkDelete="bulkDeleteItems"
									@bulkPublish="bulkPublishItems"
									@bulkUnpublish="bulkUnpublishItems" />
							</span>
							<span class="float-right">
								<pagination v-if="links && meta"
									:links="links"
									:meta="meta"
									@first="(url) => updateResourcesTable(url)"
									@last="(url) => updateResourcesTable(url)"
									@next="(url) => updateResourcesTable(url)"
									@prev="(url) => updateResourcesTable(url)" />
							</span>
						</div>
					</div>
					<br>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import BulkActionsButtonGroup from "../../components/forms/bulk-actions-button-group.vue";
import pagination from "../../components/utility/pagination.vue";

export default {
	data() {
		return {
			pageTitle: "",
			breadcrumbitems: [
				{
					text: "Lessons",
					href: "#"
				},
				{
					text: "All",
					active: true
				}
			],
			tableHeaders: [],
			tableData: [],
			resource: "",
			resources: null,
			links: null,
			meta: null,
			loading: true,
			plans: null,
			checkedItems: [],
			bulkVisible: false,
			bulkAllToggle: false,
		};
	},
	components: {
		BulkActionsButtonGroup,
		pagination
	},
	created() {
		this.setResource();
		this.fetchResources();
	},
	updated() {
		this.resources === null ? this.fetchResources() : null;
	},
	watch: {
		"$route.path": {
			handler(val, oldVal) {
				if (val !== oldVal) {
					this.setResource();
					this.fetchResources();
					this.checkedItems = [];
					this.bulkVisible = false;
					this.bulkAllToggle = false;
				}
			},
			deep: true,
		},
		checkedItems: {
			handler(val) {
				this.bulkVisible = val.length > 0;
			},
			deep: true,
		},
		bulkAllToggle: {
			handler(val) {
				this.toggleAll(val);
			},
			deep: true,
		}
	},
	methods: {
		toggleAll(val) {
			this.bulkVisible = val;
			this.resources.forEach((data, index) => {
				this.$refs[`item-${index}`][0].checked = val;
				if (val) {
					this.checkedItems.push(data.id);
				} else {
					this.checkedItems = [];
				}
			});
		},
		async bulkDeleteItems() {
			try {
				this.loading = true;
				if (this.checkedItems.length === 0) {
					return toastr["error"]("Cannot bulk delete nothing!");
				}
				let url = this.resource;
				await axios.delete(`/api/${url}/bulk?ids=${this.checkedItems.join(",")}`,);
				toastr["success"]("Items successfully deleted");
			} catch (e) {
				toastr["error"]("Unable to perform bulk delete");
			} finally {
				this.loading = false;
				await this.fetchResources();
			}
		},
		async bulkPublishItems() {
			try {
				this.loading = true;
				if (this.checkedItems.length === 0) {
					return toastr["error"]("Cannot bulk publish nothing!");
				}
				let url = this.resource;
				await axios.get(`/api/${url}/bulk/publish?ids=${this.checkedItems.join(",")}`,);
				toastr["success"]("Items successfully published!");
			} catch (e) {
				toastr["error"]("Unable to perform bulk publish");
			} finally {
				this.loading = false;
				await this.fetchResources();
			}
		},
		async bulkUnpublishItems() {
			try {
				this.loading = true;
				if (this.checkedItems.length === 0) {
					return toastr["error"]("Cannot bulk unpublish nothing!");
				}
				let url = this.resource;
				await axios.get(`/api/${url}/bulk/unpublish?ids=${this.checkedItems.join(",")}`,);
				toastr["success"]("Items successfully unpublished!");
			} catch (e) {
				toastr["error"]("Unable to perform bulk unpublish");
			} finally {
				this.loading = false;
				await this.fetchResources();
			}
		},
		getPlans() {
			axios
				.get("/api/plan")
				.then(({data}) => {
					this.plans = data;
				})
				.catch(({message}) => {
					toastr["error"]("Could not load plans");
				});
		},
		setResource() {
			switch (this.$route.path) {
			case "/admin/lessons":
				this.breadcrumbitems[0].text = "Lessons";
				this.resource = "lesson";
				this.tableHeaders = [
					"Cover",
					"Hero",
					"Title",
					"Focus",
					"Author"
				];
				this.tableData = [
					"cover",
					"hero",
					"title",
					"focus",
					"author"
				];
				break;
			case "/admin/contents":
				this.breadcrumbitems[0].text = "Contents";
				this.resource = "content";
				this.tableHeaders = [
					"Title",
					"Type",
					"Text",
					"Notes",
					"Order"
				];
				this.tableData = [
					"title",
					"type",
					"text",
					"notes",
					"order"
				];
				break;
			case "/admin/playlists":
				this.breadcrumbitems[0].text = "Playlists";
				this.resource = "playlist";
				this.tableHeaders = ["Cover", "Title", "Description"];
				this.tableData = ["cover", "name", "description"];
				break;
			case "/admin/users":
				this.breadcrumbitems[0].text = "Users";
				this.resource = "user";
				this.tableHeaders = [
					"Avatar",
					"Name",
					"Email",
					"Plan",
					"Subscription"
				];
				this.tableData = [
					"avatar",
					"name",
					"email",
					"plan",
					"status"
				];
				this.getPlans();
				break;
			default:
				return this.$router.replace({
					path: "/404"
				});
			}
		},
		fetchResources() {
			let url = this.resource;
			if (this.resource === "lesson") {
				url = "lesson/all";
			}

			return axios
				.get(`/api/${url}`)
				.then(({data, links, meta}) => {
					this.resources = data;
					if (this.resource === "user") {
						this.resources = this.resources.filter(user => {
							this.plans.forEach(plan => {
								if (+user.plan_id === +plan.id) {
									user.plan = plan.name;
									if (user.subscription.status === "none") {
										user.status = "No Subscription";
									} else {
										user.status = `${
											user.subscription.name
										}- ${user.subscription.trial_ends}`;
									}
								}
								if (+user.plan_id === 1) {
									user.plan = "Super Admin";
									user.status = "n/a";
								}
							});
							return true;
						});
					}
					this.loading = false;
					this.links = links;
					this.meta = meta;
				})
				.catch(error => {
					this.loading = false;
					toastr["error"](`Error: Could not fetch ${this.resource}s`);
				});
		},
		viewResource(id) {
			return this.$router.push({
				path: `/admin/${this.resource}s/${id}`
			});
		},
		updateResourcesTable(url) {
			this.loading = true;
			return axios
				.get(url)
				.then(({data, links, meta}) => {
					this.resources = data;
					if (this.resource === "user") {
						this.resources = this.resources.filter(user => {
							this.plans.forEach(plan => {
								if (+user.plan_id === +plan.id) {
									user.plan = plan.name;
									if (user.subscription.status === "none") {
										user.status = "No Subscription";
									} else {
										user.status = `${
											user.subscription.name
										}- ${user.subscription.trial_ends}`;
									}
								}
								if (+user.plan_id === 1) {
									user.plan = "Super Admin";
									user.status = "n/a";
								}
							});
							return true;
						});
					}
					this.loading = false;
					this.links = links;
					this.meta = meta;
				})
				.catch(error => {
					this.loading = false;
					toastr["error"](`Error: Could not fetch ${this.resource}s`);
				});
		},
		searchResources() {
			this.loading = true;
			return axios
				.get(
					`/api/search/${this.resource}/${
						this.$refs.searchValue.value
					}`
				)
				.then(({data, links, meta}) => {
					this.resources = data;
					this.loading = false;
					this.links = links;
					this.meta = meta;
				})
				.catch(error => {
					this.loading = false;
					toastr["error"](`Error: Could not fetch ${this.resource}s`);
				});
		}
	}
};
</script>
<style lang="scss" scoped>
h3 {
	padding-left: 10px;
}

.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}

#draft {
	color: #9c9c9c;
}
</style>
<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}
</style>

