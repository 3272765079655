<template>
	<a href="#"
		v-clipboard:copy="copy"
		v-clipboard:success="handleSuccess"
		v-clipboard:error="handleError">{{ message }}</a>
</template>

<script>
export default {
	props: {
		message: {
			type: String,
			default: "Click to Copy"
		},
		copy: {
			type: String,
			default: ""
		}
	},
	methods: {
		handleSuccess() {
			return toastr["success"]("Copied to Clipboard");
		},
		handleError() {
			return toastr["error"]("Unable to copy to clipboard");
		}
	}
};
</script>

<style>

</style>
