<template>
	<b-modal v-if="visible"
		centered
		size="xl"
		hide-footer
		hide-header
		@hide="$emit('hide')">
		<div v-if="visible"
			id="lesson"
			class="container">
			<h2 v-if="lesson.id"
				class="page-title">
				<span>Lesson:</span>
				<i v-if="lesson.type"
					:class="lesson.type_icon"
					:title="lesson.type"
					class="fas"
					data-toggle="tooltip" />
				{{ lesson.title }}
				<p v-if="lesson.classification"
					class="classification">
					{{ lesson.classification }}
				</p>
			</h2>


			<div class="content-container">
				<div class="page-section">
					<div class="row">
						<div class="col-lg-9 col-md-8 lesson-overview-text">
							<h4>Focus</h4>
							<div>{{ lesson.focus }}</div>

							<h4>Overview</h4>
							<div v-html="lesson.overview" />
							<ul v-if="lesson.tags[0]"
								class="tags">
								<li v-for="tag in lesson.tags"
									:key="tag.id"
									class="tag">
									{{ tag.name }}
								</li>
							</ul>
						</div>
						<div class="col-lg-3 col-md-4 lesson-sidebar">
							<span class="sidebar-item">
								<i class="fas fa-share-alt icon" />
								<a href="#"
									class="underline pl-2"
									@click="shareLesson()">Share Lesson</a>
							</span>
							<!--Share Lesson Modal Component -->
							<b-modal ref="sharemodalRef"
								centered
								size="lg"
								hide-footer
								hide-header>
								<div class="text-center">
									<img :src="lesson.cover"
										class="img-responsive rounded"
										width="100%"
										height="100%">
									<br>
									<br>
									<h4>Hi, check out this content on Get Media L.I.T!</h4>
									<div v-html="lesson.focus" />
									<br>
									<button
										class="btn btn-lg btn-primary"
										data-toggle="tooltip"
										data-placement="bottom"
										title="Share on Twitter"
										@click="shareLesson('twitter')">
										<i class="fab fa-twitter" />
										Twitter
									</button>
									<button
										class="btn btn-lg btn-primary"
										data-toggle="tooltip"
										data-placement="bottom"
										title="Share on Facebook"
										@click="shareLesson('facebook')">
										<i class="fab fa-facebook-f" />
										Facebook
									</button>
									<button
										class="btn btn-lg btn-primary"
										data-toggle="tooltip"
										data-placement="bottom"
										title="Share on Linkedin"
										@click="shareLesson('linkedin')">
										<i class="fab fa-linkedin-in" />
										LinkedIn
									</button>
									<button
										v-clipboard:copy="shareText + ' \n ' + decodeURIComponent(shareLink)"
										v-clipboard:success="onCopy"
										v-clipboard:error="onCopyError"
										class="btn btn-lg btn-primary"
										data-toggle="tooltip"
										data-placement="bottom"
										title="Copy to Clipboard">
										<i class="fas fa-copy" />
										Copy
									</button>
								</div>
							</b-modal>

							<span class="sidebar-item">
								<i class="fas fa-sun icon" />
								<a href="#"
									class="underline pl-2"
									@click="showModal">View Standards</a>
							</span>

							<b-modal id="standards-modal"
								ref="myModalRef"
								centered
								size="lg"
								hide-footer
								hide-header>
								<div class="d-block">
									<h5 class="text-center">
										Lesson Standards
									</h5>
									<br>
									<pre v-html="lesson.standards" />
									<br>
								</div>
								<button class="btn btn-md btn-info"
									@click="hideModal">
									Close
								</button>
							</b-modal>

							<span v-if="lesson.hero"
								class="sidebar-item">
								<img :src="`/images/hero/poses/${lesson.hero}0${hero_select}.png`"
									class="lesson-hero">
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
export default {
	name: "LessonDetailModal",
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		lesson: {
			type: Object,
			default: () => {},
		}
	},
	data() {
		return {
			hero_select: Math.floor(Math.random() * 5) + 1,
			status: {
				text: "Add to Favorites",
				icon: "far fa-heart",
				added: false
			},
			shareTitle: "Get Media L.I.T!",
			shareText: "Hey, Check out this content on Get Media L.I.T!",
			shareLink: encodeURIComponent(document.location.toString()),
		};
	},
	methods: {
		showModal() {
			this.$refs.myModalRef.show();
		},

		hideModal() {
			this.$refs.myModalRef.hide();
			this.$refs.sharemodalRef.hide();
		},

		shareLesson(type) {
			this.$refs.sharemodalRef.show();
			switch (type) {
			case "facebook":
				window.open(
					`https://www.facebook.com/sharer/sharer.php?u=${this.shareLink}`,
					"_blank"
				);
				break;
			case "twitter":
				window.open(
					`https://twitter.com/home?status=${this.shareText} ${this.shareLink}`,
					"_blank"
				);
				break;
			case "linkedin":
				window.open(
					`https://www.linkedin.com/sharing/share-offsite/?url=${this.shareLink}&title=${this.shareTitle}"
					&summary=${this.shareText}&source=`,
					"_blank"
				);
				break;
			default:
				break;
			}
		},

		onCopy: function(e) {
			toastr.success("Copied to Clipboard");
		},

		onCopyError: function(e) {
			toastr.error("Could not copy to Clipboard");
		}
	}
};
</script>

<style lang="scss" scoped>
	ul {
		width: 100%;
		margin-bottom: 20px;
		overflow: hidden;
		list-style-type: circle !important;
	}

	li {
		display: inline;
		float: left;
	}

	.double li {
		width: 50%;
	}

	.lesson-overview-text {
		@include breakpoint($sm) {
			padding: $container-padding;
		}

		div {
			margin-bottom: 30px;
		}
	}

	.lesson-content-page {
		.display-content {
			margin-top: 20px;
		}
	}

	.lesson-sidebar {
		padding: $container-padding;
		padding-left: 20px;

		@include breakpoint($sm) {
			border-left: 1px solid #f5f6f9;
		}

		.sidebar-item {
			display: block;
			padding: 10px;
		}

		@include breakpoint($xs) {
			#start-lesson {
				position: fixed;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 75px;
				background: white;

				button {
					display: block;
				}
			}
		}
	}

	.tags {
		padding: 0;
		margin: 0;
		overflow: hidden;
		list-style: none;
	}

	.tags li {
		float: left;
	}

	.tag {
		position: relative;
		display: inline-block;
		height: 26px;
		padding: 0 20px 0 23px;
		margin: 0 10px 10px 0;
		line-height: 26px;
		color: #999999;
		text-decoration: none;
		background: #eeeeee;
		border-radius: 3px 0 0 3px;
		-webkit-transition: color 0.2s;
	}

	.tag::before {
		position: absolute;
		top: 10px;
		left: 10px;
		width: 6px;
		height: 6px;
		content: "";
		background: #ffffff;
		border-radius: 10px;
		box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
	}

	.tag::after {
		position: absolute;
		top: 0;
		right: 0;
		content: "";
		background: #ffffff;
		border-top: 13px solid transparent;
		border-bottom: 13px solid transparent;
		border-left: 10px solid #eeeeee;
	}

	.tag:hover {
		color: white;
		background-color: $dark-blue;
	}

	.tag:hover::after {
		border-left-color: $dark-blue;
	}
</style>

<style lang="scss" scoped>
	#lesson {
		padding: 0 !important;

		.classification {
			float: right;
			padding: 10px;
			margin: auto 20px;
			font-size: 13px;
			color: #ffffff;
			text-transform: uppercase;
			background-color: $theme;

			@include breakpoint($xs) {
				display: none;
			}
		}
	}
</style>


<style lang="scss">
	#default-page {
		background-size: 80% !important;
	}
</style>
