<template>
	<div v-if="items" :class="classes1">
		<div :id="'hscroll' + _uid" :class="classes2">
			<div v-if="!grid"
				class="hscroll-button arrow-button left"
				@click="scrollBackward">
				<i class="fas fa-fw fa-chevron-left" />
			</div>

			<div v-for="(item,index) in items"
				:key="item.id"
				:class="classes3">
				<div v-if="showingPlaylist">
					<thumbnail :playlist="item"
						class="thumbnail"
						:assign-to-class-visible="assignToClassVisible" />
				</div>
				<div v-else>
					<div v-if="playlistId">
						<thumbnail :playlist-id="playlistId"
							:lesson="item"
							:lesson-sequence="index + 1"
							class="thumbnail"
							:assign-to-class-visible="assignToClassVisible" />
					</div>
					<div v-else>
						<thumbnail :lesson="item"
							class="thumbnail"
							:assign-to-class-visible="assignToClassVisible" />
					</div>
				</div>
			</div>

			<div v-if="!grid"
				class="hscroll-button arrow-button right"
				@click="scrollForward">
				<i class="fas fa-fw fa-chevron-right" />
			</div>
		</div>

		<div v-if="viewAllUrl" class="view-all">
			<button class="btn btn-md btn-primary">
				<router-link :to="viewAllUrl">
					View All
				</router-link>
			</button>
		</div>
	</div>
</template>

<script>
import thumbnail from "./thumbnail.vue";

export default {
	name: "ThumbnailIndex",

	components: {
		thumbnail
	},

	props: {
		assignToClassVisible: {
			type: Boolean,
			default: true
		},
		// use to display grid view
		grid: {
			type: Boolean,
			default: false
		},

		// Use if there are more items than have been passed to the scroll list
		viewAllUrl: {
			type: String,
			default: ""
		},

		//Use when displaying lessons only
		lessons: {
			type: Array,
			default: null
		},

		// Use when displaying playlists only
		playlists: {
			type: Array,
			default: null
		},

		// Use together with lessons when displaying lessons as part
		// of a playlist to properly route to playlist
		playlistId: {
			type: Number,
			default: null
		},
		lessonSequence: {
			type: Number,
			default: null
		}
	},

	data() {
		return {
			items: null,
			showingPlaylist: false,
			classes1: "lessonScroll page-section",
			classes2: "lesson-hscroll",
			classes3: "",
			gridClasses1: "page-section",
			gridClasses2: "row",
			gridClasses3: "col-md-6 col-lg-4 col-xl-3 text-center mb-5"
		};
	},

	created() {
		if (this.playlists) {
			this.showingPlaylist = true;
			this.items = this.playlists;
		} else {
			this.items = this.lessons;
		}
	},

	mounted() {
		if (this.grid) {
			this.classes1 = this.gridClasses1;
			this.classes2 = this.gridClasses2;
			this.classes3 = this.gridClasses3;
		}
	},

	methods: {
		sideScroll(element, direction, speed, distance, step) {
			let scrollAmount = 0;
			let slideTimer = setInterval(function () {
				if (direction === "left") {
					element.scrollLeft -= step;
				} else {
					element.scrollLeft += step;
				}
				scrollAmount += step;
				if (scrollAmount >= distance) {
					window.clearInterval(slideTimer);
				}
			}, speed);
		},

		scrollForward() {
			this.sideScroll(
				document.querySelector(
					"#hscroll" + this._uid + ".lesson-hscroll"
				),
				"right",
				5,
				500,
				10
			);
		},

		scrollBackward() {
			this.sideScroll(
				document.querySelector(
					"#hscroll" + this._uid + ".lesson-hscroll"
				),
				"left",
				5,
				500,
				10
			);
		}
	}
};
</script>


<style lang="scss" scoped>
.lessonScroll {
	position: relative;
	padding: $page-section-padding;
	background-color: transparent;

	.lesson-hscroll {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;

		.hscroll-button {
			position: absolute;
			// Height of each image in panel is known to be 150 px else top would be 50% for true center
			transform: translateY(calc($lesson-thumb-image-height / 4));

			&.left {
				left: 0;
			}

			&.right {
				right: 0;
			}
		}

		.thumbnail {
			margin-right: 5px;
			margin-left: 5px;
		}

		&::-webkit-scrollbar {
			width: 0.5px; /* remove scrollbar space */
			background: transparent; /* optional: just make scrollbar invisible */
		}

		/* optional: show position indicator in red */
		&::-webkit-scrollbar-thumb {
			display: none;
			background: $theme;
			background-clip: content-box;
			border: 6px solid transparent;
			border-radius: 9px;
		}

		&:hover {
			&::-webkit-scrollbar-thumb {
				display: block;
			}
		}
	}

	.view-all {
		text-align: right;

		a {
			color: $white;
		}
	}
}
</style>
