<template>
	<multiselect
		id="groups"
		:searchable="true"
		:multiple="false"
		:taggable="false"
		:loading="loading"
		v-model="groups"
		:internal-search="false"
		:options="options"
		:options-limit="10"
		track-by="id"
		label="name"
		name="groups"
		placeholder="Search for Parent Group"
		@search-change="searchGroupsAsync"/>
</template>

<script>
import multiselect from "vue-multiselect";
import EventBus from "../../utility/eventbus";

export default {
	components: {
		multiselect,
	},
	data() {
		return {
			loading: false,
			groups: [],
			options: [],
			searchTimer: null,
		};
	},
	mounted() {
		EventBus.$on("SET-INITIAL-GROUP", (data) => {
			if (data != null) {
				this.groups = [data];
				this.options = [data];
				this.$forceUpdate();
			}
		});
	},
	watch: {
		$props: {
			handler(val) {
				if (val != null && Object.keys(val) > 0) {
					this.groups = val;
					this.options = [val];
				}
			},
			deep: true,
			immediate: true
		},
		groups: {
			handler(val) {
				this.$emit("updatedData", this.groups);
			},
			deep: true,
		},
	},
	methods: {
		searchGroupsAsync(query) {
			if (this.searchTimer !== null) {
				clearTimeout(this.searchTimer);
			}
			this.searchTimer = setTimeout(this.searchGroups(query), 500);
		},
		async searchGroups(query) {
			try {
				if (query) {
					this.loading = true;
					const response = await axios({
						method: "get",
						url: `/api/search/group/${query}`,
						timeout: 60000
					});
					this.options = response.data;
					this.loading = false;
				}
			} catch (error) {
				this.loading = false;
				return null;
			}

		},
	}
};
</script>
