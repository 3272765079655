<template>
	<div id="default-page">
		<app-header v-if="!inIframe" is-public />
		<div class="page-wrapper">
			<div class="container-fluid">
				<router-view />
			</div>
		</div>
		<app-footer v-if="!inIframe" />
	</div>
</template>

<script>
import AppHeader from "../components/layout/header.vue";
import AppFooter from "../components/layout/footer.vue";

export default {
	name: "PublicLayout",
	components: {
		AppHeader,
		AppFooter
	},

	mounted() {
		dataLayer.push({
			"visitorLoginState": "logged-out",
		});
	},

	computed: {
		inIframe() {
			// TODO: Add public header and Footer and use this method to hide them in iframe
			try {
				return window.self !== window.top;
			} catch (e) {
				return true;
			}
		}
	},
};

</script>

<style lang="scss" scoped>
.page-wrapper {
	background-color: #ffffff;
	min-height: 100vh;
	//padding-top: $header-height;
	padding-bottom: $footer-height + 50px;
}
</style>
