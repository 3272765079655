<template>
	<div id="page"
		class="row align-middle">
		<div id="image"
			class="col-6">
			<img src="/images/403.png">
		</div>
		<div id="text"
			class="col-6">
			<h1 id="fade">
				403
			</h1>
			<h1>You are not authorized here</h1>
			<p>It looks like you've tried to reach something you shouldn't have...</p>
			<button class="btn btn-md btn-success"
				@click="backToHome">
				Home
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "Error401",
	methods: {
		backToHome() {
			return window.location.replace("/home");
		}
	}
};
</script>

<style lang="scss" scoped>
#page {
	height: 100vh;
	padding-top: 80px;

	#image {
		text-align: right;

		img {
			height: 80vh;
		}
	}

	#text {
		height: 80vh;
		padding-top: 50px;
		text-align: left;

		#fade {
			margin-bottom: -150px;
			font-family: sans-serif;
			font-size: 270px;
			color: rgba(125, 125, 125, 0.05);
		}

		h1 {
			font-size: 40px;
		}
	}
}
</style>
