<template>
	<div>
		<div class="page">
			<div class="row">
				<div class="col-md-12">
					<!--					tab navigation nav-->
					<ul id="authorizationsTabs"
						class="nav nav-tabs"
						role="tablist">
						<li class="nav-item">
							<a id="roles-tab"
								aria-controls="roles"
								aria-selected="true"
								class="nav-link active"
								data-toggle="tab"
								href="#roles"
								role="tab">Roles</a>
						</li>
						<li class="nav-item">
							<a id="permissions-tab"
								aria-controls="permissions"
								aria-selected="false"
								class="nav-link"
								data-toggle="tab"
								href="#permissions"
								role="tab">Permissions</a>
						</li>
					</ul>
					<!--					tab contents-->
					<div id="authorizationTabContent"
						class="tab-content">
						<!--						roles content-->
						<div id="roles"
							class="tab-pane fade show active"
							role="tabpanel">
							<h3>All Roles</h3>
							<br>
							<div class="row">
								<div class="col-md-12">
									<div class="input-group">
										<input ref="searchRole"
											class="form-control"
											placeholder="Search Roles"
											type="text">
										<div class="input-group-append">
											<button class="btn btn-success"
												@click="search('roles')">
												Search
											</button>
											<button class="btn btn-info"
												@click="view('new')">
												Create Role
											</button>
										</div>
										<br>
									</div>
								</div>
							</div>
							<br>
							<!-- table -->
							<div>
								<table class="table table-hover">
									<thead>
										<tr>
											<th>Name</th>
											<th>Permissions Count</th>
											<th>User Count</th>
										</tr>
									</thead>
									<tbody v-if="rolesData.roles && !loading && rolesData.roles.length > 1">
										<tr v-for="(role,index) in rolesData.roles"
											:key="index"
											style="cursor: pointer;"
											@click="view(role.id)">
											<td>{{ role.display_name }}</td>
											<td>{{ role.permissions.length ? role.permissions.length : 0 }}</td>
											<td>{{ role.users.length ? role.users.length : 0 }}</td>
										</tr>
									</tbody>
									<tbody v-else-if="loading">
										Loading Roles..
									</tbody>
									<tbody v-else>
										No roles created yet
									</tbody>
								</table>
								<div class="clearfix">
									<span class="float-left" />
									<div class="float-right">
										<pagination v-if="rolesData.links && rolesData.meta"
											:links="rolesData.links"
											:meta="rolesData.meta"
											@first="(url) => updateTable('roles',url)"
											@last="(url) => updateTable('roles',url)"
											@next="(url) => updateTable('roles',url)"
											@prev="(url) => updateTable('roles',url)" />
									</div>
								</div>
							</div>
							<br>
						</div>
						<!--						permissions content-->
						<div id="permissions"
							class="tab-pane fade"
							role="tabpanel">
							<h3>All Permissions</h3>
							<br>
							<div class="row">
								<div class="col-md-12">
									<div class="input-group">
										<input ref="searchPermission"
											class="form-control"
											placeholder="Search Permissions"
											type="text">
										<div class="input-group-append">
											<button class="btn btn-success"
												@click="search('permissions')">
												Search
											</button>
											<button
												class="btn btn-info"
												@click="permissionsModal.status = !permissionsModal.status">
												Create Permission
											</button>
										</div>
										<br>
									</div>
								</div>
							</div>
							<br>
							<!-- table -->
							<div>
								<table class="table table-hover">
									<thead>
										<tr>
											<th>Name</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody
										v-if="permissionsData.permissions && !loading && permissionsData.permissions.length > 1">
										<tr v-for="(permission,index) in permissionsData.permissions"
											:key="index"
											style="cursor: pointer;">
											<td>{{ permission.name }}</td>
											<td>
												<button class="btn btn-danger"
													type="button"
													@click="deletePermission(permission.id)">
													<i class="fas fa-trash" /> Delete
												</button>
											</td>
										</tr>
									</tbody>
									<tbody v-else-if="loading">
										Loading permissions..
									</tbody>
									<tbody v-else>
										No permissions created yet
									</tbody>
								</table>
								<div class="clearfix">
									<span class="float-left" />
									<div class="float-right">
										<pagination v-if="permissionsData.links && permissionsData.meta"
											:links="permissionsData.links"
											:meta="permissionsData.meta"
											@first="(url) => updateTable('permissions',url)"
											@last="(url) => updateTable('permissions',url)"
											@next="(url) => updateTable('permissions',url)"
											@prev="(url) => updateTable('permissions',url)" />
									</div>
								</div>
							</div>
							<br>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- The Create Permissions Modal -->
		<b-modal v-model="permissionsModal.status"
			:busy="permissionsModal.loading"
			centered
			title="Create New Permission"
			@close="modalReset"
			@ok="createPermission">
			<div>
				<div class="form-group">
					<label for="name">Permission Title:</label>
					<input id="name"
						v-model="permissionsModal.name"
						class="form-control"
						placeholder="Input permission title"
						type="text">
				</div>
				<div class="form-group">
					<label for="description">Permission Description:</label>
					<textarea id="description"
						v-model="permissionsModal.description"
						class="form-control"
						placeholder="Input permission description" />
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import axios from "axios";
import pagination from "../../components/utility/pagination.vue";

export default {
	components: {
		pagination
	},

	data() {
		return {
			rolesData: {
				roles: null,
				links: null,
				meta: null,
			},
			permissionsData: {
				permissions: null,
				links: null,
				meta: null,
			},
			permissionsModal: {
				status: false,
				name: "",
				description: "",
				loading: false
			},
			loading: true
		};
	},

	created() {
		this.fetchData();
	},

	methods: {
		// works
		async fetchData() {
			this.loading = true;
			const rolesData = await axios.get("/api/role");
			const permissionsData = await axios.get("/api/permission");
			return Promise.all([rolesData, permissionsData])
				.then(response => {
					this.loading = false;
					this.rolesData.roles = this.filterRoles(response[0].data);
					this.rolesData.links = response[0].links;
					this.rolesData.meta = response[0].meta;
					this.permissionsData.permissions = response[1].data;
					this.permissionsData.links = response[1].links;
					this.permissionsData.meta = response[1].meta;
				})
				.catch(e => {
					this.loading = false;
					return toastr["error"](
						"Could not fetch roles and permissions"
					);
				});
		},

		filterRoles(data) {
			return data.filter(element =>
				element.display_name == null
					? true
					: element.display_name.split(" ")[0].toLowerCase() !==
					"user"
			);
		},

		view(id) {
			return this.$router.push({
				path: `/admin/authorizations/${id}`
			});
		},

		modalReset() {
			return (this.permissionsModal = {
				name: "",
				description: "",
				status: false,
				loading: false
			});
		},

		createPermission(e) {
			e.preventDefault();
			let {name, description, loading} = this.permissionsModal;
			loading = true;
			if (name !== "" && description !== "") {
				return axios
					.post("/api/permission", {name, description})
					.then(({status}) => {
						if (status) {
							this.modalReset();
							this.fetchData();
							return toastr["success"]("Permission Created");
						} else {
							return toastr["error"](
								"Permission create unsuccessful"
							);
						}
					})
					.catch(e => {
						return toastr["error"]("An error occurred, try again");
					});
			}
			return toastr["error"]("Check your input entries");
		},

		deletePermission(id) {
			if (confirm("You can't undo this delete")) {
				return axios
					.delete(`/api/permission/${id}`)
					.then(({status}) => {
						if (status) {
							toastr["success"]("Deleted successfully");
							return this.fetchData();
						} else {
							return toastr["error"]("Delete unsuccessful");
						}
					})
					.catch(() => {
						return toastr["error"]("An error occurred, try again");
					});
			} else {
				return null;
			}
		},

		updateTable(tab, url) {
			this.loading = true;
			switch (tab) {
			case "roles":
				return axios
					.get(url)
					.then(({data, links, meta}) => {
						this.rolesData.roles = this.filterRoles(data);
						this.loading = false;
						this.rolesData.links = links;
						this.rolesData.meta = meta;
					})
					.catch(() => {
						return toastr["error"]("Could not fetch lessons");
					});
			case "permissions":
				return axios
					.get(url)
					.then(({data, links, meta}) => {
						this.permissionsData.permissions = data;
						this.loading = false;
						this.permissionsData.links = links;
						this.permissionsData.meta = meta;
					})
					.catch(() => {
						return toastr["error"]("Could not fetch lessons");
					});
			default:
				break;
			}
		},

		search(tab) {
			this.loading = true;
			switch (tab) {
			case "roles":
				if (this.$refs.searchRole.value === "") {
					return this.fetchData();
				}
				return axios
					.get(`/api/search/role/${this.$refs.searchRole.value}`)
					.then(({data, links, meta}) => {
						this.rolesData.roles = this.filterRoles(data);
						this.loading = false;
						this.rolesData.links = links;
						this.rolesData.meta = meta;
					})
					.catch(() => {
					});
			case "permissions":
				if (this.$refs.searchPermission.value === "") {
					return this.fetchData();
				}
				return axios
					.get(
						`/api/search/permission/${this.$refs.searchPermission.value}`
					)
					.then(({data, links, meta}) => {
						this.permissionsData.permissions = data;
						this.loading = false;
						this.permissionsData.links = links;
						this.permissionsData.meta = meta;
					})
					.catch(() => {
					});
			default:
				return;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
h3 {
	padding-left: 10px;
}

.chip {
	display: inline-block;
	padding: 0 13px;
	margin: 5px 5px;
	font-size: 12px;
	line-height: 30px;
	color: #ffffff;
	background-color: $dark-blue;
	border-radius: 16px;
	outline: none;
}

.chip:focus {
	color: #ffffff;
	background-color: #51c5c2;
}

.closebtn {
	cursor: pointer;
}

.chips.chips-initial input.chip-input {
	display: inline-block;
	float: none;
	width: 120px;
	min-width: 30px;
	border: none;
	outline: none;
}
</style>

<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}

#authorizationTabContent {
	padding: 20px;
	border-right: 1px solid #dee2e6;
	border-bottom: 1px solid #dee2e6;
	border-left: 1px solid #dee2e6;
}
</style>

