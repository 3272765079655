<template>
	<div>
		<div class="page">
			<div class="row">
				<div class="col-md-12">
					<h3>Overview</h3>
					<br>
				</div>
			</div>
			<div v-if="countStatistics.userCount && loading === false"
				class="row">
				<div class="col-md-3"
					style="cursor: pointer;"
					@click="routeToPage('/admin/users')">
					<div class="card bg-danger">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ countStatistics.userCount }}</strong> Users
							</h5>
						</div>
					</div>
				</div>
				<div class="col-md-3"
					style="cursor: pointer;"
					@click="routeToPage('/admin/lessons')">
					<div class="card bg-dark">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ countStatistics.lessonCount }}</strong> Lessons
							</h5>
						</div>
					</div>
				</div>
				<div class="col-md-3"
					style="cursor: pointer;"
					@click="routeToPage('/admin/contents')">
					<div class="card bg-success">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ countStatistics.contentCount }}</strong> Contents
							</h5>
						</div>
					</div>
				</div>
				<div class="col-md-3"
					style="cursor: pointer;"
					@click="routeToPage('/admin/playlists')">
					<div class="card bg-info">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ countStatistics.playlistCount }}</strong> Playlists
							</h5>
						</div>
					</div>
				</div>
			</div>
			<br>
			<div v-if="countStatistics.userCount && loading === false"
				class="row">
				<div class="col-md-3"
					style="cursor: pointer;"
					@click="routeToPage('/admin/groups')">
					<div class="card bg-danger">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ countStatistics.groupCount }}</strong> Groups
							</h5>
						</div>
					</div>
				</div>
				<div class="col-md-3"
					style="cursor: pointer;"
					@click="routeToPage('/admin/classrooms')">
					<div class="card bg-dark">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ countStatistics.classroomCount }}</strong> Classrooms
							</h5>
						</div>
					</div>
				</div>
				<!-- <div class="col-md-3"
					style="cursor: pointer;"
					@click="routeToPage('/admin/contents')">
					<div class="card bg-success">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ countStatistics.contentCount }}</strong> Contents
							</h5>
						</div>
					</div>
				</div>
				<div class="col-md-3"
					style="cursor: pointer;"
					@click="routeToPage('/admin/playlists')">
					<div class="card bg-info">
						<div class="card-body">
							<h5 class="card-title white-text">
								<strong>{{ countStatistics.playlistCount }}</strong> Playlists
							</h5>
						</div>
					</div>
				</div> -->
			</div>
			<br>
			<div class="row">
				<div class="col-md-12">
					<div class="card-deck">
						<div v-if="recentLessons && loading === false"
							class="card">
							<div class="card-body">
								<h5 class="card-title">
									Recent Lessons
								</h5>
								<div class="table-responsive">
									<table class="table table-hover">
										<thead class="thead-light">
											<tr>
												<th>Cover</th>
												<th>Hero</th>
												<th>Title</th>
												<th width="70px">
													Author
												</th>
											</tr>
										</thead>

										<tbody>
											<tr
												v-for="(lesson,index) in recentLessons"
												:key="index"
												style="cursor: pointer;"
												@click="viewLesson(lesson.id)">
												<td>
													<img
														:src="lesson.cover"
														class="img-thumbnail"
														width="100px"
														height="75px">
												</td>
												<td>{{ lesson.hero }}</td>
												<td>{{ lesson.title }}</td>
												<td
													width="70px">
													{{ `${lesson.author.first_name} ${lesson.author.last_name}` }}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div v-if="recentUsers && loading === false"
							class="card">
							<div class="card-body">
								<h5 class="card-title">
									Recent Users
								</h5>
								<div class="table-responsive">
									<table class="table table-hover">
										<thead class="thead-light">
											<tr>
												<th>Avatar</th>
												<th>Name</th>
												<th>Email</th>
												<th>Role</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(user,index) in recentUsers"
												:key="index"
												style="cursor: pointer;"
												@click="viewUser(user.id)">
												<td>
													<img
														:src="user.avatar ? user.avatar : '/images/default-profile.png'"
														class="img-thumbnail"
														width="100px"
														height="75px">
												</td>
												<td>{{ `${user.first_name} ${user.last_name}` }}</td>
												<td>{{ user.email }}</td>
												<td>{{ user.roles[0] ? user.roles[0].display_name : "Not assigned a role" }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			countStatistics: {},
			recentLessons: null,
			recentUsers: null,
			loading: true
		};
	},
	created() {
		this.fetchCount();
		this.fetchRecents();
	},
	updated() {
		this.countStatistics === null ? this.fetchCount() : null;
		this.recentLessons === null || this.recentUsers === null
			? this.fetchRecents()
			: null;
	},
	methods: {
		viewLesson(id) {
			return this.$router.push({
				path: `/admin/lessons/${id}`
			});
		},
		viewUser(id) {
			return this.$router.push({
				path: `/admin/users/${id}`
			});
		},
		fetchCount() {
			return axios
				.get("/api/statistics/count/all")
				.then(({ data }) => {
					this.loading = false;
					this.countStatistics = { ...data };
				})
				.catch(error => {
					this.loading = false;
					return toastr["error"]("Could not fetch count statistics");
				});
		},
		fetchRecents() {
			const recentLessonResponse = axios.get("/api/statistics/latest/lesson");
			const recentUserResponse = axios.get("/api/statistics/latest/user");

			Promise.all([recentLessonResponse, recentUserResponse])
				.then(values => {
					const [recentLessonData, recentUserData] = values;
					this.recentLessons = recentLessonData.data;
					this.recentUsers = recentUserData.data;
					this.loading = false;
				})
				.catch(error => {
					this.loading = false;
					return toastr["error"]("Could not fetch Recent lessons and users");
				});
		},
		routeToPage(page) {
			return this.$router.push({
				path: page
			});
		}
	}
};
</script>

<style scoped>
.page {
	padding: 30px 60px 60px 30px;
	background-color: white;
	border-radius: 10px;
}

.white-text {
	color: white;
}

strong {
	font-size: 2em;
}
</style>

