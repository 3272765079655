<template>
	<router-view />
</template>

<script>
// import AppHeader from "./header";
import { ability } from "../vuex/index";
export default {
	components: {
		// AppHeader
	},
	beforeRouteEnter(to, from, next) {
		if (ability.can("view", "student-dashboard")) {
			return next();
		}
		return next({
			path: "/403"
		});
	}
};
</script>

<style scoped>
	body {
		margin: 0;
	}

	.topnav {
		overflow: hidden;
		background-color: #333333;
	}

	.topnav a {
		display: block;
		float: left;
		padding: 14px 16px;
		font-size: 17px;
		color: #f2f2f2;
		text-align: center;
		text-decoration: none;
	}

	.active {
		color: white;
		background-color: #4caf50;
	}

	.topnav .icon {
		display: none;
	}

	.dropdown {
		float: left;
		overflow: hidden;
	}

	.dropdown .dropbtn {
		padding: 14px 16px;
		margin: 0;
		font-family: inherit;
		font-size: 17px;
		color: white;
		background-color: inherit;
		border: none;
		outline: none;
	}

	.dropdown-content {
		position: absolute;
		z-index: 1;
		display: none;
		min-width: 160px;
		background-color: #f9f9f9;
		box-shadow: 1px 8px 16px 1px rgba(0, 0, 0, 0.2);
	}

	.dropdown-content a {
		display: block;
		float: none;
		padding: 12px 16px;
		color: black;
		text-align: left;
		text-decoration: none;
	}

	.topnav a:hover,
	.dropdown:hover .dropbtn {
		color: white;
		background-color: #555555;
	}

	.dropdown-content a:hover {
		color: black;
		background-color: #dddddd;
	}

	.dropdown:hover .dropdown-content {
		display: block;
	}

	@media screen and (max-width: 600px) {
		.topnav a:not(:first-child),
		.dropdown .dropbtn {
			display: none;
		}

		.topnav a.icon {
			display: block;
			float: right;
		}
	}

	@media screen and (max-width: 600px) {
		.topnav.responsive {
			position: relative;
		}

		.topnav.responsive .icon {
			position: absolute;
			top: 0;
			right: 0;
		}

		.topnav.responsive a {
			display: block;
			float: none;
			text-align: left;
		}

		.topnav.responsive .dropdown {
			float: none;
		}

		.topnav.responsive .dropdown-content {
			position: relative;
		}

		.topnav.responsive .dropdown .dropbtn {
			display: block;
			width: 100%;
			text-align: left;
		}
	}
</style>
