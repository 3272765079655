<template>
	<div id="lesson"
		 class="container">
		<h2 v-if="lesson != null"
			class="page-title">
			<span>Lesson:</span>
			<router-link :to="'/lesson/' + lesson.id">
				<i
					v-if="lesson.type"
					:class="type_icon"
					:title="lesson.type"
					class="fas"
					data-toggle="tooltip"/>
				{{ lesson.title }}
			</router-link>
			<span v-if="lesson.group" class="badge badge-pill badge-info text-light">{{ lesson.group.name }}</span>
			<i
				v-if="completed && user_type === 'student'"
				class="fa-check-circle fas text-success"
				data-toggle="tooltip"
				title="lesson completed"/>
			<p v-if="lesson.classification"
			   class="classification">
				{{ lesson.classification }}
			</p>
		</h2>

		<div class="content-container">
			<router-view/>
		</div>
	</div>
</template>

<script>
import {mapActions} from "vuex";
import {LessonTypeEnum} from "../../config/enums";
import EventBus from "../../utility/eventbus";

export default {
	name: "Lesson",

	data() {
		return {
			playlist: null,
			lesson: null,
			type_icon: "",
			LessonTypeEnum: LessonTypeEnum,
			completed: false
		};
	},

	computed: {
		activity() {
			return this.$store.getters["auth/getUserActivity"];
		},
		user_type() {
			return this.$store.getters["auth/getUserType"];
		},
	},

	watch: {
		activity: {
			handler() {
				return this.isCompleted();
			},
			deep: true
		},
		lesson: {
			handler() {
				if (this.lesson.hero) {
					this.setConfig({
						page_background:
							"/images/hero/halftones/" + this.lesson.hero + ".png"
					});
					this.$nextTick(function () {
						$("[data-toggle='tooltip']").tooltip();
					});
				}
			},
			deep: true,
		},
		$route: {
			deep: true,
			handler() {
				if (this.$route.name !== "showLesson" || this.$route.name !== "showLessonContent") {
					this.lesson = null;
				}
			},
		}
	},

	mounted() {
		EventBus.$on("SET-LESSON", (data) => {
			this.lesson = data;
		});
		EventBus.$on("SET-PLAYLIST", (data) => {
			this.playlist = data;
		});
		EventBus.$on("REFRESH-USER", () => {
			this.isCompleted();
			this.$forceUpdate();
		});
	},

	methods: {
		...mapActions({
			setConfig: "config/setConfig",
		}),

		isCompleted() {
			if (Array.isArray(this.activity)) {
				let arr = [...this.activity].filter(
					activity => activity.lesson.id === this.lesson.id
				);
				if (arr[0] !== undefined && arr[0].completed_timestamp !== null) {
					this.completed = true;
				}
			}

		},

		fetch() {
			if (this.$router.currentRoute.name !== "createLesson" &&
				this.$router.currentRoute.name !== "createLessonContent" &&
				this.$router.currentRoute.name !== "createPlaylist" &&
				this.$router.currentRoute.name !== "allLessons"
			) {
				this.isCompleted();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
#lesson {
	padding: 0 !important;

	.classification {
		float: right;
		padding: 10px;
		margin: auto 20px;
		font-size: 13px;
		color: #ffffff;
		text-transform: uppercase;
		background-color: $theme;

		@include breakpoint($xs) {
			display: none;
		}
	}
}
</style>


<style lang="scss">
#default-page {
	background-size: 80% !important;
}
</style>
