<template>
	<div>
		<div v-if="group"
			id="group-thumbnail"
			@click="routeToViewGroup()">
			<div class="page-section">
				<h3>{{ group.name }}</h3>
				
				<p>{{ group.description }}</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {

	name: "GroupThumbnail",

	props: {
		group: {
			type: Object,
			default: null,
		},
	},

	data() {
		return {
		};
	},
	
	computed: {
	},
	
	mounted() {
	},

	methods: {
		routeToViewGroup() {
			return this.$router.push({
				name: "showGroup",
				params: {
					group_id: this.group.id,
				}
			});
		},
		
	}

};
</script>

<style lang="scss" scoped>
	#group-thumbnail {
		margin-bottom: 30px;
	}
</style>
