<template>
	<b-card align="left"
		title="Forgot Password">
		<b-form @submit.prevent="submit">
			<br>

			<b-form-group id="email">
				<b-form-input id="email"
					v-model="passwordForm.email"
					dusk="email"
					placeholder="Email"
					required
					type="email" />
			</b-form-group>

			<br>

			<b-form-group>
				<b-button id="submit"
					block
					dusk="submit"
					type="submit">
					<i v-if="isSaving"
						class="fas fa-circle-notch fa-spin" />
					Reset Password
				</b-button>
			</b-form-group>

			<div class="form-group m-b-0">
				<div class="col-sm-12 text-center">
					<p>
						Back to Login?
						<router-link class="text-info m-l-5"
							dusk="login"
							to="/login">
							<b>Sign In</b>
						</router-link>
					</p>
				</div>
			</div>
		</b-form>
	</b-card>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			passwordForm: {
				email: ""
			},
			isSaving: false
		};
	},
	mounted() {
	},
	methods: {
		submit(e) {
			this.isSaving = true;
			axios
				.post("/api/auth/password", this.passwordForm)
				.then(({message}) => {
					toastr["success"](message);
					this.$router.push("/login");
					this.isSaving = false;
				})
				.catch(({message}) => {
					toastr["error"](message);
					this.isSaving = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
p {
	font-size: $font-size-base;
	font-family: $font-family-base;
}
</style>
