<template>
	<div id="admin-wrapper"
		class="wrapper">
		<div class="sidenav">
			<router-link to="/admin/overview">
				Overview
			</router-link>
			<router-link v-if="$can('read', 'content')"
				to="/admin/contents">
				Content
			</router-link>
			<router-link v-if="$can('read', 'lessons')"
				to="/admin/lessons">
				Lessons
			</router-link>
			<router-link v-if="$can('read', 'playlists')"
				to="/admin/playlists">
				Playlists
			</router-link>
			<router-link v-if="$can('read', 'users')"
				to="/admin/users">
				Users
			</router-link>
			<router-link v-if="$can('read', 'plans')"
				to="/admin/plans">
				Plans
			</router-link>
			<router-link v-if="$can('read', 'groups')" to="/admin/groups">
				Groups
			</router-link>
			<router-link to="/admin/media">
				Media Manager
			</router-link>
			<router-link v-if="$can('read', 'authorizations')"
				to="/admin/authorizations">
				IAM
			</router-link>
			<router-link v-if="$can('read', 'uploads')"
				to="/admin/bulk-uploads">
				Bulk Operations
			</router-link>
			<router-link v-if="$can('read', 'announcements')" to="/admin/announcements">
				Announcements
			</router-link>
		</div>

		<!-- Page content -->
		<div class="main">
			<div v-if="showBackButton" class="clearfix">
				<div class="float-left">
					<button class="btn btn-sm btn-primary" @click="$router.go(-1);">
						<i class="fas fa-arrow-left" /> Back
					</button>
				</div>
				<div class="float-right" />
			</div>
			<br>
			<router-view />
		</div>
	</div>
</template>

<script>
import { ability } from "../config/ability";

export default {
	beforeRouteEnter(to, from, next) {
		if (ability.can("view", "admin")) {
			return next();
		}
		return next({ path: "/403" });
	},
	data() {
		return {
			showBackButton: false,
		};
	},
	mounted() {
		this.toggleBackButton();
	},
	updated() {
		this.toggleBackButton();
	},
	methods: {
		toggleBackButton() {
			const baseRoutes = [
				"/admin/overview",
				"/admin/contents",
				"/admin/lessons",
				"/admin/playlists",
				"/admin/media",
				"/admin/users",
				"/admin/authorizations",
				"/admin/bulk-uploads",
				"/admin/plans",
				"/admin/groups",
				"/admin/announcements"
			];
			this.showBackButton = !baseRoutes.includes(this.$route.path);
		},
	}
};
</script>

<style lang="scss" scoped>
	.sidenav {
		/* Set the width of the sidebar */
		position: fixed;

		/* Stay on top */
		top: 0;

		/* Stay at the top */
		left: 0;

		/* Fixed Sidebar (stay in place on scroll) */
		z-index: 1;

		/* Full-height: remove this if you want "auto" height */
		width: 200px;
		height: 100%;

		/* Disable horizontal scroll */
		padding-top: 20px;
		margin-top: 130px;

		/* Black */
		overflow-x: hidden;
		background-color: #2bb5dd;
	}

	/* The navigation menu links */
	.sidenav a {
		display: block;
		padding: 6px 8px 6px 16px;
		font-size: 20px;
		color: #f5f5f5;
		text-decoration: none;
	}

	.sidenav a.router-link-exact-active {
		color: #2bb5dd;
		background-color: white;
	}

	.sidenav a.active {
		color: #2bb5dd;
		background-color: white;
	}

	/* When you mouse over the navigation links, change their color */
	.sidenav a:hover {
		color: #2bb5dd;
		background-color: white;
	}

	/* Style page content */
	.main {
		padding-left: 30px;

		/* Same as the width of the sidebar */
		// padding: 0px 10px;
	}

	/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
	@media screen and (max-height: 450px) {
		.sidenav {
			padding-top: 15px;
		}

		.sidenav a {
			font-size: 18px;
		}
	}
</style>
<style lang="scss">
	/* .page-wrapper > .container-fluid{ */

	/* padding-right: 20px; */

	/* } */
</style>
